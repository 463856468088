import { z } from 'zod'
import { AsyncFn, IsoDateString, LocalDate, LocalDateString, TimeZoneId } from '../../../common'
import { MaintenancePlanCancellationReasonType, guidSchema, localDateSchema } from '../../../contracts'
import { AccountGuid } from '../../Accounts/Account'
import { CompanyGuid, CompanyGuidContainer, ForCompany, ForCompanyUser } from '../../Company/Company'
import { ICompanyCreate } from '../../Company/CompanyAtoms'
import { MaintenancePlanGuid } from '../../MaintenancePlans/MaintenancePlanTypes'
import { PricebookTaxRateGuid } from '../../Pricebook/PricebookTypes'
import { Guid, GuidAndReferenceNumber, bzOptional } from '../../common-schemas'
import { PaymentMethod } from '../Payments/PaymentTypes'

export enum PaymentSubscriptionInterval {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  YEARLY = 'YEARLY',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export const paymentSubscriptionIntervalDisplayNames = {
  [PaymentSubscriptionInterval.MONTHLY]: 'Monthly',
  [PaymentSubscriptionInterval.QUARTERLY]: 'Quarterly',
  [PaymentSubscriptionInterval.YEARLY]: 'Yearly',
  [PaymentSubscriptionInterval.NOT_APPLICABLE]: 'Not Applicable',
}

export const NumMonthsPerInterval = {
  [PaymentSubscriptionInterval.YEARLY]: 12,
  [PaymentSubscriptionInterval.MONTHLY]: 1,
  [PaymentSubscriptionInterval.QUARTERLY]: 3,
  [PaymentSubscriptionInterval.NOT_APPLICABLE]: 99999999,
}

export enum PaymentSubscriptionStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  PAST_DUE = 'PAST_DUE',
}

export type PaymentSubscriptionGuid = Guid
export type PaymentSubscriptionGuidContainer = {
  paymentSubscriptionGuid: PaymentSubscriptionGuid
}

export type PaymentSubscriptionRecord = {
  readonly paymentSubscriptionGuid: PaymentSubscriptionGuid
  readonly companyGuid: CompanyGuid
  readonly accountGuid: AccountGuid
  readonly billingAnchor: LocalDate
  readonly billingInterval: PaymentSubscriptionInterval
  /** Price per Payment */
  readonly priceUsd: number
  readonly paymentMethod: PaymentMethod
  readonly paymentMethodAdditionalInfo: string
  readonly externalCustomerId: string
  readonly externalSubscriptionId: string
  readonly externalPaymentMethodId: string
  readonly startedAt: IsoDateString
  readonly canceledAt?: IsoDateString
  readonly terminatesAt?: IsoDateString
  readonly pricebookTaxRateGuid?: PricebookTaxRateGuid
}

export type PaymentSubscriptionStatusRecord = CompanyGuidContainer & {
  readonly paymentSubscriptionGuid: Guid
  readonly subscriptionStatus: PaymentSubscriptionStatus
  readonly subscriptionStatusDetail?: string
  readonly occurredAt: IsoDateString
}

export type PaymentSubscriptionInvoiceRecord = {
  readonly paymentSubscriptionGuid: Guid
  readonly invoiceGuid: Guid
  readonly periodStartAt: IsoDateString
  readonly periodEndAt: IsoDateString
}

export const beginMaintenancePlanPaymentSubscriptionDtoSchema = z.object({
  paymentSubscriptionGuid: guidSchema,
  companyGuid: guidSchema,
  merchantId: z.string(),
  accountGuid: guidSchema,

  activationDate: localDateSchema,
  // NOTE: this is deliberately "nullable" not "optional". You MUST specify a tax rate.
  // If you put `null`, that's the same as "untaxed". Because failing to pay sales tax
  // is literally a crime, we want the invoker to EXPLICITLY state that the subscription
  // is not to be taxed.
  pricebookTaxRateGuid: guidSchema.nullable(),
  paymentInterval: z.nativeEnum(PaymentSubscriptionInterval),
  periodTotalPaymentPriceUsc: z.number().int().positive(),

  paymentMethodId: z.string(),

  // If this is passed in, we're using an existing payment method and don't need to attach the paymentMethodId to a Tilled customer
  paymentMethodRecordGuid: bzOptional(guidSchema),

  paymentMethod: z.nativeEnum(PaymentMethod),
  /** (i.e. last four of CC/ACH, brand of CC) */
  paymentMethodAdditionalInfo: z.string(),

  maintenancePlanGuid: guidSchema,

  suppressActivationEmail: bzOptional(z.boolean()),

  customBillingStartDate: bzOptional(localDateSchema),
  numDaysUntilAutoCancelation: bzOptional(z.number().int().positive()),
})

export type BeginMembershipPaymentSubscriptionRequestDto = z.infer<
  typeof beginMaintenancePlanPaymentSubscriptionDtoSchema
>

export const cancelMaintenancePlanPaymentSubscriptionDtoSchema = z.object({
  paymentSubscriptionGuid: guidSchema,
  companyGuid: guidSchema,
  merchantId: z.string(),
  accountGuid: guidSchema,
  cancellationReason: z.string(),
  cancellationReasonType: z.nativeEnum(MaintenancePlanCancellationReasonType),
  suppressCancellationEmail: bzOptional(z.boolean()),
  shouldExpireVisitsImmediately: bzOptional(z.boolean()),
  maintenancePlanGuid: guidSchema,
})

export type CancelMembershipPaymentSubscriptionRequestDto = z.infer<
  typeof cancelMaintenancePlanPaymentSubscriptionDtoSchema
>

export type PaymentSubscriptionWriter = AsyncFn<ForCompanyUser<PaymentSubscriptionRecord>>
export type PaymentSubscriptionStatusWriter = AsyncFn<PaymentSubscriptionStatusRecord>

export type PaymentSubscriptionInvoiceWriter = AsyncFn<PaymentSubscriptionInvoiceRecord>
export type MaintenancePlanSubscriptionCreator = AsyncFn<ForCompanyUser<BeginMembershipPaymentSubscriptionRequestDto>>
export type ExternalSubscriptionCreator = AsyncFn<
  ForCompanyUser<ExternalBeginSubscriptionRequest>,
  ExternalBeginSubscriptionResponse
>
export type ExternalSubscriptionUpdater = AsyncFn<ForCompanyUser<ExternalUpdateSubscriptionRequest>>

export type PaymentSubscriptionReader = AsyncFn<ForCompany<PaymentSubscriptionGuidContainer>, PaymentSubscriptionRecord>

export type PaymentSubscriptionInfoForCancelationReaderRequest = {
  externalSubscriptionId: string
}
export type PaymentSubscriptionInfoForCancelationReaderResponse = {
  companyGuid: CompanyGuid
  paymentSubscriptionGuid: PaymentSubscriptionGuid
}

export type PaymentSubscriptionInfoForCancelationReader = AsyncFn<
  PaymentSubscriptionInfoForCancelationReaderRequest,
  PaymentSubscriptionInfoForCancelationReaderResponse
>

export type MaintenancePlanSubscriptionCanceler = AsyncFn<ForCompanyUser<CancelMembershipPaymentSubscriptionRequestDto>>

export const updatePaymentSubscriptionContextSchema = z.object({
  paymentSubscriptionGuid: guidSchema,
  companyGuid: guidSchema,
  merchantId: z.string(),
})

export const updatePaymentSubscriptionMethodRequestSchema = z.object({
  paymentSubscriptionGuid: guidSchema,
  companyGuid: guidSchema,
  merchantId: z.string(),

  type: z.literal('UPDATE_PAYMENT_METHOD'),

  previousPaymentMethodId: bzOptional(z.string()),
  paymentMethodId: z.string(),
  paymentMethod: z.nativeEnum(PaymentMethod),
  /** (i.e. last four of CC/ACH, brand of CC) */
  paymentMethodAdditionalInfo: z.string(),
})
export type UpdatePaymentSubscriptionMethodRequest = z.infer<typeof updatePaymentSubscriptionMethodRequestSchema>

export const updatePaymentSubscriptionAmountRequestSchema = z.object({
  paymentSubscriptionGuid: guidSchema,
  companyGuid: guidSchema,
  merchantId: z.string(),

  type: z.literal('UPDATE_PAYMENT_AMOUNT'),

  intervalPriceUsc: z.number().int().positive(),
})
export type UpdatePaymentSubscriptionAmountRequest = z.infer<typeof updatePaymentSubscriptionAmountRequestSchema>

export const updatePaymentSubscriptionRequestSchema = updatePaymentSubscriptionMethodRequestSchema.or(
  updatePaymentSubscriptionAmountRequestSchema,
)

export type UpdatePaymentSubscriptionRequest = z.infer<typeof updatePaymentSubscriptionRequestSchema>

export type UpdateMaintenancePlanSubscription = AsyncFn<ForCompanyUser<UpdatePaymentSubscriptionRequest>>

export const retryPaymentSubscriptionPaymentRequestDtoSchema = z.object({
  paymentSubscriptionGuid: guidSchema,
  companyGuid: guidSchema,
  merchantId: z.string(),
})

export type RetryPaymentSubscriptionPaymentRequestDto = z.infer<typeof retryPaymentSubscriptionPaymentRequestDtoSchema>

export type RetryMaintenancePlanSubscriptionPayment = AsyncFn<RetryPaymentSubscriptionPaymentRequestDto>

export type PaymentSubscriptionWithPurchaseItemLinks = PaymentSubscriptionGuidContainer & {
  readonly maintenancePlanGuid: MaintenancePlanGuid
}

export type IMaintenancePlanSubscriptionInvoiceCreator = ICompanyCreate<
  PaymentSubscriptionWithPurchaseItemLinks,
  GuidAndReferenceNumber
>

export type ExternalBeginSubscriptionRequest = {
  readonly paymentSubscriptionGuid: PaymentSubscriptionGuid
  readonly timeZoneId: TimeZoneId
  readonly merchantId: string
  readonly accountGuid: AccountGuid
  readonly paymentMethodId: string
  readonly timeInterval: PaymentSubscriptionInterval
  readonly intervalPriceUsc: number

  // If this is passed in, we're using an existing payment method and don't need to
  // go through the process of creating a new Tilled customer and attaching the payment method
  readonly externalCustomerId?: string

  readonly maintenancePlanGuid: MaintenancePlanGuid

  /** @param customBillingStartDate - Defaults to Today, if not supplied */
  readonly customBillingStartDate?: LocalDateString

  readonly cancelAt: IsoDateString | undefined
}

type ExternalUpdateBaseRequest = {
  readonly merchantId: string
  readonly externalSubscriptionId: string
  readonly externalCustomerId: string
}

type ExternalUpdatePaymentAmountRequest = ExternalUpdateBaseRequest & {
  readonly type: 'UPDATE_PAYMENT_AMOUNT'
  readonly previousPaymentMethodId: string
  readonly paymentMethodId?: string
  readonly intervalPriceUsc: number
}

type ExternalUpdatePaymentMethodRequest = ExternalUpdateBaseRequest & {
  readonly type: 'UPDATE_PAYMENT_METHOD'
  readonly previousPaymentMethodId?: string
  readonly paymentMethodId: string
  readonly intervalPriceUsc?: number
}

export type ExternalUpdateSubscriptionRequest = ExternalUpdatePaymentAmountRequest | ExternalUpdatePaymentMethodRequest

export type ExternalBeginSubscriptionResponse = {
  readonly externalCustomerId: string
  readonly externalSubscriptionId: string
  readonly occurredAt: IsoDateString
}

export type ExternalSubscriptionIdRequest = {
  readonly merchantId: string
  readonly externalSubscriptionId: string
}

export type ExternalSubscriptionStatusResponse = {
  readonly status: PaymentSubscriptionStatus
  readonly updatedAt: IsoDateString
}

export type ExternalSubscriptionStatusReader = AsyncFn<
  ExternalSubscriptionIdRequest,
  ExternalSubscriptionStatusResponse
>
