import { isNullish } from '@breezy/shared'
import { useCallback, useState } from 'react'
import { useSyncInvoice } from '../../../hooks/useSyncInvoice'
import { useSyncPayout } from '../../../hooks/useSyncPayout'
import { useQuickbooksDesktopState } from '../../../providers/QuickbooksDesktopOnlineWrapper'
import { typedMemo } from '../../../utils/react-utils'
import {
  BasicQuickbooksSyncButton,
  IN_SYNC_TOOLTIP,
  OUT_OF_SYNC_TOOLTIP,
  QuickbooksSyncButtonBaseProps,
  UNKNOWN_TOOLTIP,
} from '../QuickbooksOnline/QuickbooksSyncButton'

const OFFLINE_TOOLTIP =
  'Connection is offline. Click to sync to Quickbooks at next connection.'
const buildOfflineTooltip = (isStale: boolean | undefined) =>
  isNullish(isStale)
    ? `${UNKNOWN_TOOLTIP} ${OFFLINE_TOOLTIP}`
    : isStale
    ? `${OUT_OF_SYNC_TOOLTIP} ${OFFLINE_TOOLTIP}`
    : `${IN_SYNC_TOOLTIP} ${OFFLINE_TOOLTIP}`

type QuickbooksAccountingSyncInvoiceButtonProps =
  QuickbooksSyncButtonBaseProps & {
    invoiceGuid: string
    invoiceDisplayId?: string
    isStale?: boolean
    loading?: boolean
    onSuccess?: () => void
  }

export const QuickbooksAccountingSyncInvoiceButton = typedMemo(
  ({
    invoiceGuid,
    invoiceDisplayId,
    isStale,
    loading,
    onSuccess,
    ...props
  }: QuickbooksAccountingSyncInvoiceButtonProps) => {
    const { isOnline, notifyOffline } = useQuickbooksDesktopState()

    const { onSyncClick, loading: syncLoading } = useSyncInvoice({
      accountingIntegrationType: 'QBD',
      invoiceGuid,
      invoiceDisplayId,
      onSuccess,
      onDeferred: notifyOffline,
    })

    return (
      <BasicQuickbooksSyncButton
        {...props}
        isStale={isStale}
        loading={loading || syncLoading}
        overrideTooltip={isOnline ? undefined : buildOfflineTooltip(isStale)}
        onSyncClick={onSyncClick}
      />
    )
  },
)

type QuickbooksAccountingSyncPayoutButtonProps =
  QuickbooksSyncButtonBaseProps & {
    payoutGuid: string
    isStale?: boolean
    loading?: boolean
    onSuccess?: () => void
  }

export const QuickbooksAccountingSyncPayoutButton = typedMemo(
  ({
    payoutGuid,
    isStale,
    loading,
    onSuccess,
    ...props
  }: QuickbooksAccountingSyncPayoutButtonProps) => {
    const { isOnline, notifyOffline } = useQuickbooksDesktopState()
    const [localIsStale, setLocalIsStale] = useState(isStale)
    const localOnSuccess = useCallback(() => {
      setLocalIsStale(false)
      onSuccess?.()
    }, [onSuccess])

    const { onSyncClick, loading: syncLoading } = useSyncPayout({
      accountingIntegrationType: 'QBD',
      payoutGuid,
      onSuccess: localOnSuccess,
      onDeferred: notifyOffline,
    })

    return (
      <BasicQuickbooksSyncButton
        {...props}
        isStale={localIsStale}
        loading={loading || syncLoading}
        overrideTooltip={
          isOnline ? undefined : buildOfflineTooltip(localIsStale)
        }
        onSyncClick={onSyncClick}
      />
    )
  },
)
