import { MantineProvider } from '@mantine/core'
import '@mantine/core/styles.css'
import '@mantine/tiptap/styles.css'
import '@mobiscroll/react/dist/css/mobiscroll.min.css'
import { ConfigProvider as AntConfigProvider, App } from 'antd'
import 'antd/dist/reset.css'
import React, { Suspense, useCallback } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import ReactDOM from 'react-dom/client'
import {
  ErrorBoundary,
  ErrorBoundaryPropsWithComponent,
} from 'react-error-boundary'
import { CSModeWrapper } from '../../components/Admin/CSMode/CSModeWrapper'
import ErrorModal from '../../components/ErrorModal/ErrorModal'
import { ErrorFallback } from '../../components/GeneralErrorFallback/GeneralErrorFallback'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { PaymentStatusModalProvider } from '../../components/PaymentWorkflow/providers/PaymentStatusModalProvider'
import { PaymentStatusProvider } from '../../components/PaymentWorkflow/providers/PaymentStatusProvider'
import { TwilioPhoneProvider } from '../../components/PhoneIntegrated/useTwilioPhone'
import { ConfigProvider } from '../../config'
import {
  BreakpointContextWrapper,
  useIsTouchScreen,
} from '../../hooks/useIsMobile'
import { PricebookPhotosEnabledProvider } from '../../hooks/useIsPricebookPhotosEnabled'
import AuthWrapper from '../../providers/AuthWrapper'
import { CompanyFinancialConfigWrapper } from '../../providers/CompanyFinancialConfigWrapper'
import { ForceHardRefreshWrapper } from '../../providers/ForceHardRefreshWrapper'
import { GoogleMapsWrapper } from '../../providers/GoogleMaps'
import { GooglePlacesWrapper } from '../../providers/GoogleMapsPlaces'
import { LaunchDarklyWrapper } from '../../providers/LaunchDarklyWrapper'
import { PricebookProvider } from '../../providers/PricebookProvider'
import { PrincipalUserWrapper } from '../../providers/PrincipalUser'
import { QuickbooksDesktopOnlineWrapper } from '../../providers/QuickbooksDesktopOnlineWrapper'
import { TimeClockProvider } from '../../providers/TimeClockProvider/TimeClockProvider'
import { TrpcWrapper } from '../../providers/TrpcWrapper'
import UrqlWrapper from '../../providers/UrqlWrapper'
import '../../themes/ant-override.less'
import '../../themes/tailwind.less'
import '../../themes/theme.less'
import { postErrorAlert } from '../../utils/GraphqlAlerts'

// export const excludeAlertsFor = ['Failed to fetch dynamically imported module']

const TopLevelErrorWrapper = React.memo<React.PropsWithChildren>(
  ({ children }) => {
    const onError = useCallback<
      NonNullable<ErrorBoundaryPropsWithComponent['onError']>
    >(async (e, info) => {
      console.error('ErrorBoundary onError', e, info)
      // if (
      //   !excludeAlertsFor.some(excludedMessage =>
      //     e.message.includes(excludedMessage),
      //   )
      // ) {
      await postErrorAlert(`FE (top level): ${e.message}`)
      // }
    }, [])

    return (
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={onError}
        onReset={window.location.reload}
      >
        {children}
      </ErrorBoundary>
    )
  },
)

// TODO: add error boundary
export const CommonAppWrapper = React.memo(
  ({ children }: { children: React.ReactNode }) => {
    const isTouchScreen = useIsTouchScreen()
    return (
      <TopLevelErrorWrapper>
        <Suspense
          fallback={
            <div className="flex h-screen w-screen items-center justify-center">
              <LoadingSpinner />
            </div>
          }
        >
          <MantineProvider>
            <AntConfigProvider>
              <App>
                <ConfigProvider>
                  <BreakpointContextWrapper>
                    <ErrorModal>
                      <DndProvider
                        backend={isTouchScreen ? TouchBackend : HTML5Backend}
                      >
                        {children}
                      </DndProvider>
                    </ErrorModal>
                  </BreakpointContextWrapper>
                </ConfigProvider>
              </App>
            </AntConfigProvider>
          </MantineProvider>
        </Suspense>
      </TopLevelErrorWrapper>
    )
  },
)

export const withApp = (app: JSX.Element) => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  )
  root.render(<CommonAppWrapper>{app}</CommonAppWrapper>)
}

type AuthenticatedAppWrapperProps = React.PropsWithChildren

export const AuthenticatedAppWrapper = React.memo<AuthenticatedAppWrapperProps>(
  ({ children }) => (
    <AuthWrapper>
      <TrpcWrapper>
        <GooglePlacesWrapper>
          <GoogleMapsWrapper>
            <PrincipalUserWrapper>
              <CSModeWrapper>
                <LaunchDarklyWrapper>
                  <ForceHardRefreshWrapper>
                    <CompanyFinancialConfigWrapper>
                      <QuickbooksDesktopOnlineWrapper>
                        <UrqlWrapper>
                          <TwilioPhoneProvider>
                            <PricebookProvider>
                              <TimeClockProvider>
                                <PaymentStatusProvider>
                                  <PaymentStatusModalProvider>
                                    <PricebookPhotosEnabledProvider>
                                      {children}
                                    </PricebookPhotosEnabledProvider>
                                  </PaymentStatusModalProvider>
                                </PaymentStatusProvider>
                              </TimeClockProvider>
                            </PricebookProvider>
                          </TwilioPhoneProvider>
                        </UrqlWrapper>
                      </QuickbooksDesktopOnlineWrapper>
                    </CompanyFinancialConfigWrapper>
                  </ForceHardRefreshWrapper>
                </LaunchDarklyWrapper>
              </CSModeWrapper>
            </PrincipalUserWrapper>
          </GoogleMapsWrapper>
        </GooglePlacesWrapper>
      </TrpcWrapper>
    </AuthWrapper>
  ),
)
