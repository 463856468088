import { TimeZoneId } from '@breezy/shared'
import { OnsitePageCollapsibleSection } from 'src/adam-components/OnsitePage/OnsitePageCollapsibleSection'
import {
  AccountCard,
  type AccountCardAccount,
} from 'src/components/Cards/AccountCard'

export interface AccountsCollapsibleV2Props {
  accounts: AccountCardAccount[]
  timezoneId: TimeZoneId
  className?: string
}

export const AccountsCollapsibleV2 = (props: AccountsCollapsibleV2Props) => {
  const { accounts } = props

  return (
    <OnsitePageCollapsibleSection
      smallTitle
      title="Accounts"
      count={accounts.length}
      defaultCollapsed={accounts.length === 0}
      className={props.className}
    >
      <div className="flex flex-col gap-3">
        {accounts.map(account => (
          <AccountCard
            key={account.accountGuid}
            account={account}
            timezoneId={props.timezoneId}
          />
        ))}
      </div>
    </OnsitePageCollapsibleSection>
  )
}
