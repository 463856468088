import { PageHeader } from '@ant-design/pro-components'
import { ComprehensiveMaintenancePlanConfigViewModel } from '@breezy/shared'
import { faScrewdriverWrench } from '@fortawesome/pro-regular-svg-icons'
import { Col, Row } from 'antd'
import { useState } from 'react'
import GqlQueryLoader from '../../components/GqlQueryLoader/GqlQueryLoader'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { Page } from '../../components/Page/Page'
import TrpcQueryLoader from '../../components/TrpcQueryLoader'
import PageTitle from '../../elements/PageTitle/PageTitle'
import ScrollCard from '../../elements/ScrollCard/ScrollCard'
import ThinDivider from '../../elements/ThinDivider'
import { GetCompanyBillingProfileFrontendQuery } from '../../generated/user/graphql'
import { trpc } from '../../hooks/trpc'
import { useFeatureFlag } from '../../hooks/useFeatureFlags'
import { useGetCompanyBillingProfile } from '../../hooks/useGetCompanyBillingProfile'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { m } from '../../utils/react-utils'
import MaintenancePlanDefinitionsView from './MaintenancePlanDefinitionsView'
import { MaintenancePlanEnableView } from './MaintenancePlanEnableView'
import MaintenancePlanRenewalTypesManageMultiYearView from './MaintenancePlanRenewalTypesManageMultiYearView'
import MaintenancePlanRenewalTypesManageView from './MaintenancePlanRenewalTypesManageView'
import MaintenancePlanServiceItemManageView from './MaintenancePlanServiceItemManageView'
import MaintenancePlanTaxRateManageView from './MaintenancePlanTaxRateManageView'

const SubComponentLoadingSpinner = () => (
  <div className="min-h-[320px] w-full">
    <LoadingSpinner />
  </div>
)

const MaintenancePlanConfigPage = m(() => {
  const companyGuid = useExpectedCompanyGuid()
  const [maintenancePlansEnabled, setMaintenancePlansEnabled] = useState(false)
  const multiYearEnabled = useFeatureFlag('multi-year-maintenance-plans')

  const query = trpc.maintenancePlans['maintenance-plans-config:get'].useQuery(
    undefined,
    {
      onSuccess(data: ComprehensiveMaintenancePlanConfigViewModel) {
        setMaintenancePlansEnabled(data.maintenancePlansEnabled)
      },
      onError() {
        setMaintenancePlansEnabled(false)
      },
    },
  )

  const { getCompanyBillingProfileQuery, refetch } =
    useGetCompanyBillingProfile(companyGuid)

  return (
    <Page requiresCompanyUser style={{ padding: 0, overflow: 'hidden' }}>
      <PageHeader
        title={
          <PageTitle title="Maintenance Plans" icon={faScrewdriverWrench} />
        }
      />
      <ScrollCard hasPageHeading>
        <Col>
          <Row className="flex">
            <TrpcQueryLoader
              query={query}
              loadingComponent={<SubComponentLoadingSpinner />}
              render={_viewModel => (
                <MaintenancePlanEnableView
                  companyMaintenancePlansEnabled={maintenancePlansEnabled}
                  refetchMaintenancePlanConfig={query.refetch}
                />
              )}
            />

            <TrpcQueryLoader
              query={query}
              loadingComponent={<SubComponentLoadingSpinner />}
              render={viewModel =>
                maintenancePlansEnabled ? (
                  <GqlQueryLoader
                    query={getCompanyBillingProfileQuery}
                    render={(data: GetCompanyBillingProfileFrontendQuery) => (
                      <>
                        <MaintenancePlanTaxRateManageView
                          companyGuid={companyGuid}
                          billingProfiles={data.billingProfiles}
                          onMutate={refetch}
                        />
                        <ThinDivider />
                        <MaintenancePlanServiceItemManageView
                          companyGuid={companyGuid}
                          billingProfiles={data.billingProfiles}
                          onMutate={refetch}
                        />
                        <ThinDivider />
                        {multiYearEnabled && (
                          <MaintenancePlanRenewalTypesManageMultiYearView
                            companyGuid={companyGuid}
                            billingProfiles={data.billingProfiles}
                            onMutate={refetch}
                          />
                        )}
                        {!multiYearEnabled && (
                          <MaintenancePlanRenewalTypesManageView
                            companyGuid={companyGuid}
                            billingProfiles={data.billingProfiles}
                            onMutate={refetch}
                          />
                        )}
                        <ThinDivider />
                        <MaintenancePlanDefinitionsView
                          refetch={query.refetch}
                          planDefinitions={viewModel.plans}
                        />
                      </>
                    )}
                  />
                ) : (
                  <></>
                )
              }
            />
          </Row>
        </Col>
      </ScrollCard>
    </Page>
  )
})

export default MaintenancePlanConfigPage
