import {
  Account,
  AccountType,
  AccountTypeDisplayNames,
  bzExpect,
  truncate,
} from '@breezy/shared'
import { CompanyGuid } from '@breezy/shared/src'
import { Alert, Button } from 'antd'
import classNames from 'classnames'
import { useCallback, useEffect, useState } from 'react'
import { trpc } from '../../../hooks/trpc'
import { useAlgoliaClient } from '../../../hooks/useAlgoliaClient'
import { JobLeadContextView } from '../../../pages/JobLeadsPage/JobLeadContextView'
import { JobLead } from '../../../pages/JobLeadsPage/types'
import { useExpectedCompany } from '../../../providers/PrincipalUser'
import { useErrorModal } from '../../ErrorModal/ErrorModal'
import { LoadingSpinner } from '../../LoadingSpinner'

type JobLeadAccountMatchesProps = {
  onRecommendedAccountSelected: (account: Account) => void
  jobLead: JobLead
}

type MultipleQueriesQuery = Parameters<
  ReturnType<typeof useAlgoliaClient>['client']['search']
>[0][number]

const queryBuilder = (
  companyGuid: CompanyGuid,
  indexName: string,
): ((query: string) => MultipleQueriesQuery) => {
  return query => {
    return {
      indexName,
      // query: 'Sarina Hamill',
      params: {
        // filters: `companyGuid:${expectedCompany.companyGuid}`,
        query,
        filters: `companyGuid:${companyGuid} AND recordType:LOCATION`,
      },
    }
  }
}

type AlgoliaContactRecord = {
  name: string
  emailAddress: string
  phoneNumber: string
}

type AlgoliaRecord = {
  companyGuid: string
  recordType: string
  accountDisplayName: string
  accountType: AccountType
  accountGuid: string
  primaryEmailAddress: string
  primaryPhoneNumber: string
  contacts: AlgoliaContactRecord[]
  tags: string[]
  locationGuid: string
  locationDisplayName: string
  streetAddress: string
  city: string
  state: string
  zipCode: string
  objectID: string
}

export const JobLeadAccountMatches = ({
  jobLead,
  onRecommendedAccountSelected,
}: JobLeadAccountMatchesProps) => {
  const { client, indexName } = useAlgoliaClient()
  const expectedCompany = useExpectedCompany()
  const q = queryBuilder(expectedCompany.companyGuid, indexName)
  const [algoliaFetchState, setAlgoliaFetchState] = useState<
    'idle' | 'fetching' | 'success' | 'error'
  >('idle')
  const [fetchSelectedAccountState, setFetchSelectedAccountState] = useState<
    'idle' | 'fetching' | 'success' | 'error'
  >('idle')
  const [recommendations, setRecommendations] = useState<AlgoliaRecord[]>([])

  const fetchRecommendations = useCallback(async () => {
    const searchQueries: MultipleQueriesQuery[] = []
    let counter = -1
    const queries = {
      phone: -1,
      email: -1,
      address: -1,
      fullName: -1,
      lastName: -1,
    }

    if (jobLead?.contactPhoneNumber) {
      searchQueries.push(q(`${jobLead?.contactPhoneNumber}`))
      queries.phone = ++counter
    }

    if (jobLead?.contactEmailAddress) {
      searchQueries.push(q(`${jobLead?.contactEmailAddress}`))
      queries.email = ++counter
    }

    if (jobLead?.serviceAddressLine1) {
      queries.address = ++counter
      searchQueries.push(q(`${jobLead?.serviceAddressLine1}`))
    }

    if (jobLead?.contactLastName) {
      if (jobLead?.contactFirstName) {
        searchQueries.push(
          q(`${jobLead?.contactFirstName} ${jobLead?.contactLastName}`),
        )
        queries.fullName = ++counter
      } else {
        searchQueries.push(q(jobLead?.contactLastName))
        queries.lastName = ++counter
      }
    }

    try {
      setAlgoliaFetchState('fetching')
      const results = await client.search<AlgoliaRecord>(searchQueries)
      const allHits = results.results
        .filter(r => 'hits' in r)
        .flatMap(r => r.hits)
      const allAccountGuids = [
        ...new Set(
          results.results
            .filter(r => 'hits' in r)
            .flatMap(r => {
              return r.hits.map(h => h.accountGuid)
            }),
        ),
      ]

      const accountsWithScores = allAccountGuids.reduce((memo, accountGuid) => {
        let score = 0
        if (queries.phone >= 0) {
          const phoneResults = results.results[queries.phone]
          if ('hits' in phoneResults) {
            if (
              phoneResults.hits.findIndex(h => h.accountGuid === accountGuid) >
              -1
            ) {
              score += 10
            }
          }
        }

        if (queries.email >= 0) {
          const emailResults = results.results[queries.email]
          if ('hits' in emailResults) {
            if (
              emailResults.hits.findIndex(h => h.accountGuid === accountGuid) >
              -1
            ) {
              score += 10
            }
          }
        }

        if (queries.address >= 0) {
          const addressResults = results.results[queries.address]
          if ('hits' in addressResults) {
            if (
              addressResults.hits.findIndex(
                h => h.accountGuid === accountGuid,
              ) > -1
            ) {
              score += 5
            }
          }
        }

        if (queries.fullName >= 0) {
          const fullNameResults = results.results[queries.fullName]
          if ('hits' in fullNameResults) {
            if (
              fullNameResults.hits.findIndex(
                h => h.accountGuid === accountGuid,
              ) > -1
            ) {
              score += 3
            }
          }
        }

        if (queries.lastName >= 0) {
          const lastNameResults = results.results[queries.lastName]
          if ('hits' in lastNameResults) {
            if (
              lastNameResults.hits.findIndex(
                h => h.accountGuid === accountGuid,
              ) > -1
            ) {
              score += 3
            }
          }
        }

        memo.push({ accountGuid, score })
        return memo
      }, [] as { accountGuid: string; score: number }[])

      const accounts = [...accountsWithScores]
        .sort((a, b) => b.score - a.score)
        .slice(0, 10)
        .map(({ accountGuid }) => {
          return bzExpect(allHits.find(hit => hit.accountGuid === accountGuid))
        })

      setRecommendations(accounts)
      setAlgoliaFetchState('success')
    } catch (e) {
      console.error(`There was an error finding algolia search matches`, e)
      setAlgoliaFetchState('error')
    }
  }, [
    client,
    jobLead?.contactEmailAddress,
    jobLead?.contactFirstName,
    jobLead?.contactLastName,
    jobLead?.contactPhoneNumber,
    jobLead?.serviceAddressLine1,
    q,
  ])

  useEffect(() => {
    fetchRecommendations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const companyGuid = expectedCompany.companyGuid
  const trpcClient = trpc.useContext().client
  const errorModal = useErrorModal()

  const onRecommendationSelected = useCallback(
    async (record: AlgoliaRecord) => {
      setFetchSelectedAccountState('fetching')
      let account: Account | undefined = undefined
      try {
        const res = await trpcClient.accounts['accounts:query'].query({
          type: 'by-account-guid',
          companyGuid,
          accountGuid: record.accountGuid,
        })
        account = res.accounts[0]
      } catch (e) {
        setFetchSelectedAccountState('error')
        console.error(`There was an error fetching recommended account`, e)
        errorModal.pushError({
          title: 'Failed to load recommended account',
          content:
            "We're sorry, we encountered an unexpected error fetching the recommended account",
        })
      }

      if (account) {
        setFetchSelectedAccountState('success')
        onRecommendedAccountSelected(account)
      }
    },
    [
      companyGuid,
      errorModal,
      onRecommendedAccountSelected,
      trpcClient.accounts,
    ],
  )

  return (
    <div className="flex w-full flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0">
      <div className="flex-[0_0_58%]">
        <div className="text-base font-semibold">
          Review matching records
          <span className="ml-1 text-bz-gray-700">
            ({recommendations.length})
          </span>
        </div>

        {(algoliaFetchState === 'idle' || algoliaFetchState === 'fetching') && (
          <LoadingSpinner />
        )}

        {algoliaFetchState === 'error' && (
          <Alert type="error" message="Unable to load recommendations" />
        )}

        {algoliaFetchState === 'success' && (
          <div>
            <div className="mt-2">
              {recommendations.length === 0 && (
                <>
                  We were unable to find any existing account matches for the
                  job lead
                </>
              )}
              {recommendations.length > 0 && (
                <>
                  We’ve found possible matches based on the job lead
                  information. Please review and select the correct account to
                  proceed with job creation.
                </>
              )}
            </div>
            <div className="mt-5 w-full rounded-lg border border-solid border-bz-gray-500 bg-white text-base shadow-bz-table">
              {recommendations.length === 0 && (
                <div className="m-4 rounded-md border border-solid border-bz-gray-500 bg-bz-gray-200 p-3 text-center text-bz-gray-700">
                  No matching records found
                </div>
              )}
              {recommendations.length > 0 && (
                <>
                  {recommendations.map((record, i) => {
                    return (
                      <div
                        className={classNames(
                          'flex p-3',
                          i === 0
                            ? ''
                            : 'border-0 border-t border-solid border-bz-gray-500',
                        )}
                        key={i}
                      >
                        <div className="flex-[0_0_50%]">
                          <div className="font-semibold">
                            {record.accountDisplayName}
                          </div>
                          <div className="text-sm">
                            {[
                              AccountTypeDisplayNames[record.accountType],
                              record.streetAddress,
                            ]
                              .filter(Boolean)
                              .join(' • ')}
                          </div>
                        </div>
                        <div className="flex flex-[0_0_50%] items-center">
                          <div className="flex-1 space-y-1">
                            <div className="text-sm">
                              {record.contacts[0]?.name ?? '–'}
                            </div>
                            <div className="text-sm">
                              {truncate(
                                record.primaryPhoneNumber ??
                                  record.primaryEmailAddress ??
                                  '–',
                                22,
                                '...',
                              )}{' '}
                            </div>
                          </div>
                          <div className="min-w-14">
                            <Button
                              loading={fetchSelectedAccountState === 'fetching'}
                              disabled={
                                fetchSelectedAccountState === 'fetching'
                              }
                              onClick={() => onRecommendationSelected(record)}
                            >
                              Select
                            </Button>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="flex-[0_0_42%]">
        <JobLeadContextView jobLead={jobLead} />
      </div>
    </div>
  )
}
