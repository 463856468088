import { PageHeader } from '@ant-design/pro-components'
import { faCode } from '@fortawesome/pro-regular-svg-icons'
import { Button } from 'antd'
import { useState } from 'react'
import { SectionedCard } from 'src/adam-components/SectionedCard/SectionedCard'
import { Page } from 'src/components/Page/Page'
import PageTitle from 'src/elements/PageTitle/PageTitle'
import { HCPCompanyProfileMigrationModal } from './components/HCPCompanyProfileMigrationModal'

export const MigrationToolsSettingsPage = () => {
  const [
    hcpCompanyProfileMigrationModalOpen,
    setHcpCompanyProfileMigrationModalOpen,
  ] = useState(false)

  return (
    <Page requiresCompanyUser className="flex flex-col p-0">
      <PageHeader title={<PageTitle title="Migration Tools" icon={faCode} />} />

      <SectionedCard
        sections={[
          {
            content: (
              <div className="flex flex-col gap-1">
                <h2 className="text-lg font-semibold">HouseCall Pro</h2>

                <Button
                  className="w-max"
                  onClick={() => setHcpCompanyProfileMigrationModalOpen(true)}
                >
                  Generate Company Profile Migration JSON
                </Button>
              </div>
            ),
          },
        ]}
      />

      <HCPCompanyProfileMigrationModal
        open={hcpCompanyProfileMigrationModalOpen}
        onClose={() => setHcpCompanyProfileMigrationModalOpen(false)}
      />
    </Page>
  )
}
