import {
  AccountLocation,
  BzDateFns,
  BzDateTime,
  getAffinityDateByVisitIndex,
  getAffinityDateNameByVisitIndex,
  Guid,
  InstalledEquipmentSummary,
  isNullish,
  MaintenancePlanDefinition,
  nextGuid,
  VisitRequest,
} from '@breezy/shared'
import { faPlus } from '@fortawesome/pro-light-svg-icons'
import { faCalendar, faCalendarCheck } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { usePrevious } from 'react-use'
import { BzSelectOption } from '../../../elements/BzSelect/BzSelectTypes'
import FaIconWithCircularBackground from '../../../elements/FaIconWithCircularBackground/FaIconWithCircularBackground'
import { ReactHookFormItem } from '../../../elements/Forms/ReactHookFormItem'
import { SelectField } from '../../../elements/Forms/SelectField'
import { SwitchField } from '../../../elements/Forms/SwitchField'
import { TextField } from '../../../elements/Forms/TextField'
import StatusTag from '../../../elements/StatusTag/StatusTag'
import ThinDivider from '../../../elements/ThinDivider'
import { useIsMobile } from '../../../hooks/useIsMobile'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import tailwindConfig from '../../../tailwind.config'
import { KeyValueRow } from '../components/KeyValueRow'
import {
  ConfigureMaintenancePlanFormData,
  getAffinityDateOptions,
} from '../configureMaintenancePlanFormSchema'
import { EquipmentCards } from '../LocationOptionCard'
import {
  getNumImpliedYears,
  LocationJob,
  WithLocationJobs,
} from '../MaintenancePlanV3Utils'
import { InstalledEquipmentPicker } from './InstalledEquipmentPicker'

type ConfigureVisitsProps = {
  selectedLocationGuid: Guid
  selectedAccountLocation: WithLocationJobs<AccountLocation>
  selectedPlanType: MaintenancePlanDefinition
  refetch: () => void
}

export const ConfigureVisitsSection = memo<ConfigureVisitsProps>(
  ({
    selectedLocationGuid,
    selectedAccountLocation,
    selectedPlanType,
    refetch,
  }) => {
    const { numVisitCreditsPerYear } = selectedPlanType
    const tzId = useExpectedCompanyTimeZoneId()
    const isMobile = useIsMobile()
    const [rerenderNonce, setRerenderNonce] = useState(0)
    const {
      control,
      formState: { errors },
      watch,
      setValue,
      trigger,
    } = useFormContext<ConfigureMaintenancePlanFormData>()

    const activationDate = watch('activationDate')
    const visits = watch('visits')
    const previousActivationDate = usePrevious(activationDate)
    const numFixedDurationYears = watch('numFixedDurationYears')
    const yearMultiplier = getNumImpliedYears(numFixedDurationYears)

    const { fields } = useFieldArray({
      control,
      name: 'visits',
    })

    const installedEquipment = useMemo(() => {
      return selectedAccountLocation?.location.installedEquipment ?? []
    }, [selectedAccountLocation])

    const [equipmentPickerVisitIndex, setEquipmentPickerVisitIndex] = useState<
      number | undefined
    >(undefined)
    const [visitSelectedEquipments, setVisitSelectedEquipments] = useState<
      Record<number, InstalledEquipmentSummary[]>
    >(
      visits.reduce((acc, visit, index) => {
        acc[index] = visit.installedEquipmentGuids.map(
          guid =>
            installedEquipment.find(e => e.installedEquipmentGuid === guid)!,
        )
        return acc
      }, {} as Record<number, InstalledEquipmentSummary[]>),
    )

    useEffect(() => {
      if (
        !!previousActivationDate &&
        activationDate !== previousActivationDate
      ) {
        // NOTE: Clearing to allow the useEffect below to repopulate the visits correctly.
        setValue('visits', [])
        setRerenderNonce(prev => prev + 1)
      }
    }, [activationDate, previousActivationDate, tzId, visits, setValue])

    useEffect(() => {
      const totalNumVisits = numVisitCreditsPerYear * yearMultiplier
      if (visits.length === totalNumVisits) {
        return
      }

      let v: VisitRequest[] = [...visits]

      if (visits.length > totalNumVisits) {
        v = v.slice(0, totalNumVisits)
      } else {
        const existingEquipmentGuids =
          visits.length > 0 ? visits[0].installedEquipmentGuids : []

        const startYear = Math.floor(visits.length / numVisitCreditsPerYear)

        for (
          let yearOffset = startYear;
          yearOffset < yearMultiplier;
          yearOffset++
        ) {
          const startVisit =
            yearOffset === startYear
              ? visits.length % numVisitCreditsPerYear
              : 0

          for (let i = startVisit; i < numVisitCreditsPerYear; i++) {
            const affinityDate = getAffinityDateByVisitIndex(
              i,
              activationDate,
              tzId,
              yearOffset,
            )

            v.push({
              visitGuid: nextGuid(),
              name: getAffinityDateNameByVisitIndex(i),
              affinityDate,
              installedEquipmentGuids: existingEquipmentGuids,
              isCompletedOverride: false,
              shouldLinkToJob: false,
              jobGuid: undefined,
            })
          }
        }
      }

      v.sort((a, b) => a.affinityDate.localeCompare(b.affinityDate))

      setValue('visits', v)
      setVisitSelectedEquipments(
        v.reduce((acc, visit, index) => {
          acc[index] = visit.installedEquipmentGuids.map(
            guid =>
              installedEquipment.find(e => e.installedEquipmentGuid === guid)!,
          )
          return acc
        }, {} as Record<number, InstalledEquipmentSummary[]>),
      )
    }, [
      numVisitCreditsPerYear,
      yearMultiplier,
      activationDate,
      tzId,
      visits,
      installedEquipment,
      setValue,
    ])

    const monthOptions = useMemo(
      () => getAffinityDateOptions(activationDate, tzId, yearMultiplier),
      [activationDate, tzId, yearMultiplier],
    )

    const monthOptionsWithLabels = useMemo(() => {
      return monthOptions.map(month => ({
        label: BzDateTime.fromDateString(month, tzId)
          .toDate()
          .toLocaleDateString('en-US', { month: 'long', year: 'numeric' }),
        value: month,
      }))
    }, [monthOptions, tzId])

    const toggleVisitCompleted = useCallback(
      (index: number) => {
        const isCompleted = !(visits[index].isCompletedOverride ?? false)
        setValue(`visits.${index}.isCompletedOverride`, isCompleted)
        if (!isCompleted) {
          setValue(`visits.${index}.shouldLinkToJob`, false)
          setValue(`visits.${index}.jobGuid`, undefined)
        }
        setRerenderNonce(prev => prev + 1)
        trigger('visits')
      },
      [visits, setValue, trigger],
    )

    const jobOptions = useMemo<BzSelectOption[]>(() => {
      const jobs = selectedAccountLocation.location.jobs.sort((a, b) =>
        b.createdAt.localeCompare(a.createdAt),
      )

      return jobs.map(job => ({
        label: `Job #${job.displayId} - (${
          job.jobType.name
        }) - ${BzDateFns.formatFromISO(job.createdAt, 'MMM d, yyyy', tzId)}`,
        value: job.jobGuid,
        customNode: <VisitLinkedJobOption job={job} />,
      }))
    }, [selectedAccountLocation.location.jobs, tzId])

    const hasJobOptions = useMemo(() => jobOptions.length > 0, [jobOptions])

    const visitJobGuids = useMemo(() => {
      return visits.map(v => v.jobGuid).filter(j => !isNullish(j))
    }, [visits])

    const defaultJobOption = useMemo(() => {
      if (!jobOptions.length) {
        return undefined
      }
      if (!visitJobGuids.length) {
        return jobOptions[0]
      }

      const jobOptionsWithoutVisits = jobOptions.find(
        j => !visitJobGuids.includes(j.value),
      )

      return jobOptionsWithoutVisits ?? jobOptions[0]
    }, [jobOptions, visitJobGuids])

    const toggleLinkToJob = useCallback(
      (index: number) => {
        const newShouldLinkToJobValue = !(
          visits[index].shouldLinkToJob ?? false
        )
        setValue(`visits.${index}.shouldLinkToJob`, newShouldLinkToJobValue)
        if (newShouldLinkToJobValue) {
          setValue(`visits.${index}.jobGuid`, defaultJobOption?.value)
        } else {
          setValue(`visits.${index}.jobGuid`, undefined)
        }
        setRerenderNonce(prev => prev + 1)
        trigger('visits')
      },
      [setValue, visits, defaultJobOption, trigger],
    )

    const addEquipment = useCallback(
      (equips: InstalledEquipmentSummary[]) => {
        if (isNullish(equipmentPickerVisitIndex)) {
          return
        }

        const existingEquipment =
          visitSelectedEquipments[equipmentPickerVisitIndex] ?? []
        const newEquipmentMap = new Map<string, InstalledEquipmentSummary>()
        existingEquipment.forEach(e =>
          newEquipmentMap.set(e.installedEquipmentGuid, e),
        )
        equips.forEach(e => newEquipmentMap.set(e.installedEquipmentGuid, e))
        const newEquipment = Array.from(newEquipmentMap.values())

        setValue(
          `visits.${equipmentPickerVisitIndex}.installedEquipmentGuids`,
          newEquipment.map(e => e.installedEquipmentGuid),
        )
        setVisitSelectedEquipments(prev => ({
          ...prev,
          [equipmentPickerVisitIndex]: newEquipment,
        }))
        setEquipmentPickerVisitIndex(undefined)
      },
      [setValue, equipmentPickerVisitIndex, visitSelectedEquipments],
    )

    const removeEquipment = useCallback(
      (visitIndex: number, installedEquipmentGuid: Guid) => {
        const existingEquipment = visitSelectedEquipments[visitIndex] ?? []
        const newEquipment = existingEquipment.filter(
          e => e.installedEquipmentGuid !== installedEquipmentGuid,
        )
        setValue(
          `visits.${visitIndex}.installedEquipmentGuids`,
          newEquipment.map(e => e.installedEquipmentGuid),
        )
        setVisitSelectedEquipments(prev => ({
          ...prev,
          [visitIndex]: newEquipment,
        }))
      },
      [setValue, visitSelectedEquipments],
    )

    return (
      <>
        <div className="mb-1 text-base font-semibold text-bz-text">
          Plan Visits{' '}
          <span className="text-[#8c8c8c]">({numVisitCreditsPerYear})</span>
        </div>
        <div className="text-md mb-6 text-bz-text-tertiary">
          Visits can have a custom name, date, and linked equipment for
          consistent and accurate service.
        </div>

        {fields.map((field, index) => {
          const visitCompletedClassName = visits[index].isCompletedOverride
            ? 'bg-bz-gray-200'
            : 'bg-transparent'
          const isCompleted = visits[index].isCompletedOverride ?? false
          const allowLinkToJob = isCompleted
          const shouldLinkToJob = visits[index].shouldLinkToJob ?? false
          const isLast = index === fields.length - 1
          return (
            <div className="flex flex-col" key={field.id}>
              <div className="flex flex-row gap-x-3">
                {!isMobile && (
                  <div className="w-[44px]">
                    <FaIconWithCircularBackground
                      backgroundColor="rgba(0, 0, 0, 0.04)"
                      iconDefinition={
                        isCompleted ? faCalendarCheck : faCalendar
                      }
                      iconClassName={
                        isCompleted
                          ? 'text-[16px] text-[#1677ff]'
                          : 'text-[16px] text-[#565656]'
                      }
                      diameterPx={44}
                    />
                  </div>
                )}
                <div className="flex w-full flex-col gap-y-6 md:gap-y-3">
                  <div className="grid grid-cols-1 gap-6 md:grid-cols-2 md:gap-3">
                    <div>
                      <ReactHookFormItem
                        noBottomMargin
                        control={control}
                        errors={errors}
                        name={`visits.${index}.name`}
                        label={`Visit #${index + 1}`}
                        required={false}
                        className="w-full"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            placeholder="Visit name"
                            size={isMobile ? 'large' : 'middle'}
                            disabled={isCompleted}
                          />
                        )}
                      />
                    </div>
                    <div>
                      <ReactHookFormItem
                        noBottomMargin
                        control={control}
                        errors={errors}
                        name={`visits.${index}.affinityDate`}
                        label="Placeholder Date"
                        required={true}
                        className="w-full"
                        render={({ field }) => (
                          <SelectField
                            options={monthOptionsWithLabels}
                            {...field}
                            title="Visit Placeholder Date"
                            size={isMobile ? 'large' : 'middle'}
                            disabled={isCompleted}
                            key={`${field.value}-${rerenderNonce}`} // Force re-render when value changes
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-y-3">
                    <div className="flex flex-col">
                      <div className="mb-[2px] text-[14px] font-semibold leading-[22px]">
                        Equipment to Service
                      </div>
                      <div className="mb-3 text-[14px] leading-[22px] text-[#8c8c8c]">
                        Link the location's equipment to the visit for
                        streamlined service and data tracking.
                      </div>
                      <div className="flex min-h-[56px] flex-col gap-y-3 rounded-2xl bg-[#fafafa] p-3">
                        {visitSelectedEquipments[index] && (
                          <EquipmentCards
                            installedEquipments={
                              visitSelectedEquipments[index] ?? []
                            }
                            onRemove={guid => removeEquipment(index, guid)}
                          />
                        )}
                        <Button
                          type="link"
                          className="pl-3 text-left"
                          onClick={() => {
                            setEquipmentPickerVisitIndex(index)
                          }}
                        >
                          <FontAwesomeIcon icon={faPlus} className="mr-2" />
                          Link Equipment for Visit
                        </Button>
                      </div>
                    </div>
                    <div
                      className={`flex flex-col rounded-2xl p-[13px] ${visitCompletedClassName}`}
                    >
                      <div className="grid grid-cols-1 gap-y-3 md:grid-cols-2 md:gap-x-3">
                        <div className="w-full">
                          <ReactHookFormItem
                            noBottomMargin
                            control={control}
                            errors={errors}
                            name={`visits.${index}.isCompletedOverride`}
                            label={`Mark Visit #${index + 1} as Completed`}
                            labelPosition="right"
                            required={true}
                            render={({ field }) => (
                              <SwitchField
                                {...field}
                                withIcons
                                onChange={checked => {
                                  toggleVisitCompleted(index)
                                }}
                              />
                            )}
                          />
                        </div>
                        {allowLinkToJob && (
                          <div className="w-full">
                            <ReactHookFormItem
                              noBottomMargin
                              control={control}
                              errors={errors}
                              name={`visits.${index}.shouldLinkToJob`}
                              label={`Link to existing Job${
                                hasJobOptions ? '' : ' (No jobs)'
                              }`}
                              labelPosition="right"
                              required={true}
                              render={({ field }) => (
                                <SwitchField
                                  {...field}
                                  withIcons
                                  onChange={checked => {
                                    toggleLinkToJob(index)
                                  }}
                                  disabled={!hasJobOptions}
                                />
                              )}
                            />
                          </div>
                        )}
                      </div>
                      {shouldLinkToJob && (
                        <ReactHookFormItem
                          noBottomMargin
                          control={control}
                          errors={errors}
                          name={`visits.${index}.jobGuid`}
                          label=""
                          required={false}
                          className="mt-3"
                          render={({ field }) => (
                            <SelectField
                              {...field}
                              title="Select job"
                              size={isMobile ? 'large' : 'middle'}
                              options={jobOptions}
                              onChange={val => {
                                field.onChange(val)
                                setRerenderNonce(prev => prev + 1)
                                // For whatever reason, triggering the entire validation of the
                                // form rather than just trigger('visits') was required to refresh
                                // the error validation when changing the jobGuid on a visit. I believe this
                                // is because when we update the jobGuid for a visit, it's not a top-level field
                                // which doesn't trigger a re-render of the form.
                                trigger()
                              }}
                            />
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {!isLast && (
                <ThinDivider
                  dividerStyle="dashed"
                  widthPx={1}
                  borderColorClassName={
                    tailwindConfig.theme.extend.colors.bz.border
                  }
                  styleOverrides={{ marginTop: '16px', marginBottom: '16px' }}
                />
              )}
            </div>
          )
        })}
        {!isNullish(equipmentPickerVisitIndex) && (
          <InstalledEquipmentPicker
            locationGuid={selectedLocationGuid}
            installedEquipment={installedEquipment}
            selectedEquipmentGuids={(
              visitSelectedEquipments[equipmentPickerVisitIndex] ?? []
            ).map(e => e.installedEquipmentGuid)}
            onSave={addEquipment}
            onCancel={() => setEquipmentPickerVisitIndex(undefined)}
            refetch={refetch}
          />
        )}
      </>
    )
  },
)

type VisitLinkedJobOptionProps = {
  job: LocationJob
}

const VisitLinkedJobOption = memo<VisitLinkedJobOptionProps>(({ job }) => {
  const tzId = useExpectedCompanyTimeZoneId()
  return (
    <div className="flex flex-1 flex-col gap-y-1 py-5">
      <div className="mb-[6px] text-[16px] font-semibold leading-[24px]">
        {`#${job.displayId} ${job.jobType.name}`}
      </div>
      <KeyValueRow
        label="Status"
        leftAlign
        value={
          job.workCompletedAt ? (
            <StatusTag border="strong" color="green" text="Completed" />
          ) : (
            <StatusTag border="strong" color="gray" text="Incomplete" />
          )
        }
      />
      {job.workCompletedAt && (
        <KeyValueRow
          label="Completed On"
          leftAlign
          value={BzDateFns.formatFromISO(
            job.workCompletedAt,
            'MMM. d, yyyy',
            tzId,
          )}
        />
      )}
      <KeyValueRow
        label="Location"
        leftAlign
        value={`${job.location.address.line1} (${job.location.address.zipCode})`}
      />
    </div>
  )
})
