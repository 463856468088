import { BzDateFns, isNullish } from '@breezy/shared'
import {
  faEllipsis,
  faFileLines,
  faLocationDot,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Dropdown, MenuProps, Skeleton } from 'antd'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { OnsiteConfirmModal } from 'src/adam-components/OnsiteModal/OnsiteModal'
import { OnsitePageSimpleSectionItem } from 'src/adam-components/OnsitePage/OnsitePageSimpleSectionItem'
import { SectionedCard } from 'src/adam-components/SectionedCard/SectionedCard'
import { FileEditModal } from 'src/components/Files/FileEditModal'
import { FILE_UPDATE_NAME_MUTATION } from 'src/components/Files/FileElements.gql'
import GqlQueryLoader from 'src/components/GqlQueryLoader/GqlQueryLoader'
import { Tag } from 'src/components/Tags'
import { useFileUpload } from 'src/components/Upload/AsyncFileUpload'
import { AsyncUploadWrapper } from 'src/components/Upload/Upload'
import { DocumentType, gql } from 'src/generated'
import { trpc } from 'src/hooks/trpc'
import { useExpectedCompanyTimeZoneId } from 'src/providers/PrincipalUser'
import { useMessage } from 'src/utils/antd-utils'
import { useMutation, useQuery } from 'urql'

const ACCOUNT_FILES_QUERY = gql(/* GraphQL */ `
  query AttachmentsTabAccountFilesQuery($accountGuid: uuid!) {
    accountsByPk(accountGuid: $accountGuid) {
      fileLinks(orderBy: { createdAt: DESC }) {
        file {
          cdnUrl
          storageStrategy
          resourceUrn
          fileGuid
          fileName
          metadata
          createdByUserGuid
          fileSizeBytes
          fileTypeMime
          createdAt
        }
        job {
          jobGuid
          displayId
          jobType {
            name
          }
          location {
            locationGuid
            address {
              addressGuid
              line1
            }
          }
        }
      }
    }
  }
`)

type AccountFilesQuery = DocumentType<typeof ACCOUNT_FILES_QUERY>

export interface AttachmentsTabProps {
  accountGuid: string
  editable?: boolean
}

export const AttachmentsTab = (props: AttachmentsTabProps) => {
  const timezoneId = useExpectedCompanyTimeZoneId()

  const message = useMessage()

  const accountFilesQuery = useQuery({
    query: ACCOUNT_FILES_QUERY,
    variables: { accountGuid: props.accountGuid },
  })

  const accountFiles = useMemo<
    NonNullable<AccountFilesQuery['accountsByPk']>['fileLinks']
  >(() => {
    if (!accountFilesQuery[0].data?.accountsByPk) {
      return []
    }

    return accountFilesQuery[0].data.accountsByPk.fileLinks
  }, [accountFilesQuery])

  const [fileGuidToDelete, setFileGuidToDelete] = useState<string | null>()

  const useFileUploadProps = useFileUpload(
    (data, record) => {
      if (!data.recordWritten) {
        if (data.failed) {
          message.destroy(`${data.fileGuid}-upload-nofitication`)
        } else {
          message.open({
            key: `${data.fileGuid}-upload-nofitication`,
            content: 'Uploading attachment...',
          })
        }
      } else if (record) {
        message.destroy(`${data.fileGuid}-upload-nofitication`)
        message.success('Attachment uploaded!')
        setFileGuidToDelete(null)
        accountFilesQuery[1]()
      }
    },
    { accountGuid: props.accountGuid },
  )

  const [fileToEdit, setFileToEdit] = useState<
    (typeof accountFiles)[number]['file'] | null
  >(null)

  const updateFileNameMutation = useMutation(FILE_UPDATE_NAME_MUTATION)

  const dropdownItems = useMemo<NonNullable<MenuProps['items']>>(() => {
    const items: NonNullable<MenuProps['items']> = [
      {
        key: 'file-edit',
        label: 'Edit',
      },
      {
        key: 'file-delete',
        label: 'Delete',
      },
    ]

    return items
  }, [])

  const onDropdownClick = useCallback(
    (input: { key: string; fileGuid: string }) => {
      switch (input.key) {
        case 'file-edit': {
          const file = accountFiles.find(
            curr => curr.file.fileGuid === input.fileGuid,
          )
          if (!file) {
            return
          }

          setFileToEdit(file.file)
          break
        }
        case 'file-delete':
          setFileGuidToDelete(input.fileGuid)
          break
        default:
          break
      }
    },
    [accountFiles],
  )

  const removeFileMut = trpc.files['files:remove'].useMutation({
    onSuccess: () => {
      message.success('Successfully removed the attachment')
      setFileGuidToDelete(null)
      accountFilesQuery[1]()
    },
    onError: () => {
      message.error('Failed to remove the attachment')
    },
  })

  const navigate = useNavigate()

  return (
    <>
      <GqlQueryLoader
        query={accountFilesQuery}
        loadingComponent={
          <SectionedCard
            noBorder
            small
            sections={[
              {
                verticalPaddingClassName: 'p-0',
                content: (
                  <div className="flex w-full flex-row items-center justify-between">
                    <Skeleton.Button active size="small" />

                    <Skeleton.Button active size="small" />
                  </div>
                ),
              },
              {
                content: (
                  <OnsitePageSimpleSectionItem
                    className="border-0 shadow-none"
                    smallIconMode
                    icon={<FontAwesomeIcon icon={faFileLines} />}
                    subContent={
                      <div className="flex flex-col gap-2">
                        <Skeleton.Input active size="small" className="mt-1" />
                      </div>
                    }
                  >
                    <Skeleton.Input active size="small" />
                  </OnsitePageSimpleSectionItem>
                ),
              },
            ]}
          />
        }
        render={() => (
          <SectionedCard
            noBorder
            small
            sections={[
              {
                verticalPaddingClassName: 'p-0',
                content: (
                  <div className="flex w-full flex-row items-center justify-between">
                    <span className="font-semibold">
                      {accountFiles.length === 1
                        ? '1 attachment'
                        : `${accountFiles.length} attachments`}
                    </span>

                    {props.editable && (
                      <div>
                        <AsyncUploadWrapper {...useFileUploadProps}>
                          <Button type="link">+ Upload Attachment</Button>
                        </AsyncUploadWrapper>
                      </div>
                    )}
                  </div>
                ),
              },
              ...accountFiles.map(({ file, job }) => ({
                content: (
                  <OnsitePageSimpleSectionItem
                    key={file.fileGuid}
                    className="border-0 shadow-none"
                    smallIconMode
                    icon={<FontAwesomeIcon icon={faFileLines} />}
                    rightContent={
                      <Dropdown
                        menu={{
                          items: dropdownItems,
                          onClick: ({ key }) =>
                            onDropdownClick({ key, fileGuid: file.fileGuid }),
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faEllipsis}
                          className="cursor-pointer"
                        />
                      </Dropdown>
                    }
                    subContent={
                      <div className="flex flex-col gap-2">
                        <span className="text-xs text-bz-text-secondary">
                          {BzDateFns.formatFromISO(
                            file.createdAt,
                            'MMM dd, yyyy',
                            timezoneId,
                          )}{' '}
                          @{' '}
                          {BzDateFns.formatFromISO(
                            file.createdAt,
                            'h:mm a',
                            timezoneId,
                          )}
                        </span>

                        {!isNullish(job) && (
                          <div className="flex flex-row items-center">
                            <Tag
                              tagStyleVersion="v2"
                              icon={faLocationDot}
                              compact
                              tag={{ name: job.location.address.line1 }}
                              onClick={() =>
                                navigate(
                                  `/locations/${job.location.locationGuid}`,
                                )
                              }
                            />

                            <Tag
                              tagStyleVersion="v2"
                              compact
                              tag={{
                                name: `${job.jobType.name} #${job.displayId}`,
                              }}
                              onClick={() => navigate(`/jobs/${job.jobGuid}`)}
                            />
                          </div>
                        )}
                      </div>
                    }
                  >
                    <a
                      href={file.cdnUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="w-max text-bz-text hover:underline"
                    >
                      {file.fileName}
                    </a>
                  </OnsitePageSimpleSectionItem>
                ),
              })),
            ]}
          />
        )}
      />

      <FileEditModal
        open={!isNullish(fileToEdit)}
        loading={updateFileNameMutation[0].fetching}
        saveText={updateFileNameMutation[0].fetching ? 'Saving...' : 'Save'}
        fileGuid={fileToEdit?.fileGuid ?? ''}
        defaultValues={
          !isNullish(fileToEdit)
            ? {
                fileName: fileToEdit.fileName,
              }
            : undefined
        }
        onSubmit={data => {
          try {
            updateFileNameMutation[1]({
              fileGuid: data.fileGuid,
              fileName: data.fileName,
            })

            setFileToEdit(null)
            accountFilesQuery[1]()

            message.success('Updated attachment!')
          } catch (err) {
            message.error('Failed to update attachment')
          }
        }}
        onClose={() => setFileToEdit(null)}
      />

      <OnsiteConfirmModal
        header="Delete Attachment"
        open={!isNullish(fileGuidToDelete)}
        confirmText={removeFileMut.isLoading ? 'Deleting...' : 'Delete'}
        danger
        confirmDisabled={removeFileMut.isLoading}
        onConfirm={() => {
          if (isNullish(fileGuidToDelete)) {
            return
          }

          removeFileMut.mutate({ fileGuid: fileGuidToDelete })
        }}
        onCancel={() => setFileGuidToDelete(null)}
      >
        <p>Are you sure you want to delete this attachment?</p>
      </OnsiteConfirmModal>
    </>
  )
}
