import { z } from 'zod'
import { guidSchema, timeZoneIdSchema } from '../../contracts'
import { AddressDtoSchema } from '../Address/Address'
import { bzOptional } from '../common-schemas'

export const hcpCompanyMigrationProfileSchema = z.object({
  hcpApiToken: z.string(),
  companyName: z.string(),
  companyGuid: guidSchema,
  timezone: timeZoneIdSchema,
  executingUserGuid: guidSchema,
  excludedHcpCustomerIds: bzOptional(z.array(z.string())),
  jobTypes: z.array(
    z.object({
      jobTypeGuid: guidSchema,
      jobTypeName: z.string(),
      jobClass: z.string(),
      jobLifecycleGuid: guidSchema,
    }),
  ),
  MISC_INSTALL_JOB_TYPE_GUID: guidSchema,
  MISC_SERVICE_JOB_TYPE_GUID: guidSchema,
  MISC_MAINTENANCE_JOB_TYPE_GUID: guidSchema,
  ESTIMATE_REQUEST: guidSchema,
  lifecycles: z.array(
    z.object({
      jobLifecycleName: z.string(),
      jobLifecycleGuid: guidSchema,
      jobLifecycleStatusGuid: guidSchema,
      name: z.string(),
    }),
  ),
  TEAM_MEMBERS: z.array(
    z.object({
      userGuid: bzOptional(guidSchema),
      emailAddress: z.string(),
      firstName: z.string(),
      lastName: z.string(),
      hcpId: z.string(),
    }),
  ),
  DEFAULT_TECHNICIAN_USER_GUID: guidSchema,
  taxRates: bzOptional(
    z.array(
      z.object({
        guid: guidSchema,
        taxRate: z.number(),
        name: z.string(),
      }),
    ),
  ),
  DEFAULT_TAX_RATE: z.object({
    guid: guidSchema,
    taxRate: z.number(),
    name: z.string(),
  }),
  maintenancePlans: z.array(z.unknown()),
  leadSources: z.array(
    z.object({
      companyLeadSourceGuid: guidSchema,
      leadSourceName: z.string(),
    }),
  ),
  defaultLeadSourceGuid: guidSchema,
  defaultExistingCustomerLeadSourceGuid: guidSchema,
  defaultMissingAddress: AddressDtoSchema,
})

export type HcpCompanyMigrationProfile = z.infer<typeof hcpCompanyMigrationProfileSchema>
