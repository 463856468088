import {
  EquipmentOperationalStatus,
  InstalledEquipmentSummary,
  isNullish,
} from '@breezy/shared'
import { useCallback, useState } from 'react'
import { OnsitePageCollapsibleSection } from 'src/adam-components/OnsitePage/OnsitePageCollapsibleSection'
import { EquipmentCard } from 'src/components/Cards/EquipmentCard'
import { EquipmentDetailsModal } from 'src/components/EquipmentDetailsModal/EquipmentDetailsModal'
import { gql } from 'src/generated'
import { useMessage } from 'src/utils/antd-utils'
import { useMutation } from 'urql'

export const EQUIPMENT_UPDATE_OPERATIONAL_STATUS_MUTATION = gql(/* GraphQL */ `
  mutation EquipmentCollapsibleUpdateOperationalStatusMutation(
    $installedEquipmentGuid: uuid!
    $operationalStatus: String!
  ) {
    updateInstalledEquipmentByPk(
      pkColumns: { installedEquipmentGuid: $installedEquipmentGuid }
      _set: { operationalStatus: $operationalStatus }
    ) {
      operationalStatus
    }
  }
`)

export interface EquipmentCollapsibleProps {
  // TODO: Refactor so this only takes in the minimal amount of data
  equipment: InstalledEquipmentSummary[]
  canManage?: boolean
  onAddEquipment?: () => void
  onEquipmentEdit?: (equipment: InstalledEquipmentSummary) => void
  refresh?: () => void
}

export const EquipmentCollapsible = (props: EquipmentCollapsibleProps) => {
  const message = useMessage()

  const [openedEquipment, setOpenedEquipment] =
    useState<InstalledEquipmentSummary | null>(null)

  const equipmentUpdateOperationalStatusMutation = useMutation(
    EQUIPMENT_UPDATE_OPERATIONAL_STATUS_MUTATION,
  )

  const onEquipmentDeactivate = useCallback(
    (equipment: InstalledEquipmentSummary) => {
      equipmentUpdateOperationalStatusMutation[1]({
        installedEquipmentGuid: equipment.installedEquipmentGuid,
        operationalStatus: EquipmentOperationalStatus.DEACTIVATED,
      })

      message.success('Successfully deactivated equipment')

      props.refresh?.()
    },
    [equipmentUpdateOperationalStatusMutation, message, props],
  )

  const onEquipmentReactivate = useCallback(
    (equipment: InstalledEquipmentSummary) => {
      equipmentUpdateOperationalStatusMutation[1]({
        installedEquipmentGuid: equipment.installedEquipmentGuid,
        operationalStatus: EquipmentOperationalStatus.IN_SERVICE,
      })

      message.success('Successfully reactivated equipment')

      props.refresh?.()
    },
    [equipmentUpdateOperationalStatusMutation, message, props],
  )

  return (
    <OnsitePageCollapsibleSection
      smallTitle
      title="Equipment"
      count={props.equipment.length}
      defaultCollapsed={props.equipment.length === 0}
      onAdd={props.canManage ? props.onAddEquipment : undefined}
      addButtonTestId="equipment-collapsible-add-button"
    >
      <div className="flex flex-col gap-3">
        {props.equipment
          .sort((a, _) => {
            // Move equipment that is deactivated to the back of the list
            if (a.operationalStatus === EquipmentOperationalStatus.IN_SERVICE) {
              return -1
            }

            return 1
          })
          .map(equipment => (
            <EquipmentCard
              key={equipment.installedEquipmentGuid}
              equipment={equipment}
              onTitleClick={() => setOpenedEquipment(equipment)}
              onEdit={props.canManage ? props.onEquipmentEdit : undefined}
              onDeactivate={onEquipmentDeactivate}
              onReactivate={onEquipmentReactivate}
            />
          ))}
      </div>

      {!isNullish(openedEquipment) && (
        <EquipmentDetailsModal
          open={!isNullish(openedEquipment)}
          equipment={openedEquipment}
          onClose={() => setOpenedEquipment(null)}
        />
      )}
    </OnsitePageCollapsibleSection>
  )
}
