import { nextGuid } from '@breezy/shared'
import {
  faEdit,
  faPlus,
  faSave,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, Input, Space, Table } from 'antd'
import type { InputRef } from 'antd/es/input'
import { useCallback, useRef, useState } from 'react'
import { useMutation, useQuery } from 'urql'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import {
  FETCH_COMPANY_BUSINESS_UNITS,
  UPSERT_BUSINESS_UNIT,
} from './BusinessUnitsSettingsPage.gql'

interface BusinessUnit {
  businessUnitGuid: string
  companyGuid: string
  name: string
  accountingIntegrationName?: string
}

interface EditingBusinessUnit extends BusinessUnit {
  isNew?: boolean
}

export const BusinessUnitsEditPanel = ({
  showAccounting,
}: {
  showAccounting: boolean
}) => {
  const companyGuid = useExpectedCompanyGuid()
  const [editingKey, setEditingKey] = useState<string>('')
  const [newUnit, setNewUnit] = useState<EditingBusinessUnit | null>(null)
  const [form] = Form.useForm()
  const nameInputRef = useRef<InputRef>(null)

  const [{ data, fetching }, refetch] = useQuery({
    query: FETCH_COMPANY_BUSINESS_UNITS,
    variables: { companyGuid },
  })

  const [, upsertBusinessUnit] = useMutation(UPSERT_BUSINESS_UNIT)

  const isEditing = useCallback(
    (record: BusinessUnit) => record.businessUnitGuid === editingKey,
    [editingKey],
  )

  const edit = useCallback(
    (record: BusinessUnit) => {
      form.setFieldsValue({ ...record })
      setEditingKey(record.businessUnitGuid)
      // Use setTimeout to ensure the input is rendered before focusing
      setTimeout(() => {
        nameInputRef.current?.focus()
      }, 100)
    },
    [form],
  )

  const cancel = useCallback(() => {
    setEditingKey('')
    setNewUnit(null)
  }, [])

  const save = useCallback(
    async (businessUnitGuid: string) => {
      try {
        const row = await form.validateFields()
        await upsertBusinessUnit({
          object: {
            businessUnitGuid,
            companyGuid,
            name: row.name,
            accountingIntegrationName: row.accountingIntegrationName,
            updatedAt: new Date().toISOString(),
          },
        })
        setEditingKey('')
        setNewUnit(null)
        refetch()
      } catch (errInfo) {
        console.error('Validate Failed:', errInfo)
      }
    },
    [form, upsertBusinessUnit, refetch, companyGuid],
  )

  const addNew = useCallback(() => {
    const newBusinessUnit = {
      businessUnitGuid: nextGuid(),
      companyGuid,
      name: '',
      accountingIntegrationName: '',
      isNew: true,
    }
    setNewUnit(newBusinessUnit)
    form.setFieldsValue(newBusinessUnit)
    setEditingKey(newBusinessUnit.businessUnitGuid)
    // Focus name input when adding new
    setTimeout(() => {
      nameInputRef.current?.focus()
    }, 100)
  }, [companyGuid, form, setEditingKey, setNewUnit])

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent, record: BusinessUnit) => {
      if (e.key === 'Enter') {
        save(record.businessUnitGuid)
      } else if (e.key === 'Escape') {
        cancel()
      }
    },
    [save, cancel],
  )

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      editable: true,
      width: '35%',
      render: (_: unknown, record: BusinessUnit) => {
        const editing = isEditing(record)
        return (
          <div className="py-2">
            {editing ? (
              <Form.Item
                name="name"
                rules={[{ required: true, message: 'Name is required' }]}
                className="mb-0"
              >
                <Input
                  ref={nameInputRef}
                  onKeyDown={e => handleKeyPress(e, record)}
                  autoComplete="off"
                />
              </Form.Item>
            ) : (
              <div className="pl-3">{record.name}</div>
            )}
          </div>
        )
      },
    },
    ...(showAccounting
      ? [
          {
            title: 'Accounting Name Override',
            dataIndex: 'accountingIntegrationName',
            editable: true,
            width: '35%',
            render: (_: unknown, record: BusinessUnit) => {
              const editing = isEditing(record)
              return (
                <div className="py-2">
                  {editing ? (
                    <Form.Item
                      name="accountingIntegrationName"
                      className="mb-0"
                    >
                      <Input
                        onKeyDown={e => handleKeyPress(e, record)}
                        autoComplete="off"
                      />
                    </Form.Item>
                  ) : (
                    <div className="pl-3">
                      {record.accountingIntegrationName}
                    </div>
                  )}
                </div>
              )
            },
          },
        ]
      : []),
    {
      title: 'Actions',
      width: '30%',
      render: (_: unknown, record: BusinessUnit) => {
        const editing = isEditing(record)
        return (
          <div className="py-2">
            {editing ? (
              <Space className="flex items-center">
                <Button
                  type="link"
                  onClick={() => save(record.businessUnitGuid)}
                >
                  <FontAwesomeIcon icon={faSave} className="mr-1" />
                  Save
                </Button>
                <Button type="link" onClick={cancel} danger>
                  <FontAwesomeIcon icon={faXmark} className="mr-1" />
                  Cancel
                </Button>
              </Space>
            ) : (
              <Button type="link" onClick={() => edit(record)}>
                <FontAwesomeIcon icon={faEdit} className="mr-1" />
                Edit
              </Button>
            )}
          </div>
        )
      },
    },
  ]

  if (fetching) {
    return <LoadingSpinner />
  }

  const businessUnits: BusinessUnit[] = [...(data?.businessUnits || [])]
  if (newUnit) {
    businessUnits.push(newUnit)
  }

  return (
    <div className="w-full">
      <Form form={form} component={false}>
        <Table
          dataSource={businessUnits}
          columns={columns}
          rowKey="businessUnitGuid"
          pagination={false}
        />
      </Form>
      <Button
        type="primary"
        onClick={addNew}
        className="mt-6 h-10 text-lg font-medium shadow-md transition-shadow hover:shadow-lg"
        disabled={!!editingKey}
      >
        <FontAwesomeIcon icon={faPlus} className="mr-2" />
        Add Business Unit
      </Button>
    </div>
  )
}

export default BusinessUnitsEditPanel
