import {
  AccountGuid,
  AppointmentGuid,
  BzDateFns,
  formatUsc,
  formatUscConcise,
  JobGuid,
  JobSummary,
  LocationGuid,
  PaymentMethodDisplayNames,
  ServiceHistoryResponse,
} from '@breezy/shared'
import { Skeleton } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  OnsiteModal,
  OnsiteModalContent,
} from '../../../../../adam-components/OnsiteModal/OnsiteModal'
import { Banner } from '../../../../../elements/Banner/Banner'
import { SmallCard } from '../../../../../elements/Card/SmallCard'
import { HtmlRenderer } from '../../../../../elements/HtmlRenderer/HtmlRenderer'
import { Link } from '../../../../../elements/Link/Link'
import StatusTag from '../../../../../elements/StatusTag/StatusTag'
import { trpc } from '../../../../../hooks/trpc'
import { useIsOfficeApp } from '../../../../../hooks/useIsOfficeApp'
import { useExpectedCompanyTimeZoneId } from '../../../../../providers/PrincipalUser'
import { useMessage } from '../../../../../utils/antd-utils'

type ServiceHistoryProps = {
  appointmentGuid?: AppointmentGuid
  jobGuid?: JobGuid
  locationGuid?: LocationGuid
  accountGuid?: AccountGuid
}
const CACHE_KEY_PREFIX = 'serviceHistory_'
const CACHE_EXPIRY_TIME = 24 * 60 * 60 * 1000 // 24 hours in milliseconds

const getCacheKey = ({
  appointmentGuid,
  locationGuid,
  jobGuid,
  accountGuid,
}: ServiceHistoryProps) =>
  `${CACHE_KEY_PREFIX}${appointmentGuid || ''}-${locationGuid}-${
    jobGuid || ''
  }${accountGuid ? `-${accountGuid}` : ''}`

const MAX_RETRY_COUNT = 3

const useServiceHistory = ({
  appointmentGuid,
  jobGuid,
  locationGuid,
  accountGuid,
}: ServiceHistoryProps) => {
  const [serviceHistory, setServiceHistory] = useState<
    ServiceHistoryResponse | undefined
  >(undefined)
  const [retryCount, setRetryCount] = useState(0)
  const toastMessage = useMessage()
  const generateAIMessage =
    trpc.serviceHistory[
      'service-history:generate-service-history'
    ].useMutation()

  const getServiceHistory = useCallback(
    async (
      forceRefresh = false,
    ): Promise<ServiceHistoryResponse | undefined> => {
      const cacheKey = getCacheKey({
        appointmentGuid,
        locationGuid,
        jobGuid,
        accountGuid,
      })
      const cachedData = localStorage.getItem(cacheKey)

      if (!forceRefresh && cachedData) {
        const { data, timestamp } = JSON.parse(cachedData)
        if (Date.now() - timestamp < CACHE_EXPIRY_TIME) {
          setServiceHistory(data)
          return data
        }
      }

      if (retryCount >= MAX_RETRY_COUNT) {
        toastMessage.error(
          'Failed to generate service history after multiple attempts. Please try again later.',
        )
        return
      }

      try {
        const result = await generateAIMessage.mutateAsync({
          locationGuid,
          jobGuid,
          accountGuid,
        })
        if (result) {
          localStorage.setItem(
            cacheKey,
            JSON.stringify({ data: result, timestamp: Date.now() }),
          )
          setServiceHistory(result)
          return result
        } else {
          throw new Error('Failed to generate service history')
        }
      } catch (error) {
        console.error('Error generating service history:', error)
        setRetryCount(prevCount => prevCount + 1)
        toastMessage.error('Failed to generate service history. Retrying...')
        // Retry after a short delay
        setTimeout(() => getServiceHistory(forceRefresh), 2000)
      }
    },
    [
      appointmentGuid,
      locationGuid,
      jobGuid,
      retryCount,
      toastMessage,
      generateAIMessage,
      accountGuid,
    ],
  )

  return {
    serviceHistory,
    isLoading: generateAIMessage.isLoading,
    getServiceHistory,
    retryCount,
  }
}

const ServiceHistory = React.memo<ServiceHistoryProps>(
  ({ appointmentGuid, jobGuid, locationGuid, accountGuid }) => {
    const { serviceHistory, isLoading, getServiceHistory, retryCount } =
      useServiceHistory({
        appointmentGuid,
        jobGuid,
        locationGuid,
        accountGuid,
      })

    useEffect(() => {
      const fetchData = async () => {
        if (!serviceHistory && !isLoading && retryCount < MAX_RETRY_COUNT) {
          await getServiceHistory()
        }
      }
      fetchData()
    }, [locationGuid, serviceHistory, isLoading, getServiceHistory, retryCount])

    const content = useMemo(() => {
      if (
        !isLoading &&
        serviceHistory &&
        serviceHistory.jobSummaries.length === 0
      ) {
        return (
          <Banner color="gray">
            <div className="w-full text-sm text-bz-text-tertiary">
              No service history available. Once jobs are completed, a summary
              of their recent service history will appear here.
            </div>
          </Banner>
        )
      }

      return (
        <>
          <div className="text-xl font-semibold">Last 18 months</div>
          {isLoading ? (
            <Skeleton active paragraph={{ rows: 10 }} />
          ) : serviceHistory ? (
            <HtmlRenderer
              htmlContent={`<div class="text-base *:last:mb-0">${serviceHistory.serviceHistoryLlmSummary}</div>`}
            />
          ) : null}

          <div className="mt-2 text-xl font-semibold">Job Summaries</div>
          <div className="pb-10">
            <div className="flex flex-col gap-3">
              {isLoading ? (
                <>
                  <Skeleton.Node
                    active
                    style={{
                      width: '100%',
                      height: '400px',
                      borderRadius: '12px',
                    }}
                  >
                    <></>
                  </Skeleton.Node>
                  <Skeleton.Node
                    active
                    style={{
                      width: '100%',
                      height: '400px',
                      borderRadius: '12px',
                    }}
                  >
                    <></>
                  </Skeleton.Node>
                </>
              ) : serviceHistory ? (
                serviceHistory.jobSummaries.map(jobSummary => (
                  <JobSummaryCard key={jobSummary.jobGuid} {...jobSummary} />
                ))
              ) : null}
            </div>
          </div>
        </>
      )
    }, [isLoading, serviceHistory])
    return <div className="flex min-h-0 flex-col gap-y-4">{content}</div>
  },
)

const SmallCardSection: React.FC<{
  title: string
  children: React.ReactNode
}> = ({ title, children }) => (
  <div className="mb-[2px] flex items-center justify-between text-base leading-7">
    <div className="font-semibold">{title}</div>
    <div className="max-w-[70%] truncate text-right">{children}</div>
  </div>
)

const JobSummaryCard = React.memo<JobSummary>(jobSummary => {
  const tzId = useExpectedCompanyTimeZoneId()
  const isOfficeApp = useIsOfficeApp()

  return (
    <SmallCard
      header={
        <div className="flex flex-row justify-between text-base font-semibold">
          <Link to={`/jobs/${jobSummary.jobGuid}`}>
            {jobSummary.jobTypeName} #{jobSummary.displayId}
          </Link>
          {jobSummary.workCompletedAt ? (
            <StatusTag border="strong" color="green" text="Completed" />
          ) : null}
        </div>
      }
    >
      <div className="flex min-h-0 flex-col space-y-[2px]">
        <div className="mb-3 text-base">{jobSummary.jobLlmSummary}</div>

        <SmallCardSection title="Location">
          {jobSummary.locationSummary.addressLine1}
        </SmallCardSection>

        <SmallCardSection title="Created Date">
          {BzDateFns.formatFromISO(jobSummary.createdAt, 'MMM. d, yyyy', tzId)}
        </SmallCardSection>

        {jobSummary.workCompletedAt ? (
          <SmallCardSection title="Completed Date">
            {BzDateFns.formatFromISO(
              jobSummary.workCompletedAt,
              'MMM. d, yyyy',
              tzId,
            )}
          </SmallCardSection>
        ) : null}

        {jobSummary.invoiceSummaries.map(invoice => (
          <SmallCardSection key={invoice.invoiceGuid} title="Invoice">
            <Link to={`/invoice/${invoice.invoiceGuid}`}>
              #{invoice.displayId} ({formatUsc(invoice.totalUsc)})
            </Link>
          </SmallCardSection>
        ))}

        {jobSummary.paymentSummaries.map(payment => (
          <SmallCardSection key={payment.paymentRecordGuid} title="Payment">
            {PaymentMethodDisplayNames[payment.paymentMethod]} (
            {formatUscConcise(payment.amountUsd * 100)})
          </SmallCardSection>
        ))}

        {jobSummary.estimateSummaries.map(estimate => (
          <SmallCardSection key={estimate.estimateGuid} title="Estimate">
            <Link to={`/estimates/${estimate.estimateGuid}`}>
              #{estimate.displayId} ({formatUsc(estimate.totalUsc)})
            </Link>
          </SmallCardSection>
        ))}

        {jobSummary.jobVisitSummaries.map((visit, index) => (
          <SmallCardSection
            key={visit.jobAppointmentGuid}
            title={`Visit #${index + 1}`}
          >
            {isOfficeApp ? (
              <>
                {visit.appointmentType} (
                {BzDateFns.formatFromISO(
                  visit.appointmentWindowStart,
                  'MMM. d, yyyy',
                  tzId,
                )}
                )
              </>
            ) : (
              <Link to={`/appointments/${visit.jobAppointmentGuid}`}>
                {visit.appointmentType} (
                {BzDateFns.formatFromISO(
                  visit.appointmentWindowStart,
                  'MMM. d, yyyy',
                  tzId,
                )}
                )
              </Link>
            )}
          </SmallCardSection>
        ))}
      </div>
    </SmallCard>
  )
})

type ServiceHistoryModalProps = ServiceHistoryProps & {
  onClose: () => void
}

export const ServiceHistoryModal = React.memo<ServiceHistoryModalProps>(
  ({ appointmentGuid, jobGuid, locationGuid, accountGuid, onClose }) => {
    return (
      <OnsiteModal open size="large" onClose={onClose}>
        <OnsiteModalContent onClose={onClose} header="Service History">
          <ServiceHistory
            appointmentGuid={appointmentGuid}
            jobGuid={jobGuid}
            locationGuid={locationGuid}
            accountGuid={accountGuid}
          />
        </OnsiteModalContent>
      </OnsiteModal>
    )
  },
)
