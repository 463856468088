import { RRule } from 'rrule'
import { z } from 'zod'
import { AsyncFn, BadInputException, BzDateFns, IsoDateString, LocalDateString, TimeZoneId } from '../../common'
import { guidSchema, isoDateStringSchema } from '../../contracts/_common'
import { CompanyTimeWindowRequest, CompanyTimeZoneReader, ForCompany } from '../Company/Company'
import { UserGuid } from '../Users/User'
import { bzOptional } from '../common-schemas'

export const TechnicianCapacitySchema = z.object({
  technicianCapacityGuid: guidSchema,
  userGuid: guidSchema,
  start: isoDateStringSchema,
  end: isoDateStringSchema,
})

export type TechnicianCapacity = z.infer<typeof TechnicianCapacitySchema>

export enum TechnicianCapacityBlockReasonType {
  NOT_SCHEDULED = 'NOT_SCHEDULED',
  LUNCH = 'LUNCH',
  BREAK = 'BREAK',
  ON_CALL = 'ON_CALL',
  PERSONAL_APPOINTMENT = 'PERSONAL_APPOINTMENT',
  INTERNAL_MEETING = 'INTERNAL_MEETING',
  TRAINING = 'TRAINING',
  PTO = 'PTO',
  HOLIDAY = 'HOLIDAY',
  OUT_SICK = 'OUT_SICK',
  SHOP_WORK = 'SHOP_WORK',
  SUPPLY_PARTS_PICKUP = 'SUPPLY_PARTS_PICKUP',
  OTHER = 'OTHER',
}

const displayReasonTypes = {
  [TechnicianCapacityBlockReasonType.NOT_SCHEDULED]: 'Not Scheduled',
  [TechnicianCapacityBlockReasonType.LUNCH]: 'Lunch',
  [TechnicianCapacityBlockReasonType.BREAK]: 'Break',
  [TechnicianCapacityBlockReasonType.ON_CALL]: 'On Call',
  [TechnicianCapacityBlockReasonType.PERSONAL_APPOINTMENT]: 'Personal Appointment',
  [TechnicianCapacityBlockReasonType.INTERNAL_MEETING]: 'Internal Meeting',
  [TechnicianCapacityBlockReasonType.TRAINING]: 'Training',
  [TechnicianCapacityBlockReasonType.PTO]: 'PTO',
  [TechnicianCapacityBlockReasonType.HOLIDAY]: 'Holiday',
  [TechnicianCapacityBlockReasonType.OUT_SICK]: 'Out Sick',
  [TechnicianCapacityBlockReasonType.SHOP_WORK]: 'Shop Work',
  [TechnicianCapacityBlockReasonType.SUPPLY_PARTS_PICKUP]: 'Supply/Parts Pickup',
  [TechnicianCapacityBlockReasonType.OTHER]: 'Other',
}

export const formatTechnicianCapacityBlockReasonType = (type: TechnicianCapacityBlockReasonType) =>
  displayReasonTypes[type]

export const TechnicianCapacityBlockSchema = z.object({
  guid: guidSchema,
  userGuids: z.array(guidSchema),
  start: isoDateStringSchema,
  end: isoDateStringSchema,
  reasonType: z.nativeEnum(TechnicianCapacityBlockReasonType),
  reasonDescription: bzOptional(z.string()),
  recurrenceRule: bzOptional(z.string()),
  recurrenceRuleExceptions: bzOptional(z.string()),
})

export type TechnicianCapacityBlock = z.infer<typeof TechnicianCapacityBlockSchema>

export type ITechCapacityBlockReader = {
  read: AsyncFn<CompanyTimeWindowRequest, TechnicianCapacityBlock[]>
  readByUserGuid: AsyncFn<ForCompany<{ userGuid: string }>, TechnicianCapacityBlock[]>
  readByUserGuids: AsyncFn<ForCompany<{ userGuids: UserGuid[] }>, TechnicianCapacityBlock[]>
}
export type ITechCapacityBlockWriter = {
  upsert: AsyncFn<ForCompany<TechnicianCapacityBlock>>
  delete: AsyncFn<{ guid: string }>
  deleteMultiple: AsyncFn<{ guids: string[] }>
}

export type UserBlockDeleter = AsyncFn<ForCompany<{ userGuid: UserGuid }>>

type TechnicianCapacityBlockInstancesReaderRequest = {
  type: 'local-date'
  input: ForCompany<{
    date: LocalDateString
    technicianGuids: UserGuid[]
  }>
}

type TechCapacityTimeWindow = {
  start: IsoDateString
  end: IsoDateString
}
export type TechnicianCapacityBlockInstancesMap = Record<UserGuid, TechCapacityTimeWindow[]>

type TechnicianCapacityBlockInstancesReaderResponse = {
  technicianCapacityBlockInstances: TechnicianCapacityBlockInstancesMap
}

/**
 * Given a date and a list of technician guids, this function will return the expanded
 * capacity block instances for the requested date. This function will expand any recurring technician capacity blocks
 * for the given date and return the specific bounds for the capacity block for each technician.
 */
export type TechnicianCapacityBlockInstancesReader = AsyncFn<
  TechnicianCapacityBlockInstancesReaderRequest,
  TechnicianCapacityBlockInstancesReaderResponse
>

const getExceptionDates = (exceptions: string | undefined, tzId: TimeZoneId): Date[] => {
  if (!exceptions) return []
  return exceptions.split(',').map(dateStr => BzDateFns.parseISO(dateStr as IsoDateString, tzId))
}

const isDateExcluded = (date: Date, exceptions: Date[]): boolean => {
  return exceptions.some(exception => BzDateFns.isSameDay(date, exception))
}

/**
 * Given a list of technician capacity blocks, this function will return the expanded
 * capacity block instances for the requested date. This function will expand any recurring technician capacity blocks
 * for the given date and return the specific bounds for the capacity block for each technician.
 */
export const expandTechnicianCapacityBlocks = (
  blocks: TechnicianCapacityBlock[],
  technicianGuids: string[],
  startOfDay: IsoDateString,
  endOfDay: IsoDateString,
  tzId: TimeZoneId,
): TechnicianCapacityBlockInstancesMap => {
  const result: TechnicianCapacityBlockInstancesMap = {}

  technicianGuids.forEach(userGuid => {
    result[userGuid] = []
  })

  blocks.forEach(block => {
    const exceptions = getExceptionDates(block.recurrenceRuleExceptions, tzId)

    block.userGuids.forEach(userGuid => {
      // If the block is recurring, we need to expand it to all the dates it occurs on
      if (block.recurrenceRule) {
        const rule = RRule.fromString(block.recurrenceRule)
        // 1. Get all the instances of the block between the start and end of the day
        const recurrenceInstances = rule
          .between(BzDateFns.parseISO(startOfDay, tzId), BzDateFns.parseISO(endOfDay, tzId), true)
          .filter(date => !isDateExcluded(date, exceptions))

        // 2. For each instance, get the start and end of the block
        recurrenceInstances.forEach(recurrenceInstance => {
          const start = BzDateFns.formatISO(
            BzDateFns.copyTime(recurrenceInstance, BzDateFns.parseISO(block.start, tzId)),
            tzId,
          )
          const end = BzDateFns.formatISO(
            BzDateFns.copyTime(recurrenceInstance, BzDateFns.parseISO(block.end, tzId)),
            tzId,
          )
          result[userGuid].push({ start, end })
        })
      } else {
        const blockStart = BzDateFns.parseISO(block.start, tzId)
        const blockEnd = BzDateFns.parseISO(block.end, tzId)
        const windowStart = BzDateFns.parseISO(startOfDay, tzId)
        const windowEnd = BzDateFns.parseISO(endOfDay, tzId)

        if (blockStart < windowEnd && blockEnd > windowStart) {
          result[userGuid].push({
            start: BzDateFns.formatISO(blockStart, tzId),
            end: BzDateFns.formatISO(blockEnd, tzId),
          })
        }
      }
    })
  })

  return result
}

/**
 * Given a list of technician capacity blocks, this function will return the expanded
 * capacity block instances for the requested date. This function will expand any recurring technician capacity blocks
 * for the given date and return the specific bounds for the capacity block for each technician.
 */
export const createTechnicianCapacityBlockInstancesReader =
  (
    techCapacityBlockReader: ITechCapacityBlockReader,
    tzReader: CompanyTimeZoneReader,
  ): TechnicianCapacityBlockInstancesReader =>
  async ({ type, input }) => {
    if (type !== 'local-date') throw new BadInputException('Invalid Request Type')

    const { companyGuid, date, technicianGuids } = input
    const tzId = await tzReader({ companyGuid })
    const startOfDay = BzDateFns.localDateStringToIsoDateString(date, tzId)
    const endOfDay = BzDateFns.formatISO(BzDateFns.addDays(BzDateFns.parseISO(startOfDay, tzId), 1), tzId)

    const technicianCapacityBlocks = await techCapacityBlockReader.readByUserGuids({
      companyGuid,
      userGuids: technicianGuids,
    })

    return {
      technicianCapacityBlockInstances: expandTechnicianCapacityBlocks(
        technicianCapacityBlocks,
        technicianGuids,
        startOfDay,
        endOfDay,
        tzId,
      ),
    }
  }
