import {
  AddressDtoSchema,
  bzExpect,
  bzOptional,
  guidSchema,
  hcpCompanyMigrationProfileSchema,
  STATE_ABBREVIATION_DISPLAY_NAMES,
  STATE_ABBREVIATIONS,
} from '@breezy/shared'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Input, Select } from 'antd'
import { useCallback, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { OnsiteBasicModal } from 'src/adam-components/OnsiteModal/OnsiteModal'
import GqlQueryLoader from 'src/components/GqlQueryLoader/GqlQueryLoader'
import { gql } from 'src/generated'
import { useExpectedCompany } from 'src/providers/PrincipalUser'
import { useMessage } from 'src/utils/antd-utils'
import { useQuery } from 'urql'
import { z } from 'zod'

const hcpCompanyMigrationProfileFormSchema = z.object({
  companyName: z.string(),
  hcpApiToken: z.string(),
  executingUserGuid: guidSchema,
  excludedHcpCustomerIds: bzOptional(z.array(z.string())),
  teamMembers: z
    .object({
      userGuid: bzOptional(guidSchema),
      emailAddress: z.string(),
      firstName: z.string(),
      lastName: z.string(),
      hcpId: z.string(),
    })
    .array(),
  defaultJobTypeGuids: z.object({
    installJobTypeGuid: guidSchema,
    serviceJobTypeGuid: guidSchema,
    maintenanceJobTypeGuid: guidSchema,
    estimateRequestGuid: guidSchema,
  }),
  defaultTaxRate: z.object({
    guid: guidSchema,
    taxRate: z.number(),
    name: z.string(),
  }),
  defaultTechnicianUserGuid: guidSchema,
  defaultLeadSourceGuid: guidSchema,
  defaultExistingCustomerLeadSourceGuid: guidSchema,
  defaultMissingAddress: AddressDtoSchema,
})

type HCPCompanyMigrationProfileFormSchema = z.infer<
  typeof hcpCompanyMigrationProfileFormSchema
>

const HCP_COMPANY_MIGRATION_PROFILE_DATA_QUERY = gql(/* GraphQL */ `
  query HCPCompanyMigrationProfileDataQuery($companyGuid: uuid!) {
    jobTypes(where: { companyGuid: { _eq: $companyGuid } }) {
      jobClass
      jobTypeGuid
      name
      jobLifecycleGuid
    }
    users(where: { companyUser: { companyGuid: { _eq: $companyGuid } } }) {
      userGuid
      firstName
      lastName
      emailAddress
    }
    companyLeadSources(where: { companyGuid: { _eq: $companyGuid } }) {
      companyLeadSourceGuid
      canonicalLeadSourceName
      canonicalLeadSourceNameOverride
    }
    pricebookTaxRates(where: { companyGuid: { _eq: $companyGuid } }) {
      pricebookTaxRateGuid
      rate
      name
    }
    jobLifecycleStatuses(where: { companyGuid: { _eq: $companyGuid } }) {
      jobLifecycleStatusGuid
      jobLifecycleGuid
      name
      jobLifecycle {
        name
      }
    }
  }
`)

export interface HCPCompanyMigrationProfileFormProps {
  jobTypes: {
    jobTypeGuid: string
    jobTypeName: string
    jobClass: string
    jobLifecycleGuid: string
  }[]
  jobLifecycleStatuses: {
    jobLifecycleStatusGuid: string
    jobLifecycleGuid: string
    jobLifecycleName: string
    jobLifecycleStatusName: string
  }[]
  teamMembers: {
    userGuid: string
    emailAddress: string
    firstName: string
    lastName: string
  }[]
  taxRates: {
    taxRateGuid: string
    taxRate: number
    name: string
  }[]
  leadSources: {
    companyLeadSourceGuid: string
    leadSourceName: string
  }[]
  onSubmit: (data: HCPCompanyMigrationProfileFormSchema) => void
}

export const HCPCompanyMigrationProfileForm = (
  props: HCPCompanyMigrationProfileFormProps,
) => {
  const form = useForm<HCPCompanyMigrationProfileFormSchema>({
    resolver: zodResolver(hcpCompanyMigrationProfileFormSchema),
    defaultValues: {
      teamMembers: props.teamMembers.map(teamMember => ({
        lastName: teamMember.lastName,
        firstName: teamMember.firstName,
        userGuid: teamMember.userGuid,
        emailAddress: teamMember.emailAddress,
      })),
    },
  })

  const onSubmit = form.handleSubmit(
    data => props.onSubmit(data),
    err => console.error(err),
  )

  const teamMemberSelectOptions = useMemo(() => {
    return props.teamMembers
      .sort((a, b) => a.firstName.localeCompare(b.firstName))
      .map(teamMember => ({
        label: `${teamMember.firstName} ${teamMember.lastName}`,
        value: teamMember.userGuid,
      }))
  }, [props.teamMembers])

  const jobTypeSelectOptions = useMemo(() => {
    const jobTypeMap: Map<
      string,
      {
        jobTypeName: string
        jobTypeGuid: string
        jobLifecycleGuid: string
        jobClass: string
      }[]
    > = new Map()

    for (const jobType of props.jobTypes) {
      if (!jobTypeMap.has(jobType.jobClass)) {
        jobTypeMap.set(jobType.jobClass, [jobType])
      } else {
        const currJobTypes = bzExpect(jobTypeMap.get(jobType.jobClass))
        currJobTypes.push(jobType)
        jobTypeMap.set(jobType.jobClass, currJobTypes)
      }
    }

    return Array.from(
      jobTypeMap.entries().map(([jobClass, jobTypes]) => ({
        label: jobClass,
        title: jobClass,
        options: jobTypes.map(jobType => ({
          label: jobType.jobTypeName,
          value: jobType.jobTypeGuid,
        })),
      })),
    )
  }, [props.jobTypes])

  const leadSourceSelectOptions = useMemo(() => {
    return props.leadSources.map(leadSource => ({
      label: leadSource.leadSourceName,
      value: leadSource.companyLeadSourceGuid,
    }))
  }, [props.leadSources])

  return (
    <form className="flex flex-col gap-4" onSubmit={onSubmit}>
      <h2 className="text-lg font-semibold">General Info</h2>

      <Controller
        control={form.control}
        name="companyName"
        render={({ field }) => (
          <label className="flex flex-col gap-2">
            <span>Company Name</span>

            <Input {...field} />
          </label>
        )}
      />

      <Controller
        control={form.control}
        name="hcpApiToken"
        render={({ field }) => (
          <label className="flex flex-col gap-2">
            <span>HouseCall Pro API Token</span>

            <Input {...field} />
          </label>
        )}
      />

      <Controller
        control={form.control}
        name="executingUserGuid"
        render={({ field }) => (
          <label className="flex flex-col gap-2">
            <span>Executing User</span>

            <Select
              {...field}
              showSearch
              placeholder="Select executing user"
              optionFilterProp="label"
              onChange={value => form.setValue('executingUserGuid', value)}
              options={teamMemberSelectOptions}
            />
          </label>
        )}
      />

      <Controller
        control={form.control}
        name="defaultTechnicianUserGuid"
        render={({ field }) => (
          <label className="flex flex-col gap-2">
            <span>Default Technician User</span>

            <Select
              {...field}
              showSearch
              placeholder="Select default technician user"
              optionFilterProp="label"
              onChange={value =>
                form.setValue('defaultTechnicianUserGuid', value)
              }
              options={teamMemberSelectOptions}
            />
          </label>
        )}
      />

      <Controller
        control={form.control}
        name="defaultTaxRate"
        render={() => (
          <label className="flex flex-col gap-2">
            <span>Default Tax Rate</span>

            <Select
              showSearch
              placeholder="Select default tax rate"
              optionFilterProp="label"
              onChange={value => {
                const taxRate = props.taxRates.find(
                  curr => curr.taxRateGuid === value,
                )

                if (!taxRate) {
                  return
                }

                form.setValue('defaultTaxRate', {
                  name: taxRate.name,
                  taxRate: taxRate.taxRate,
                  guid: taxRate.taxRateGuid,
                })
              }}
              options={props.taxRates.map(taxRate => ({
                label: taxRate.name,
                value: taxRate.taxRateGuid,
              }))}
            />
          </label>
        )}
      />

      <h2 className="text-lg font-semibold">Default Lead Sources</h2>

      <Controller
        control={form.control}
        name="defaultLeadSourceGuid"
        render={({ field }) => (
          <label className="flex flex-col gap-2">
            <span>Default Lead Source</span>

            <Select
              {...field}
              showSearch
              placeholder="Select default lead source"
              optionFilterProp="label"
              onChange={value => form.setValue('defaultLeadSourceGuid', value)}
              options={leadSourceSelectOptions}
            />
          </label>
        )}
      />

      <Controller
        control={form.control}
        name="defaultExistingCustomerLeadSourceGuid"
        render={({ field }) => (
          <label className="flex flex-col gap-2">
            <span>Default Existing Customer Lead Source</span>

            <Select
              {...field}
              showSearch
              placeholder="Select default lead source for existing customer"
              optionFilterProp="label"
              onChange={value =>
                form.setValue('defaultExistingCustomerLeadSourceGuid', value)
              }
              options={leadSourceSelectOptions}
            />
          </label>
        )}
      />

      <h2 className="text-lg font-semibold">Default Job Types</h2>

      <Controller
        control={form.control}
        name="defaultJobTypeGuids.installJobTypeGuid"
        render={({ field }) => (
          <label className="flex flex-col gap-2">
            <span>Install Job Type</span>

            <Select
              {...field}
              showSearch
              placeholder="Select default install job type"
              optionFilterProp="label"
              onChange={value =>
                form.setValue('defaultJobTypeGuids.installJobTypeGuid', value)
              }
              options={jobTypeSelectOptions}
            />
          </label>
        )}
      />

      <Controller
        control={form.control}
        name="defaultJobTypeGuids.serviceJobTypeGuid"
        render={({ field }) => (
          <label className="flex flex-col gap-2">
            <span>Service Job Type</span>

            <Select
              {...field}
              showSearch
              placeholder="Select default service job type"
              optionFilterProp="label"
              onChange={value =>
                form.setValue('defaultJobTypeGuids.serviceJobTypeGuid', value)
              }
              options={jobTypeSelectOptions}
            />
          </label>
        )}
      />

      <Controller
        control={form.control}
        name="defaultJobTypeGuids.maintenanceJobTypeGuid"
        render={({ field }) => (
          <label className="flex flex-col gap-2">
            <span>Maintenance Job Type</span>

            <Select
              {...field}
              showSearch
              placeholder="Select default maintenance job type"
              optionFilterProp="label"
              onChange={value =>
                form.setValue(
                  'defaultJobTypeGuids.maintenanceJobTypeGuid',
                  value,
                )
              }
              options={jobTypeSelectOptions}
            />
          </label>
        )}
      />

      <Controller
        control={form.control}
        name="defaultJobTypeGuids.estimateRequestGuid"
        render={({ field }) => (
          <label className="flex flex-col gap-2">
            <span>Estimate Job Type</span>

            <Select
              {...field}
              showSearch
              placeholder="Select default estimate request job type"
              optionFilterProp="label"
              onChange={value =>
                form.setValue('defaultJobTypeGuids.estimateRequestGuid', value)
              }
              options={jobTypeSelectOptions}
            />
          </label>
        )}
      />

      <div className="flex flex-col gap-2">
        <h2 className="text-lg font-semibold">Default Missing Address</h2>

        <div className="flex w-full flex-row justify-between gap-2">
          <Controller
            control={form.control}
            name="defaultMissingAddress.line1"
            render={({ field }) => (
              <label className="flex w-full flex-col gap-2">
                <span>Line 1</span>

                <Input {...field} />
              </label>
            )}
          />

          <Controller
            control={form.control}
            name="defaultMissingAddress.line2"
            render={({ field }) => (
              <label className="flex w-full flex-col gap-2">
                <span>Line 2</span>

                <Input {...field} />
              </label>
            )}
          />
        </div>

        <div className="flex flex-row justify-between gap-2">
          <Controller
            control={form.control}
            name="defaultMissingAddress.city"
            render={({ field }) => (
              <label className="flex w-full flex-col gap-2">
                <span>City</span>

                <Input {...field} />
              </label>
            )}
          />

          <Controller
            control={form.control}
            name="defaultMissingAddress.stateAbbreviation"
            render={() => (
              <label className="flex w-full flex-col gap-2">
                <span>State</span>

                <Select
                  showSearch
                  placeholder="Select state..."
                  optionFilterProp="label"
                  onChange={value =>
                    form.setValue(
                      'defaultMissingAddress.stateAbbreviation',
                      value,
                    )
                  }
                  options={STATE_ABBREVIATIONS.map(stateAbbreviation => ({
                    label: STATE_ABBREVIATION_DISPLAY_NAMES[stateAbbreviation],
                    value: stateAbbreviation,
                  }))}
                />
              </label>
            )}
          />

          <Controller
            control={form.control}
            name="defaultMissingAddress.zipCode"
            render={({ field }) => (
              <label className="flex w-full flex-col gap-2">
                <span>ZIP Code</span>

                <Input {...field} />
              </label>
            )}
          />
        </div>
      </div>

      <h2 className="text-lg font-semibold">Team Members HCP Ids</h2>

      <div className="flex flex-col gap-2">
        {props.teamMembers.map((teamMember, idx) => (
          <div
            key={teamMember.userGuid}
            className="flex w-full flex-row justify-between gap-2"
          >
            <Controller
              control={form.control}
              name={`teamMembers.${idx}.hcpId`}
              render={({ field }) => (
                <label className="flex w-full flex-col gap-2">
                  <span>{`${teamMember.firstName} ${teamMember.lastName} HCP Id`}</span>

                  <Input {...field} />
                </label>
              )}
            />
          </div>
        ))}
      </div>

      <Button htmlType="submit" type="primary">
        Generate
      </Button>
    </form>
  )
}

export interface HCPCompanyProfileMigrationModalProps {
  open: boolean
  onClose: () => void
}

export const HCPCompanyProfileMigrationModal = (
  props: HCPCompanyProfileMigrationModalProps,
) => {
  const company = useExpectedCompany()

  const hcpDataQuery = useQuery({
    query: HCP_COMPANY_MIGRATION_PROFILE_DATA_QUERY,
    variables: { companyGuid: company.companyGuid },
  })

  const message = useMessage()

  const jobTypes = useMemo(() => {
    const data = hcpDataQuery[0].data
    if (!data) {
      return []
    }

    return data.jobTypes.map(jobType => ({
      jobTypeGuid: jobType.jobTypeGuid,
      jobTypeName: jobType.name,
      jobClass: jobType.jobClass,
      jobLifecycleGuid: jobType.jobLifecycleGuid,
    }))
  }, [hcpDataQuery])

  const lifecycleStatuses = useMemo(() => {
    const data = hcpDataQuery[0].data
    if (!data) {
      return []
    }

    return data.jobLifecycleStatuses.map(status => ({
      jobLifecycleGuid: status.jobLifecycleGuid,
      jobLifecycleName: status.jobLifecycle.name,
      jobLifecycleStatusGuid: status.jobLifecycleStatusGuid,
      jobLifecycleStatusName: status.name,
    }))
  }, [hcpDataQuery])

  const taxRates = useMemo(() => {
    const data = hcpDataQuery[0].data
    if (!data) {
      return []
    }

    return data.pricebookTaxRates.map(taxRate => ({
      name: taxRate.name,
      taxRate: taxRate.rate,
      guid: taxRate.pricebookTaxRateGuid,
    }))
  }, [hcpDataQuery])

  const leadSources = useMemo(() => {
    const data = hcpDataQuery[0].data
    if (!data) {
      return []
    }

    return data.companyLeadSources.map(leadSource => ({
      companyLeadSourceGuid: leadSource.companyLeadSourceGuid,
      leadSourceName:
        leadSource.canonicalLeadSourceNameOverride ??
        leadSource.canonicalLeadSourceName,
    }))
  }, [hcpDataQuery])

  const onFormSubmit = useCallback(
    (data: HCPCompanyMigrationProfileFormSchema) => {
      const queryData = hcpDataQuery[0].data

      if (!queryData) {
        return
      }

      const migrationProfileParse = hcpCompanyMigrationProfileSchema.safeParse({
        hcpApiToken: data.hcpApiToken,
        companyName: data.companyName,
        companyGuid: company.companyGuid,
        timezone: company.timezone,
        executingUserGuid: data.executingUserGuid,
        excludedHcpCustomerIds: [],
        jobTypes,
        MISC_INSTALL_JOB_TYPE_GUID: data.defaultJobTypeGuids.installJobTypeGuid,
        MISC_SERVICE_JOB_TYPE_GUID: data.defaultJobTypeGuids.serviceJobTypeGuid,
        MISC_MAINTENANCE_JOB_TYPE_GUID:
          data.defaultJobTypeGuids.maintenanceJobTypeGuid,
        ESTIMATE_REQUEST: data.defaultJobTypeGuids.estimateRequestGuid,
        lifecycles: lifecycleStatuses.map(status => ({
          ...status,
          name: status.jobLifecycleStatusName,
        })),
        TEAM_MEMBERS: data.teamMembers,
        DEFAULT_TECHNICIAN_USER_GUID: data.defaultTechnicianUserGuid,
        taxRates,
        DEFAULT_TAX_RATE: data.defaultTaxRate,
        maintenancePlans: [],
        leadSources,
        defaultLeadSourceGuid: data.defaultLeadSourceGuid,
        defaultExistingCustomerLeadSourceGuid:
          data.defaultExistingCustomerLeadSourceGuid,
        defaultMissingAddress: data.defaultMissingAddress,
      })

      if (migrationProfileParse.success) {
        navigator.clipboard.writeText(
          JSON.stringify(migrationProfileParse.data, null, 2),
        )
        message.success('Company Migration Profile JSON copied to clipboard!')
      } else {
        console.error(migrationProfileParse.error)
        message.error('Failed to parse form. See console for more details')
      }
    },
    [
      company.companyGuid,
      company.timezone,
      hcpDataQuery,
      jobTypes,
      leadSources,
      lifecycleStatuses,
      message,
      taxRates,
    ],
  )

  return (
    <OnsiteBasicModal
      header="HCP Company Migration Profile JSON Generator"
      open={props.open}
      onClose={props.onClose}
    >
      <GqlQueryLoader
        query={hcpDataQuery}
        render={data => (
          <HCPCompanyMigrationProfileForm
            onSubmit={onFormSubmit}
            jobTypes={jobTypes}
            jobLifecycleStatuses={lifecycleStatuses}
            teamMembers={data.users.map(user => ({
              emailAddress: user.emailAddress,
              userGuid: user.userGuid,
              firstName: user.firstName,
              lastName: user.lastName,
            }))}
            taxRates={data.pricebookTaxRates.map(taxRate => ({
              name: taxRate.name,
              taxRate: taxRate.rate,
              taxRateGuid: taxRate.pricebookTaxRateGuid,
            }))}
            leadSources={data.companyLeadSources.map(leadSource => ({
              companyLeadSourceGuid: leadSource.companyLeadSourceGuid,
              leadSourceName:
                leadSource.canonicalLeadSourceNameOverride ??
                leadSource.canonicalLeadSourceName,
            }))}
          />
        )}
      />
    </OnsiteBasicModal>
  )
}
