import {
  MaintenancePlanCollapsibleViewModel,
  MaintenancePlanStatus,
  formatUsc,
  getAllInstalledEquipmentGuidsFromVisits,
  getNumUnusedMaintenancePlanVisits,
  getRenewalDueAt,
} from '@breezy/shared'
import React, { useMemo } from 'react'
import { OnsitePageCollapsibleSection } from 'src/adam-components/OnsitePage/OnsitePageCollapsibleSection'
import {
  MaintenancePlanCard,
  MaintenancePlanCardProps,
} from 'src/components/Cards/MaintenancePlanCard'
import { useExpectedCompanyTimeZoneId } from 'src/providers/PrincipalUser'
import ThinDivider from '../../../elements/ThinDivider'
import useAppNavigation from '../../../hooks/useAppNav'
import { useCompanyMaintenancePlansEnabled } from '../../../hooks/useCompanyMaintenancePlansEnabled'
import MaintenancePlanStatusTag from '../../../pages/MaintenancePlanDetailsPage/MaintenancePlanStatusTag'
import tailwindConfig from '../../../tailwind.config'
import { m } from '../../../utils/react-utils'
import CollapsibleItem from '../../Page/BzCollapsible/CollapsibleItem/CollapsibleItem'

type MaintenancePlansCollapsibleContentProps = {
  plans: MaintenancePlanCollapsibleViewModel[]
  activeMaintenancePlanGuid?: string
  hideCanceledPlans?: boolean
}

export const MaintenancePlansCollapsibleContent =
  React.memo<MaintenancePlansCollapsibleContentProps>(
    ({ plans, activeMaintenancePlanGuid, hideCanceledPlans = false }) => {
      const appNav = useAppNavigation()

      const sortedPlans = useMemo(() => {
        const filteredPlans = hideCanceledPlans
          ? plans.filter(p => p.status !== MaintenancePlanStatus.CANCELED)
          : plans

        return filteredPlans.sort((a, b) => {
          if (a.maintenancePlanGuid === activeMaintenancePlanGuid) return -1
          if (b.maintenancePlanGuid === activeMaintenancePlanGuid) return 1
          return 0
        })
      }, [plans, hideCanceledPlans, activeMaintenancePlanGuid])
      return (
        <>
          {sortedPlans.map((p, index) => (
            <React.Fragment key={p.maintenancePlanGuid}>
              <CollapsibleItem
                title={p.locationAddress.line1}
                tag={
                  <MaintenancePlanStatusTag
                    status={p.status}
                    paymentFlow={p.paymentFlow}
                  />
                }
                onTitleClick={() =>
                  appNav.navigateToMaintenancePlanDetailsPage(
                    p.maintenancePlanGuid,
                  )
                }
                contentList={[
                  { key: 'Type', value: p.planTypeName },
                  { key: 'Yearly Revenue', value: formatUsc(p.yearlyPriceUsc) },
                  {
                    key: 'Num Covered Equipment',
                    value: getAllInstalledEquipmentGuidsFromVisits(
                      p.visits,
                    ).length.toString(),
                  },
                  {
                    key: 'Num Unused Visits',
                    value: getNumUnusedMaintenancePlanVisits(
                      p.visits,
                    ).toString(),
                  },
                ]}
                alwaysOpen
              />
              {sortedPlans.length > 1 &&
                index === 0 &&
                p.maintenancePlanGuid === activeMaintenancePlanGuid && (
                  <ThinDivider
                    widthPx={1}
                    borderColorClassName={
                      tailwindConfig.theme.extend.colors.bz.border
                    }
                    styleOverrides={{ marginBottom: 4 }}
                  />
                )}
            </React.Fragment>
          ))}
        </>
      )
    },
  )

type MaintenancePlansCollapsibleProps =
  MaintenancePlansCollapsibleContentProps & {
    createNew?: () => void
    titleOverride?: string
    technicianAppStyle?: boolean
    className?: string
  }

const MaintenancePlansCollapsible = m<MaintenancePlansCollapsibleProps>(
  ({
    plans,
    createNew,
    titleOverride,
    technicianAppStyle,
    activeMaintenancePlanGuid,
    hideCanceledPlans = false,
    className,
  }) => {
    const isCompanyMaintenancePlansEnabled = useCompanyMaintenancePlansEnabled()
    const timezoneId = useExpectedCompanyTimeZoneId()

    const items: MaintenancePlanCardProps['maintenancePlan'][] = useMemo(() => {
      const items: MaintenancePlanCardProps['maintenancePlan'][] = []

      for (const plan of plans) {
        if (
          hideCanceledPlans &&
          plan.status === MaintenancePlanStatus.CANCELED
        ) {
          continue
        }

        const renewalDate = getRenewalDueAt(
          {
            yearlyPriceUsc: plan.yearlyPriceUsc,
            activatedAt: plan.activatedAt,
            billingStartAt: plan.billingStartAt,
          },
          timezoneId,
        )

        items.push({
          maintenancePlanGuid: plan.maintenancePlanGuid,
          location: {
            locationGuid: plan.locationGuid,
            address: {
              line1: plan.locationAddress.line1,
            },
          },
          billing: {
            frequency: plan.paymentInterval,
            anchorDay: plan.billingAnchor,
          },
          status: plan.status,
          activationDate: plan.activatedAt,
          renewalDate: renewalDate,
          numCoveredEquipment: plan.coveredInstalledEquipmentGuids.length,
          paymentFlow: plan.paymentFlow,
          yearlyRevenueUsc: plan.yearlyPriceUsc,
          planTypeName: plan.planTypeName,
          isActiveMaintenancePlanGuid:
            plan.maintenancePlanGuid === activeMaintenancePlanGuid,
        })
      }

      return items.sort((a, b) => {
        if (a.maintenancePlanGuid === activeMaintenancePlanGuid) return -1
        if (b.maintenancePlanGuid === activeMaintenancePlanGuid) return 1
        return 0
      })
    }, [activeMaintenancePlanGuid, hideCanceledPlans, plans, timezoneId])

    if (!isCompanyMaintenancePlansEnabled) {
      return null
    }

    return (
      <OnsitePageCollapsibleSection
        smallTitle
        title={titleOverride ?? 'Maintenance Plans'}
        count={plans.length}
        defaultCollapsed={plans.length === 0}
        onAdd={createNew}
        testId="maintenance plan-collapsible"
        addButtonTestId="maintenance plans-collapsible-add-button"
        className={className}
      >
        <div className="flex flex-col gap-2">
          {items.map(plan => (
            <MaintenancePlanCard
              key={plan.maintenancePlanGuid}
              maintenancePlan={plan}
              timezoneId={timezoneId}
            />
          ))}
        </div>
      </OnsitePageCollapsibleSection>
    )
  },
)

export default MaintenancePlansCollapsible
