import { faRotate } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Button, Popover } from 'antd'
import { useCallback, useMemo, useState } from 'react'
import ThinDivider from '../../../elements/ThinDivider'
import { useQuickbooksDesktopState } from '../../../providers/QuickbooksDesktopOnlineWrapper'

const connectedColor = 'green'
const inactiveColor = 'red'

export const QuickbooksDesktopStatusWidget = () => {
  const { isOnline, isEnabled, recheck } = useQuickbooksDesktopState()
  const [isChecking, setIsChecking] = useState(false)

  const statusColor = useMemo(() => {
    if (!isEnabled) return inactiveColor
    return isOnline ? connectedColor : inactiveColor
  }, [isEnabled, isOnline])

  const handleRecheck = useCallback(async () => {
    setIsChecking(true)
    try {
      await recheck()
    } finally {
      setIsChecking(false)
    }
  }, [recheck])

  const popoverContent = useMemo(
    () => (
      <div className="flex flex-col">
        <ThinDivider />
        {!isEnabled && <div>QuickBooks Desktop not enabled</div>}
        {isEnabled && isOnline && (
          <div>Connection is online and working properly</div>
        )}
        {isEnabled && !isOnline && (
          <div>
            <div className="space-y-2">
              <div className="font-medium text-red-600">
                Connection is currently offline
              </div>
              <div className="text-sm italic text-gray-500">
                (Status will update within 5 minutes)
              </div>
              <div className="text-gray-700">
                Items will be automatically synced as soon as the connection is
                reestablished.
              </div>
              <Button
                size="small"
                onClick={handleRecheck}
                loading={isChecking}
                icon={<FontAwesomeIcon icon={faRotate} />}
                style={{ minWidth: 140 }}
              >
                Check Status Now
              </Button>
            </div>
            <ThinDivider />
            <a
              href="https://docs.conductor.is/help/connection-errors/connection-not-active"
              target="_blank"
              rel="noopener noreferrer"
              className="mt-2 block text-blue-600 hover:underline"
            >
              View troubleshooting guide
            </a>
          </div>
        )}
      </div>
    ),
    [isEnabled, isOnline, handleRecheck, isChecking],
  )

  return (
    <Popover
      title="QuickBooks Desktop"
      placement="bottomRight"
      content={popoverContent}
    >
      <Badge dot color={statusColor}>
        <span className="grey8 font-mono text-[18px]">qb</span>
      </Badge>
    </Popover>
  )
}
