import { Guid } from '@breezy/shared'
import { useQuery } from 'urql'
import { EventColorConfig } from '../../utils/job-utils'
import { FETCH_SCHEDULE_COLOR_MODE_QUERY } from './useScheduleColorMode.gql'

const defaultScheduleColorMode = 'byJobClass'

export type ScheduleColorMode = 'byJobClass' | 'byTech'

export const useScheduleColorMode = (companyGuid: Guid): ScheduleColorMode => {
  const [{ data }] = useQuery({
    query: FETCH_SCHEDULE_COLOR_MODE_QUERY,
    variables: { companyGuid },
  })

  const rawColorMode = data?.companyConfig?.scheduleColorMode
  const result =
    rawColorMode === 'byJobClass' || rawColorMode === 'byTech'
      ? rawColorMode
      : defaultScheduleColorMode

  return result
}

export const toEventColorConfig = (primaryHueHex: string): EventColorConfig => {
  // Convert hex to HSL to manipulate saturation and lightness
  const hexToHSL = (hex: string) => {
    // Remove # if present
    hex = hex.replace('#', '')

    // Convert hex to RGB
    const r = parseInt(hex.substring(0, 2), 16) / 255
    const g = parseInt(hex.substring(2, 4), 16) / 255
    const b = parseInt(hex.substring(4, 6), 16) / 255

    const max = Math.max(r, g, b)
    const min = Math.min(r, g, b)
    const l = (max + min) / 2
    let h = 0,
      s = 0

    if (max !== min) {
      const d = max - min
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min)

      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0)
          break
        case g:
          h = (b - r) / d + 2
          break
        case b:
          h = (r - g) / d + 4
          break
      }
      h /= 6
    }

    return { h: h * 360, s: s * 100, l: l * 100 }
  }

  const hslToHex = (h: number, s: number, l: number) => {
    l /= 100
    const a = (s * Math.min(l, 1 - l)) / 100
    const f = (n: number) => {
      const k = (n + h / 30) % 12
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
      return Math.round(255 * color)
        .toString(16)
        .padStart(2, '0')
    }
    return `#${f(0)}${f(8)}${f(4)}`
  }

  const baseHSL = hexToHSL(primaryHueHex)

  // Main accent (original color)
  const mainAccent = primaryHueHex

  // Light accent: decrease saturation by 15%, increase lightness by 25%
  const lightAccent = hslToHex(
    baseHSL.h,
    Math.max(baseHSL.s, 0), // No saturation decrease
    Math.min(baseHSL.l + 20, 100), // Larger lightness increase, capped at 100%
  )

  // Background: keep higher saturation, maximize lightness
  const background = hslToHex(
    baseHSL.h,
    Math.max(baseHSL.s, 0), // No saturation decrease
    Math.min(baseHSL.l + 38, 95), // Larger lightness increase, capped at 95%
  )

  return {
    eventColor: background,
    background: background,
    mapPinColor: mainAccent,
    ribbon: mainAccent,
    completedRibbon: lightAccent,
  }
}
