import { AccountGuid, InvoiceTerm } from '@breezy/shared'
import React, { useCallback, useMemo } from 'react'
import { useQuery } from 'urql'

import { InvoiceWidgetCard } from 'src/components/InvoicesWidget/InvoicesWidget'
import { InvoicesWidgetInvoice } from 'src/components/InvoicesWidget/InvoicesWidget.gql'
import {
  OnsiteModal,
  OnsiteModalContent,
} from '../../../adam-components/OnsiteModal/OnsiteModal'
import { PaginationControls } from '../../../adam-components/Pagination/PaginationControls'
import { usePagination } from '../../../adam-components/Pagination/usePagination'
import { SimpleInvoiceV2CollapsibleV2 } from '../../../components/collapsibles/InvoicesCollapsible/InvoicesV2CollapsibleV2'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { gql } from '../../../generated'
import { AccountInvoicesCollapsibleCardsQuery } from '../../../generated/user/graphql'
import { useModalState } from '../../../utils/react-utils'
import { DEFAULT_ACCOUNT_COLLAPSIBLES_LIMIT } from '../accountDetailsV2Utils'

const ACCOUNT_INVOICES_QUERY = gql(/* GraphQL */ `
  query AccountInvoicesCollapsibleCards(
    $accountGuid: uuid!
    $limit: Int!
    $offset: Int!
  ) {
    accountsByPk(accountGuid: $accountGuid) {
      invoicesAggregate {
        aggregate {
          count
        }
      }
      invoices(
        orderBy: [{ issuedAt: DESC_NULLS_FIRST }]
        limit: $limit
        offset: $offset
      ) {
        serviceCompletionDate
        displayId
        invoiceGuid
        status
        totalUsc
        maintenancePlanLink {
          maintenancePlanGuid
        }
        jobLink {
          jobGuid
          job {
            displayId
            jobType {
              name
            }
          }
        }
        invoiceTerm
        issuedAt
        paymentRecords {
          paymentMethod
          paymentRecordGuid
          amountUsd
        }
        createdByUser {
          fullName
        }
        cartItems: invoiceCartItems(orderBy: { seq: ASC }) {
          cartItem {
            name
            unitPriceUsc
            quantity
            isDiscountable
          }
        }
        discounts: invoiceDiscounts {
          discountRate
          type: discountType
          discountAmountUsc
        }
      }
    }
  }
`)

const convertQueryToInvoiceCardInvoices = (
  invoices: NonNullable<
    AccountInvoicesCollapsibleCardsQuery['accountsByPk']
  >['invoices'],
): InvoicesWidgetInvoice[] => {
  return invoices.map(invoice => ({
    invoiceGuid: invoice.invoiceGuid,
    displayId: invoice.displayId,
    issuedAt: invoice.issuedAt,
    status: invoice.status,
    totalUsc: invoice.totalUsc,
    invoiceTerm: invoice.invoiceTerm as unknown as InvoiceTerm,
    jobLink: invoice.jobLink,
    maintenancePlan: invoice.maintenancePlanLink
      ? {
          maintenancePlanGuid: invoice.maintenancePlanLink.maintenancePlanGuid,
        }
      : undefined,
    payments: invoice.paymentRecords.map(paymentRecord => ({
      paymentRecordGuid: paymentRecord.paymentRecordGuid,
      paymentMethod: paymentRecord.paymentMethod,
      amountUsd: paymentRecord.amountUsd,
    })),
    cartItems: invoice.cartItems,
    createdByUser: invoice.createdByUser,
    discounts: invoice.discounts,
    paymentRecords: invoice.paymentRecords,
  }))
}

const useFetchAccountInvoices = (
  accountGuid: AccountGuid,
  {
    limit = DEFAULT_ACCOUNT_COLLAPSIBLES_LIMIT,
    offset = 0,
  }: { limit?: number; offset?: number },
) => {
  const accountInvoicesQuery = useQuery({
    query: ACCOUNT_INVOICES_QUERY,
    variables: {
      accountGuid,
      limit,
      offset,
    },
  })

  const invoices = useMemo(
    () =>
      convertQueryToInvoiceCardInvoices(
        accountInvoicesQuery[0].data?.accountsByPk?.invoices ?? [],
      ),
    [accountInvoicesQuery],
  )
  const total = useMemo(
    () =>
      accountInvoicesQuery[0].data?.accountsByPk?.invoicesAggregate?.aggregate
        ?.count ?? 0,
    [accountInvoicesQuery],
  )

  return {
    invoices,
    refetch: accountInvoicesQuery[1],
    fetching: accountInvoicesQuery[0].fetching,
    total,
  }
}

type AccountInvoicesCollapsibleProps = {
  accountGuid: AccountGuid
  onViewMore?: () => void
}

export const AccountInvoicesCollapsible =
  React.memo<AccountInvoicesCollapsibleProps>(({ accountGuid, onViewMore }) => {
    const { invoices, total } = useFetchAccountInvoices(accountGuid, {
      offset: 0,
    })

    const [viewMoreOpen, viewMoreInvoices, closeViewMoreInvoices] =
      useModalState()

    return (
      <>
        <SimpleInvoiceV2CollapsibleV2
          invoices={invoices}
          mode="paginated"
          total={total}
          onViewMore={onViewMore ?? viewMoreInvoices}
        />
        {viewMoreOpen && (
          <AccountInvoicesViewMoreModal
            accountGuid={accountGuid}
            onClose={closeViewMoreInvoices}
          />
        )}
      </>
    )
  })

type AccountInvoicesViewMoreModalProps = {
  accountGuid: AccountGuid
  onClose: () => void
}

const AccountInvoicesViewMoreModal =
  React.memo<AccountInvoicesViewMoreModalProps>(
    ({ accountGuid, onClose: externalOnClose }) => {
      const { page, pageSize, setPage, setPageSize, limit, offset, resetPage } =
        usePagination('10', 1)

      const { invoices, total, fetching } = useFetchAccountInvoices(
        accountGuid,
        {
          limit,
          offset,
        },
      )

      const onClose = useCallback(() => {
        externalOnClose()
        setPageSize('10')
        resetPage()
      }, [externalOnClose, setPageSize, resetPage])

      return (
        <>
          <OnsiteModal open size="large" onClose={onClose}>
            <OnsiteModalContent
              onClose={onClose}
              header="Invoices"
              footer={
                <PaginationControls
                  className="mt-4"
                  page={page}
                  pageSize={pageSize}
                  totalItems={total}
                  setPage={setPage}
                  setPageSize={setPageSize}
                />
              }
            >
              {fetching ? (
                <LoadingSpinner />
              ) : (
                <div className="flex min-h-0 flex-col gap-2">
                  {invoices.map((invoice, idx) => (
                    <div>
                      <InvoiceWidgetCard
                        key={invoice.invoiceGuid}
                        small
                        readonly
                        {...invoice}
                      />
                    </div>
                  ))}
                </div>
              )}
            </OnsiteModalContent>
          </OnsiteModal>
        </>
      )
    },
  )
