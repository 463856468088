import { mapGqlLocationMaintenancePlansToMinimals } from '@breezy/backend/src/application-types'
import { parseEquipment } from '@breezy/backend/src/application-types/parsers'
import {
  AccountContact,
  AccountGuid,
  AccountLocation,
  BzDateTime,
  IsoDateString,
  LocalDate,
  LocalDateString,
  Location,
  MaintenancePlanPaymentInterval,
  NumPeriodsPerYearForInterval,
  TaxRateSetEventData,
  TimeZoneId,
  toTitleCase,
  uscDivide,
  uscMultiply,
} from '@breezy/shared'
import { useCallback, useMemo } from 'react'
import { useQuery } from 'urql'
import { DocumentType, gql } from '../../generated'
import { MaintenancePlanWizardPaginatedAccountLocationsQuery } from '../../generated/user/graphql'
import { DEFAULT_ACCOUNT_COLLAPSIBLES_LIMIT } from '../AccountDetailsPageV2/accountDetailsV2Utils'

export const toPlanBillingLabel = (
  isFreePlan: boolean,
  isAutoRenewing: boolean,
  paymentInterval: MaintenancePlanPaymentInterval,
  numYears: number | undefined,
) => {
  const impliedNumYears = !numYears || numYears < 0 ? 1 : numYears
  const yearText = impliedNumYears > 1 ? 'Years' : 'Year'
  if (isFreePlan) {
    return isAutoRenewing
      ? 'No Billing - Free Plan - Forever'
      : `No Billing - Free Plan - ${impliedNumYears} ${yearText}`
  }
  const isOneYearPlan = isAutoRenewing || numYears === 1
  const yearPlanTextName = isOneYearPlan ? 'Annual' : `${numYears}-Year`
  return `${yearPlanTextName} Plan, Paid ${toTitleCase(
    paymentInterval.toString(),
  )}`
}

export const getBillingAnchorDate = (
  billingAnchorDay: string,
  activationDate: LocalDateString,
  tzId: TimeZoneId,
): LocalDateString | undefined => {
  const billingAnchorDayOfMonth = Number(billingAnchorDay)
  if (
    isNaN(billingAnchorDayOfMonth) ||
    billingAnchorDayOfMonth < 1 ||
    billingAnchorDayOfMonth > 31
  ) {
    return undefined
  }

  let currentDateTime = BzDateTime.fromDateString(activationDate, tzId)
  while (currentDateTime.dayOfMonth() !== billingAnchorDayOfMonth) {
    currentDateTime = currentDateTime.plusDays(1)
  }
  return currentDateTime.toLocalDateString()
}

export const calcSubtotalPerInterval = ({
  isFreePlan,
  isImportedPlan,
  subtotalYearlyPriceUsc,
  adjustmentYearlyPriceUsc,
  billingPaymentInterval,
  numYears,
}: {
  isFreePlan: boolean
  isImportedPlan: boolean
  subtotalYearlyPriceUsc: number
  adjustmentYearlyPriceUsc: number
  billingPaymentInterval: MaintenancePlanPaymentInterval
  numYears: number
}) => {
  if (isFreePlan || isImportedPlan) {
    return 0
  }

  if (billingPaymentInterval === MaintenancePlanPaymentInterval.UPFRONT) {
    return uscMultiply(
      subtotalYearlyPriceUsc + adjustmentYearlyPriceUsc,
      numYears,
    )
  }

  return uscDivide(
    subtotalYearlyPriceUsc + adjustmentYearlyPriceUsc,
    NumPeriodsPerYearForInterval[billingPaymentInterval],
  )
}

export const calcMaintenancePlanPricing = ({
  isFreePlan,
  isImportedPlan,
  subtotalYearlyPriceUsc,
  adjustmentYearlyPriceUsc,
  billingPaymentInterval,
  taxRate,
  numYears,
}: {
  isFreePlan: boolean
  isImportedPlan: boolean
  subtotalYearlyPriceUsc: number
  adjustmentYearlyPriceUsc: number
  billingPaymentInterval: MaintenancePlanPaymentInterval
  taxRate: TaxRateSetEventData
  numYears: number
}) => {
  const intervalSubtotalPriceUsc = calcSubtotalPerInterval({
    isFreePlan,
    isImportedPlan,
    subtotalYearlyPriceUsc,
    adjustmentYearlyPriceUsc,
    billingPaymentInterval,
    numYears,
  })

  const intervalTaxPriceUsc = uscMultiply(
    intervalSubtotalPriceUsc,
    taxRate.rate,
  )

  const intervalTotalPriceUsc = intervalSubtotalPriceUsc + intervalTaxPriceUsc

  const annualTotalPriceUsc = uscDivide(
    uscMultiply(
      intervalTotalPriceUsc,
      NumPeriodsPerYearForInterval[billingPaymentInterval],
    ),
    numYears,
  )

  return {
    intervalSubtotalPriceUsc,
    intervalTaxPriceUsc,
    intervalTotalPriceUsc,
    annualTotalPriceUsc,
  }
}

export const zeroTaxRate: TaxRateSetEventData = {
  // This needs to be a real guid to satisfy the zod guidSchema form validation
  taxRateGuid: '63feeac0-4ec1-11ef-8646-af448ef42b3e',
  rate: 0,
}

export const isZeroTaxRate = (taxRate: {
  taxRateGuid: string | null
  rate: number
}) => taxRate.taxRateGuid === zeroTaxRate.taxRateGuid

const MAINTENANCE_PLAN_WIZARD_ACCOUNT_QUERY = gql(/* GraphQL */ `
  query MaintenancePlanWizardAccount($accountGuid: uuid!) {
    accountsByPk(accountGuid: $accountGuid) {
      accountContacts(
        orderBy: [
          { primary: DESC }
          { archived: ASC }
          { createdAt: DESC }
          { updatedAt: DESC }
        ]
        limit: 1
      ) {
        accountContactGuid
        archived
        primary
        companyGuid
        accountGuid
        contact {
          contactGuid
          companyGuid
          firstName
          lastName
          salutation
          title
          notificationPreferenceType
          primaryEmailAddress {
            emailAddress
            emailAddressGuid
            companyGuid
          }
          primaryPhoneNumber {
            phoneNumber
            phoneNumberGuid
            companyGuid
            type
            unsubscribed
          }
          additionalEmailAddress {
            emailAddress
            emailAddressGuid
            companyGuid
          }
          additionalPhoneNumber {
            phoneNumber
            phoneNumberGuid
            companyGuid
            type
            unsubscribed
          }
        }
      }
    }
  }
`)

type MaintenancePlanWizardAccountQuery = DocumentType<
  typeof MAINTENANCE_PLAN_WIZARD_ACCOUNT_QUERY
>

const convertQueryToAccountContacts = (
  accountContacts: NonNullable<
    MaintenancePlanWizardAccountQuery['accountsByPk']
  >['accountContacts'],
): AccountContact[] => {
  return (
    accountContacts
      .map(ac => ({
        accountContactGuid: ac.accountContactGuid,
        accountGuid: ac.accountGuid,
        companyGuid: ac.companyGuid,
        primary: ac.primary,
        archived: ac.archived,
        contact: ac.contact,
      }))
      .sort((a, b) => {
        return a.primary ? -1 : b.primary ? 1 : a.archived ? 1 : -1
      }) ?? []
  )
}

export const useCurrentMaintenancePlanWizardAccount = (
  accountGuid?: string,
) => {
  const mpWizardAccountQuery = useQuery({
    query: MAINTENANCE_PLAN_WIZARD_ACCOUNT_QUERY,
    variables: { accountGuid: accountGuid ?? '' },
    pause: !accountGuid,
  })

  const currentAccountPrimaryContact = useMemo(() => {
    if (!mpWizardAccountQuery[0].data?.accountsByPk) {
      return undefined
    }

    return convertQueryToAccountContacts(
      mpWizardAccountQuery[0].data?.accountsByPk.accountContacts ?? [],
    )[0]
  }, [mpWizardAccountQuery])

  const refetch = useCallback(() => {
    mpWizardAccountQuery[1]()
  }, [mpWizardAccountQuery])

  return {
    mpWizardAccountQuery,
    currentAccountPrimaryContact,
    fetching: mpWizardAccountQuery[0].fetching,
    refetch,
  }
}

/** TODO: DRY this up with the other account location queries */
const MAINTENANCE_PLAN_WIZARD_SELECTED_ACCOUNT_LOCATION_QUERY =
  gql(/* GraphQL */ `
    query MaintenancePlanWizardSelectedAccountLocation($locationGuid: uuid!) {
      accountLocations(
        where: { location: { locationGuid: { _eq: $locationGuid } } }
        orderBy: [{ isArchived: ASC }, { createdAt: DESC }, { updatedAt: DESC }]
        limit: 1
      ) {
        location {
          locationGuid
          address {
            addressGuid
            line1
            line2
            stateAbbreviation
            zipCode
            city
          }
          companyGuid
          displayName
          estimatedBuildDate
          estimatedSquareFootage
          propertyType
          municipality
          maintenancePlans {
            ...MaintenancePlanMinimal
          }
          installedEquipment {
            installedEquipmentGuid
            averageLifeExpectancyYears
            description
            equipmentType
            estimatedEndOfLifeDate
            installationDate
            installationParty
            installedEquipmentGuid
            laborWarrantyEndDate
            manufacturingDate
            laborWarrantyStartDate
            laborWarrantyTerms
            equipmentDimensions
            locationGuid
            manufacturer
            manufacturerWarrantyEndDate
            manufacturerWarrantyStartDate
            manufacturerWarrantyTerms
            modelNumber
            operationalStatus
            equipmentCondition
            serialNumber
          }
          jobs(orderBy: { createdAt: DESC }) {
            jobGuid
            displayId
            jobType {
              name
            }
            workCompletedAt
            createdAt
            location {
              address {
                line1
                zipCode
              }
            }
          }
        }
        accountGuid
        companyGuid
        isArchived
        isBillingAddress
      }
    }
  `)

type AccountLocationsQuery = DocumentType<
  typeof MAINTENANCE_PLAN_WIZARD_SELECTED_ACCOUNT_LOCATION_QUERY
>

export type LocationJob = {
  jobGuid: string
  displayId: number
  jobType: {
    name: string
  }
  createdAt: IsoDateString
  workCompletedAt?: IsoDateString
  location: {
    address: {
      line1: string
      zipCode: string
    }
  }
}

export type WithLocationJobs<T extends { location: Location }> = T & {
  location: T['location'] & {
    jobs: LocationJob[]
  }
}
/** TODO: DRY this up with the other account location queries */
const convertQueryToAccountLocationsWithJobs = (
  accountLocations: NonNullable<AccountLocationsQuery['accountLocations']>,
): WithLocationJobs<AccountLocation>[] => {
  return accountLocations.map(al => ({
    ...al,
    maintenancePlanGuids: al.location.maintenancePlans.map(
      mp => mp.maintenancePlanGuid,
    ),
    location: {
      ...al.location,
      estimatedBuildDate: al.location.estimatedBuildDate
        ? LocalDate.parse(al.location.estimatedBuildDate)
        : undefined,
      maintenancePlans: mapGqlLocationMaintenancePlansToMinimals(
        al.location.maintenancePlans,
      ),
      installedEquipment: al.location.installedEquipment.map(parseEquipment),
      jobs: al.location.jobs.map(j => ({
        jobGuid: j.jobGuid,
        displayId: j.displayId,
        jobType: j.jobType,
        createdAt: j.createdAt,
        workCompletedAt: j.workCompletedAt,
        location: j.location,
      })),
    },
  }))
}

/** TODO: DRY this up with the other account location queries */
export const useMaintenancePlanWizardSelectedAccountLocation = (
  locationGuid?: string,
) => {
  const mpWizardSelectedAccountLocationQuery = useQuery({
    query: MAINTENANCE_PLAN_WIZARD_SELECTED_ACCOUNT_LOCATION_QUERY,
    variables: { locationGuid: locationGuid ?? '' },
    pause: !locationGuid,
  })

  const refetch = useCallback(() => {
    mpWizardSelectedAccountLocationQuery[1]()
  }, [mpWizardSelectedAccountLocationQuery])

  const selectedAccountLocation = useMemo(() => {
    if (!mpWizardSelectedAccountLocationQuery[0].data?.accountLocations) {
      return undefined
    }

    return convertQueryToAccountLocationsWithJobs(
      mpWizardSelectedAccountLocationQuery[0].data?.accountLocations ?? [],
    )[0]
  }, [mpWizardSelectedAccountLocationQuery])

  const selectedAccountLocationInstalledEquipment = useMemo(() => {
    return selectedAccountLocation?.location.installedEquipment ?? []
  }, [selectedAccountLocation])

  return {
    mpWizardSelectedAccountLocationQuery,
    selectedAccountLocation,
    selectedAccountLocationInstalledEquipment,
    fetching: mpWizardSelectedAccountLocationQuery[0].fetching,
    refetch,
  }
}

/** TODO: DRY this up with the other account location queries */
const MAINTENANCE_PLAN_WIZARD_PAGINATED_ACCOUNT_LOCATIONS_QUERY =
  gql(/* GraphQL */ `
    query MaintenancePlanWizardPaginatedAccountLocations(
      $accountGuid: uuid!
      $offset: Int!
      $limit: Int!
    ) {
      accountsByPk(accountGuid: $accountGuid) {
        accountLocationsAggregate {
          aggregate {
            count
          }
        }
        accountLocations(
          orderBy: [
            { isBillingAddress: DESC }
            { isArchived: ASC }
            { createdAt: DESC }
            { updatedAt: DESC }
          ]
          offset: $offset
          limit: $limit
        ) {
          location {
            locationGuid
            address {
              addressGuid
              line1
              line2
              stateAbbreviation
              zipCode
              city
            }
            companyGuid
            displayName
            estimatedBuildDate
            estimatedSquareFootage
            propertyType
            municipality
            maintenancePlans {
              ...MaintenancePlanMinimal
            }
            installedEquipment {
              installedEquipmentGuid
              averageLifeExpectancyYears
              description
              equipmentType
              estimatedEndOfLifeDate
              installationDate
              installationParty
              installedEquipmentGuid
              laborWarrantyEndDate
              manufacturingDate
              laborWarrantyStartDate
              laborWarrantyTerms
              equipmentDimensions
              locationGuid
              manufacturer
              manufacturerWarrantyEndDate
              manufacturerWarrantyStartDate
              manufacturerWarrantyTerms
              modelNumber
              operationalStatus
              equipmentCondition
              serialNumber
            }
          }
          accountGuid
          companyGuid
          isArchived
          isBillingAddress
        }
      }
    }
  `)

/** TODO: DRY this up with the other account location queries */
const convertQueryToAccountLocations = (
  accountLocations: NonNullable<
    MaintenancePlanWizardPaginatedAccountLocationsQuery['accountsByPk']
  >['accountLocations'],
): AccountLocation[] => {
  return accountLocations.map(al => ({
    ...al,
    maintenancePlanGuids: al.location.maintenancePlans.map(
      mp => mp.maintenancePlanGuid,
    ),
    location: {
      ...al.location,
      estimatedBuildDate: al.location.estimatedBuildDate
        ? LocalDate.parse(al.location.estimatedBuildDate)
        : undefined,
      maintenancePlans: mapGqlLocationMaintenancePlansToMinimals(
        al.location.maintenancePlans,
      ),
      installedEquipment: al.location.installedEquipment.map(parseEquipment),
    },
  }))
}

/** TODO: DRY this up with the other account location queries */
export const useFetchAccountLocations = (
  accountGuid: AccountGuid,
  {
    limit = DEFAULT_ACCOUNT_COLLAPSIBLES_LIMIT,
    offset = 0,
  }: { limit?: number; offset?: number },
) => {
  const accountLocationsQuery = useQuery({
    query: MAINTENANCE_PLAN_WIZARD_PAGINATED_ACCOUNT_LOCATIONS_QUERY,
    variables: {
      accountGuid,
      limit,
      offset,
    },
  })

  const total = useMemo(() => {
    return (
      accountLocationsQuery[0].data?.accountsByPk?.accountLocationsAggregate
        ?.aggregate?.count ?? 0
    )
  }, [accountLocationsQuery])
  const accountLocations = useMemo(() => {
    return convertQueryToAccountLocations(
      accountLocationsQuery[0].data?.accountsByPk?.accountLocations ?? [],
    )
  }, [accountLocationsQuery])

  const locations = useMemo(() => {
    return accountLocations.map(al => al.location)
  }, [accountLocations])

  return {
    accountLocationsQuery,
    refetch: accountLocationsQuery[1],
    fetching: accountLocationsQuery[0].fetching,
    accountLocations,
    total,
    locations,
  }
}

const MAINTENANCE_PLAN_LIMITED_ACCOUNT_JOBS_QUERY = gql(/* GraphQL */ `
  query MaintenancePlanLimitedAccountJobs($where: JobsBoolExp!) {
    jobs(where: $where) {
      jobGuid
      displayId
      jobType {
        name
      }
      jobLifecycleStatus {
        specialStatus
      }
      appointments {
        appointmentGuid: jobAppointmentGuid
      }
    }
  }
`)

export const useMaintenancePlanLimitedAccountJobs = (jobGuids: string[]) => {
  const mpLimitedAccountJobsQuery = useQuery({
    query: MAINTENANCE_PLAN_LIMITED_ACCOUNT_JOBS_QUERY,
    variables: { where: { jobGuid: { _in: jobGuids } } },
  })

  const limitedAccountJobs = useMemo(() => {
    if (!mpLimitedAccountJobsQuery[0].data?.jobs) {
      return []
    }

    return mpLimitedAccountJobsQuery[0].data?.jobs ?? []
  }, [mpLimitedAccountJobsQuery])

  const refetch = useCallback(() => {
    mpLimitedAccountJobsQuery[1]()
  }, [mpLimitedAccountJobsQuery])

  return {
    mpLimitedAccountJobsQuery,
    limitedAccountJobs,
    fetching: mpLimitedAccountJobsQuery[0].fetching,
    refetch,
  }
}

const MAINTENANCE_PLAN_WIZARD_SELECTED_ACCOUNT_QUERY = gql(/* GraphQL */ `
  query MaintenancePlanWizardSelectedAccount($accountGuid: uuid!) {
    accountsByPk(accountGuid: $accountGuid) {
      accountDisplayName
      createdAt
      jobs(
        where: { jobLifecycleStatus: { specialStatus: { _eq: "Completed" } } }
        orderBy: { createdAt: DESC }
        limit: 1
      ) {
        jobGuid
        displayId
        jobInvoices {
          invoice {
            invoiceGuid
            status
            totalUsc
          }
        }
        jobType {
          name
        }
        appointments {
          assignments {
            technician {
              firstName
              lastName
            }
          }
        }
        location {
          displayName
          address {
            addressGuid
            line1
            line2
            stateAbbreviation
            zipCode
            city
          }
        }
        jobLifecycleStatusUpdatedAt
        jobCreatedAt: createdAt
        jobLifecycleStatus {
          specialStatus
        }
      }
      tags {
        tag {
          tagGuid
          name
          color
        }
      }
    }
  }
`)

export const useMaintenancePlanWizardSelectedAccountInfo = (
  accountGuid: string,
) => {
  const mpWizardSelectedAccountQuery = useQuery({
    query: MAINTENANCE_PLAN_WIZARD_SELECTED_ACCOUNT_QUERY,
    variables: { accountGuid },
  })

  const selectedAccountInfo = useMemo(() => {
    if (!mpWizardSelectedAccountQuery[0].data?.accountsByPk) {
      return undefined
    }

    return mpWizardSelectedAccountQuery[0].data?.accountsByPk
  }, [mpWizardSelectedAccountQuery])

  return {
    mpWizardSelectedAccountQuery,
    selectedAccountInfo,
    fetching: mpWizardSelectedAccountQuery[0].fetching,
  }
}

export const autoRenewingPlanLabel = 'Indefinite (Auto-renewing)'
export const fixedNumYearsPlanLabel = (numYears: number) =>
  `${numYears}-Year Plan (Fixed)`

export const getNumImpliedYears = (
  numFixedDurationYears: number | undefined,
) => {
  if (numFixedDurationYears === -1) {
    return 1
  } else if (numFixedDurationYears) {
    return numFixedDurationYears
  } else {
    return 1
  }
}
