import { useQuery } from 'urql'
import { FETCH_COMPANY_BUSINESS_UNITS } from '../../pages/BusinessUnitsSettingsPage/BusinessUnitsSettingsPage.gql'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'

export type BusinessUnitSimple = {
  businessUnitGuid: string
  name: string
}

export const useBusinessUnits = () => {
  const companyGuid = useExpectedCompanyGuid()
  const [{ data }] = useQuery({
    query: FETCH_COMPANY_BUSINESS_UNITS,
    variables: { companyGuid },
  })

  return (
    data?.businessUnits.map(bu => ({
      businessUnitGuid: bu.businessUnitGuid,
      name: bu.name,
    })) ?? undefined
  )
}
