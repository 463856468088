import { z } from 'zod'
import { bzOptional, ForCompany, Guid } from '..'
import { AsyncFn, BzDateFns, LocalDateString } from '../../common'
import { guidSchema, isoDateStringSchema } from '../../contracts'
import { JobClass } from '../Job'
import { InstantBookingWeeklySchedule, InstantBookingWeeklyScheduleSchema } from './InstantBooking'

export const ONLINE_BOOKING_SERVICE_TYPES = ['MAINTENANCE', 'SERVICE', 'ESTIMATE'] as const
export type OnlineBookingServiceType = (typeof ONLINE_BOOKING_SERVICE_TYPES)[number]
export const OnlineBookingServiceTypeSchema = z.enum(ONLINE_BOOKING_SERVICE_TYPES)

export const OnlineBookingServiceTypeDisplayNames: Record<OnlineBookingServiceType, string> = {
  MAINTENANCE: 'Maintenance',
  SERVICE: 'Service',
  ESTIMATE: 'Estimate',
}

export const OnlineBookingServiceTypeConfigDescriptions: Record<OnlineBookingServiceType, string> = {
  MAINTENANCE:
    'Set up and manage regular maintenance services to keep your customers’ HVAC systems running efficiently',
  SERVICE: 'Set up and manage one-time service calls to address specific equipment issues',
  ESTIMATE: 'Set up and manage estimate requests to provide customers with quotes for new equipment',
}

export const OnlineBookingServiceTypeDescriptions: Record<OnlineBookingServiceType, string> = {
  MAINTENANCE: 'I’d like a maintenance service for my system',
  SERVICE: 'My equipment isn’t functioning as expected',
  ESTIMATE: 'I’m interested in replacing my current system',
}

export const OnlineBookingServiceTypeJobTypeLabels: Record<OnlineBookingServiceType, string> = {
  MAINTENANCE: 'What kind of maintenance would you like?',
  SERVICE: 'Please select your issue',
  ESTIMATE: 'Which system are you looking to replace?',
}

export const OnlineBookingServiceTypeToJobClassMap: Record<OnlineBookingServiceType, JobClass> = {
  MAINTENANCE: JobClass.MAINTENANCE,
  SERVICE: JobClass.SERVICE,
  ESTIMATE: JobClass.SALES,
}

export const OnlineBookingServiceTypeToJobClasses: Record<OnlineBookingServiceType, JobClass[]> = {
  MAINTENANCE: [JobClass.MAINTENANCE],
  SERVICE: [JobClass.SERVICE],
  ESTIMATE: [JobClass.ESTIMATE_REPLACE, JobClass.ESTIMATE_REPAIR, JobClass.SALES],
}

export const tryParseOnlineBookingServiceType = (value: string): OnlineBookingServiceType | undefined => {
  return ONLINE_BOOKING_SERVICE_TYPES.find(type => type === value)
}

export const ONLINE_BOOKING_TYPES = ['REQUEST', 'INSTANT'] as const
export type OnlineBookingType = (typeof ONLINE_BOOKING_TYPES)[number]

export const OnlineBookingTypeDisplayNames: Record<OnlineBookingType, string> = {
  REQUEST: 'Request',
  INSTANT: 'Instant',
}

export const getBookingLinkUrl = (accountAppUrl: string, companyGuid: string) =>
  `${accountAppUrl}/book-now?companyGuid=${companyGuid}`

export const ONLINE_BOOKING_EARLIEST_AVAILABLE_TIMES = [
  '12 hours',
  '1 day',
  '2 days',
  '3 days',
  '4 days',
  '5 days',
  '6 days',
  '7 days',
] as const

export type OnlineBookingEarliestAvailableTime = (typeof ONLINE_BOOKING_EARLIEST_AVAILABLE_TIMES)[number]

export const getEarliestAvailableDate = (
  from: LocalDateString,
  earliestAvailableTime: OnlineBookingEarliestAvailableTime,
) => {
  const fromDate = BzDateFns.startOfDay(BzDateFns.parseLocalDate(from))
  switch (earliestAvailableTime) {
    case '12 hours':
      return BzDateFns.addHours(fromDate, 12)
    case '1 day':
      return BzDateFns.addDays(fromDate, 1)
    case '2 days':
      return BzDateFns.addDays(fromDate, 2)
    case '3 days':
      return BzDateFns.addDays(fromDate, 3)
    case '4 days':
      return BzDateFns.addDays(fromDate, 4)
    case '5 days':
      return BzDateFns.addDays(fromDate, 5)
    case '6 days':
      return BzDateFns.addDays(fromDate, 6)
    case '7 days':
      return BzDateFns.addDays(fromDate, 7)
  }
}

export const ONLINE_BOOKING_LATEST_AVAILABLE_TIMES = ['14 days', '21 days', '30 days', '45 days', '60 days'] as const

export type OnlineBookingLatestAvailableTime = (typeof ONLINE_BOOKING_LATEST_AVAILABLE_TIMES)[number]

export const getLastestAvailableDate = (
  from: LocalDateString,
  latestAvailableTime: OnlineBookingLatestAvailableTime,
) => {
  const fromDate = BzDateFns.startOfDay(BzDateFns.parseLocalDate(from))
  switch (latestAvailableTime) {
    case '14 days':
      return BzDateFns.addDays(fromDate, 14)
    case '21 days':
      return BzDateFns.addDays(fromDate, 21)
    case '30 days':
      return BzDateFns.addDays(fromDate, 30)
    case '45 days':
      return BzDateFns.addDays(fromDate, 45)
    case '60 days':
      return BzDateFns.addDays(fromDate, 60)
  }
}

export const OnlineBookingServiceTypeSettingsConfigSchema = z.object({
  onlineBookingServiceTypeGuid: guidSchema,
  companyGuid: guidSchema,
  serviceType: z.enum(ONLINE_BOOKING_SERVICE_TYPES),
  bookingType: z.enum(ONLINE_BOOKING_TYPES),
  earliestAvailability: bzOptional(z.enum(ONLINE_BOOKING_EARLIEST_AVAILABLE_TIMES)),
  latestAvailability: bzOptional(z.enum(ONLINE_BOOKING_LATEST_AVAILABLE_TIMES)),
  legalBlurb: bzOptional(z.string()),
  bookableJobTypeGuids: z.array(guidSchema),
  bookableTechnicianGuids: z.array(guidSchema),
  instantBookingWeeklySchedule: InstantBookingWeeklyScheduleSchema,
})
export type OnlineBookingServiceTypeSettingsConfig = z.infer<typeof OnlineBookingServiceTypeSettingsConfigSchema>

export const OnlineBookingServiceTypeMetadataSchema = z.object({
  onlineBookingServiceTypeGuid: guidSchema,
  serviceType: z.enum(ONLINE_BOOKING_SERVICE_TYPES),
  bookingType: z.enum(ONLINE_BOOKING_TYPES),
  updatedAt: isoDateStringSchema,
})

export type OnlineBookingServiceTypeMetadata = z.infer<typeof OnlineBookingServiceTypeMetadataSchema>

type DefaultOnlineBookingServiceTypeConfig = {
  serviceType: OnlineBookingServiceType
  bookingType: OnlineBookingType
}

export const DEFAULT_ONLINE_BOOKING_SERVICE_TYPE_CONFIGS: DefaultOnlineBookingServiceTypeConfig[] = [
  {
    serviceType: 'MAINTENANCE',
    bookingType: 'REQUEST',
  },
  {
    serviceType: 'SERVICE',
    bookingType: 'REQUEST',
  },
  {
    serviceType: 'ESTIMATE',
    bookingType: 'REQUEST',
  },
]

export type OnlineBookingServiceTypeConfigReaderRequest = {
  type: 'by-service-type'
  input: ForCompany<{
    serviceType: OnlineBookingServiceType
  }>
}

export type OnlineBookingServiceTypeConfigReaderResponse = {
  onlineBookingServiceTypeGuid: Guid
  serviceType: OnlineBookingServiceType
  bookingType: OnlineBookingType
  bookableJobTypes: {
    jobTypeGuid: Guid
    durationMinutes: number
  }[]
  bookableTechnicianGuids: Guid[]
  earliestAvailability?: OnlineBookingEarliestAvailableTime
  latestAvailability?: OnlineBookingLatestAvailableTime
  instantBookingWeeklySchedule?: InstantBookingWeeklySchedule
  legalBlurb?: string
}

export type OnlineBookingServiceTypeConfigReader = AsyncFn<
  OnlineBookingServiceTypeConfigReaderRequest,
  OnlineBookingServiceTypeConfigReaderResponse
>
