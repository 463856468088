import {
  AccountGuid,
  AppointmentGuid,
  calculateInferredAppointmentStatus,
  JobClass,
} from '@breezy/shared'
import React, { useCallback, useMemo, useState } from 'react'
import { useQuery } from 'urql'
import {
  OnsiteModal,
  OnsiteModalContent,
} from '../../../adam-components/OnsiteModal/OnsiteModal'
import { PaginationControls } from '../../../adam-components/Pagination/PaginationControls'
import { usePagination } from '../../../adam-components/Pagination/usePagination'
import { CancelAppointmentModal } from '../../../components/CancelAppointmentModal/CancelAppointmentModal'
import { VisitCard, VisitCardVisit } from '../../../components/Cards/VisitCard'
import {
  useVisitDescriptionModal,
  VisitDescriptionModal,
  VisitsCollapsible,
} from '../../../components/collapsibles/VisitsCollapsible/VisitsCollapsible'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import UpsertAppointmentDrawer from '../../../components/NewAppointmentModal/UpsertAppointmentDrawer'
import { gql } from '../../../generated'
import { AccountVisitsCollapsibleQuery } from '../../../generated/user/graphql'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import { useModalState, useQueryParamFlag } from '../../../utils/react-utils'
import { DEFAULT_ACCOUNT_COLLAPSIBLES_LIMIT } from '../accountDetailsV2Utils'
import { WithAvailableAccountJobs } from '../WithAvailableAccountJobs'

const ACCOUNT_VISITS_QUERY = gql(/* GraphQL */ `
  query AccountVisitsCollapsible(
    $accountGuid: uuid!
    $limit: Int!
    $offset: Int!
  ) {
    # Get total count
    jobAppointmentsAggregate(
      where: { job: { accountGuid: { _eq: $accountGuid } } }
    ) {
      aggregate {
        count
      }
    }
    # Get paginated appointments
    jobAppointments(
      where: { job: { accountGuid: { _eq: $accountGuid } } }
      orderBy: [{ appointmentWindowStart: DESC }]
      limit: $limit
      offset: $offset
    ) {
      jobAppointmentGuid
      appointmentType
      appointmentWindowStart
      appointmentWindowEnd
      description
      cancellationStatus {
        canceled
      }
      assignments {
        assignmentStatus {
          jobAppointmentAssignmentStatusType
        }
        technician {
          fullName
        }
        assignmentStart
        assignmentEnd
      }
      job {
        jobGuid
        displayId
        jobType {
          name
        }
        location {
          locationGuid
          address {
            line1
          }
        }
      }
    }
  }
`)

const convertVisitToVisitCardVisit = (
  visit: NonNullable<AccountVisitsCollapsibleQuery['jobAppointments'][number]>,
): VisitCardVisit => ({
  description: visit.description,
  appointment: {
    appointmentGuid: visit.jobAppointmentGuid,
    appointmentStatus: calculateInferredAppointmentStatus(
      visit.cancellationStatus?.canceled || false,
      visit.assignments.map(assignment => ({
        assignmentStatus:
          assignment.assignmentStatus?.jobAppointmentAssignmentStatusType ||
          'TO_DO',
      })),
    ),
    appointmentType: visit.appointmentType,
    appointmentWindowStart: visit.appointmentWindowStart,
    appointmentWindowEnd: visit.appointmentWindowEnd,
  },
  job: {
    jobGuid: visit.job.jobGuid,
    jobTypeName: visit.job.jobType.name,
    displayId: visit.job.displayId.toString(),
    assignedTechs: visit.assignments.map(assignment => ({
      name: assignment.technician.fullName,
      assignmentStart: assignment.assignmentStart,
      assignmentEnd: assignment.assignmentEnd,
    })),
  },
  location: {
    locationGuid: visit.job.location.locationGuid,
    address: {
      line1: visit.job.location.address.line1,
    },
  },
})

const useFetchAccountVisits = (
  accountGuid: AccountGuid,
  {
    limit = DEFAULT_ACCOUNT_COLLAPSIBLES_LIMIT,
    offset = 0,
  }: { limit?: number; offset?: number },
) => {
  const accountVisitsQuery = useQuery({
    query: ACCOUNT_VISITS_QUERY,
    variables: {
      accountGuid,
      limit,
      offset,
    },
  })

  const total = useMemo(() => {
    return (
      accountVisitsQuery[0].data?.jobAppointmentsAggregate?.aggregate?.count ??
      0
    )
  }, [accountVisitsQuery])

  const visits = useMemo(
    () =>
      (accountVisitsQuery[0].data?.jobAppointments ?? []).map(
        convertVisitToVisitCardVisit,
      ),
    [accountVisitsQuery],
  )

  return {
    accountVisitsQuery,
    refetch: accountVisitsQuery[1],
    fetching: accountVisitsQuery[0].fetching,
    total,
    visits,
  }
}

type AccountVisitsCollapsibleProps = {
  accountGuid: AccountGuid
  editable?: boolean
}

export const AccountVisitsCollapsible =
  React.memo<AccountVisitsCollapsibleProps>(
    ({ accountGuid, editable = false }) => {
      const timezoneId = useExpectedCompanyTimeZoneId()

      const [createNewVisitOpen, openCreateNewVisit, closeCreateNewVisit] =
        useModalState(false)
      const [
        cancelAppointmentOpen,
        openCancelAppointment,
        closeCancelAppointment,
      ] = useModalState(false)

      const [appointmentToCancel, setAppointmentToCancel] = useState<
        AppointmentGuid | undefined
      >(undefined)

      const [viewMoreOpen, viewMoreVisits, closeViewMoreVisits] =
        useQueryParamFlag('viewMoreVisits')
      const { visits, total, refetch } = useFetchAccountVisits(accountGuid, {
        offset: 0,
      })

      const onCloseCreateNewVisit = useCallback(() => {
        closeCreateNewVisit()
        refetch()
      }, [closeCreateNewVisit, refetch])

      const onConfirmCancelAppointment = useCallback(() => {
        closeCancelAppointment()
        refetch()
        setAppointmentToCancel(undefined)
      }, [closeCancelAppointment, refetch])

      return (
        <>
          <VisitsCollapsible
            timezoneId={timezoneId}
            mode="paginated"
            visits={visits}
            total={total}
            editable={editable}
            onViewMore={viewMoreVisits}
            onCreateVisit={openCreateNewVisit}
            onCancelVisit={({ appointmentGuid }) => {
              setAppointmentToCancel(appointmentGuid)
              openCancelAppointment()
            }}
          />

          {viewMoreOpen && (
            <AccountVisitsViewMoreModal
              accountGuid={accountGuid}
              editable={editable}
              onClose={closeViewMoreVisits}
            />
          )}

          {createNewVisitOpen && (
            <WithAvailableAccountJobs
              accountGuid={accountGuid}
              render={availableJobs => (
                <UpsertAppointmentDrawer
                  mode="create"
                  jobGuid={visits[0]?.job.jobGuid ?? ''}
                  jobClass={JobClass.INSTALL}
                  open
                  onCancel={closeCreateNewVisit}
                  onAppointmentCreated={onCloseCreateNewVisit}
                  jobList={availableJobs}
                  labelClassName="semibold_14_22 grey9"
                />
              )}
            />
          )}
          {cancelAppointmentOpen && appointmentToCancel && (
            <CancelAppointmentModal
              modalClassName="z-[1020]"
              onClose={closeCancelAppointment}
              onConfirm={onConfirmCancelAppointment}
              appointmentGuid={appointmentToCancel}
            />
          )}
        </>
      )
    },
  )

type AccountVisitsViewMoreModalProps = {
  accountGuid: AccountGuid
  editable: boolean
  onClose: () => void
}

const AccountVisitsViewMoreModal = React.memo<AccountVisitsViewMoreModalProps>(
  ({ accountGuid, editable, onClose: externalOnClose }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const { page, pageSize, setPage, setPageSize, limit, offset, resetPage } =
      usePagination('10', 1)

    const { visits, total, fetching, refetch } = useFetchAccountVisits(
      accountGuid,
      {
        limit,
        offset,
      },
    )

    const {
      descriptionModalOpen,
      onViewDescription,
      ...visitDescriptionModalProps
    } = useVisitDescriptionModal()

    const [
      cancelAppointmentOpen,
      openCancelAppointment,
      closeCancelAppointment,
    ] = useModalState(false)

    const [appointmentToCancel, setAppointmentToCancel] = useState<
      AppointmentGuid | undefined
    >(undefined)

    const onClose = useCallback(() => {
      externalOnClose()
      setPageSize('10')
      resetPage()
    }, [externalOnClose, setPageSize, resetPage])

    const onConfirmCancelAppointment = useCallback(() => {
      closeCancelAppointment()
      refetch()
      setAppointmentToCancel(undefined)
    }, [closeCancelAppointment, refetch])

    return (
      <>
        <OnsiteModal open size="large" onClose={onClose}>
          <OnsiteModalContent
            onClose={onClose}
            header="Visits"
            footer={
              <PaginationControls
                className="mt-4"
                page={page}
                pageSize={pageSize}
                totalItems={total}
                setPage={setPage}
                setPageSize={setPageSize}
              />
            }
          >
            {fetching ? (
              <LoadingSpinner />
            ) : (
              <div className="flex min-h-0 flex-col gap-2">
                {visits.map(visit => (
                  <div>
                    <VisitCard
                      key={visit.appointment.appointmentGuid}
                      visit={visit}
                      editable={editable}
                      timezoneId={tzId}
                      onCancelVisit={({ appointmentGuid }) => {
                        setAppointmentToCancel(appointmentGuid)
                        openCancelAppointment()
                      }}
                      onViewDescription={onViewDescription}
                      onViewChecklist={() => {}}
                    />
                  </div>
                ))}
              </div>
            )}
          </OnsiteModalContent>
        </OnsiteModal>
        {cancelAppointmentOpen && appointmentToCancel && (
          <CancelAppointmentModal
            modalClassName="z-[1020]"
            onClose={closeCancelAppointment}
            onConfirm={onConfirmCancelAppointment}
            appointmentGuid={appointmentToCancel}
          />
        )}

        {descriptionModalOpen && (
          <VisitDescriptionModal {...visitDescriptionModalProps} />
        )}
      </>
    )
  },
)
