import { Styled } from '../utils/Stylable'

type FakeFormItemProps = {
  label: string
  required?: boolean
  children: React.ReactNode
}

export const FakeFormItem = ({
  label,
  children,
  required,
  className,
}: Styled<FakeFormItemProps>) => {
  return (
    <div className={`col w-full", gap-y-2 ${className}`}>
      <div className="text-[14px] font-semibold leading-[22px] text-[#1F1F1F]">
        {label}
        {required && <span className="text-red-500">&nbsp;*</span>}
      </div>
      {children}
    </div>
  )
}
