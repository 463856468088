import { gql } from '../generated'

export const UPSERT_COMPANY_CONFIG_MUTATION = gql(/* GraphQL */ `
  mutation UpsertCompanyConfig($config: CompanyConfigInsertInput!) {
    insertCompanyConfigOne(
      object: $config
      onConflict: {
        constraint: company_config_pkey
        updateColumns: [
          confirmationNotificationSuppressedByDefault
          defaultAccountManagerUserGuid
          locationMunicipalityFieldEnabled
          hiddenEquipmentTypesJsonArray
          visibleInstallProjectTypesJsonArray
          blurb
        ]
      }
    ) {
      __typename
    }
  }
`)

export const UPSERT_COMPANY_NOTIFICATIONS_SETTINGS_MUTATION =
  gql(/* GraphQL */ `
    mutation UpsertCompanyNotificationSettings(
      $settings: CompanyNotificationSettingsInsertInput!
    ) {
      insertCompanyNotificationSettingsOne(
        object: $settings
        onConflict: {
          constraint: company_notification_settings_pkey
          updateColumns: [
            replyToEmail
            estimateAcceptNotifyCreator
            estimateAcceptNotifyEmail
            estimateAcceptNotifyUserGuids
            estimateReminderCount
            estimateReminderFrequency
            estimateReviewedNotifyCreator
            estimateReviewedNotifyUserGuids
            invoicePaymentReminderCount
            invoicePaymentReminderFrequency
            paymentFailedNotifyCreator
            paymentFailedNotifyEmail
            paymentFailedNotifyUserGuids
            materialRestockReportUserGuids
            materialRestockReportEmail
            jobLeadCreatedUserGuids
            jobLeadCreatedEmail
            lapsedPaymentMaintenancePlanUserGuids
            lapsedPaymentMaintenancePlanNotifyEmail
          ]
        }
      ) {
        __typename
      }
    }
  `)

export const UPSERT_COMPANY_CONFIG_MAINTENANCE_PLANS_ENABLED_MUTATION =
  gql(/* GraphQL */ `
    mutation UpsertCompanyConfigMaintenancePlansEnabled(
      $config: CompanyConfigInsertInput!
    ) {
      insertCompanyConfigOne(
        object: $config
        onConflict: {
          constraint: company_config_pkey
          updateColumns: [maintenancePlansEnabled]
        }
      ) {
        __typename
      }
    }
  `)

export const UPSERT_COMPANY_CONFIG_TIMESHEETS_ENABLED_MUTATION =
  gql(/* GraphQL */ `
    mutation UpsertCompanyConfigTimesheetsEnabled(
      $config: CompanyConfigInsertInput!
    ) {
      insertCompanyConfigOne(
        object: $config
        onConflict: {
          constraint: company_config_pkey
          updateColumns: [timesheetsEnabled]
        }
      ) {
        __typename
      }
    }
  `)

export const UPSERT_COMPANY_CONFIG_TIMESHEETS_PAY_PERIOD_END_ANCHOR =
  gql(/* GraphQL */ `
    mutation UpsertCompanyConfigTimesheetsPayPeriodEndAnchor(
      $config: CompanyConfigInsertInput!
    ) {
      insertCompanyConfigOne(
        object: $config
        onConflict: {
          constraint: company_config_pkey
          updateColumns: [timesheetPayPeriodBeginAnchor]
        }
      ) {
        __typename
      }
    }
  `)

export const UPSERT_COMPANY_CONFIG_TIMESHEETS_PAY_PERIOD_END_RRULE =
  gql(/* GraphQL */ `
    mutation UpsertCompanyConfigTimesheetsPayPeriodEndRRule(
      $config: CompanyConfigInsertInput!
    ) {
      insertCompanyConfigOne(
        object: $config
        onConflict: {
          constraint: company_config_pkey
          updateColumns: [timesheetPayPeriodBeginRrule]
        }
      ) {
        __typename
      }
    }
  `)

export const UPSERT_COMPANY_CONFIG_TECHNICIAN_PERFORMANCE_ENABLED_MUTATION =
  gql(/* GraphQL */ `
    mutation UpsertCompanyConfigTechnicianPerformanceEnabled(
      $config: CompanyConfigInsertInput!
    ) {
      insertCompanyConfigOne(
        object: $config
        onConflict: {
          constraint: company_config_pkey
          updateColumns: [technicianPerformanceEnabled]
        }
      ) {
        __typename
      }
    }
  `)

export const UPSERT_COMPANY_CONFIG_DRIP_MARKETING_SETTINGS_MUTATION =
  gql(/* GraphQL */ `
    mutation UpsertCompanyConfigDripMarketingSettings(
      $settings: CompanyDripMarketingConfigInsertInput!
    ) {
      insertCompanyDripMarketingConfigOne(
        object: $settings
        onConflict: {
          constraint: company_drip_marketing_config_pkey
          updateColumns: [
            yelpReviewUrl
            googleReviewUrl
            facebookReviewUrl
            jobCompletedEnabled
            jobCompletedDelayMs
            jobCompletedCustomEmailContent
            maintenanceRemindersEnabled
            maintenanceRemindersDelayMonths
            maintenanceRemindersCustomEmailContent
          ]
        }
      ) {
        __typename
      }
    }
  `)

export const READ_COMPANY_CONFIG_QUERY = gql(/* GraphQL */ `
  query ReadCompanyConfigQuery($companyGuid: uuid!) {
    companyConfigByPk(companyGuid: $companyGuid) {
      confirmationNotificationSuppressedByDefault
      defaultAccountManagerUserGuid
      hiddenEquipmentTypesJsonArray
      locationMunicipalityFieldEnabled
      visibleInstallProjectTypesJsonArray
      blurb
      maintenancePlansEnabled
    }
  }
`)

export const FETCH_PRICEBOOK_SETTINGS_CONFIG_QUERY = gql(/* GraphQL */ `
  query FetchPricebookSettingsConfig($companyGuid: uuid!) {
    companyConfigByPk(companyGuid: $companyGuid) {
      pricebookPhotosEnabled
    }
  }
`)
