import { gql } from '../../generated/user/gql'

export const CHECK_IF_COMPANY_HAS_ACTIVE_MAINTENANCE_PLANS = gql(/* GraphQL */ `
  query CheckIfCompanyHasActiveMaintenancePlans($companyGuid: uuid!) {
    maintenancePlansQueryable(
      limit: 1
      where: { companyGuid: { _eq: $companyGuid }, status: { _eq: "ACTIVE" } }
    ) {
      status
    }
  }
`)

export const FETCH_COMPANY_MAINTENANCE_PLAN_ENABLED = gql(/* GraphQL */ `
  query FetchCompanyMaintenancePlansEnabled($companyGuid: uuid!) {
    companyConfig(where: { companyGuid: { _eq: $companyGuid } }) {
      maintenancePlansEnabled
    }
  }
`)

export const UPSERT_COMPANY_BILLING_PROFILE_RENEWAL_TYPES = gql(/* GraphQL */ `
  mutation UpsertCompanyBillingProfileRenewalTypes(
    $object: BillingProfilesInsertInput!
  ) {
    insertBillingProfilesOne(
      object: $object
      onConflict: {
        constraint: billing_profiles_pkey
        updateColumns: [
          maintenancePlanAutoRenewalAllowed
          maintenancePlanManualRenewalAllowed
          updatedAt
        ]
      }
    ) {
      __typename
    }
  }
`)

export const UPSERT_COMPANY_BILLING_PROFILE_FIXED_PLAN_DURATIONS =
  gql(/* GraphQL */ `
    mutation UpsertCompanyBillingProfileFixedPlanDurations(
      $object: BillingProfilesInsertInput!
    ) {
      insertBillingProfilesOne(
        object: $object
        onConflict: {
          constraint: billing_profiles_pkey
          updateColumns: [
            maintenancePlanStaticDurationNumYearsAllowed
            updatedAt
          ]
        }
      ) {
        __typename
      }
    }
  `)
