import { useTsController } from '@ts-react/form'
import { Form } from 'antd'
import React, { useCallback } from 'react'
import ContactRadioSelection from '../../../ProgressiveJobCreationModal/LinkedInformation/ContactRadioSelection/ContactRadioSelection'
import { FinancingWizardAccount } from '../types'

export type ContactSelectionAccountWithContacts = FinancingWizardAccount

type ContactSelectionFieldProps = {
  account: ContactSelectionAccountWithContacts
  refetchAccount: () => void
  description?: string
  disabledContactGuids?: string[]
  disabledExtra?: React.ReactNode
}

const ContactSelectionField = React.memo<ContactSelectionFieldProps>(
  ({
    account,
    refetchAccount,
    description = 'You need to select who the primary contact will be so the financing application can be associated with their information for your own data keeping purposes.',
    disabledContactGuids = [],
    disabledExtra,
  }) => {
    const {
      field: { onChange, value },
    } = useTsController<string>()

    const { error } = useTsController<string>()

    const onContactSelected = useCallback(
      (contactGuid: string) => {
        onChange(contactGuid)
      },
      [onChange],
    )
    return (
      <Form.Item
        validateStatus={error ? 'error' : 'success'}
        help={error?.errorMessage}
        className="overflow-auto"
      >
        <ContactRadioSelection
          title="Select Contact"
          accountGuid={account.accountGuid}
          accountContacts={account.accountContacts}
          refetchAccountContacts={refetchAccount}
          selectedContactGuid={value}
          setSelectedContactGuid={onContactSelected}
          description={description}
          editable={false}
          showAdditionalPhoneNumber={false}
          bypassFormSubmit={true}
          disabledContactGuids={disabledContactGuids}
          disabledExtra={disabledExtra}
        />
      </Form.Item>
    )
  },
)

export default ContactSelectionField
