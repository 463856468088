import { PageHeader } from '@ant-design/pro-components'
import { isNullish } from '@breezy/shared'
import { faBuilding } from '@fortawesome/pro-regular-svg-icons'
import { Col, Row } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery } from 'urql'
import { FeatureEnableView } from '../../components/FeatureEnableView/FeatureEnableView'
import { GqlQueryLoader } from '../../components/GqlQueryLoader/GqlQueryLoader'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { Page } from '../../components/Page/Page'
import BzCheckBox from '../../elements/BzCheckBox/BzCheckBox'
import PageTitle from '../../elements/PageTitle/PageTitle'
import ScrollCard from '../../elements/ScrollCard/ScrollCard'
import ThinDivider from '../../elements/ThinDivider'
import { ReadAccountingCompanyConfigQueryQuery } from '../../generated/user/graphql'
import {
  READ_ACCOUNTING_COMPANY_CONFIG_QUERY,
  UPSERT_ACCOUNTING_COMPANY_CONFIG_SYNC_BUSINESS_UNITS_MUTATION,
} from '../../gql/CompanyAccountingConfig.gql'
import { useFeatureFlag } from '../../hooks/useFeatureFlags'
import { useAccountingIntegrationType } from '../../providers/CompanyFinancialConfigWrapper'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { m } from '../../utils/react-utils'
import BusinessUnitsEditPanel from './BusinessUnitsEditPanel'
import {
  FETCH_COMPANY_BUSINESS_UNITS_ENABLED,
  UPSERT_COMPANY_BUSINESS_UNITS_ENABLED,
} from './BusinessUnitsSettingsPage.gql'

const SubComponentLoadingSpinner = () => (
  <div className="min-h-[320px] w-full">
    <LoadingSpinner />
  </div>
)

const BusinessUnitsSettingsPage = m(() => {
  const companyGuid = useExpectedCompanyGuid()
  const businessUnitsFeatureFlag = useFeatureFlag('businessUnits')
  const [isBusinessUnitsEnabledState, setIsBusinessUnitsEnabledState] =
    useState<boolean | undefined>(undefined)
  const accountingIntegrationType = useAccountingIntegrationType()
  const accountingCompanyConfigQuery = useQuery({
    query: READ_ACCOUNTING_COMPANY_CONFIG_QUERY,
    variables: { companyGuid },
  })

  const [, setSyncBusinessUnits] = useMutation(
    UPSERT_ACCOUNTING_COMPANY_CONFIG_SYNC_BUSINESS_UNITS_MUTATION,
  )

  const [{ data, fetching }, refetch] = useQuery({
    query: FETCH_COMPANY_BUSINESS_UNITS_ENABLED,
    variables: { companyGuid },
    requestPolicy: 'network-only',
    pause: !businessUnitsFeatureFlag,
  })

  const [, setBusinessUnitsEnabledMutation] = useMutation(
    UPSERT_COMPANY_BUSINESS_UNITS_ENABLED,
  )

  const setBusinessUnitsEnabled = useCallback(
    async (enabled: boolean) => {
      setIsBusinessUnitsEnabledState(enabled)
      await setBusinessUnitsEnabledMutation({
        object: {
          companyGuid,
          businessUnitsEnabled: enabled,
        },
      })
      refetch()
    },
    [setBusinessUnitsEnabledMutation, companyGuid, refetch],
  )

  const businessUnitsEnabledRaw = useMemo(
    () => data?.companyConfig[0]?.businessUnitsEnabled,
    [data],
  )
  const businessUnitsEnabled = useMemo(
    () => isBusinessUnitsEnabledState ?? businessUnitsEnabledRaw ?? false,
    [isBusinessUnitsEnabledState, businessUnitsEnabledRaw],
  )

  useEffect(() => {
    setIsBusinessUnitsEnabledState(businessUnitsEnabledRaw)
  }, [businessUnitsEnabledRaw])

  if (!businessUnitsFeatureFlag) {
    return (
      <div className="flex w-full min-w-[700px] flex-col">
        <h1>Coming Soon</h1>
      </div>
    )
  }

  return (
    <Page requiresCompanyUser style={{ padding: 0, overflow: 'hidden' }}>
      <PageHeader
        title={<PageTitle title="Business Units" icon={faBuilding} />}
      />
      <ScrollCard hasPageHeading>
        <Col>
          <Row className="flex">
            {fetching && isNullish(businessUnitsEnabledRaw) ? (
              <SubComponentLoadingSpinner />
            ) : (
              <div className="w-full max-w-[800px]">
                <div className="flex w-full flex-col space-y-4 p-4">
                  <FeatureEnableView
                    featureTitle="Business Units"
                    featureEnabled={businessUnitsEnabled}
                    isLoading={fetching}
                    setValue={async (enabled: boolean) => {
                      await setBusinessUnitsEnabled(enabled)
                    }}
                    refetch={refetch}
                  />

                  {!!businessUnitsEnabled && !!accountingIntegrationType && (
                    <GqlQueryLoader
                      query={accountingCompanyConfigQuery}
                      render={(data: ReadAccountingCompanyConfigQueryQuery) => (
                        <>
                          <BzCheckBox
                            label="Sync Business Units to Accounting"
                            value={
                              data?.accCompanyConfigByPk?.syncBusinessUnits ??
                              false
                            }
                            onChange={async e => {
                              await setSyncBusinessUnits({
                                object: {
                                  companyGuid,
                                  accountingIntegrationType,
                                  syncBusinessUnits: e,
                                  updatedAt: new Date().toISOString(),
                                },
                              })
                            }}
                          />
                          <ThinDivider />
                          <BusinessUnitsEditPanel
                            showAccounting={
                              !!accountingIntegrationType &&
                              !!data?.accCompanyConfigByPk?.syncBusinessUnits
                            }
                          />
                        </>
                      )}
                    />
                  )}
                </div>
              </div>
            )}
          </Row>
        </Col>
      </ScrollCard>
    </Page>
  )
})

export default BusinessUnitsSettingsPage
