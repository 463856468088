import { GetCompanyBillingProfileFrontendQuery } from '@breezy/backend/src/query/gql/generated/graphql'
import {
  RenewalTypeOption,
  getFixedPlanDurationsFromBillingProfiles,
  getRenewalTypeOptionFromBillingProfiles,
  isNullish,
} from '@breezy/shared'
import { Select } from 'antd'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useMutation } from 'urql'
import { FakeFormItem } from '../../adam-components/FakeFormItem'
import { BzSelect } from '../../elements/BzSelect/BzSelect'
import { useMessage } from '../../utils/antd-utils'
import {
  UPSERT_COMPANY_BILLING_PROFILE_FIXED_PLAN_DURATIONS,
  UPSERT_COMPANY_BILLING_PROFILE_RENEWAL_TYPES,
} from './MaintenancePlanConfig.gql'
import { MaintenancePlanBillingProfileViewProps } from './maintenancePlanConfigUtils'
import { SectionDescriptionStyle, SectionHeaderStyle } from './mp_styles'

const ObjectType = 'Renewal Settings' as const

const getInsertableOriginalRow = (
  billingProfile: GetCompanyBillingProfileFrontendQuery['billingProfiles'][number],
) => {
  const {
    __typename,
    emailAddress,
    phoneNumber,
    businessAddress,
    defaultTaxRate,
    maintenancePlanTaxRate,
    maintenancePlanServiceItem,
    ...originalRow
  } = billingProfile

  return originalRow
}

const MaintenancePlanRenewalTypesManageMultiYearView = ({
  billingProfiles,
}: MaintenancePlanBillingProfileViewProps) => {
  const message = useMessage()
  const [justPickedRenewalType, setJustPickedRenewalType] = useState<
    RenewalTypeOption | undefined
  >(undefined)
  const justPickedDurations = useRef<string[] | undefined>(undefined)

  const currentRenewalTypeOption = useMemo(() => {
    if (!isNullish(justPickedRenewalType)) return justPickedRenewalType
    return getRenewalTypeOptionFromBillingProfiles(billingProfiles)
  }, [justPickedRenewalType, billingProfiles])

  const currentFixedPlanDurations = useMemo(() => {
    return getFixedPlanDurationsFromBillingProfiles(billingProfiles)
      .sort((a, b) => a - b)
      .map(x => x.toString())
  }, [billingProfiles])

  const [{ fetching: isLoadingRenewalTypes }, upsertRenewalOptionsMut] =
    useMutation(UPSERT_COMPANY_BILLING_PROFILE_RENEWAL_TYPES)
  const [{ fetching: isLoadingDurations }, upsertDurationsMut] = useMutation(
    UPSERT_COMPANY_BILLING_PROFILE_FIXED_PLAN_DURATIONS,
  )
  const isLoading = isLoadingRenewalTypes || isLoadingDurations

  const upsertRenewalOptions = useCallback(
    async (option: RenewalTypeOption) => {
      try {
        await upsertRenewalOptionsMut({
          object: {
            ...getInsertableOriginalRow(billingProfiles[0]),
            maintenancePlanAutoRenewalAllowed:
              option === 'AUTO' || option === 'BOTH',
            maintenancePlanManualRenewalAllowed:
              option === 'MANUAL' || option === 'BOTH',
            updatedAt: new Date().toISOString(),
          },
        })
      } catch (e) {
        console.error(`Failed to update ${ObjectType}`, e)
        message.error(`There was an unknown issue updating ${ObjectType}`)
        setJustPickedRenewalType(undefined)
      }
    },
    [upsertRenewalOptionsMut, billingProfiles, message],
  )

  const onChangeRenewalTypes = useCallback(
    async (t: RenewalTypeOption) => {
      setJustPickedRenewalType(t)
      await upsertRenewalOptions(t)
    },
    [upsertRenewalOptions],
  )

  const upsertDurations = useCallback(
    async (durations: string[]) => {
      try {
        await upsertDurationsMut({
          object: {
            ...getInsertableOriginalRow(billingProfiles[0]),
            maintenancePlanStaticDurationNumYearsAllowed: durations
              .map(x => Number(x))
              .sort((a, b) => a - b),
            updatedAt: new Date().toISOString(),
          },
        })
      } catch (e) {
        console.error(`Failed to update ${ObjectType}`, e)
        message.error(`There was an unknown issue updating ${ObjectType}`)
      }
    },
    [upsertDurationsMut, billingProfiles, message],
  )
  const commitDurations = useCallback(async () => {
    if (justPickedDurations.current) {
      await upsertDurations(justPickedDurations.current)
      justPickedDurations.current = undefined
    }
  }, [upsertDurations])

  const onChangeDurations = useCallback(
    (d: string[]) => {
      justPickedDurations.current = d
      commitDurations()
    },
    [commitDurations],
  )

  return (
    <>
      <div className="col w-full px-2 pt-2">
        <div className="row flex-between flex">
          <div className="col max-w-[320px]">
            <h3 className={SectionHeaderStyle}>{ObjectType}</h3>
            <p className={SectionDescriptionStyle}>
              Configure plan renewal and duration settings for your Maintenance
              plans
            </p>
          </div>
          <div className="col w-full max-w-[600px]">
            {
              <div className="col">
                <FakeFormItem label="Renewal Type" required>
                  <BzSelect
                    title="Renewal Type"
                    allowClear={false}
                    value={currentRenewalTypeOption}
                    onChange={value =>
                      onChangeRenewalTypes(value as RenewalTypeOption)
                    }
                    className="grey10 mb-6 w-full w-full text-[20px] font-semibold"
                    disabled={isLoading}
                    options={[
                      {
                        value: 'AUTO',
                        label: 'Auto-renewing only',
                      },
                      {
                        value: 'MANUAL',
                        label: 'Fixed Duration only',
                      },
                      {
                        value: 'BOTH',
                        label: 'Auto-renewing and Fixed Duration',
                      },
                    ]}
                  />
                </FakeFormItem>
                <FakeFormItem
                  label="Fixed Plan Durations"
                  required
                  className="mb-2"
                >
                  <Select
                    title="Fixed Plan Durations"
                    allowClear={false}
                    mode="multiple"
                    value={currentFixedPlanDurations}
                    onChange={onChangeDurations}
                    disabled={currentRenewalTypeOption === 'AUTO'}
                    options={[
                      {
                        value: '1',
                        label: '1-Year',
                      },
                      {
                        value: '2',
                        label: '2-Years',
                      },
                      {
                        value: '3',
                        label: '3-Years',
                      },
                      {
                        value: '4',
                        label: '4-Years',
                      },
                      {
                        value: '5',
                        label: '5-Years',
                      },
                      {
                        value: '6',
                        label: '6-Years',
                      },
                      {
                        value: '7',
                        label: '7-Years',
                      },
                      {
                        value: '8',
                        label: '8-Years',
                      },
                      {
                        value: '9',
                        label: '9-Years',
                      },
                      {
                        value: '10',
                        label: '10-Years',
                      },
                    ]}
                  />
                </FakeFormItem>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default MaintenancePlanRenewalTypesManageMultiYearView
