import { convertFetchLoanRecordToLoanRecord } from '@breezy/backend/src/application-types/loans/LoanRecordConversions'
import { AccountGuid, PaymentStatus } from '@breezy/shared'
import React, { useCallback, useMemo } from 'react'
import { useQuery } from 'urql'

import {
  PaymentCard,
  PaymentCardPayment,
} from '../../../components/Cards/PaymentCard'
import { PaymentsCollapsible } from '../../../components/collapsibles/PaymentsCollapsible/PaymentsCollapsible'
import { gql } from '../../../generated'

import {
  OnsiteModal,
  OnsiteModalContent,
} from '../../../adam-components/OnsiteModal/OnsiteModal'
import { PaginationControls } from '../../../adam-components/Pagination/PaginationControls'
import { usePagination } from '../../../adam-components/Pagination/usePagination'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { AccountPaymentsCollapsibleV2Query } from '../../../generated/user/graphql'
import { useModalState } from '../../../utils/react-utils'
import { DEFAULT_ACCOUNT_COLLAPSIBLES_LIMIT } from '../accountDetailsV2Utils'

const ACCOUNT_PAYMENTS_QUERY = gql(/* GraphQL */ `
  query AccountPaymentsCollapsibleV2(
    $accountGuid: uuid!
    $limit: Int!
    $offset: Int!
  ) {
    accountsByPk(accountGuid: $accountGuid) {
      paymentRecordsAggregate {
        aggregate {
          count
        }
      }
      paymentRecords(
        orderBy: [{ occurredAt: DESC }]
        limit: $limit
        offset: $offset
      ) {
        externalPaymentId
        amountUsd
        paymentMethod
        paymentRecordGuid
        occurredAt
        paymentReferenceNumber
        paymentStatuses(orderBy: { occurredAt: DESC }, limit: 1) {
          paymentStatus
        }
        invoiceGuid
        invoiceV2 {
          displayId
        }
        paymentLink {
          jobGuid
          loanRecord {
            ...LoanRecord
          }
        }
      }
    }
  }
`)

const convertQueryToPaymentCardPayments = (
  paymentRecords: NonNullable<
    AccountPaymentsCollapsibleV2Query['accountsByPk']
  >['paymentRecords'],
): PaymentCardPayment[] => {
  return paymentRecords.map(payment => ({
    paymentRecordGuid: payment.paymentRecordGuid,
    paymentMethod: payment.paymentMethod,
    occuredAt: payment.occurredAt,
    amountUsd: payment.amountUsd,
    referenceNumber: payment.paymentReferenceNumber,
    displayId: payment.invoiceV2?.displayId,
    status:
      payment.paymentStatuses[0]?.paymentStatus ?? PaymentStatus.SUBMITTING,
    invoiceGuid: payment.invoiceGuid,
    jobGuid: payment.paymentLink?.jobGuid,
    loanRecord: payment.paymentLink?.loanRecord
      ? convertFetchLoanRecordToLoanRecord(payment.paymentLink.loanRecord)
      : undefined,
  }))
}

const useFetchAccountPayments = (
  accountGuid: AccountGuid,
  {
    limit = DEFAULT_ACCOUNT_COLLAPSIBLES_LIMIT,
    offset = 0,
  }: { limit?: number; offset?: number },
) => {
  const accountPaymentsQuery = useQuery({
    query: ACCOUNT_PAYMENTS_QUERY,
    variables: {
      accountGuid,
      limit,
      offset,
    },
  })

  const payments = useMemo(
    () =>
      convertQueryToPaymentCardPayments(
        accountPaymentsQuery[0].data?.accountsByPk?.paymentRecords ?? [],
      ),
    [accountPaymentsQuery],
  )
  const total = useMemo(
    () =>
      accountPaymentsQuery[0].data?.accountsByPk?.paymentRecordsAggregate
        ?.aggregate?.count ?? 0,
    [accountPaymentsQuery],
  )

  return {
    payments,
    refetch: accountPaymentsQuery[1],
    fetching: accountPaymentsQuery[0].fetching,
    total,
  }
}

type AccountPaymentsCollapsibleProps = {
  accountGuid: AccountGuid
}

export const AccountPaymentsCollapsible =
  React.memo<AccountPaymentsCollapsibleProps>(({ accountGuid }) => {
    const { payments, total } = useFetchAccountPayments(accountGuid, {
      offset: 0,
    })

    const [viewMoreOpen, viewMorePayments, closeViewMorePayments] =
      useModalState()

    return (
      <>
        <PaymentsCollapsible
          payments={payments}
          mode="paginated"
          total={total}
          onViewMore={viewMorePayments}
        />
        {viewMoreOpen && (
          <AccountPaymentsViewMoreModal
            accountGuid={accountGuid}
            onClose={closeViewMorePayments}
          />
        )}
      </>
    )
  })

type AccountPaymentsViewMoreModalProps = {
  accountGuid: AccountGuid
  onClose: () => void
}

const AccountPaymentsViewMoreModal =
  React.memo<AccountPaymentsViewMoreModalProps>(
    ({ accountGuid, onClose: externalOnClose }) => {
      const { page, pageSize, setPage, setPageSize, limit, offset, resetPage } =
        usePagination('10', 1)

      const { payments, total, fetching } = useFetchAccountPayments(
        accountGuid,
        {
          limit,
          offset,
        },
      )

      const onClose = useCallback(() => {
        externalOnClose()
        setPageSize('10')
        resetPage()
      }, [externalOnClose, setPageSize, resetPage])

      return (
        <>
          <OnsiteModal open size="large" onClose={onClose}>
            <OnsiteModalContent
              onClose={onClose}
              header="Payments"
              footer={
                <PaginationControls
                  className="mt-4"
                  page={page}
                  pageSize={pageSize}
                  totalItems={total}
                  setPage={setPage}
                  setPageSize={setPageSize}
                />
              }
            >
              {fetching ? (
                <LoadingSpinner />
              ) : (
                <div className="flex min-h-0 flex-col gap-2">
                  {payments.map((payment, idx) => (
                    <div>
                      <PaymentCard
                        key={payment.paymentRecordGuid}
                        payment={payment}
                      />
                    </div>
                  ))}
                </div>
              )}
            </OnsiteModalContent>
          </OnsiteModal>
        </>
      )
    },
  )
