import { ComprehensiveJobDetails } from '@breezy/backend/src/application-types'
import {
  AccountGuid,
  BzDateTime,
  JOB_CLASSES_THAT_SUPPORT_JOB_OUTCOMES,
  JobLifecycleStatus,
  Location,
  bzExpect,
  effectiveLocationDisplayName,
  formatEquipmentJobLink,
  isNullish,
  jobOutcomesDataShouldBeCollectedOnTransition,
  richJobTypeDescriptor,
} from '@breezy/shared'
import { Button, Divider, Select } from 'antd'
import cn from 'classnames'
import { useCallback, useMemo, useState } from 'react'
import ActionItemsWithEllipsis from 'src/elements/ActionItems/ActionItemsWithEllipsis'
import { useQuery, useSubscription } from 'urql'
import { BehindFeatureFlag } from '../../components/BehindFeatureFlag/BehindFeatureFlag'
import { DateFormat, DateView } from '../../components/Dates'
import GqlQueryLoader from '../../components/GqlQueryLoader/GqlQueryLoader'
import ChipsPanel from '../../components/JobOutcomesModal/ChipsPanel'
import { JobOutcomesModal } from '../../components/JobOutcomesModal/JobOutcomesModal'
import { useJobSoldMaintenancePlan } from '../../components/JobOutcomesModal/hooks/useJobSoldMaintenancePlan'
import { JobDetailsForOutcomes } from '../../components/JobOutcomesModal/job-outcome-modal-types'
import { LifecycleStatusCircle } from '../../components/LifecycleStatusCircle/LifecycleStatusCircle'
import MaintenancePlanVipIcon from '../../components/MaintenancePlanVipIcon/MaintenancePlanVipIcon'
import VerticalKeyValue from '../../components/Page/Columns/TagColumn/VerticalKeyValue'
import { RichCompanyLeadSourceView } from '../../components/RichCompanyLeadSourceView/RichCompanyLeadSourceView'
import { TagList } from '../../components/Tags'
import TrpcQueryLoader from '../../components/TrpcQueryLoader'
import { ActionItem } from '../../elements/ActionItems/ActionItems'
import BzColumn from '../../elements/BzColumn/BzColumn'
import { EmDash } from '../../elements/EmDash/EmDash'
import ThinDivider from '../../elements/ThinDivider'
import { useCanManageTechnicianPerformance } from '../../hooks/permission/useCanManageTechnicianPerformance'
import { useCanViewAccount } from '../../hooks/permission/useCanViewAccount'
import { trpc } from '../../hooks/trpc'
import { useMessage } from '../../utils/antd-utils'
import { TextWithLineBreaks } from '../../utils/formatters'
import { m } from '../../utils/react-utils'
import {
  FETCH_JOB_TECHNICIAN_PERFORMANCE_INFO,
  FETCH_JOB_WORK_COMPLETED_AT_SUBSCRIPTION,
} from './JobInfoCard.gql'
import { JobMaintenancePlanVisits } from './JobMaintenancePlanVisits'
import { RevenueAttributionCard } from './RevenueAttributionCard'
import { TechnicianRevenueAttributionSection } from './components/TechnicianRevenueAttributionSection'

type JobInfoCardProps = {
  comprehensiveJobDetails: ComprehensiveJobDetails
  navigateToAccountDetails: () => void
  navigateToServiceLocationDetails?: () => void
  serviceLocation?: Location
  actionItems?: ActionItem[]
  editable?: boolean
  onEdit?: () => void
  refetchComprehensiveJobDetails: () => void
}

const JobInfoCard = m<JobInfoCardProps>(
  ({
    comprehensiveJobDetails,
    navigateToAccountDetails,
    navigateToServiceLocationDetails,
    serviceLocation,
    actionItems,
    editable = false,
    onEdit,
    refetchComprehensiveJobDetails,
  }) => {
    const message = useMessage()
    const jobSoldMaintenancePlan = useJobSoldMaintenancePlan(
      comprehensiveJobDetails.getJobGuid(),
    )
    const canManageTechnicianPerformance = useCanManageTechnicianPerformance()
    const shouldShowRevenueAttributionPanel = useMemo(() => {
      return JOB_CLASSES_THAT_SUPPORT_JOB_OUTCOMES.includes(
        comprehensiveJobDetails.getJobType().jobClass,
      )
    }, [comprehensiveJobDetails])
    const [showJobOutcomesModal, setShowJobOutcomesModal] = useState(false)
    const [
      jobLifecycleStatusGuidForJobOutcomesModal,
      setJobLifecycleStatusGuidForJobOutcomesModal,
    ] = useState('')

    const openJobOutcomesModalForStatusChange = useCallback(
      (jobLifecycleStatusGuidOnSubmission: string) => {
        setShowJobOutcomesModal(true)
        setJobLifecycleStatusGuidForJobOutcomesModal(
          jobLifecycleStatusGuidOnSubmission,
        )
      },
      [],
    )

    const closeJobOutcomesModal = useCallback(() => {
      setShowJobOutcomesModal(false)
      setJobLifecycleStatusGuidForJobOutcomesModal('')
    }, [])

    const { canView: canViewAccount } = useCanViewAccount(
      comprehensiveJobDetails.getAccount().accountGuid,
    )

    const lifecycleQuery = trpc.jobLifecycles['job-lifecycles:get'].useQuery()

    const [jobLifecycle, jobLifecycleStatus] = useMemo(() => {
      const jobLifecycleStatusGuid =
        comprehensiveJobDetails.getJobLifecycleStatusGuid()

      for (const lifecycle of lifecycleQuery.data ?? []) {
        for (const status of lifecycle.statuses) {
          if (status.jobLifecycleStatusGuid === jobLifecycleStatusGuid) {
            return [lifecycle, status]
          }
        }
      }

      return []
    }, [comprehensiveJobDetails, lifecycleQuery.data])

    const statusGuidToStatusMap = useMemo(() => {
      const statusGuidToStatusMap: Record<string, JobLifecycleStatus> = {}

      for (const lifecycle of lifecycleQuery.data ?? []) {
        for (const status of lifecycle.statuses) {
          statusGuidToStatusMap[status.jobLifecycleStatusGuid] = status
        }
      }
      return statusGuidToStatusMap
    }, [lifecycleQuery.data])

    const [
      optimisticJobLifecycleStatusGuid,
      setOptimisticJobLifecycleStatusGuid,
    ] = useState<string>()

    const changeStatusMutation = trpc.jobLifecycles[
      'job-lifecycles:change-status'
    ].useMutation({
      onError: () => {
        message.error('Status failed to update. Please try again later.')
        setOptimisticJobLifecycleStatusGuid(undefined)
      },
      onSuccess: () => {
        message.success('Status successfully updated!')
        refetchComprehensiveJobDetails()
      },
    })

    const updateJobStatus = useCallback(
      (jobLifecycleStatusGuid: string) => {
        setOptimisticJobLifecycleStatusGuid(jobLifecycleStatusGuid)
        changeStatusMutation.mutate({
          jobGuid: comprehensiveJobDetails.getJobGuid(),
          jobLifecycleStatusGuid,
        })
      },
      [changeStatusMutation, comprehensiveJobDetails],
    )

    const onStatusSelectChange = useCallback(
      (newJobLifecycleStatusGuid: string) => {
        const currentJobLifecycleStatusGuid =
          optimisticJobLifecycleStatusGuid ??
          jobLifecycleStatus?.jobLifecycleStatusGuid

        if (!currentJobLifecycleStatusGuid) {
          updateJobStatus(newJobLifecycleStatusGuid)
          return
        }

        const currentStatus =
          statusGuidToStatusMap[currentJobLifecycleStatusGuid]
        const nextStatus = statusGuidToStatusMap[newJobLifecycleStatusGuid]

        const shouldShowJobOutcomesModal =
          jobOutcomesDataShouldBeCollectedOnTransition(
            currentStatus,
            nextStatus,
            canManageTechnicianPerformance,
            comprehensiveJobDetails.getJobType().jobClass,
          )

        if (shouldShowJobOutcomesModal) {
          openJobOutcomesModalForStatusChange(newJobLifecycleStatusGuid)
        } else {
          updateJobStatus(newJobLifecycleStatusGuid)
        }
      },
      [
        optimisticJobLifecycleStatusGuid,
        jobLifecycleStatus?.jobLifecycleStatusGuid,
        statusGuidToStatusMap,
        canManageTechnicianPerformance,
        comprehensiveJobDetails,
        openJobOutcomesModalForStatusChange,
        updateJobStatus,
      ],
    )

    const fetchJobTechnicianPerformanceInfoQuery = useQuery({
      query: FETCH_JOB_TECHNICIAN_PERFORMANCE_INFO,
      variables: {
        jobGuid: comprehensiveJobDetails.getJobGuid(),
      },
    })

    const [{ data: workCompletedAtSubscription }] = useSubscription({
      query: FETCH_JOB_WORK_COMPLETED_AT_SUBSCRIPTION,
      variables: {
        jobGuid: comprehensiveJobDetails.getJobGuid(),
      },
    })

    const jobOutcomesDetails: JobDetailsForOutcomes = useMemo(() => {
      return {
        jobGuid: comprehensiveJobDetails.getJobGuid(),
        accountGuid: comprehensiveJobDetails.getAccount()
          .accountGuid as AccountGuid,
        jobType: comprehensiveJobDetails.getJobTypeInfo(),
        isOpportunity: comprehensiveJobDetails.isOpportunity(),
        isHotLead: comprehensiveJobDetails.isHotLead(),
        isMembershipOpportunity:
          comprehensiveJobDetails.isMembershipOpportunity(),
        isMembershipRenewalOpportunity:
          comprehensiveJobDetails.isMembershipRenewalOpportunity(),
        isConverted: comprehensiveJobDetails.isConverted(),
        isMembershipSold: comprehensiveJobDetails.isMembershipSold(),
        commissionOverheadDeductionRate:
          comprehensiveJobDetails.getCommissionOverheadDeductionRate(),
        commissionOverheadFlatDeductionUsc:
          comprehensiveJobDetails.getCommissionOverheadFlatDeductionUsc(),
        commissionJobCostsDeductionRate:
          comprehensiveJobDetails.getCommissionJobCostsDeductionRate(),
        commissionJobCostsFlatDeductionUsc:
          comprehensiveJobDetails.getCommissionJobCostsFlatDeductionUsc(),
        teamMembers: comprehensiveJobDetails.getTeamMembers(),
        workCompletedAt:
          workCompletedAtSubscription?.jobsByPk?.workCompletedAt ??
          comprehensiveJobDetails.getWorkCompletedAt(),
      }
    }, [
      comprehensiveJobDetails,
      workCompletedAtSubscription?.jobsByPk?.workCompletedAt,
    ])

    const onJobOutcomesModalOk = useCallback(() => {
      try {
        if (jobLifecycleStatusGuidForJobOutcomesModal) {
          updateJobStatus(jobLifecycleStatusGuidForJobOutcomesModal)
        }

        refetchComprehensiveJobDetails()
        const [, refetchJobTechnicianPerformanceInfo] =
          fetchJobTechnicianPerformanceInfoQuery
        refetchJobTechnicianPerformanceInfo()
        closeJobOutcomesModal()
      } catch (e) {
        message.error(
          'Failed to complete job. Please reload the application and try again. If the problem persists, please contact support',
        )
      }
    }, [
      closeJobOutcomesModal,
      fetchJobTechnicianPerformanceInfoQuery,
      jobLifecycleStatusGuidForJobOutcomesModal,
      message,
      refetchComprehensiveJobDetails,
      updateJobStatus,
    ])

    const onJobOutcomesModalCancel = useCallback(() => {
      closeJobOutcomesModal()
    }, [closeJobOutcomesModal])

    return (
      <>
        <BzColumn noPadding>
          <div className="flex justify-between gap-x-3">
            <div className="text-xl font-semibold leading-7">
              {richJobTypeDescriptor(comprehensiveJobDetails.getJobType().name)}{' '}
              for
              {` `}
              <span
                onClick={canViewAccount ? navigateToAccountDetails : undefined}
                className={cn({
                  'text-bz-primary hover:cursor-pointer': canViewAccount,
                })}
              >
                {comprehensiveJobDetails.getPointOfContact().fullName}
              </span>
              {` at `}
              {serviceLocation &&
                (navigateToServiceLocationDetails ? (
                  <span
                    onClick={
                      canViewAccount
                        ? navigateToServiceLocationDetails
                        : undefined
                    }
                    className={cn({
                      'text-bz-primary hover:cursor-pointer': canViewAccount,
                    })}
                  >
                    {effectiveLocationDisplayName(serviceLocation)}
                  </span>
                ) : (
                  <span>{effectiveLocationDisplayName(serviceLocation)}</span>
                ))}
            </div>

            <div className="row no-wrap space-x-2">
              <MaintenancePlanVipIcon
                maintenancePlan={comprehensiveJobDetails.getMaintenancePlan()}
                inline={false}
              />
            </div>
          </div>

          <div className="mt-1 flex w-full flex-row items-center gap-2">
            <TrpcQueryLoader
              query={lifecycleQuery}
              render={() => (
                <>
                  {jobLifecycleStatus && jobLifecycle && (
                    <Select
                      size="large"
                      className="w-full grow sm:w-auto"
                      disabled={!editable}
                      loading={changeStatusMutation.isLoading}
                      popupMatchSelectWidth={false}
                      value={
                        optimisticJobLifecycleStatusGuid ??
                        jobLifecycleStatus.jobLifecycleStatusGuid
                      }
                      onChange={onStatusSelectChange}
                    >
                      {jobLifecycle.statuses.map(status => {
                        return (
                          <Select.Option
                            key={status.jobLifecycleStatusGuid}
                            value={status.jobLifecycleStatusGuid}
                          >
                            <div
                              className={cn(
                                'flex flex-row items-center space-x-4',
                              )}
                            >
                              <LifecycleStatusCircle
                                color={status.color}
                                specialStatus={status.specialStatus}
                              />
                              <span>{status.name}</span>
                            </div>
                          </Select.Option>
                        )
                      })}
                    </Select>
                  )}
                </>
              )}
            />

            <Divider type="vertical" />

            <div data-testid="jobEditButton">
              <Button size="large" onClick={onEdit}>
                Edit
              </Button>
            </div>

            {editable && actionItems && actionItems.length > 0 && (
              <ActionItemsWithEllipsis actionItems={actionItems} asButton />
            )}
          </div>

          <ThinDivider widthPx={1} />

          <ChipsPanel
            job={jobOutcomesDetails}
            isJobConverted={comprehensiveJobDetails.isConverted() ?? false}
            isMembershipSold={!isNullish(jobSoldMaintenancePlan)}
            isJobWorkComplete={jobLifecycleStatus?.isWorkComplete ?? false}
          />

          {shouldShowRevenueAttributionPanel && (
            <GqlQueryLoader
              query={fetchJobTechnicianPerformanceInfoQuery}
              render={data => (
                <RevenueAttributionCard
                  comprehensiveJobDetails={comprehensiveJobDetails}
                  editable={jobLifecycleStatus?.isWorkComplete ?? false}
                  onEdit={() => setShowJobOutcomesModal(true)}
                  jobTechnicianPerformanceInfo={data}
                />
              )}
            />
          )}

          <JobMaintenancePlanVisits
            jobGuid={comprehensiveJobDetails.getJobGuid()}
            maintenancePlans={comprehensiveJobDetails.getMaintenancePlans()}
            activeMaintenancePlanVisitGuid={comprehensiveJobDetails.getMaintenancePlanVisitGuid()}
            refetchJob={refetchComprehensiveJobDetails}
          />

          <div className="py-2">
            <VerticalKeyValue
              pair={{
                key: 'Summary',
                value: (
                  <TextWithLineBreaks>
                    {comprehensiveJobDetails.getSummary() ?? ''}
                  </TextWithLineBreaks>
                ),
              }}
            />
          </div>
          <div className="grid grid-cols-2 items-start gap-y-2">
            <BehindFeatureFlag
              enabledFeatureFlag="businessUnits"
              render={
                <VerticalKeyValue
                  pair={{
                    key: 'Business Unit',
                    value: comprehensiveJobDetails.getBusinessUnit()?.name ?? (
                      <EmDash />
                    ),
                  }}
                />
              }
            />

            <VerticalKeyValue
              pair={{
                key: 'Equipment',
                value: (
                  <div>
                    {comprehensiveJobDetails.getEquipmentTypeJobLinks().length >
                    0 ? (
                      <>
                        {comprehensiveJobDetails
                          .getEquipmentTypeJobLinks()
                          .map(et => (
                            <div
                              key={`${et.relationshipType}-${et.equipmentType}`}
                            >
                              {formatEquipmentJobLink(et)}
                            </div>
                          ))}
                      </>
                    ) : (
                      <EmDash />
                    )}
                  </div>
                ),
              }}
            />

            <VerticalKeyValue
              pair={{
                key: 'Job Type',
                value: comprehensiveJobDetails.getJobType().name,
              }}
            />

            <VerticalKeyValue
              pair={{
                key: 'Lead Source',
                value: (() => {
                  const leadSource = comprehensiveJobDetails.getLeadSource()
                  if (!leadSource) return <EmDash />
                  return (
                    <RichCompanyLeadSourceView
                      accountGuid={
                        comprehensiveJobDetails.getAccount().accountGuid
                      }
                      leadSource={leadSource}
                    />
                  )
                })(),
              }}
            />

            <VerticalKeyValue
              pair={{
                key: 'Created On',
                value: (
                  <DateView
                    isoWithOffsetTimestamp={BzDateTime.fromJsJoda(
                      comprehensiveJobDetails.getCreatedOn(),
                    )
                      .toDate()
                      .toISOString()}
                    format={DateFormat['M/d/yy @ h:mm a']}
                  />
                ),
              }}
            />

            <VerticalKeyValue
              pair={{
                key: 'Work Completed On',
                value: (() => {
                  const workCompletedAt =
                    comprehensiveJobDetails.getWorkCompletedAt()
                  return (
                    <>
                      {workCompletedAt ? (
                        <DateView
                          isoWithOffsetTimestamp={workCompletedAt}
                          format={DateFormat['M/d/yy @ h:mm a']}
                        />
                      ) : (
                        <EmDash />
                      )}
                    </>
                  )
                })(),
              }}
            />

            <VerticalKeyValue
              pair={{
                key: 'Customer PO Number',
                value: comprehensiveJobDetails.getCustomerPurchaseOrderNumber(),
              }}
            />
          </div>

          <div className="flex flex-col space-y-3">
            <span className="font-semibold">Tags</span>
            <TagList tags={comprehensiveJobDetails.getTags()} spacingY={2} />
          </div>

          {shouldShowRevenueAttributionPanel && (
            <GqlQueryLoader
              query={fetchJobTechnicianPerformanceInfoQuery}
              render={data => (
                <TechnicianRevenueAttributionSection
                  technicianEarnedRevenueSummaries={data.jobTechnicianEarnedRevenueSummaries.map(
                    summary => ({
                      technician: {
                        userGuid: bzExpect(summary.technician).userGuid,
                        name: bzExpect(summary.technician).fullName,
                      },
                      totalEarnedRevenueUsc: summary.totalEarnedRevenueUsc,
                    }),
                  )}
                  technicianSalesCommissionsAndBonuses={data.jobTechnicianSalesCommissionAndBonus.map(
                    commissionAndBonus => ({
                      technician: {
                        userGuid: bzExpect(commissionAndBonus.technician)
                          .userGuid,
                        name: bzExpect(commissionAndBonus.technician).fullName,
                      },
                      bonusUsc: commissionAndBonus.bonusUsc,
                      commissionRate: commissionAndBonus.commissionRate,
                      commissionUsc: commissionAndBonus.commissionUsc,
                      commissionableBaseUsc:
                        commissionAndBonus.commissionableBaseUsc,
                      totalDeductionsUsc: commissionAndBonus.totalDeductionsUsc,
                      totalSoldRevenueUsc:
                        commissionAndBonus.totalSoldRevenueUsc,
                    }),
                  )}
                  onEdit={() => setShowJobOutcomesModal(true)}
                />
              )}
            />
          )}
        </BzColumn>

        {showJobOutcomesModal && (
          <JobOutcomesModal
            open={showJobOutcomesModal}
            job={jobOutcomesDetails}
            onOk={onJobOutcomesModalOk}
            onCancel={onJobOutcomesModalCancel}
          />
        )}
      </>
    )
  },
)

export default JobInfoCard
