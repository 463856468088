import {
  type FetchAllCompaniesDocument,
  type ResultOf,
} from '@breezy/backend/src/query'
import {
  CompanyGuid,
  EstimateEntityTypeName,
  InvoiceEntityTypeName,
  MaintenancePlanEntityTypeName,
  TimeZoneId,
  bzExpect,
} from '@breezy/shared'
import { getFormattedPhoneNumber } from '@breezy/shared/src/common/phone-utils'
import {
  faCircleInfo,
  faMoneyCheckDollarPen,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Popover, Space, Table, Tooltip } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { getConfig } from '../../config'
import { DateView } from '../../elements/DateView/DateView'
import { EmDash } from '../../elements/EmDash/EmDash'
import RenderIf from '../../elements/RenderIf/RenderIf'
import { trpc } from '../../hooks/trpc'
import { TwilioLogo } from '../../pages/CommsPage/CommsTwilioLogo'
import { VapiLogo } from '../../pages/CommsPage/CommsVapiLogo'
import { useMessage, useModal } from '../../utils/antd-utils'
import {
  QuickbooksDesktopEnableModal,
  QuickbooksDesktopEnableModalProps,
} from '../AccountingIntegration/QuickbooksDesktop/QuickbooksDesktopEnableModal'
import {
  SyncPaymentsOrPayoutsDrawer,
  SyncPaymentsOrPayoutsProps,
} from '../Admin/AdminSyncs/SyncPaymentsOrPayoutsDrawer'
import { DateFormat } from '../Dates'
import { WisetackLogo } from '../Financing/WisetackLogo'
import { ResyncSearchRecordsDrawer } from '../ResyncSearchRecordsDrawer'
import { TilledLogo } from '../TilledLogo/TilledLogo'
import { UpdateFinancialConfigDrawer } from '../UpdateFinancialConfigDrawer/UpdateFinancialConfigDrawer'

type FetchAllCompaniesResult = ResultOf<
  typeof FetchAllCompaniesDocument
>['companies']
type FetchAllCompaniesResultItem = ResultOf<
  typeof FetchAllCompaniesDocument
>['companies'][0]

type CompanyUserItem = FetchAllCompaniesResultItem['companyUsers'][0]

type CompaniesGridProps = {
  companies: FetchAllCompaniesResult
  onCompanyAction: () => void
}

type ResyncEntityType =
  | typeof MaintenancePlanEntityTypeName
  | typeof EstimateEntityTypeName
  | typeof InvoiceEntityTypeName

export const CompaniesGrid: React.FC<CompaniesGridProps> = React.memo(
  ({ companies, onCompanyAction }) => {
    const message = useMessage()
    const [resyncPaymentOrPayoutsCtx, setResyncPaymentOrPaymentsCtx] =
      useState<SyncPaymentsOrPayoutsProps>()
    const companiesToDisplay = useMemo(() => {
      return companies
    }, [companies])
    const resyncMutation = trpc.resync['resync:entity-type'].useMutation()
    const [quickbooksDesktopEnableModal, setQuickbooksDesktopEnableModal] =
      useState<QuickbooksDesktopEnableModalProps | undefined>()

    const resyncEntities = useCallback(
      (companyGuid: string, entityType: ResyncEntityType) => {
        resyncMutation.mutate(
          {
            companyGuid,
            entityType,
          },
          { onSuccess: onCompanyAction },
        )
      },
      [resyncMutation, onCompanyAction],
    )

    const Modal = useModal()

    const addressGeocodeMigratorMutation =
      trpc.devTools['devtools:migrate:address-geocode'].useMutation()

    const migrateAddressesWithGeocodes = useCallback(
      (companyGuid: string) => {
        addressGeocodeMigratorMutation.mutateAsync(
          { companyGuid },
          {
            onSuccess: res => {
              const hasNonMigrated = res.addressesNotMigrated.length > 0
              const payload = {
                title: 'Addresses Geocoded',
                width: 500,
                content: (
                  <div>
                    <div>
                      <strong>Company Guid:</strong> {companyGuid}
                    </div>
                    <div>
                      <strong>Addressses Migrated:</strong>{' '}
                      {res.addressesMigrated}
                    </div>
                    <div
                      className={classNames({
                        'text-red-500': hasNonMigrated,
                      })}
                    >
                      <div>
                        <strong>Addresses Still To Geocode:</strong>
                        {hasNonMigrated ? '' : ' 0'}
                      </div>
                      {hasNonMigrated && (
                        <ol>
                          {res.addressesNotMigrated.map(
                            ({ addressGuid, reason }) => (
                              <li key={addressGuid}>
                                {addressGuid}
                                <ul>
                                  <li>{reason}</li>
                                </ul>
                              </li>
                            ),
                          )}
                        </ol>
                      )}
                    </div>
                  </div>
                ),
              }
              if (hasNonMigrated) {
                Modal.error(payload)
              } else {
                Modal.success(payload)
              }
            },
          },
        )
      },
      [Modal, addressGeocodeMigratorMutation],
    )

    const generateRecommendationsMutation =
      trpc.devTools['devtools:generate-recommendations:company'].useMutation()

    const generateRecommendations = useCallback(
      (companyGuid: string, companyTimeZoneId: TimeZoneId) => {
        generateRecommendationsMutation.mutate(
          { companyGuid, timezone: companyTimeZoneId },
          {
            onSuccess: () => {
              message.success('Recommendations generated')
              onCompanyAction()
            },
          },
        )
      },
      [generateRecommendationsMutation, message, onCompanyAction],
    )

    const migrateDupeAffinityDatesMutation =
      trpc.devTools[
        'devtools:maintenance-plans:migrate-dupe-affinity-dates'
      ].useMutation()

    const migrateDupeAffinityDates = useCallback(
      (companyGuid: string) => {
        migrateDupeAffinityDatesMutation.mutateAsync(
          { companyGuid },
          {
            onSuccess: res => {
              const hasNonMigrated = res.plansNotMigrated.length > 0
              const payload = {
                title: 'Maintenance Plans Migrated Dupe Affinity Dates',
                width: 500,
                content: (
                  <div>
                    <div>
                      <strong>Company Guid:</strong> {companyGuid}
                    </div>
                    <div>
                      <strong>Maintenance Plans Migrated:</strong>{' '}
                      {res.migratedPlansCount}
                    </div>
                    <div
                      className={classNames({
                        'text-red-500': hasNonMigrated,
                      })}
                    >
                      <div>
                        <strong>Plans Not Migrated:</strong>
                        {hasNonMigrated ? '' : ' 0'}
                      </div>
                      {hasNonMigrated && (
                        <ol>
                          {res.plansNotMigrated.map(
                            ({ maintenancePlanGuid, reason }) => (
                              <li key={maintenancePlanGuid}>
                                {maintenancePlanGuid}
                                <ul>
                                  <li>{reason}</li>
                                </ul>
                              </li>
                            ),
                          )}
                        </ol>
                      )}
                    </div>
                  </div>
                ),
              }
              if (hasNonMigrated) {
                Modal.error(payload)
              } else {
                Modal.success(payload)
              }
              onCompanyAction()
            },
            onError: error => {
              console.error(error)
              message.error(
                `Failed to migrate dupe affinity dates: ${error.message}`,
              )
            },
          },
        )
      },
      [migrateDupeAffinityDatesMutation, Modal, message, onCompanyAction],
    )

    const [companyGuid, setCompanyGuid] = useState<string | undefined>()
    const [searchRecordsDrawerOpen, setSearchRecordsDrawerOpen] =
      useState(false)
    const [
      updateFinancialConfigDrawerOpen,
      setUpdateFinancialConfigDrawerOpen,
    ] = useState(false)

    const [openActionMenuId, setOpenActionMenuId] = useState('')

    const handleOpenChange = (newOpen: boolean, companyGuid: string) => {
      if (newOpen) {
        setOpenActionMenuId(companyGuid)
      } else {
        setOpenActionMenuId('')
      }
    }

    const updateVapiTestPhoneNumberMutation =
      trpc.devTools['devtools:update-vapi-test-phone-number'].useMutation()

    const clearSearchRecordsMutation =
      trpc.searcher['searcher:clear-index-for-company'].useMutation()

    const clearSearchRecords = useCallback(
      async (companyGuid: CompanyGuid) => {
        try {
          await clearSearchRecordsMutation.mutateAsync({ companyGuid })
          message.success('Search records cleared successfully')
          onCompanyAction()
        } catch (e) {
          console.error(e)
          message.error('Failed to clear search records')
        }
      },
      [clearSearchRecordsMutation, onCompanyAction, message],
    )

    const updateVapiTestPhoneNumber = useCallback(
      (companyGuid: string) => {
        updateVapiTestPhoneNumberMutation.mutate(
          { companyGuid },
          {
            onSuccess: () => {
              message.success('VAPI test phone number updated successfully')
              onCompanyAction()
            },
            onError: error => {
              console.error(error)
              message.error(
                `Failed to update VAPI test phone number: ${error.message}`,
              )
            },
          },
        )
      },
      [updateVapiTestPhoneNumberMutation, message, onCompanyAction],
    )

    const isProduction = getConfig().env === 'production'

    const columns = [
      {
        title: 'Company ID',
        dataIndex: 'companyGuid',
        key: 'companyGuid',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Live Since',
        dataIndex: 'breezySystemOfRecordDate',
        render: (_: unknown, record: FetchAllCompaniesResultItem) => {
          return (
            <DateView
              isoWithOffsetTimestamp={record.breezySystemOfRecordDate}
              format={DateFormat['MMM d, yyyy']}
              zone={{ type: 'explicit', timezone: record.timezone }}
            />
          )
        },
      },
      {
        title: 'Financial Config',
        dataIndex: 'financialConfig',
        key: 'financialConfig',
        render: (_: unknown, record: FetchAllCompaniesResultItem) => (
          <div className="flex flex-row flex-nowrap gap-2">
            <RenderIf if={!!record.billingProfile?.wisetackMerchantId}>
              <WisetackLogo size="sm" />
            </RenderIf>
            <RenderIf if={!!record.billingProfile?.merchantId}>
              <TilledLogo size="sm" />
            </RenderIf>
            <RenderIf
              if={
                !record.billingProfile?.merchantId &&
                !record.billingProfile?.wisetackMerchantId
              }
            >
              <Tooltip
                title={`No financial integration configured. Wisetack Loan Promo will be visible but will be unable to create loan and prequal applications. To update the company finance config to enable financial integrations, click the "Update Financial Configuration" action.`}
              >
                <FontAwesomeIcon icon={faCircleInfo} />
              </Tooltip>
            </RenderIf>
          </div>
        ),
      },
      {
        title: 'Accounting Integration',
        dataIndex: 'accountingIntegration',
        key: 'accountingIntegration',
        render: (_: unknown, record: FetchAllCompaniesResultItem) => (
          <>
            <div>
              {(record.accCompanyConfigs?.length ?? 0) > 0 ? (
                (
                  record.accCompanyConfigs[0].accountingIntegrationType ??
                  'NONE'
                ).toUpperCase()
              ) : (
                <EmDash />
              )}
            </div>
          </>
        ),
      },
      {
        title: 'Integrated Phones',
        dataIndex: 'integratedPhones',
        key: 'integratedPhones',
        render: (_: unknown, record: FetchAllCompaniesResultItem) => (
          <div className="flex flex-row flex-nowrap gap-2">
            {(record.integratedPhones ?? []).map(phone => (
              <Tooltip title={getFormattedPhoneNumber(phone.phoneNumber)}>
                {phone.phoneProvider === 'twilio' && <TwilioLogo size="sm" />}
                {phone.phoneProvider === 'vapi' && <VapiLogo size="sm" />}
              </Tooltip>
            ))}
          </div>
        ),
      },
      {
        title: 'Job Count',
        dataIndex: 'jobsAggregate',
        key: 'jobsAggregate',
        render: (
          jobsAggregate: FetchAllCompaniesResultItem['jobsAggregate'],
        ) => {
          return jobsAggregate?.aggregate?.jobCount || 0
        },
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (_: unknown, record: FetchAllCompaniesResultItem) => (
          <Space>
            <Popover
              trigger="click"
              content={
                <div className="p0 flex flex-col flex-nowrap content-start">
                  <Button
                    type="link"
                    htmlType="button"
                    loading={resyncMutation.isLoading}
                    onClick={() =>
                      resyncEntities(
                        record.companyGuid,
                        MaintenancePlanEntityTypeName,
                      )
                    }
                  >
                    🔄 Resync Maintenance Plans
                  </Button>
                  <Button
                    type="link"
                    htmlType="button"
                    loading={resyncMutation.isLoading}
                    onClick={() =>
                      resyncEntities(record.companyGuid, InvoiceEntityTypeName)
                    }
                  >
                    🔄 Resync Invoices
                  </Button>
                  <Button
                    type="link"
                    htmlType="button"
                    loading={resyncMutation.isLoading}
                    onClick={() =>
                      resyncEntities(record.companyGuid, EstimateEntityTypeName)
                    }
                  >
                    🔄 Resync Estimates
                  </Button>
                  {record.billingProfile?.merchantId && (
                    <Button
                      type="link"
                      htmlType="button"
                      onClick={() =>
                        setResyncPaymentOrPaymentsCtx({
                          type: 'payment',
                          companyGuid: record.companyGuid,
                          merchantId: bzExpect(
                            record.billingProfile?.merchantId,
                            'merchantId',
                          ),
                          onCancel: () =>
                            setResyncPaymentOrPaymentsCtx(undefined),
                        })
                      }
                    >
                      🔄 Resync Payments
                    </Button>
                  )}
                  {record.billingProfile?.merchantId && (
                    <Button
                      type="link"
                      htmlType="button"
                      onClick={() =>
                        setResyncPaymentOrPaymentsCtx({
                          type: 'payouts',
                          companyGuid: record.companyGuid,
                          merchantId: bzExpect(
                            record.billingProfile?.merchantId,
                            'merchantId',
                          ),
                          onCancel: () =>
                            setResyncPaymentOrPaymentsCtx(undefined),
                        })
                      }
                    >
                      🔄 Resync Payouts
                    </Button>
                  )}
                  <Button
                    type="link"
                    htmlType="button"
                    onClick={() => {
                      setSearchRecordsDrawerOpen(true)
                      setCompanyGuid(record.companyGuid)
                    }}
                  >
                    🔄 Resync Search Records
                  </Button>
                  <Button
                    type="link"
                    htmlType="button"
                    loading={clearSearchRecordsMutation.isLoading}
                    onClick={() => {
                      clearSearchRecords(record.companyGuid)
                    }}
                  >
                    ❌ Clear Search Records
                  </Button>
                  <Button
                    type="link"
                    htmlType="button"
                    loading={addressGeocodeMigratorMutation.isLoading}
                    onClick={() =>
                      migrateAddressesWithGeocodes(record.companyGuid)
                    }
                  >
                    🔄 Geocode Addresses
                  </Button>
                  <Button
                    type="link"
                    htmlType="button"
                    loading={generateRecommendationsMutation.isLoading}
                    onClick={() =>
                      generateRecommendations(
                        record.companyGuid,
                        record.timezone,
                      )
                    }
                  >
                    🔄 Regenerate Today's Recommendations
                  </Button>

                  <Button
                    type="link"
                    htmlType="button"
                    icon={<FontAwesomeIcon icon={faMoneyCheckDollarPen} />}
                    onClick={() => {
                      setUpdateFinancialConfigDrawerOpen(true)
                      setCompanyGuid(record.companyGuid)
                    }}
                  >
                    Update Financial Configuration
                  </Button>
                  <Button
                    type="link"
                    htmlType="button"
                    loading={migrateDupeAffinityDatesMutation.isLoading}
                    onClick={() => migrateDupeAffinityDates(record.companyGuid)}
                  >
                    🔄 Migrate Dupe Affinity Dates
                  </Button>
                  <Button
                    type="link"
                    htmlType="button"
                    onClick={() =>
                      setQuickbooksDesktopEnableModal({
                        companyGuid: record.companyGuid,
                        companyName: record.name,
                        // NOTE: We don't required root email address for companies,
                        // and Conductor only uses this for id purposes, so we're sending a fake email
                        companyEmail: `${record.companyGuid}@getbreezyapp.com`,
                        open: true,
                        onClose: () =>
                          setQuickbooksDesktopEnableModal(undefined),
                      })
                    }
                  >
                    🔌 Enable Quickbooks Desktop
                  </Button>
                  <RenderIf if={!isProduction}>
                    <Button
                      type="link"
                      htmlType="button"
                      loading={updateVapiTestPhoneNumberMutation.isLoading}
                      onClick={() =>
                        updateVapiTestPhoneNumber(record.companyGuid)
                      }
                    >
                      🔗 Link AI CSR Test Phone Number
                    </Button>
                  </RenderIf>
                </div>
              }
              placement="topRight"
              open={openActionMenuId === record.companyGuid}
              onOpenChange={(newOpen: boolean) =>
                handleOpenChange(newOpen, record.companyGuid)
              }
            />

            <Button
              type="primary"
              onClick={() => setOpenActionMenuId(record.companyGuid)}
            >
              Actions
            </Button>
          </Space>
        ),
      },
    ]

    const innerTableColumns = [
      {
        title: 'Email',
        key: 'emailAddress',
        render: (companyUser: CompanyUserItem) => {
          return companyUser?.user?.emailAddress
        },
      },
      {
        title: 'First Name',
        key: 'firstName',
        render: (companyUser: CompanyUserItem) => {
          return companyUser?.user?.firstName
        },
      },
      {
        title: 'Last Name',
        key: 'lastName',
        render: (companyUser: CompanyUserItem) => {
          return companyUser?.user?.lastName
        },
      },
      {
        title: 'Roles',
        key: 'roles',
        render: (companyUser: CompanyUserItem) => {
          return companyUser?.user?.roles.map(r => r.name).join(', ') || ''
        },
      },
      {
        title: 'Permissions',
        key: 'permissions',
        render: (companyUser: CompanyUserItem) => {
          return (
            companyUser?.user?.permissions.map(p => p.name).join(', ') || ''
          )
        },
      },
    ]

    return (
      <>
        <Table
          rowKey="companyGuid"
          expandable={{
            expandedRowRender: item => {
              return (
                <Table
                  rowKey={item => item.user.userGuid}
                  pagination={false}
                  dataSource={item.companyUsers}
                  columns={innerTableColumns}
                />
              )
            },
            rowExpandable: () => true,
          }}
          dataSource={companiesToDisplay}
          columns={columns}
        />

        <SyncPaymentsOrPayoutsDrawer item={resyncPaymentOrPayoutsCtx} />

        <ResyncSearchRecordsDrawer
          companyGuid={companyGuid}
          open={searchRecordsDrawerOpen}
          onClose={() => {
            setSearchRecordsDrawerOpen(false)
          }}
        />

        <UpdateFinancialConfigDrawer
          open={updateFinancialConfigDrawerOpen}
          companyGuid={companyGuid ?? ''}
          onFinancialConfigUpdated={() => {
            setUpdateFinancialConfigDrawerOpen(false)
            setCompanyGuid(undefined)
          }}
          onCancel={() => {
            setUpdateFinancialConfigDrawerOpen(false)
            setCompanyGuid(undefined)
          }}
        />

        <QuickbooksDesktopEnableModal
          companyGuid={quickbooksDesktopEnableModal?.companyGuid ?? ''}
          companyName={quickbooksDesktopEnableModal?.companyName ?? ''}
          companyEmail={quickbooksDesktopEnableModal?.companyEmail ?? ''}
          open={!!quickbooksDesktopEnableModal}
          onClose={() => {
            setQuickbooksDesktopEnableModal(undefined)
            onCompanyAction()
          }}
        />
      </>
    )
  },
)
