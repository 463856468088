import { BzDateFns, IsoDateString } from '@breezy/shared'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { faOctagonExclamation } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popover } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { OnResize, useResizeObserver } from '../../hooks/useResizeObserver'
import { EventColorConfig } from '../../utils/job-utils'
import {
  StateSetter,
  stopPropagation,
  useStrictContext,
} from '../../utils/react-utils'
import { SchedulePageContext } from './scheduleUtils'

const MIN_WIDTH_FOR_WARNING_LABEL = 75

export type BaseEventBoxProps = React.PropsWithChildren<{
  title: string
  colorConfig: EventColorConfig
  icon?: React.ReactNode
  avatarContent?: React.ReactNode
  start: IsoDateString
  end: IsoDateString
  className?: string
  completed?: boolean
  warning?: string
  popoverContent?: React.ReactNode
  popoverOpen?: boolean
  setPopoverOpen?: StateSetter<boolean>
}>

export const BaseEventBox = React.memo<BaseEventBoxProps>(
  ({
    children,
    colorConfig,
    title,
    icon,
    avatarContent,
    start,
    end,
    className,
    completed,
    warning,
    popoverContent,
    popoverOpen,
    setPopoverOpen,
  }) => {
    const { scheduleView } = useStrictContext(SchedulePageContext)

    const { is15Minutes, isAnHourOrLess } = useMemo(() => {
      const startDate = BzDateFns.parseISO(start, BzDateFns.UTC)
      const endDate = BzDateFns.parseISO(end, BzDateFns.UTC)
      const mins = BzDateFns.differenceInMinutes(endDate, startDate)
      return {
        is15Minutes: mins <= 15,
        isAnHourOrLess: mins <= 60,
      }
    }, [end, start])

    const [forceSmallWarning, setForceSmallWarning] = useState(false)

    const containerRef = useRef<HTMLDivElement>(null)

    const onResize = useCallback<OnResize>(({ width }) => {
      setForceSmallWarning(width < MIN_WIDTH_FOR_WARNING_LABEL)
    }, [])

    useResizeObserver(containerRef, onResize)

    const content = (
      <div
        ref={containerRef}
        className={classNames(
          'mr-[2px] flex h-full min-h-0 min-w-0 flex-1 flex-row items-stretch overflow-hidden text-[0.625rem] leading-[0.875rem] hover:opacity-85',
          completed ? 'text-bz-text-tertiary' : 'text-bz-text',
          completed ? 'bg-bz-border-secondary' : colorConfig.background,
          is15Minutes ? 'rounded' : 'rounded-md',
          {
            'border-2 border-solid border-bz-orange-600': !!warning,
          },
          className,
        )}
        style={{
          backgroundColor: colorConfig.background.startsWith('#')
            ? colorConfig.background
            : undefined,
        }}
      >
        <div
          className={classNames(
            'min-w-1',
            colorConfig[completed ? 'completedRibbon' : 'ribbon'],
          )}
          style={{
            backgroundColor: colorConfig[
              completed ? 'completedRibbon' : 'ribbon'
            ].startsWith('#')
              ? colorConfig[completed ? 'completedRibbon' : 'ribbon']
              : undefined,
          }}
        />
        <div
          className={classNames('flex min-w-0 flex-1 flex-col px-1 py-1', {
            'ml-1': !is15Minutes,
          })}
        >
          <div
            className={classNames(
              'mb-[1px] flex min-h-0 items-start justify-end',
              is15Minutes ? 'flex-col-reverse' : 'flex-row ',
            )}
          >
            <div className="line-clamp-2 max-h-7 flex-1 break-words font-semibold">
              {title}
            </div>
            {icon || avatarContent ? (
              <div
                className={classNames(
                  'flex h-3.5 min-w-4 flex-row items-center space-x-1',
                  { 'ml-0.5': !is15Minutes },
                )}
              >
                {warning ? (
                  <div className="flex min-h-4 flex-row items-center rounded bg-bz-orange-100 px-1 leading-3 text-bz-orange-800">
                    {!isAnHourOrLess && !forceSmallWarning && (
                      <div className="mr-1 font-semibold">{warning}</div>
                    )}
                    <FontAwesomeIcon icon={faOctagonExclamation} />
                  </div>
                ) : icon || completed ? (
                  <div className="*:min-h-3 *:min-w-3">
                    {completed ? <FontAwesomeIcon icon={faCheck} /> : icon}
                  </div>
                ) : null}
                {avatarContent && scheduleView !== 'DISPATCH' ? (
                  <div className="flex min-h-4 min-w-4 items-center justify-center rounded-full bg-bz-text-secondary text-[8px] font-semibold text-white transition-all">
                    {avatarContent}
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
          {!is15Minutes && (
            <div className="truncate whitespace-nowrap">{children}</div>
          )}
        </div>
      </div>
    )

    if (popoverContent) {
      return (
        <Popover
          zIndex={1009}
          mouseEnterDelay={0.3}
          mouseLeaveDelay={0.3}
          open={popoverOpen}
          onOpenChange={setPopoverOpen}
          placement="left"
          arrow={false}
          overlayClassName="schedule-v2-tooltip"
          content={<div onClick={stopPropagation}>{popoverContent}</div>}
        >
          {content}
        </Popover>
      )
    }
    return content
  },
)
