import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import React, { useCallback } from 'react'
import { trpc } from '../../hooks/trpc'

type FormSchema = {
  fileLink: string
  startIndexInclusive: number
  endIndexInclusive: number | undefined
}

type ChunkMigrationFormSchema = {
  fileUrl: string
  companyGuid: string
  workloadName: string
  noFanout: boolean
}

export const DataMigrationView = React.memo(() => {
  const migrateData =
    trpc.devTools['devtools:account-full-history-migration'].useMutation()
  const migrateAccountHistoryFromUrl =
    trpc.devTools['devtools:migrate-account-history-from-url'].useMutation()
  const bulkImportMaintenancePlans =
    trpc.maintenancePlans[
      'maintenance-plans:bulk-activate-imported'
    ].useMutation()
  const [form] = useForm<FormSchema>()
  const [chunkForm] = useForm<ChunkMigrationFormSchema>()

  const [modal, contextHolder] = Modal.useModal()

  const migrateDataViaApi = useCallback(
    async (values: FormSchema) => {
      try {
        const result = await migrateData.mutateAsync(values)
        modal.confirm({
          title: 'Migration Complete',
          content: (
            <>
              Successfully migrated {result.successCount} accounts.
              <br />
              Errors: {result.errorCount}
              {result.errors.map((error, idx) => (
                <div key={idx}>
                  Account: {error.accountGuid} at index {error.accountIndex}
                  <br />
                  Message: {error.message}
                  <br />
                  Cause: {`${error.cause}`}
                  <br />
                  Stack: {error.stack}
                </div>
              ))}
            </>
          ),
        })
      } catch (error: unknown) {
        modal.error({
          title: 'Error',
          content: error instanceof Error ? error.message : 'Unknown error',
        })
      }
    },
    [migrateData, modal],
  )

  const migrateAccountHistoryChunks = useCallback(
    async (values: ChunkMigrationFormSchema) => {
      try {
        const result = await migrateAccountHistoryFromUrl.mutateAsync(values)
        modal.confirm({
          title: 'Migration Queued',
          content: (
            <>
              Successfully queued {result.totalAccounts} accounts for migration.
              <br />
              Chunks created: {result.successfulChunks}
              <br />
              Failed chunks: {result.failedChunks ?? 0}
              <br />
            </>
          ),
        })
      } catch (error: unknown) {
        modal.error({
          title: 'Error',
          content: error instanceof Error ? error.message : 'Unknown error',
        })
      }
    },
    [migrateAccountHistoryFromUrl, modal],
  )

  return (
    <div style={{ marginTop: 32 }}>
      <h2>Bulk Maintenance Plan Import</h2>
      <Form
        layout="vertical"
        onFinish={async values => {
          try {
            const result = await bulkImportMaintenancePlans.mutateAsync({
              fileLink: values.fileLink,
            })
            modal.confirm({
              title: 'Import Complete',
              content: (
                <>
                  Successfully imported {result.totalImported} maintenance
                  plans.
                  <br />
                  {result.errors.length > 0 && (
                    <>
                      Errors: {result.errors.length}
                      {result.errors.map((error, idx) => (
                        <div key={idx}>
                          Account: {error.accountDisplayName}
                          <br />
                          Address: {error.fullAddress}
                          <br />
                          Message: {error.messages}
                          <br />
                          Index: {error.index}
                        </div>
                      ))}
                    </>
                  )}
                </>
              ),
            })
          } catch (error: unknown) {
            modal.error({
              title: 'Error',
              content: error instanceof Error ? error.message : 'Unknown error',
            })
          }
        }}
        initialValues={{
          fileLink:
            'https://breezy-onboarding-resources.s3.us-east-1.amazonaws.com/right-way-single-plan-import.json',
        }}
      >
        <Row>
          <Col xs={8}>
            <Row>
              <Col xs={24}>
                <Form.Item
                  name="fileLink"
                  label="Link to Maintenance Plans JSON file"
                  rules={[{ required: true, type: 'url' }]}
                >
                  <Input type="url" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={bulkImportMaintenancePlans.isLoading}
                    >
                      Import Maintenance Plans
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      <Divider />
      <h2>Chunked Account Migration</h2>
      <Form
        form={chunkForm}
        layout="vertical"
        onFinish={migrateAccountHistoryChunks}
        validateTrigger="onBlur"
        initialValues={{
          fileUrl:
            'https://breezy-onboarding-resources.s3.us-east-1.amazonaws.com/kaw-valley-single-account.json',
          // 'https://breezy-onboarding-resources.s3.us-east-1.amazonaws.com/100-accounts-slice.json',
          workloadName: 'account-history-migration-example',
          noFanout: false,
          requireValidatedInput: true,
        }}
      >
        <Row>
          <Col xs={8}>
            <Row>
              <Col xs={24}>
                <Form.Item
                  name="fileUrl"
                  label="Link to JSON file"
                  rules={[{ required: true, type: 'url' }]}
                >
                  <Input type="url" />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="companyGuid"
                  label="Company GUID"
                  rules={[
                    {
                      required: true,
                      pattern:
                        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/,
                      message: 'Please enter a valid GUID',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="workloadName"
                  label="Workload Name"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="noFanout"
                  label="No Fanout (Single Account at a Time)"
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="requireValidatedInput"
                  label="Require Validated Input"
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={migrateAccountHistoryFromUrl.isLoading}
                    >
                      Start Chunked Migration
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      <Divider />
      <h2>Legacy Account Migration</h2>
      <Form
        form={form}
        layout="vertical"
        onFinish={migrateDataViaApi}
        validateTrigger="onBlur"
        initialValues={{
          fileLink:
            'https://breezy-onboarding-resources.s3.us-east-1.amazonaws.com/example.json',
          startIndexInclusive: 0,
        }}
      >
        <Row>
          <Col xs={8}>
            <Row>
              <Col xs={24}>
                <Form.Item name="fileLink" label="Link to JSON file">
                  <Input type="url" />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="startIndexInclusive"
                  label="Index of Account to Start (Inclusive)"
                >
                  <InputNumber />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="endIndexInclusive"
                  label="End Index (Exclusive)"
                  help="Leave blank to process the entire file"
                >
                  <InputNumber />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={migrateData.isLoading}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

      {contextHolder}
    </div>
  )
})
