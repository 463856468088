import {
  AssignmentStatus,
  BzDateFns,
  Guid,
  InferredAppointmentStatus,
  IsoDateString,
  JobClass,
  Merge,
  parseRRule,
  R,
  RoleId,
  SchedulingCapability,
  TechnicianCapacityBlockReasonType,
  TimeZoneId,
} from '@breezy/shared'
import {
  MbscCalendarEvent,
  MbscCalendarEventData,
  MbscEventClickEvent,
  MbscEventCreateEvent,
  MbscEventUpdateEvent,
} from '@mobiscroll/react'
import { ICalendarData } from '@mobiscroll/react/dist/src/core/shared/calendar-view/calendar-view.types'
import moment, { Moment } from 'moment-timezone'
import React, { useEffect, useMemo, useState } from 'react'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { StateSetter, useStrictContext } from '../../utils/react-utils'
import {
  ArrivalWindowMap,
  FixedBlockCalendarEvent,
  FixedNonBlockCalendarEvent,
} from './PendingChanges/pendingChanges'
import {
  ScheduleAppointment,
  ScheduleAssignment,
  UnassignedAppointment,
} from './Schedule.gql'
import { ScheduleMode, ScheduleView } from './ScheduleContext'
import { usePersistedDismissedRunningLateAlerts } from './usePersistedDismissedRunningLateAlerts'

type BlockGuid = string
type AppointmentGuid = string
type AssignmentGuid = string

type BzCalendarEventCommon = MbscCalendarEvent & {
  userGuids: Guid[]
}

export type BlockCalendarEvent = BzCalendarEventCommon & {
  blockGuid: BlockGuid
  reasonType: TechnicianCapacityBlockReasonType
  reasonDescription?: string
  appointmentGuid?: never
  assignmentGuid?: never
  unmodifiedStart?: IsoDateString
  unmodifiedEnd?: IsoDateString
  unmodifiedRecurrenceExceptions?: string
  appointmentInfo?: never
}

export type NonBlockCalendarEvent = BzCalendarEventCommon & {
  appointmentGuid: AppointmentGuid
  assignmentGuid: AssignmentGuid
  blockGuid?: never
  appointmentInfo?: never
}

export type BzCalendarEvent = BlockCalendarEvent | NonBlockCalendarEvent

export type BzCalendarEventData = MbscCalendarEventData & {
  original: BzCalendarEvent
}

export type BzEventCreateEvent = MbscEventCreateEvent & {
  event: BzCalendarEvent
}

export type BzEventUpdateEvent = MbscEventUpdateEvent & {
  /**
   * `event` represents the current updated state for a specific item on the Calendar.
   */
  event: BzCalendarEvent

  /**
   * `oldEvent` represents the state the appointment entity was in prior to it being updated. If this event
   * represents an updated non-appointment item, this is an exact replica of `event`.
   */
  oldEvent: BzCalendarEvent

  /**
   * `newEvent` represents an event specifically for updated items that are non-appointment entities. For appointment
   * entities, this should never be populated.
   */
  newEvent?: BzCalendarEvent
}

export type BzEventClickEvent = MbscEventClickEvent & {
  event: BzCalendarEvent
}

export type BzBlockInfo = {
  blockGuid: Guid
  userGuids: Guid[]
  start: IsoDateString
  end: IsoDateString
  reasonType: TechnicianCapacityBlockReasonType
  reasonDescription?: string
  recurrenceRule?: string
  recurrenceRuleExceptions?: string
}

type EventChangeInfo = {
  assignmentGuid: Guid
  appointmentGuid: Guid
  userGuids: Guid[]
  start: IsoDateString
  end: IsoDateString
}

type BlockChangeInfo = BzBlockInfo

export type BzChangeInfo = EventChangeInfo | BlockChangeInfo

export type TechnicianResource = {
  id: Guid
  name: string
  userGuid: Guid
  deactivatedAt?: IsoDateString
  schedulingCapability: SchedulingCapability
  avatarShortString: string
  firstName: string
  lastName: string
  roles: {
    role: RoleId
  }[]
  userPrimaryHueHex: string | undefined
}

export type SchedulePageContextType = {
  scheduleMode: ScheduleMode
  scheduleView: ScheduleView
  setScheduleView: (view: ScheduleView) => void
  selectedDate: IsoDateString
  setSelectedDate: StateSetter<IsoDateString>
  setSelectedAppointmentGuid: (appointmentGuid: AppointmentGuid) => void
  setEditingAppointmentGuid: (appointmentGuid: AppointmentGuid) => void
  editingAppointmentGuid?: AppointmentGuid
  selectedTechGuids: Guid[]
}

export const SchedulePageContext = React.createContext<
  SchedulePageContextType | undefined
>(undefined)

export const isOutsideOfArrivalWindow = (
  startStr: IsoDateString,
  appointmentGuid: AppointmentGuid,
  arrivalWindowStart: IsoDateString,
  arrivalWindowEnd: IsoDateString,
  arrivalWindowChangeMap: ArrivalWindowMap,
) => {
  // UTC is fine since we're comparing moments in time
  const start = BzDateFns.parseISO(startStr, BzDateFns.UTC)
  const arrivalStart = BzDateFns.parseISO(
    arrivalWindowChangeMap[appointmentGuid]?.start ?? arrivalWindowStart,
    BzDateFns.UTC,
  )
  const arrivalEnd = BzDateFns.parseISO(
    arrivalWindowChangeMap[appointmentGuid]?.end ?? arrivalWindowEnd,
    BzDateFns.UTC,
  )
  return !BzDateFns.isWithinInterval(start, {
    start: arrivalStart,
    end: arrivalEnd,
  })
}

export const isBlockEvent = (
  event: BzCalendarEvent,
): event is BlockCalendarEvent => 'blockGuid' in event

export const isNewBlockEvent = (
  event: FixedBlockCalendarEvent | FixedNonBlockCalendarEvent,
): event is FixedBlockCalendarEvent => 'blockGuid' in event

export type FullScheduleAppointment = ScheduleAppointment & {
  assignments?: ScheduleAssignment[]
}

export type AppointmentMap = Record<
  AppointmentGuid,
  FullScheduleAppointment | undefined
>

export const withMobiscrollBlockFixForRecurringEvent = (
  rawBlock: BzBlockInfo,
  tzId: TimeZoneId,
): BzBlockInfo => {
  if (!rawBlock.recurrenceRule) {
    return rawBlock
  }

  const block = { ...rawBlock }
  // If they have a recurrence rule, we have to make a special check. Mobiscroll doesn't respect time zones when working
  // with recurring events. It uses the date of the timestamp, even if it's in UTC and we say our data is in a different
  // timezone. So if you make an event in the evening, it's the next day in UTC. Thus, it won't show the first
  // occurrence (because it looks like it's the wrong day of the week if you're recurring weekly) and it will display
  // the occurrences on the wrong days. It's so dumb because it properly transforms the times (if the time is like 1am
  // UTC it will correctly change it to the correct time to the previous night, but just not touch the date). So we have
  // to cheese it. If we detect a "crossover"--the in UTC isn't the same as the date in the local timezone--we need to
  // cheese it and make the dates one day earlier. We need to do the same thing for the recurrence exceptions. We save
  // the original values so when we do a write we are writing the correct values (these new values are essentially for
  // display-only).
  const startDate = BzDateFns.parseISO(block.start, tzId)

  const UTCStartDate = BzDateFns.parseISO(block.start, BzDateFns.UTC)
  if (!BzDateFns.isSameDay(startDate, UTCStartDate)) {
    block.start = BzDateFns.formatISO(BzDateFns.subDays(startDate, 1), tzId)

    block.end = BzDateFns.withTimeZone(block.end, tzId, date =>
      BzDateFns.subDays(date, 1),
    )

    if (block.recurrenceRuleExceptions) {
      const newExceptions: string[] = []
      for (const exception of block.recurrenceRuleExceptions.split(',')) {
        newExceptions.push(
          BzDateFns.withTimeZone(
            // I know these exceptions are an array of iso dates
            // eslint-disable-next-line breezy/no-to-iso-date-string
            BzDateFns.toIsoDateString(exception),
            tzId,
            date => BzDateFns.subDays(date, 1),
          ),
        )
      }

      block.recurrenceRuleExceptions = newExceptions.join(',')
    }
  }

  const parsedRRule = parseRRule(rawBlock.recurrenceRule)

  // Another idiotic mobiscroll issue: If you do a monthly recurrence by weekday (first monday of the month, last
  // tuesday of the month, etc) it will not show the first one. It doesn't seem to be timezone-dependent. Setting the
  // dates back a day fixes it.
  if (
    parsedRRule.byMonthOption === 'BY_WEEKDAY' ||
    parsedRRule.byMonthOption === 'BY_LAST_WEEKDAY'
  ) {
    block.start = BzDateFns.withTimeZone(block.start, tzId, date =>
      BzDateFns.subDays(date, 1),
    )
    block.end = BzDateFns.withTimeZone(block.end, tzId, date =>
      BzDateFns.subDays(date, 1),
    )
  }

  return block
}

export type AssignmentWithStatus = {
  assignmentStatus?: { status?: AssignmentStatus }
}

export const getInferredAppointmentStatusFromAssignments = (
  assignments: AssignmentWithStatus[],
  appointmentCanceled?: boolean,
): InferredAppointmentStatus => {
  if (appointmentCanceled) {
    return 'CANCELED'
  } else if (assignments.length > 0) {
    if (assignments.some(a => a.assignmentStatus?.status === 'EN_ROUTE')) {
      return 'EN_ROUTE'
    } else if (
      assignments.some(a => a.assignmentStatus?.status === 'IN_PROGRESS')
    ) {
      return 'IN_PROGRESS'
    } else if (
      assignments.some(a => a.assignmentStatus?.status === 'COMPLETED')
    ) {
      return 'COMPLETED'
    } else {
      return 'ASSIGNED'
    }
  } else {
    return 'UNASSIGNED'
  }
}

// Transposed from packages/shared/src/domain/Job/JobClass.ts
export const defaultDurationMinutesForJobClass = {
  [JobClass.INSTALL]: 510, // 8 hours, 30 min
  [JobClass.WARRANTY]: 150, // 2 hours, 30 min
  [JobClass.CALLBACK]: 150, // 2 hours, 30 min
  [JobClass.SERVICE]: 90, // 1 hour, 30 min
  [JobClass.MAINTENANCE]: 90, // 1 hour, 30 min
  [JobClass.ESTIMATE_REPLACE]: 90, // 1 hour, 30 min
  [JobClass.ESTIMATE_REPAIR]: 90, // 1 hour, 30 min
  [JobClass.SALES]: 90, // 1 hour, 30 min
  [JobClass.UNKNOWN]: 90, // 1 hour, 30 min
}

export type NewAssignmentCalendarEvent = Merge<
  MbscCalendarEvent & {
    assignmentGuid: AssignmentGuid
    appointmentGuid: AppointmentGuid
    appointmentInfo: UnassignedAppointment
    jobClass: JobClass
    accountDisplayName: string
  }
>

export type NewAssignmentCalendarEventData = MbscCalendarEventData & {
  original: NewAssignmentCalendarEvent
}

export type NewAssignmentCreateEvent = MbscEventCreateEvent & {
  event: NewAssignmentCalendarEvent
}

export type RunningLateStatus = 'RUNNING_LATE' | 'AFTER_ARRIVAL_WINDOW'

export const useRunningLateStatus = (
  appointmentGuid: AppointmentGuid,
  appointmentWindowEnd: IsoDateString,
  status?: InferredAppointmentStatus,
): RunningLateStatus | undefined => {
  const tzId = useExpectedCompanyTimeZoneId()
  const { isDismissed } =
    usePersistedDismissedRunningLateAlerts(appointmentGuid)

  return useMemo(() => {
    // ASSIGNED means it has a technician but they aren't en route or later
    if (isDismissed || status !== 'ASSIGNED') {
      return undefined
    }

    const windowEnd = BzDateFns.parseISO(appointmentWindowEnd, tzId)

    // Don't show appointments on previous days as "running late"
    if (BzDateFns.isBeforeToday(windowEnd, tzId)) {
      return undefined
    }

    if (BzDateFns.isBefore(windowEnd, BzDateFns.now(tzId))) {
      return 'AFTER_ARRIVAL_WINDOW'
    }
    const isLessThan30MinToWindowEnd =
      BzDateFns.differenceInMinutes(windowEnd, BzDateFns.now(tzId)) < 30

    return isLessThan30MinToWindowEnd ? 'RUNNING_LATE' : undefined
  }, [appointmentWindowEnd, isDismissed, status, tzId])
}

type SchedulePopoverContextType = {
  forcePopoverHidden: boolean
  setForcePopoverHidden: StateSetter<boolean>
}

export const SchedulePopoverContext = React.createContext<
  SchedulePopoverContextType | undefined
>(undefined)

export const usePopoverState = (): [boolean, StateSetter<boolean>] => {
  const { forcePopoverHidden } = useStrictContext(SchedulePopoverContext)

  const [popoverOpen, setPopoverOpen] = useState(false)

  useEffect(() => {
    if (forcePopoverHidden) {
      setPopoverOpen(false)
    }
  }, [forcePopoverHidden])

  return [popoverOpen, setPopoverOpen]
}

export const fixMbscDate = (date: ICalendarData['start']) =>
  // This whole thing is fixing up fucked up mobiscroll dates, so converting to our type makes sense
  // eslint-disable-next-line breezy/no-to-iso-date-string
  BzDateFns.toIsoDateString(
    moment.isMoment((date as { m: Moment }).m)
      ? (date as { m: Moment }).m.toISOString()
      : date instanceof Date
      ? date.toISOString()
      : `${date}`,
  )

export const resolveUpdateUserGuids = (
  oldEvent: BzCalendarEvent,
  event: BzCalendarEvent,
): Guid[] => {
  const oldResource = `${oldEvent.resource}`
  const newResource = `${event.resource}`

  if (oldResource === newResource) {
    return event.userGuids
  }

  const index = R.findIndex(R.equals(oldResource), event.userGuids)
  return R.update(index, newResource, event.userGuids)
}

export const getStartDateForView = (
  view: ScheduleView,
  tzId: TimeZoneId,
  selectedDate?: IsoDateString,
): IsoDateString => {
  if (['DISPATCH', 'DAY'].includes(view)) {
    return selectedDate
      ? BzDateFns.withTimeZone(selectedDate, tzId, BzDateFns.startOfDay)
      : BzDateFns.formatISO(BzDateFns.getToday(tzId), tzId)
  } else {
    const d = selectedDate
      ? BzDateFns.parseISO(selectedDate, tzId)
      : BzDateFns.getToday(tzId)
    const startOfWeek = BzDateFns.startOfWeek(d)
    return BzDateFns.formatISO(startOfWeek, tzId)
  }
}
