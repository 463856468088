import { AccountLocation, BzAddress, isNullish } from '@breezy/shared'
import { faLocationDot } from '@fortawesome/pro-regular-svg-icons'
import { Button, Row, Typography } from 'antd'
import React, { memo, useCallback, useMemo, useState } from 'react'
import { Card } from '../../adam-components/Card/Card'
import { OnsiteModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import { SectionedCard } from '../../adam-components/SectionedCard/SectionedCard'
import { CreateAccountLocationModalForm } from '../../components/CreateAccountLocationModalForm/CreateAccountLocationModalForm'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import {
  ItemPicker,
  ItemPickerItem,
} from '../../components/Pricebook/ItemPicker'
import FaIconWithCircularBackground from '../../elements/FaIconWithCircularBackground/FaIconWithCircularBackground'
import useIsMobile from '../../hooks/useIsMobile'
import { useModalState } from '../../utils/react-utils'
import { Carousel } from '../EstimatesFlow/components/Carousel'
import { renderCarouselHeader } from './components/CarouselHeader'
import { SectionHeaderText } from './components/SectionHeaderText'
import { WizardColum } from './components/WizardColum'
import { usePageContainerWidth } from './hooks/usePageContainerWidth'
import { LocationOptionCard } from './LocationOptionCard'
import { useFetchAccountLocations } from './MaintenancePlanV3Utils'
const GUTTER = 12

type Props = {
  selectedAccountGuid: string
  onLocationSelected: (locationGuid: string) => void
  goToNextStep: () => void
}

export const SelectLocationPanel = memo<Props>(
  ({ selectedAccountGuid, onLocationSelected, goToNextStep }) => {
    const [
      createNewAccountLocationModalOpen,
      openCreateNewAccountLocationModal,
      closeCreateNewAccountLocationModal,
    ] = useModalState()

    const [
      selectFromAllLocationsModalOpen,
      openSelectFromAllLocationsModal,
      closeSelectFromAllLocationsModal,
    ] = useModalState()

    const { accountLocations, total, refetch, fetching } =
      useFetchAccountLocations(selectedAccountGuid, {
        limit: 3,
        offset: 0,
      })

    const hasMoreLocations = useMemo(
      () => total > accountLocations.length,
      [total, accountLocations.length],
    )

    const isMobile = useIsMobile()

    const { scrollbarWidth, pageContainerWidth } = usePageContainerWidth()

    const itemWidth = useMemo(() => {
      if (isMobile) {
        // Subtract 26px from the container width to show a peek of the next item
        return Math.min(
          pageContainerWidth - 32 - (scrollbarWidth ?? 0) - 26,
          360,
        )
      }
      return 600
    }, [isMobile, pageContainerWidth, scrollbarWidth])

    const carouselChildren = useMemo(() => {
      if (!isNullish(accountLocations)) {
        const numLocations = accountLocations.length
        const locationCards = accountLocations.map((accountLocation, i) => {
          return (
            <div
              key={accountLocation.location.locationGuid}
              style={numLocations > 1 ? { width: `${itemWidth}px` } : {}}
              // pb-2 is to make sure the box shadow isn't cut off
              className="h-full max-h-full pb-2"
            >
              <LocationOptionCard
                accountLocation={accountLocation}
                onLocationSelected={onLocationSelected}
                goToNextStep={goToNextStep}
                dataTestId={`location-option-${i}`}
              />
            </div>
          )
        })

        if (hasMoreLocations) {
          locationCards.push(
            <div
              key="more-locations"
              style={{ width: `${itemWidth}px` }}
              // pb-2 is to make sure the box shadow isn't cut off
              className="max-h-full cursor-pointer pb-2"
              onClick={openSelectFromAllLocationsModal}
            >
              <Card className="items-center justify-center gap-3">
                <FaIconWithCircularBackground
                  iconDefinition={faLocationDot}
                  backgroundColor="#FFF"
                  borderColor="#D9D9D9"
                  diameterPx={44}
                />
                <Typography.Title
                  level={5}
                  className="mb-0 ml-[6px] text-center"
                >
                  View All {total - numLocations} Locations
                </Typography.Title>
              </Card>
            </div>,
          )
        }

        return locationCards
      } else {
        return (
          <div className="center-vh flex h-full w-full items-center justify-center">
            <LoadingSpinner />
          </div>
        )
      }
    }, [
      accountLocations,
      hasMoreLocations,
      itemWidth,
      onLocationSelected,
      goToNextStep,
      total,
      openSelectFromAllLocationsModal,
    ])

    const shouldUseCarousel = useMemo(() => {
      return accountLocations && accountLocations.length > 1
    }, [accountLocations])

    const carouselMargin = useMemo(
      () =>
        isMobile
          ? 16
          : Math.max((pageContainerWidth - 754 - (scrollbarWidth ?? 0)) / 2, 0),
      [isMobile, pageContainerWidth, scrollbarWidth],
    )
    if (fetching) {
      return (
        <WizardColum>
          <SectionHeaderText text="Select Location" />
          <SectionedCard
            heightClassName="min-h-[312px]"
            loading
            sections={[]}
          />
        </WizardColum>
      )
    }

    return (
      <>
        {shouldUseCarousel ? (
          <div style={{ margin: `0 -${carouselMargin}px` }}>
            <Carousel
              renderHeaderWithDots={renderCarouselHeader('Select Location')}
              margin={carouselMargin}
              mistyMargin={carouselMargin}
              gutter={GUTTER}
            >
              {carouselChildren}
            </Carousel>
          </div>
        ) : (
          <div className="flex flex-col gap-y-4">
            <SectionHeaderText text="Select Location" />
            <>{carouselChildren}</>
          </div>
        )}
        <Button
          type="text"
          className="mb-0 mt-4 h-[44px] w-full p-0 hover:bg-transparent"
          onClick={openCreateNewAccountLocationModal}
        >
          <Row className="h-11 items-center">
            <FaIconWithCircularBackground
              iconDefinition={faLocationDot}
              backgroundColor="#FFF"
              borderColor="#D9D9D9"
              diameterPx={44}
            />
            <Typography.Title level={5} className="mb-0 ml-[6px] text-center">
              Create new location
            </Typography.Title>
          </Row>
        </Button>
        {createNewAccountLocationModalOpen && (
          <CreateAccountLocationModalForm
            accountGuid={selectedAccountGuid}
            onModalCancel={closeCreateNewAccountLocationModal}
            onLocationCreated={() => {
              closeCreateNewAccountLocationModal()
              refetch()
            }}
          />
        )}
        {selectFromAllLocationsModalOpen && (
          <SelectFromAllLocationsModal
            accountGuid={selectedAccountGuid}
            onCancel={closeSelectFromAllLocationsModal}
            onSave={locationGuid => {
              onLocationSelected(locationGuid)
              closeSelectFromAllLocationsModal()
              goToNextStep()
            }}
          />
        )}
      </>
    )
  },
)

type SelectFromAllLocationsModalProps = {
  accountGuid: string
  onCancel: () => void
  onSave: (selectedLocationGuid: string) => void
}

const SelectFromAllLocationsModal =
  React.memo<SelectFromAllLocationsModalProps>(
    ({ accountGuid, onCancel, onSave: externalOnSave }) => {
      const [selectedLocationGuid, setSelectedLocationGuid] = useState<
        string | undefined
      >(undefined)
      const { accountLocations, fetching } = useFetchAccountLocations(
        accountGuid,
        {
          limit: 500,
          offset: 0,
        },
      )

      const accountLocationOptions = useMemo(() => {
        return accountLocations.map(
          (item, index): ItemPickerItem => ({
            id: item.location.locationGuid,
            name: BzAddress.formatAddressSingleLine(item.location.address),
            customNode: (
              <LocationTitleAndContent
                location={item}
                dataTestId={`location-option-${index}`}
                index={index}
              />
            ),
            value: 0,
          }),
        )
      }, [accountLocations])

      const onItemSelect = useCallback((locationGuid: string) => {
        setSelectedLocationGuid(locationGuid)
      }, [])

      const onSave = useCallback(() => {
        if (selectedLocationGuid) {
          externalOnSave(selectedLocationGuid)
        }
      }, [selectedLocationGuid, externalOnSave])

      return (
        <OnsiteModal
          modalClassName="z-[1020] maintenance-plan-activated-modal"
          className="maintenance-plan-activated-modal"
          size="default"
        >
          {fetching ? (
            <div className="flex min-h-[300px] flex-col items-center justify-center">
              <LoadingSpinner />
            </div>
          ) : (
            <AccountLocationsPicker
              accountLocationOptions={accountLocationOptions}
              selectedLocationGuid={selectedLocationGuid}
              onCancel={onCancel}
              onSave={onSave}
              onItemSelect={onItemSelect}
            />
          )}
        </OnsiteModal>
      )
    },
  )

type LocationTitleAndContentProps = {
  location: AccountLocation
  dataTestId?: string
  index: number
}
const LocationTitleAndContent = React.memo<LocationTitleAndContentProps>(
  ({ location, dataTestId, index }) => {
    return (
      <div
        className="flex w-full flex-col gap-y-[2px] overflow-hidden py-5"
        data-testid={`${dataTestId}-${index}`}
      >
        <div className="mb-1 text-base font-semibold">
          {BzAddress.formatAddressSingleLine(location.location.address)}
        </div>
      </div>
    )
  },
)

type AccountLocationsPickerProps = {
  accountLocationOptions: ItemPickerItem[]
  selectedLocationGuid: string | undefined
  onCancel: () => void
  onSave: () => void
  onItemSelect: (locationGuid: string) => void
}
const AccountLocationsPicker = React.memo<AccountLocationsPickerProps>(
  ({
    accountLocationOptions,
    selectedLocationGuid,
    onCancel,
    onSave,
    onItemSelect,
  }) => {
    return (
      <ItemPicker
        canSelectMultiple={false}
        items={accountLocationOptions}
        title="Select Location"
        emptyState="No locations have been added to this account yet"
        onCancel={onCancel}
        onBackFallback={onCancel}
        onSave={onSave}
        selectedItemCountMap={
          selectedLocationGuid ? { [selectedLocationGuid]: 1 } : {}
        }
        onItemSelect={onItemSelect}
        addText="Save"
        itemLabel=""
        isLoading={false}
        hideMultipleQuantityPicker
        hideCart
        showItemValue={false}
      />
    )
  },
)
