import { LocalDateString, TimeZoneId } from '../../common'
import { BzDateTime } from '../DateTime/BzDateTime'

// NOTE: Pure sorcery. This is a blend of ChatGPTs report on the least busy months,
// and the interweaving to space the visit apart when they are more than 1.
export const defaultAffinityMonthsNumbersJs = [9, 3, 6, 0, 8, 2, 10, 4, 11, 1, 5, 7]

export const getAffinityDateByVisitIndex = (
  visitIndex: number,
  fromActivationDate: LocalDateString,
  tzId: TimeZoneId,
  plusYears: number = 0,
): LocalDateString => {
  const activationDate = BzDateTime.fromDateString(fromActivationDate, tzId)
  const activationMonth = activationDate.toDate().getMonth()
  const activationYear = activationDate.toDate().getFullYear()
  const baseYear = activationYear + plusYears
  const nextYear = baseYear + 1
  const month = defaultAffinityMonthsNumbersJs[visitIndex % 12]
  return getLocalDateForMonth(month, month <= activationMonth ? nextYear : baseYear)
}

export const getLocalDateForMonth = (month: number, year: number): LocalDateString => {
  return `${year}-${(month + 1).toString().padStart(2, '0')}-01` as LocalDateString
}
