import { useQuery } from 'urql'
import { gql } from '../generated'
import { useExpectedCompanyGuid } from '../providers/PrincipalUser'

const GET_COMPANY_BILLING_PROFILE_QUERY = gql(/* GraphQL */ `
  query GetCompanyBillingProfileFrontend($companyGuid: uuid!) {
    billingProfiles(where: { companyGuid: { _eq: $companyGuid } }) {
      companyGuid
      invoiceMemo
      invoiceTerm
      businessFullLegalName
      contractorLicenseNumber
      logoUrl
      estimateDisclaimer
      invoiceDisclaimer
      tilledMerchantAccountId
      emailAddressGuid
      emailAddress {
        emailAddress
        emailAddressGuid
        companyGuid
        createdByUserGuid
      }
      phoneNumberGuid
      phoneNumber {
        phoneNumber
        phoneNumberGuid
        companyGuid
        type
      }
      websiteUrl
      websiteDisplayName
      wisetackMerchantId
      businessAddressGuid
      businessAddress {
        canonicalFullAddress
        line1
        line2
        city
        stateAbbreviation
        zipCode
      }
      defaultPricebookTaxRateGuid
      defaultTaxRate {
        pricebookTaxRateGuid
        name
        rate
      }
      maintenancePlanTaxRateGuid
      maintenancePlanTaxRate {
        pricebookTaxRateGuid
        name
        rate
      }
      maintenancePlanServiceItemGuid
      maintenancePlanServiceItem {
        guid: pricebookItemGuid
        name
        description
      }
      maintenancePlanAutoRenewalAllowed
      maintenancePlanManualRenewalAllowed
      maintenancePlanStaticDurationNumYearsAllowed
    }
  }
`)

export const useGetCompanyBillingProfile = (companyGuid: string) => {
  const getCompanyBillingProfileQuery = useQuery({
    query: GET_COMPANY_BILLING_PROFILE_QUERY,
    variables: { companyGuid: companyGuid },
  })

  const { data, fetching, error } = getCompanyBillingProfileQuery[0]
  return {
    getCompanyBillingProfileQuery,
    data,
    fetching,
    error,
    refetch: getCompanyBillingProfileQuery[1],
  }
}

export const useCompanyBillingProfile = () => {
  const companyGuid = useExpectedCompanyGuid()
  const { data, fetching, error } = useGetCompanyBillingProfile(companyGuid)

  const profile = data?.billingProfiles[0]
  if (!fetching && !profile) {
    return {
      fetching,
      error: new Error('Company billing profile not found'),
      billingProfile: undefined,
    }
  }

  return { fetching, error, billingProfile: profile }
}
