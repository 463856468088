import { isNullish, TimeZoneId } from '@breezy/shared'
import { useMemo } from 'react'
import { OnsitePageCollapsibleSection } from 'src/adam-components/OnsitePage/OnsitePageCollapsibleSection'
import { JobCard, JobCardJob } from 'src/components/Cards/JobCard'
import GqlQueryLoader from 'src/components/GqlQueryLoader/GqlQueryLoader'
import { gql } from 'src/generated'
import { useQuery } from 'urql'

const LINKED_JOBS_QUERY = gql(/* GraphQL */ `
  query LinkedJobsCollapsibleLinkedJobsQuery($jobGuid: uuid!) {
    jobsWithLinkedJob(where: { jobGuid: { _eq: $jobGuid } }) {
      linked_job {
        displayId
        jobGuid
        createdAt
        workCompletedAt
        jobType {
          name
        }
        location {
          locationGuid
          address {
            line1
            line2
            city
            stateAbbreviation
            zipCode
            addressGuid
          }
        }
        jobLifecycleStatus {
          name
          color
        }
      }
    }
  }
`)

export interface LinkedJobsCollapsibleProps {
  jobGuid: string
  timezone: TimeZoneId
}

export const LinkedJobsCollapsible = (props: LinkedJobsCollapsibleProps) => {
  const [linkedJobsQueryRes, linkedJobsQueryRefetch] = useQuery({
    query: LINKED_JOBS_QUERY,
    variables: { jobGuid: props.jobGuid },
  })

  const linkedJobs = useMemo<JobCardJob[]>(() => {
    if (isNullish(linkedJobsQueryRes.data)) {
      return []
    }

    return linkedJobsQueryRes.data.jobsWithLinkedJob.map(
      ({ linked_job: linkedJob }) => ({
        jobGuid: linkedJob.jobGuid,
        jobTypeName: linkedJob.jobType.name,
        displayId: linkedJob.displayId.toString(),
        createdAt: linkedJob.createdAt,
        completedAt: linkedJob.workCompletedAt,
        jobLifecycleStatus: {
          name: linkedJob.jobLifecycleStatus.name,
          color: linkedJob.jobLifecycleStatus.color,
        },
        location: {
          locationGuid: linkedJob.location.locationGuid,
          address: {
            line1: linkedJob.location.address.line1,
          },
        },
        tags: [],
      }),
    )
  }, [linkedJobsQueryRes.data])

  return (
    <OnsitePageCollapsibleSection
      smallTitle
      title="Linked Jobs"
      count={linkedJobsQueryRes.fetching ? undefined : linkedJobs.length}
      defaultCollapsed={linkedJobs.length === 0}
    >
      <GqlQueryLoader
        query={[linkedJobsQueryRes, linkedJobsQueryRefetch]}
        render={() => (
          <div className="flex flex-col gap-3">
            {linkedJobs.map(linkedJob => (
              <JobCard
                key={linkedJob.jobGuid}
                job={linkedJob}
                timezoneId={props.timezone}
              />
            ))}
          </div>
        )}
        errorComponent={<span>Failed to load linked jobs</span>}
      />
    </OnsitePageCollapsibleSection>
  )
}
