import {
  BzDateTime,
  LocalDateString,
  MaintenancePlanDetailsViewModel,
  MaintenancePlanPaymentFlow,
  MaintenancePlanPaymentInterval,
  TimeZoneId,
  futureCloneVisits,
} from '@breezy/shared'
import { ConfigureMaintenancePlanFormData } from './configureMaintenancePlanFormSchema'
import { zeroTaxRate } from './MaintenancePlanV3Utils'

export const preferredPaymentInterval = MaintenancePlanPaymentInterval.YEARLY

const createCommonDefaultValues = (
  tzId: TimeZoneId,
  isImport: boolean,
  allowsAutoRenew: boolean,
): Partial<ConfigureMaintenancePlanFormData> & {
  activationDate: LocalDateString
} => {
  const activationDate = BzDateTime.now(tzId).toLocalDateString()
  return {
    activationDate,
    billingAnchorDay: BzDateTime.now(tzId)
      .toJsJodaLocalDate()
      .dayOfMonth()
      .toString(),
    billingAnchorDate: activationDate,
    taxRate: zeroTaxRate,

    numFixedDurationYears: isImport || !allowsAutoRenew ? 1 : -1,
  }
}

const createDefaultValuesForNewFromScratch = (
  tzId: TimeZoneId,
  isImport: boolean,
  allowsAutoRenew: boolean,
): Partial<ConfigureMaintenancePlanFormData> => {
  const commonValues = createCommonDefaultValues(
    tzId,
    isImport,
    allowsAutoRenew,
  )
  return {
    ...commonValues,

    shouldSendActivationEmail: !isImport,
    isImportedPlan: isImport,
    isAutoRenewing: !isImport,
    billingPaymentInterval: isImport
      ? MaintenancePlanPaymentInterval.UPFRONT
      : preferredPaymentInterval,
    visits: [],
  }
}

const createDefaultValuesForNewFromExisting = (
  tzId: TimeZoneId,
  mp: MaintenancePlanDetailsViewModel,
  allowsAutoRenew: boolean,
): Partial<ConfigureMaintenancePlanFormData> => {
  const commonValues = createCommonDefaultValues(tzId, false, allowsAutoRenew)
  return {
    ...commonValues,

    shouldSendActivationEmail: true,
    isFreePlan: mp.isFreePlan,
    isAutoRenewing: true,
    billingPaymentInterval: preferredPaymentInterval,
    visits: futureCloneVisits(mp.visits, commonValues.activationDate, tzId),
  }
}

const createDefaultValuesForRenewExisting = (
  tzId: TimeZoneId,
  mp: MaintenancePlanDetailsViewModel,
  allowsAutoRenew: boolean,
): Partial<ConfigureMaintenancePlanFormData> => {
  const commonValues = createCommonDefaultValues(tzId, false, allowsAutoRenew)
  return {
    ...commonValues,

    shouldSendActivationEmail: true,
    isFreePlan: mp.isFreePlan,
    isAutoRenewing: mp.paymentFlow === MaintenancePlanPaymentFlow.AUTO,
    billingPaymentInterval:
      mp.paymentFlow === MaintenancePlanPaymentFlow.AUTO
        ? preferredPaymentInterval
        : MaintenancePlanPaymentInterval.UPFRONT,

    visits: futureCloneVisits(mp.visits, commonValues.activationDate, tzId),
  }
}

type CreateMaintenancePlanFormParams =
  | {
      tzId: TimeZoneId
      mode: 'new-from-scratch'
      isImport: boolean
      allowsAutoRenew: boolean
      mp?: never
    }
  | {
      tzId: TimeZoneId
      mode: 'new-from-existing' | 'renew-existing'
      isImport: boolean
      allowsAutoRenew: boolean
      mp: MaintenancePlanDetailsViewModel
    }

export const createDefaultValuesForCreateMaintenancePlanForm = ({
  tzId,
  mode,
  isImport,
  allowsAutoRenew,
  mp,
}: CreateMaintenancePlanFormParams): Partial<ConfigureMaintenancePlanFormData> => {
  if (mode === 'new-from-existing')
    return createDefaultValuesForNewFromExisting(tzId, mp, allowsAutoRenew)
  if (mode === 'renew-existing')
    return createDefaultValuesForRenewExisting(tzId, mp, allowsAutoRenew)

  return createDefaultValuesForNewFromScratch(tzId, isImport, allowsAutoRenew)
}
