import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useInterval } from 'react-use'
import { trpc } from '../hooks/trpc'
import { useQuickbooksDesktopEnabled } from './CompanyFinancialConfigWrapper'
import { useCompanyGuid } from './PrincipalUser'

const QBD_POLL_INTERVAL = 1000 * 60 * 5 // 5 minutes

type QBDContextType = {
  isOnline: boolean
  isEnabled: boolean
  recheck: () => Promise<boolean>
  notifyOffline: () => void
}

const QBDContext = createContext<QBDContextType>({
  isOnline: false,
  isEnabled: false,
  recheck: () => Promise.resolve(false),
  notifyOffline: () => {},
})

const useQBDState = () => {
  const [isOnline, setIsOnline] = useState(false)

  const companyGuid = useCompanyGuid()
  const isEnabled = useQuickbooksDesktopEnabled()

  const { refetch } = trpc.accounting['accounting-app:is-online'].useQuery(
    { companyGuid: companyGuid ?? '' },
    {
      enabled: false,
    },
  )

  const checkOnlineStatus = useCallback(async () => {
    if (!isEnabled || !companyGuid) {
      setIsOnline(false)
      return false
    }

    try {
      const result = await refetch()
      setIsOnline(result.data ?? false)
      return result.data ?? false
    } catch (e) {
      console.error('Error checking QBD online status:', e)
      setIsOnline(false)
      return false
    }
  }, [isEnabled, companyGuid, refetch])

  const notifyOffline = useCallback(() => {
    setIsOnline(false)
  }, [])

  // Check initially
  useEffect(() => {
    checkOnlineStatus()
  }, [isEnabled, checkOnlineStatus])

  // Poll every 5 minutes
  useInterval(checkOnlineStatus, QBD_POLL_INTERVAL)

  return {
    isOnline,
    isEnabled,
    recheck: checkOnlineStatus,
    notifyOffline,
  }
}

export const QuickbooksDesktopOnlineWrapper = ({
  children,
}: React.PropsWithChildren) => {
  const state = useQBDState()

  return <QBDContext.Provider value={state}>{children}</QBDContext.Provider>
}

export const useQuickbooksDesktopState = () => {
  const context = useContext(QBDContext)
  if (!context) {
    throw new Error(
      'useQuickbooksDesktopState must be used within a QuickbooksDesktopOnlineWrapper',
    )
  }
  return context
}
