import { gql } from '../../generated'

export const NOTIFICATION_SETTINGS_QUERY = gql(/* GraphQL */ `
  query GetNotificationSettings($companyGuid: uuid!) {
    companyNotificationSettingsByPk(companyGuid: $companyGuid) {
      replyToEmail
      estimateAcceptNotifyCreator
      estimateAcceptNotifyEmail
      estimateAcceptNotifyUserGuids
      estimateReminderCount
      estimateReminderFrequency
      estimateReviewedNotifyCreator
      estimateReviewedNotifyUserGuids
      invoicePaymentReminderCount
      invoicePaymentReminderFrequency
      paymentFailedNotifyCreator
      paymentFailedNotifyEmail
      paymentFailedNotifyUserGuids
      materialRestockReportEmail
      materialRestockReportUserGuids
      jobLeadCreatedUserGuids
      jobLeadCreatedEmail
      lapsedPaymentMaintenancePlanUserGuids
      lapsedPaymentMaintenancePlanNotifyEmail
    }
    companyUsers {
      userGuid
      userByUserGuid {
        fullName
      }
    }
  }
`)
