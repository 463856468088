import { DocumentType, gql } from '../../../generated'

const TEAM_PERFORMANCE_JOB_FRAGMENT = gql(/* GraphQL */ `
  fragment TeamPerformanceJobFragment on Jobs {
    jobGuid
    createdAt
    workCompletedAt
    isOpportunity
    isConverted
    isHotLead
    isMembershipOpportunity
    isMembershipRenewalOpportunity
    isMembershipSold
    jobType {
      jobTypeGuid
      jobClass
    }
    jobTeamMembers {
      userGuid
      turnedOverJob
    }
    maintenancePlan {
      yearlyPriceUsc
    }
    estimates {
      status
      estimateOptions {
        isSelected
        totalUsc
      }
    }
    jobTechnicianEarnedRevenueSummaries {
      technicianUserGuid
      totalEarnedRevenueUsc
    }
    jobTechnicianSoldRevenueSummaries {
      technicianUserGuid
      totalSoldRevenueUsc
    }
    jobFullyPaidInvoicesV2Summary: jobFullyPaidInvoicesV2SummaryMv {
      totalInvoicedAmountUsc
      totalPaidAmountUsd
      totalSubtotalAmountUsc
      totalDiscountAmountUsc
      totalTaxAmountUsc
    }
    jobAppointmentAssignments {
      technicianUserGuid
      jobAppointment {
        jobAppointmentInvoices {
          invoice {
            invoiceGuid
            subtotalUsc
            status
          }
        }
      }
    }
  }
`)

export const TEAM_PERFORMANCE_DASHBOARD_METRICS_QUERY = gql(/* GraphQL */ `
  query TeamPerformanceDashboardMetrics($where: JobsBoolExp!) {
    jobs(where: $where, orderBy: { displayId: ASC }) {
      ...TeamPerformanceJobFragment
    }
  }
`)

export type TeamPerformanceJob = DocumentType<
  typeof TEAM_PERFORMANCE_JOB_FRAGMENT
>
