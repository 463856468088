import {
  BzDateFns,
  CalculatePaths,
  EquipmentType,
  formatAbbreviatedUsc,
  formatEquipmentType,
  getAllInstalledEquipmentFromVisits,
  JobClass,
  LocalDateString,
  MaintenancePlanPaymentFlow,
  MaintenancePlanPaymentIntervalDisplayNames,
  MaintenancePlanStatus,
  maintenancePlanStatusDisplayNames,
  NextVisitViewModel,
  R,
  streetAddressLine1And2Condensed,
  Tag,
  TimeZoneId,
  VISIT_STATUS_TYPES,
  VisitEquipmentViewModel,
  VisitStatus,
  VisitStatusDisplayNames,
} from '@breezy/shared'
import {
  faArrowsRotate,
  faBan,
  faEllipsis,
  faSliders,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Dropdown, Form, Radio, Tooltip } from 'antd'
import { ItemType } from 'antd/es/menu/hooks/useItems'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'urql'
import { ContactCell } from '../../adam-components/ListPage/ContactCell'
import { DetailChip } from '../../adam-components/ListPage/DetailChip'
import { DetailsCell } from '../../adam-components/ListPage/DetailsCell'
import { ListPageContainer } from '../../adam-components/ListPage/ListPageContainer'
import {
  ListPageTable,
  ListPageTableColsConfig,
} from '../../adam-components/ListPage/ListPageTable'
import { SummaryStatBox } from '../../adam-components/ListPage/SummaryStats'
import { usePagination } from '../../adam-components/Pagination/usePagination'
import { EquipmentInfoCard } from '../../components/EquipmentInfoCard/EquipmentInfoCard'
import { Page } from '../../components/Page/Page'
import { Link } from '../../elements/Link/Link'
import {
  IntComparisonExp,
  MaintenancePlanListPageDataQuery,
  MaintenancePlansQueryableBoolExp,
  MaintenancePlansQueryableOrderBy,
  TimestamptzComparisonExp,
} from '../../generated/user/graphql'
import useAppNavigation from '../../hooks/useAppNav'
import {
  useExpectedCompanyGuid,
  useExpectedCompanyTimeZoneId,
} from '../../providers/PrincipalUser'
import { mapQueryNextVisitToNextVisitViewModel } from './utils'

import { mapQueryVisitsToVisitViewModels } from '@breezy/backend/src/application-types'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCalendar } from '@fortawesome/pro-regular-svg-icons'
import {
  faCheck,
  faExclamation,
  faMinus,
} from '@fortawesome/pro-solid-svg-icons'
import { zodResolver } from '@hookform/resolvers/zod'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { ListPageFilter } from '../../adam-components/ListPage/ListPageFilter'
import { ListPageFilterBar } from '../../adam-components/ListPage/ListPageFilterBar'
import { ListPageFilterButton } from '../../adam-components/ListPage/ListPageFilterButton'
import { OnsiteConfirmModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import { MaintenancePlanTagRow } from '../../components/MaintenancePlanTagRow/MaintenancePlanTagRow'
import { EditVisitsModal } from '../../components/Visits/EditVisitsModal'
import { VisitMiniCard } from '../../components/Visits/VisitMiniCard'
import { ReactHookFormItem } from '../../elements/Forms/ReactHookFormItem'
import { SelectField } from '../../elements/Forms/SelectField'
import { useReactHookFormSubmit } from '../../elements/Forms/useReactHookFormSubmit'
import Switch from '../../elements/Switch/Switch'
import ThinDivider from '../../elements/ThinDivider'
import { TAGS_MINIMAL_FOR_COMPANY_QUERY } from '../../gql/queries/Tags.gql'
import { useFetchCompanyUniqueZipCodes } from '../../hooks/fetch/useFetchCompanyUniqueZipCodes'
import {
  useBooleanState,
  useModalState,
  useQueryParamState,
  useQueryParamStateArray,
  useQueryParamStateEnumArray,
  useQueryParamStateWithOptions,
} from '../../utils/react-utils'
import {
  MaintenancePlanCancelInfo,
  MaintenancePlanCancelModal,
} from '../MaintenancePlanDetailsPage/MaintenancePlanCancelModal'
import MaintenancePlanStatusTag from '../MaintenancePlanDetailsPage/MaintenancePlanStatusTag'
import {
  COMPANY_MAINTENANCE_PLAN_TYPES_QUERY,
  MAINTENANCE_PLAN_COUNT_SUMMARY_QUERY,
  MAINTENANCE_PLAN_OVERDUE_VISITS_SUMMARY_QUERY,
  MAINTENANCE_PLAN_YEARLY_PRICE_SUMMARY_QUERY,
  NEW_MAINTENANCE_PLANS_LIST_PAGE_QUERY,
} from './NewMaintenancePlansListPage.gql'

type MaintenancePlanListPageItem =
  MaintenancePlanListPageDataQuery['maintenancePlansQueryable'][number]

type MaintenancePlanActionsDropdownProps = MaintenancePlanListPageItem & {
  refetch: () => void
  onCancel: (vm: MaintenancePlanCancelInfo) => void
}

const MaintenancePlanActionsDropdown =
  React.memo<MaintenancePlanActionsDropdownProps>(
    ({ onCancel, refetch, ...vm }) => {
      const appNav = useAppNavigation()
      const [isEditingVisits, setIsEditingVisits] = useState(false)
      const tzId = useExpectedCompanyTimeZoneId()

      const dropdownItems = useMemo(() => {
        const items: ItemType[] = [
          {
            key: 'Edit Visits',
            label: (
              <div onClick={() => setIsEditingVisits(true)}>Edit Visits</div>
            ),
          },
        ]

        if (vm.status !== MaintenancePlanStatus.CANCELED) {
          items.push({
            key: 'Update Payment Info',
            label: (
              <div
                onClick={() => {
                  appNav.navigateToMaintenancePlanPayPage(
                    vm.maintenancePlanGuid,
                    true,
                  )
                }}
              >
                Update Payment Info
              </div>
            ),
          })
          items.push({
            key: 'Schedule Visit',
            label: (
              <div
                onClick={() =>
                  appNav.navigateToCreateNewJob({
                    jobClass: JobClass.MAINTENANCE.toString(),
                    accountGuid: vm.accountGuid,
                    locationGuid: vm.locationGuid,
                  })
                }
              >
                Schedule Visit
              </div>
            ),
          })
          items.push({
            type: 'divider',
          })
          items.push({
            key: 'Cancel Plan',
            label: (
              <div
                className="text-bz-red-600"
                onClick={() =>
                  onCancel({
                    accountGuid: vm.accountGuid,
                    maintenancePlanGuid: vm.maintenancePlanGuid,
                    planDefinition: vm.maintenancePlanDefinition?.marketingInfo
                      ?.name
                      ? {
                          name: vm.maintenancePlanDefinition.marketingInfo.name,
                        }
                      : undefined,
                    paymentSubscriptionGuid: vm.paymentSubscriptionGuid,
                    primaryContactDisplayName:
                      vm.account.accountContacts[0].contact.fullName ??
                      vm.account.accountDisplayName,
                    visits: mapQueryVisitsToVisitViewModels(
                      vm.maintenancePlanVisits,
                    ),
                  })
                }
              >
                Cancel Plan
              </div>
            ),
          })
        }

        return items
      }, [
        appNav,
        onCancel,
        vm.account.accountContacts,
        vm.account.accountDisplayName,
        vm.accountGuid,
        vm.locationGuid,
        vm.maintenancePlanDefinition?.marketingInfo?.name,
        vm.maintenancePlanGuid,
        vm.maintenancePlanVisits,
        vm.paymentSubscriptionGuid,
        vm.status,
        setIsEditingVisits,
      ])

      return (
        <>
          <Dropdown
            className="mb-4"
            menu={{
              items: dropdownItems,
            }}
          >
            <Button
              shape="circle"
              icon={<FontAwesomeIcon icon={faEllipsis} />}
            />
          </Dropdown>
          {isEditingVisits && (
            <EditVisitsModal
              accountGuid={vm.accountGuid}
              locationGuid={vm.locationGuid}
              planActivationDate={BzDateFns.formatLocalDate(
                BzDateFns.parseISO(
                  vm.activatedAt ?? new Date().toISOString(),
                  tzId,
                ),
              )}
              visits={mapQueryVisitsToVisitViewModels(
                vm.maintenancePlanVisits,
              ).map((visit, index) => ({ ...visit, index }))}
              onClose={() => setIsEditingVisits(false)}
              afterSubmit={() => {
                setIsEditingVisits(false)
                refetch()
              }}
            />
          )}
        </>
      )
    },
  )

const Months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
] as const
type MonthOption = (typeof Months)[number]

type MonthRange = {
  start: LocalDateString
  end: LocalDateString
}

const getMonthRanges = (
  selectedMonths: MonthOption[],
  years: number[],
): MonthRange[] => {
  return years.flatMap(year =>
    selectedMonths.map(month => {
      const monthIndex = Months.indexOf(month)
      const startDate = BzDateFns.createDate({
        year,
        month: monthIndex,
        date: 1,
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      })
      const endDate = BzDateFns.endOfMonth(startDate)

      return {
        start: BzDateFns.formatLocalDate(startDate),
        end: BzDateFns.formatLocalDate(endDate),
      }
    }),
  )
}

const NEXT_VISIT_STATUS_OPTIONS = VISIT_STATUS_TYPES.map(status => ({
  value: status,
  label: VisitStatusDisplayNames[status],
}))

const EQUIPMENT_TYPE_OPTIONS = Object.values(EquipmentType).map(type => ({
  value: type,
  label: formatEquipmentType(type),
}))

const EquipmentAgeOptions = ['Unknown', '0-2', '3-6', '7-10', '10+'] as const
type EquipmentAgeOption = (typeof EquipmentAgeOptions)[number]

type EquipmentAgeRange =
  | {
      start: LocalDateString
      end: LocalDateString
    }
  | undefined
const getEquipmentAgeRange = (
  age: EquipmentAgeOption,
  tzId: TimeZoneId,
): EquipmentAgeRange => {
  const equipmentAgeOption = EquipmentAgeOptions.find(ea => ea === age)
  if (equipmentAgeOption === 'Unknown') {
    return undefined
  } else if (equipmentAgeOption === '0-2') {
    return {
      start: BzDateFns.formatLocalDate(
        BzDateFns.subYears(BzDateFns.now(tzId), 2),
      ),
      end: BzDateFns.formatLocalDate(
        BzDateFns.subYears(BzDateFns.now(tzId), 0),
      ),
    }
  } else if (equipmentAgeOption === '3-6') {
    return {
      start: BzDateFns.formatLocalDate(
        BzDateFns.subYears(BzDateFns.now(tzId), 6),
      ),
      end: BzDateFns.formatLocalDate(
        BzDateFns.subYears(BzDateFns.now(tzId), 3),
      ),
    }
  } else if (equipmentAgeOption === '7-10') {
    return {
      start: BzDateFns.formatLocalDate(
        BzDateFns.subYears(BzDateFns.now(tzId), 10),
      ),
      end: BzDateFns.formatLocalDate(
        BzDateFns.subYears(BzDateFns.now(tzId), 7),
      ),
    }
  } else if (equipmentAgeOption === '10+') {
    return {
      start: BzDateFns.formatLocalDate(
        BzDateFns.subYears(BzDateFns.now(tzId), 99),
      ),
      end: BzDateFns.formatLocalDate(
        BzDateFns.subYears(BzDateFns.now(tzId), 10),
      ),
    }
  } else {
    return undefined
  }
}
const getEquipmentAgeRanges = (
  ages: EquipmentAgeOption[],
  tzId: TimeZoneId,
): EquipmentAgeRange[] => {
  return ages.map(age => getEquipmentAgeRange(age, tzId))
}

const VISITS_REMAINING_TYPES = [
  'NONE',
  '1_OR_MORE',
  '2_OR_MORE',
  'ANY',
] as const

type VisitsRemainingType = (typeof VISITS_REMAINING_TYPES)[number]

const VisitsRemainingDisplayNames = {
  NONE: 'None',
  '1_OR_MORE': '1 or more',
  '2_OR_MORE': '2 or more',
  ANY: 'Any',
}

const VisitsRemainingOptions = VISITS_REMAINING_TYPES.map(type => ({
  value: type,
  label: VisitsRemainingDisplayNames[type],
}))

const VISITS_REMAINING_TO_WHERE_CONDITION: Record<
  VisitsRemainingType,
  IntComparisonExp
> = {
  NONE: { _eq: 0 },
  '1_OR_MORE': { _gte: 1 },
  '2_OR_MORE': { _gte: 2 },
  ANY: {},
}

const MAINTENANCE_PLAN_STATUS_OPTIONS = Object.values(
  MaintenancePlanStatus,
).map(status => ({
  value: status,
  label: maintenancePlanStatusDisplayNames[status],
}))

const AUTO_RENEWING_STATUSES = ['AUTO', 'MANUAL', 'ALL'] as const

type AutoRenewingStatus = (typeof AUTO_RENEWING_STATUSES)[number]

const PLAN_RENEWAL_DATE_TYPES = [
  'LAST_3_MONTHS',
  'LAST_MONTH',
  'THIS_MONTH',
  'NEXT_3_MONTHS',
  'ALL_TIME',
] as const

type PlanRenewalDateType = (typeof PLAN_RENEWAL_DATE_TYPES)[number]
const PlanRenewalDateDisplayNames = {
  LAST_3_MONTHS: 'Last 3 Months',
  LAST_MONTH: 'Last Month',
  THIS_MONTH: 'This Month',
  NEXT_3_MONTHS: 'Next 3 Months',
  ALL_TIME: 'All Time',
}

const PLAN_RENEWAL_DATE_OPTIONS = PLAN_RENEWAL_DATE_TYPES.map(type => ({
  value: type,
  label: PlanRenewalDateDisplayNames[type],
}))

const PLAN_RENEWAL_DATE_TO_WHERE_CONDITION: Record<
  PlanRenewalDateType,
  TimestamptzComparisonExp
> = {
  LAST_3_MONTHS: {
    _gte: BzDateFns.nowUtcTransform(date => BzDateFns.subMonths(date, 3)),
    _lte: BzDateFns.nowUtcTransform(date => BzDateFns.endOfMonth(date)),
  },
  LAST_MONTH: {
    _gte: BzDateFns.nowUtcTransform(date =>
      BzDateFns.startOfMonth(BzDateFns.subMonths(date, 1)),
    ),
    _lte: BzDateFns.nowUtcTransform(date => BzDateFns.endOfMonth(date)),
  },
  THIS_MONTH: {
    _gte: BzDateFns.nowUtcTransform(date => BzDateFns.startOfMonth(date)),
    _lte: BzDateFns.nowUtcTransform(date => BzDateFns.endOfMonth(date)),
  },
  NEXT_3_MONTHS: {
    _gte: BzDateFns.nowUtcTransform(date => BzDateFns.startOfMonth(date)),
    _lte: BzDateFns.nowUtcTransform(date =>
      BzDateFns.endOfMonth(BzDateFns.addMonths(date, 3)),
    ),
  },
  ALL_TIME: {},
}

const FILTER_LABELS = {
  nextVisitStatuses: 'Next Visit Status',
  nextVisitMonths: 'Next Visit Date',
  equipmentTypes: 'Equipment Type',
  equipmentAges: 'Equipment Age',
  zipCodes: 'Zip Code',
  tagNames: 'Account Tag',
  visitsRemaining: 'Visits Remaining',
  planTypes: 'Plan Type',
  planStatuses: 'Plan Status',
  autoRenewingStatus: 'Auto-renewing',
  planRenewalDate: 'Plan Renewal Date',
}

const moreFiltersSchema = z.object({
  nextVisitMonths: z.array(z.enum(Months)),
  nextVisitStatuses: z.array(z.enum(VISIT_STATUS_TYPES)),
  equipmentTypes: z.array(z.nativeEnum(EquipmentType)),
  equipmentAges: z.array(z.enum(EquipmentAgeOptions)),
  zipCodes: z.array(z.string()),
  tagNames: z.array(z.string()),
  visitsRemaining: z.enum(VISITS_REMAINING_TYPES),
  planTypes: z.array(z.string()),
  planStatuses: z.array(z.nativeEnum(MaintenancePlanStatus)),
  autoRenewingStatus: z.enum(AUTO_RENEWING_STATUSES),
  planRenewalDate: z.enum(PLAN_RENEWAL_DATE_TYPES),
})

type MoreFiltersSchema = z.infer<typeof moreFiltersSchema>

const computeHasFilters = ({
  nextVisitMonths,
  nextVisitStatuses,
  equipmentTypes,
  equipmentAges,
  zipCodes,
  tagNames,
  visitsRemaining,
  planTypes,
  planStatuses,
  autoRenewingStatus,
  planRenewalDate,
}: MoreFiltersSchema) =>
  nextVisitMonths.length > 0 ||
  nextVisitStatuses.length > 0 ||
  equipmentTypes.length > 0 ||
  equipmentAges.length > 0 ||
  zipCodes.length > 0 ||
  tagNames.length > 0 ||
  visitsRemaining !== 'ANY' ||
  planTypes.length > 0 ||
  planStatuses.length > 0 ||
  autoRenewingStatus !== 'ALL' ||
  planRenewalDate !== 'ALL_TIME'

type MoreFiltersProps = {
  nextVisitMonths: MonthOption[]
  setNextVisitMonths: (nextVisitMonths: MonthOption[]) => void
  nextVisitStatuses: VisitStatus[]
  setNextVisitStatuses: (nextVisitStatuses: VisitStatus[]) => void
  equipmentTypes: EquipmentType[]
  setEquipmentTypes: (equipmentTypes: EquipmentType[]) => void
  equipmentAges: EquipmentAgeOption[]
  setEquipmentAges: (equipmentAges: EquipmentAgeOption[]) => void
  zipCodeOptions: string[]
  zipCodes: string[]
  setZipCodes: (zipCodes: string[]) => void
  tagOptions: Tag[]
  tagNames: string[]
  setTagNames: (tagNames: string[]) => void
  visitsRemaining: VisitsRemainingType
  setVisitsRemaining: (visitsRemaining: VisitsRemainingType) => void
  planTypeOptions: string[]
  planTypes: string[]
  setPlanTypes: (planTypes: string[]) => void
  planStatuses: MaintenancePlanStatus[]
  setPlanStatuses: (planStatuses: MaintenancePlanStatus[]) => void
  autoRenewingStatus: AutoRenewingStatus
  setAutoRenewingStatus: (autoRenewingStatus: AutoRenewingStatus) => void
  planRenewalDate: PlanRenewalDateType
  setPlanRenewalDate: (planRenewalDate: PlanRenewalDateType) => void
}

const MoreFilters = React.memo<MoreFiltersProps>(
  ({
    nextVisitMonths,
    setNextVisitMonths,
    nextVisitStatuses,
    setNextVisitStatuses,
    equipmentTypes,
    setEquipmentTypes,
    equipmentAges,
    setEquipmentAges,
    zipCodeOptions,
    zipCodes,
    setZipCodes,
    tagOptions,
    tagNames,
    setTagNames,
    visitsRemaining,
    setVisitsRemaining,
    planTypeOptions,
    planTypes,
    setPlanTypes,
    planStatuses,
    setPlanStatuses,
    autoRenewingStatus,
    setAutoRenewingStatus,
    planRenewalDate,
    setPlanRenewalDate,
  }) => {
    const [moreFiltersOpen, openMoreFilters, closeMoreFilters] =
      useBooleanState()

    const defaultValues = useMemo<MoreFiltersSchema>(
      () => ({
        nextVisitMonths,
        nextVisitStatuses,
        equipmentTypes,
        equipmentAges,
        zipCodes,
        tagNames,
        visitsRemaining,
        planTypes,
        planStatuses,
        autoRenewingStatus,
        planRenewalDate,
      }),
      [
        nextVisitMonths,
        nextVisitStatuses,
        equipmentTypes,
        equipmentAges,
        zipCodes,
        tagNames,
        visitsRemaining,
        planTypes,
        planStatuses,
        autoRenewingStatus,
        planRenewalDate,
      ],
    )

    const {
      formState: { errors, dirtyFields },
      control,
      handleSubmit,
      reset,
      watch,
      setValue,
    } = useForm<MoreFiltersSchema>({
      resolver: zodResolver(moreFiltersSchema),
      defaultValues,
    })

    const watchAutoRenewingStatus = watch('autoRenewingStatus')

    useEffect(() => {
      if (watchAutoRenewingStatus !== 'MANUAL') {
        setValue('planRenewalDate', 'ALL_TIME')
      }
    }, [watchAutoRenewingStatus, setValue])

    const onOpen = useCallback(() => {
      reset(defaultValues)
      openMoreFilters()
    }, [defaultValues, openMoreFilters, reset])

    const onSubmit = useCallback(
      (data: MoreFiltersSchema) => {
        setNextVisitMonths(data.nextVisitMonths)
        setNextVisitStatuses(data.nextVisitStatuses)
        setEquipmentTypes(data.equipmentTypes)
        setEquipmentAges(data.equipmentAges)
        setZipCodes(data.zipCodes)
        setTagNames(data.tagNames)
        setVisitsRemaining(data.visitsRemaining)
        setPlanTypes(data.planTypes)
        setPlanStatuses(data.planStatuses)
        setAutoRenewingStatus(data.autoRenewingStatus)
        setPlanRenewalDate(data.planRenewalDate)
        closeMoreFilters()
      },
      [
        closeMoreFilters,
        setNextVisitMonths,
        setNextVisitStatuses,
        setEquipmentTypes,
        setEquipmentAges,
        setZipCodes,
        setTagNames,
        setVisitsRemaining,
        setPlanTypes,
        setPlanStatuses,
        setAutoRenewingStatus,
        setPlanRenewalDate,
      ],
    )

    const hasFilters = useMemo(
      () =>
        computeHasFilters({
          nextVisitMonths,
          nextVisitStatuses,
          equipmentTypes,
          equipmentAges,
          zipCodes,
          tagNames,
          visitsRemaining,
          planTypes,
          planStatuses,
          autoRenewingStatus,
          planRenewalDate,
        }),
      [
        nextVisitMonths,
        nextVisitStatuses,
        equipmentTypes,
        equipmentAges,
        zipCodes,
        tagNames,
        visitsRemaining,
        planTypes,
        planStatuses,
        autoRenewingStatus,
        planRenewalDate,
      ],
    )

    const [areYouSureOpen, openAreYouSure, closeAreYouSure] = useBooleanState()

    const onClose = useCallback(() => {
      if (R.keys(dirtyFields).some(key => dirtyFields[key])) {
        openAreYouSure()
      }
      closeMoreFilters()
    }, [closeMoreFilters, dirtyFields, openAreYouSure])

    const onAreYouSureCancel = useCallback(() => {
      closeAreYouSure()
      openMoreFilters()
    }, [closeAreYouSure, openMoreFilters])

    const onAreYouSureConfirm = useCallback(() => {
      closeAreYouSure()
    }, [closeAreYouSure])

    const [submitElement, triggerSubmit] = useReactHookFormSubmit()

    return (
      <>
        <ListPageFilterButton
          size="large"
          icon={<FontAwesomeIcon icon={faSliders} />}
          onClick={onOpen}
          hasFilters={hasFilters}
        >
          Filters
        </ListPageFilterButton>
        <OnsiteConfirmModal
          open={areYouSureOpen}
          danger
          header="Discard changes?"
          onCancel={onAreYouSureCancel}
          onConfirm={onAreYouSureConfirm}
          confirmText="Discard Changes"
        >
          The filters that you've set will not be applied unless you select
          "Show Results". Are you sure you want to close without applying the
          selected filters?
        </OnsiteConfirmModal>
        <OnsiteConfirmModal
          open={moreFiltersOpen}
          size="large-width"
          header="Filters"
          onCancel={onClose}
          onConfirm={triggerSubmit}
          confirmText="Show Results"
          modalClassName="h-[90%]"
        >
          <Form layout="vertical" onSubmitCapture={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-2 gap-3">
              <ReactHookFormItem
                control={control}
                name="nextVisitMonths"
                label="Next Visit Date"
                errors={errors}
                render={({ field }) => (
                  <SelectField
                    mode="multiple"
                    size="large"
                    options={Months.map(month => ({
                      value: month,
                      label: month,
                    }))}
                    title="Next Visit Date"
                    showSearch
                    {...field}
                    values={field.value}
                  />
                )}
              />
              <ReactHookFormItem
                control={control}
                name="nextVisitStatuses"
                label="Next Visit Status"
                errors={errors}
                render={({ field }) => (
                  <SelectField
                    mode="multiple"
                    size="large"
                    options={NEXT_VISIT_STATUS_OPTIONS}
                    title="Next Visit Status"
                    showSearch
                    {...field}
                    values={field.value}
                  />
                )}
              />
            </div>
            <ReactHookFormItem
              control={control}
              name="equipmentTypes"
              label="Equipment Type"
              errors={errors}
              render={({ field }) => (
                <SelectField
                  mode="multiple"
                  size="large"
                  sheetSize="full"
                  options={EQUIPMENT_TYPE_OPTIONS}
                  title="Equipment Type"
                  {...field}
                  values={field.value}
                />
              )}
            />
            <ReactHookFormItem
              control={control}
              name="equipmentAges"
              label="Equipment Age"
              errors={errors}
              render={({ field }) => (
                <SelectField
                  mode="multiple"
                  options={EquipmentAgeOptions.map(age => ({
                    value: age,
                    label: age,
                  }))}
                  size="large"
                  title="Equipment Age"
                  showSearch
                  {...field}
                  values={field.value}
                />
              )}
            />
            <div className="grid grid-cols-2 gap-3">
              <ReactHookFormItem
                control={control}
                name="visitsRemaining"
                label="Visits Remaining"
                errors={errors}
                render={({ field }) => (
                  <SelectField
                    options={VisitsRemainingOptions}
                    title="Visits Remaining"
                    size="large"
                    {...field}
                  />
                )}
              />
              <ReactHookFormItem
                control={control}
                name="zipCodes"
                label="Zip Codes"
                errors={errors}
                render={({ field }) => (
                  <SelectField
                    mode="multiple"
                    title="Zip Codes"
                    options={zipCodeOptions.map(zipCode => ({
                      label: zipCode,
                      value: zipCode,
                    }))}
                    sheetSize="half"
                    size="large"
                    {...field}
                    values={field.value}
                  />
                )}
              />
            </div>
            <ReactHookFormItem
              control={control}
              name="tagNames"
              label="Account Tags"
              errors={errors}
              render={({ field }) => (
                <SelectField
                  mode="multiple"
                  title="Tags"
                  options={tagOptions.map(tag => ({
                    label: tag.name,
                    value: tag.name,
                  }))}
                  sheetSize="half"
                  size="large"
                  {...field}
                  values={field.value}
                />
              )}
            />
            <ThinDivider widthPx={1} />
            <div className="grid grid-cols-2 gap-3">
              <ReactHookFormItem
                control={control}
                name="autoRenewingStatus"
                label="Auto-renewing"
                errors={errors}
                render={({ field }) => (
                  <Radio.Group
                    value={field.value}
                    size="large"
                    onChange={e => field.onChange(e.target.value)}
                    optionType="button"
                  >
                    <Radio value="AUTO">Yes</Radio>
                    <Radio value="MANUAL">No</Radio>
                  </Radio.Group>
                )}
              />
              <ReactHookFormItem
                control={control}
                name="planRenewalDate"
                label="Plan Renewal Date"
                errors={errors}
                render={({ field }) => (
                  <SelectField
                    options={PLAN_RENEWAL_DATE_OPTIONS}
                    title="Plan Renewal Date"
                    disabled={watchAutoRenewingStatus !== 'MANUAL'}
                    size="large"
                    {...field}
                  />
                )}
              />
            </div>
            <ThinDivider widthPx={1} />
            <ReactHookFormItem
              control={control}
              name="planTypes"
              label="Plan Type"
              errors={errors}
              render={({ field }) => (
                <SelectField
                  mode="multiple"
                  showSearch
                  options={planTypeOptions.map(planType => ({
                    value: planType,
                    label: planType,
                  }))}
                  title="Plan Type"
                  size="large"
                  {...field}
                  values={field.value}
                />
              )}
            />
            <ReactHookFormItem
              control={control}
              name="planStatuses"
              label="Plan Status"
              errors={errors}
              render={({ field }) => (
                <SelectField
                  mode="multiple"
                  options={MAINTENANCE_PLAN_STATUS_OPTIONS}
                  title="Plan Status"
                  size="large"
                  {...field}
                  values={field.value}
                />
              )}
            />

            {submitElement}
          </Form>
        </OnsiteConfirmModal>
      </>
    )
  },
)

type EquipmentCardProps = {
  equipment: VisitEquipmentViewModel
}

const EquipmentCard = React.memo<EquipmentCardProps>(({ equipment }) => {
  const {
    equipmentType,
    manufacturer,
    installationDate,
    modelNumber,
    serialNumber,
    estimatedEndOfLifeDate,
  } = equipment
  return (
    <EquipmentInfoCard
      equipmentType={equipmentType as EquipmentType}
      manufacturer={manufacturer}
      installDate={
        installationDate
          ? (installationDate?.toString() as LocalDateString)
          : undefined
      }
      modelNumber={modelNumber}
      serialNumber={serialNumber}
      estimatedEndOfLifeDate={
        estimatedEndOfLifeDate
          ? (estimatedEndOfLifeDate?.toString() as LocalDateString)
          : undefined
      }
    />
  )
})

type EquipmentCellProps = {
  equipment: VisitEquipmentViewModel[]
}

const EquipmentCell = React.memo<EquipmentCellProps>(({ equipment }) => {
  if (!equipment.length) {
    return null
  }

  return (
    <div className="flex flex-row space-x-2">
      <DetailChip
        className="max-w-[205px] *:truncate"
        popoverContent={<EquipmentCard equipment={equipment[0]} />}
      >
        {formatEquipmentType(equipment[0].equipmentType as EquipmentType)}
      </DetailChip>
      {equipment.length > 1 && (
        <DetailChip
          square
          popoverPlacement="left"
          popoverContent={
            <div className="flex flex-col space-y-2">
              {equipment.slice(1).map(equipment => (
                <EquipmentCard
                  equipment={equipment}
                  key={equipment.installedEquipmentGuid}
                />
              ))}
            </div>
          }
        >
          +{equipment.length - 1}
        </DetailChip>
      )}
    </div>
  )
})

type NextVisitCellProps = {
  nextVisit: NextVisitViewModel | undefined
}

const formatVisitChipDate = (visit: NextVisitViewModel) => {
  return BzDateFns.localDateToFormattedDateString(
    visit.affinityDate,
    'MMMM yyyy',
  )
}

type CircleIconProps = {
  iconBgClassName: string
  iconClassName: string
  icon: IconProp
  tooltipContent?: React.ReactNode
}

const CircleIcon = React.memo<CircleIconProps>(
  ({ iconBgClassName, iconClassName, icon, tooltipContent }) => {
    return (
      <Tooltip title={tooltipContent}>
        <div
          className={classNames(
            'flex h-5 w-5 items-center justify-center rounded-full p-[2px]',
            iconBgClassName,
          )}
        >
          <FontAwesomeIcon
            className={classNames('h-[12px] w-[16px]', iconClassName)}
            icon={icon}
          />
        </div>
      </Tooltip>
    )
  },
)

const NextVisitCell = React.memo<NextVisitCellProps>(({ nextVisit }) => {
  if (!nextVisit) {
    return (
      <DetailChip className="w-fit *:truncate">
        <div className="flex flex-row items-center justify-center space-x-[6px]">
          <div>All Done!</div>
          <CircleIcon
            iconBgClassName="bg-bz-green-200"
            iconClassName="text-bz-green-800"
            icon={faCheck}
          />
        </div>
      </DetailChip>
    )
  }

  return (
    <DetailChip
      className="w-fit *:truncate"
      popoverContent={
        nextVisit.status === 'OVERDUE' ? (
          <div className="flex max-w-[300px] flex-col">
            <p className="mb-3">
              This plan visit is overdue. Please schedule the visit as soon as
              possible.
            </p>
            <VisitMiniCard
              visitIndex={nextVisit.visitNumber - 1}
              visit={nextVisit}
            />
          </div>
        ) : (
          <VisitMiniCard
            visitIndex={nextVisit.visitNumber - 1}
            visit={nextVisit}
          />
        )
      }
    >
      <div className="flex flex-row items-center justify-center space-x-[6px]">
        <div className="flex flex-row items-end justify-center space-x-1">
          <div
            className={classNames('text-xs font-semibold leading-5', {
              'text-bz-text-tertiary':
                nextVisit.status === 'UNSCHEDULED' ||
                nextVisit.status === 'SCHEDULED',
              'text-bz-magenta-800': nextVisit.status === 'OVERDUE',
            })}
          >
            #{nextVisit.visitNumber}
          </div>
          <div
            className={classNames('text-sm leading-[22px]', {
              'text-bz-magenta-800': nextVisit.status === 'OVERDUE',
            })}
          >
            {formatVisitChipDate(nextVisit)}
          </div>
        </div>
        <Switch value={nextVisit.status}>
          {{
            UNSCHEDULED: () => (
              <CircleIcon
                icon={faMinus}
                iconBgClassName="bg-bz-fill"
                iconClassName="text-bz-text-secondary"
              />
            ),
            SCHEDULED: () => (
              <CircleIcon
                icon={faCalendar}
                iconBgClassName="bg-bz-blue-200"
                iconClassName="text-bz-blue-800"
              />
            ),
            OVERDUE: () => (
              <CircleIcon
                icon={faExclamation}
                iconBgClassName="bg-bz-magenta-200"
                iconClassName="text-bz-magenta-800"
              />
            ),
            default: () => null,
          }}
        </Switch>
      </div>
    </DetailChip>
  )
})

type PlanRenewalCellProps = {
  maintenancePlan: MaintenancePlanListPageDataQuery['maintenancePlansQueryable'][number]
}

const PlanRenewalCell = React.memo<PlanRenewalCellProps>(
  ({ maintenancePlan }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const isAutoRenewal = useMemo(
      () => maintenancePlan.paymentFlow === 'AUTO',
      [maintenancePlan.paymentFlow],
    )

    const renewalDate = useMemo(() => {
      if (isAutoRenewal && maintenancePlan.activatedAt) {
        return BzDateFns.format(
          BzDateFns.addYears(
            BzDateFns.parseISO(maintenancePlan.activatedAt, tzId),
            1,
          ),
          'MMM d, yyyy',
        )
      } else if (maintenancePlan.terminatesAt) {
        return BzDateFns.format(
          BzDateFns.parseISO(maintenancePlan.terminatesAt, tzId),
          'MMM d, yyyy',
        )
      } else if (maintenancePlan.lapsedAt && maintenancePlan.activatedAt) {
        return BzDateFns.format(
          BzDateFns.addYears(
            BzDateFns.parseISO(maintenancePlan.activatedAt, tzId),
            1,
          ),
          'MMM d, yyyy',
        )
      } else {
        return 'N/A'
      }
    }, [
      isAutoRenewal,
      maintenancePlan.activatedAt,
      maintenancePlan.terminatesAt,
      maintenancePlan.lapsedAt,
      tzId,
    ])

    const tooltipContent = useMemo(() => {
      if (isAutoRenewal) {
        return 'This maintenance plan is set to automatically renew on the renewal date.'
      }
      return 'This maintenance plan will not automatically renew. Please take action to renew the plan if desired.'
    }, [isAutoRenewal])

    return (
      <div className="flex items-center space-x-[6px] whitespace-nowrap">
        <div className="truncate">{renewalDate}</div>
        <CircleIcon
          icon={isAutoRenewal ? faArrowsRotate : faBan}
          iconBgClassName={isAutoRenewal ? 'bg-bz-fill' : 'bg-bz-orange-200'}
          iconClassName={
            isAutoRenewal ? 'text-bz-text-secondary' : 'text-bz-orange-800'
          }
          tooltipContent={tooltipContent}
        />
      </div>
    )
  },
)

const SORT_TYPES = [
  'NEXT_VISIT_DATE',
  'PLAN_RENEWAL_DATE',
  'ZIP_CODE_ASC',
  'ZIP_CODE_DESC',
] as const

type SortType = (typeof SORT_TYPES)[number]

const PRETTY_SORT_TYPE_MAP: Record<SortType, string> = {
  NEXT_VISIT_DATE: 'Next Visit Date (Soonest)',
  PLAN_RENEWAL_DATE: 'Plan Renewal Date (Soonest)',
  ZIP_CODE_ASC: 'Zip Code (Ascending)',
  ZIP_CODE_DESC: 'Zip Code (Descending)',
}

const SORT_TYPE_OPTIONS = SORT_TYPES.map(type => ({
  key: type,
  label: PRETTY_SORT_TYPE_MAP[type],
}))

export const NewMaintenancePlansListPage = React.memo(() => {
  const { page, pageSize, setPage, setPageSize, resetPage, limit, offset } =
    usePagination('50')

  const [searchTerm, setSearchTerm] = useQueryParamState('search', '')

  const [nextVisitMonths, setNextVisitMonths] = useQueryParamStateEnumArray(
    'nextVisitMonths',
    [],
    Months,
  )
  const [nextVisitStatuses, setNextVisitStatuses] = useQueryParamStateEnumArray(
    'nextVisitStatuses',
    [],
    VISIT_STATUS_TYPES,
  )

  const [equipmentTypes, setEquipmentTypes] = useQueryParamStateEnumArray(
    'equipment',
    [],
    Object.values(EquipmentType) as [EquipmentType],
  )

  const [equipmentAges, setEquipmentAges] = useQueryParamStateEnumArray(
    'equipmentAges',
    [],
    EquipmentAgeOptions,
  )
  const [tagNames, setTagNames] = useQueryParamStateArray('tags', [])
  const [visitsRemaining, setVisitsRemaining] = useQueryParamStateWithOptions(
    'visitsRemaining',
    'ANY' as const,
    VISITS_REMAINING_TYPES,
  )

  const [zipCodes, setZipCodes] = useQueryParamStateArray('zipCodes', [])
  const [planTypes, setPlanTypes] = useQueryParamStateArray('planTypes', [])

  const [planStatuses, setPlanStatuses] = useQueryParamStateEnumArray(
    'planStatuses',
    [],
    Object.values(MaintenancePlanStatus) as [MaintenancePlanStatus],
  )
  const [autoRenewingStatus, setAutoRenewingStatus] =
    useQueryParamStateWithOptions(
      'autoRenewingStatus',
      'ALL' as const,
      AUTO_RENEWING_STATUSES,
    )
  const [planRenewalDate, setPlanRenewalDate] = useQueryParamStateWithOptions(
    'planRenewalDate',
    'ALL_TIME' as const,
    PLAN_RENEWAL_DATE_TYPES,
  )
  const companyGuid = useExpectedCompanyGuid()
  const [{ data: tagOptionsData, fetching: fetchingTagOptions }] = useQuery({
    query: TAGS_MINIMAL_FOR_COMPANY_QUERY,
    variables: { companyGuid },
  })

  const [{ data: planTypeOptionsData, fetching: fetchingPlanTypeOptions }] =
    useQuery({
      query: COMPANY_MAINTENANCE_PLAN_TYPES_QUERY,
      variables: { where: { companyGuid: { _eq: companyGuid } } },
    })

  const { zipCodes: zipCodeOptions, fetchingZipCodes } =
    useFetchCompanyUniqueZipCodes(companyGuid)

  const tagsByNameMap = useMemo(
    () => R.indexBy(R.prop('name'), tagOptionsData?.tags ?? []),
    [tagOptionsData?.tags],
  )
  const planTypeOptions = useMemo(
    () =>
      (planTypeOptionsData?.maintenancePlanDefinitions ?? []).map(plan => ({
        maintenancePlanDefinitionGuid: plan.maintenancePlanDefinitionGuid,
        name: plan.marketingInfo?.name ?? 'Unknown',
      })),
    [planTypeOptionsData?.maintenancePlanDefinitions],
  )
  const planTypeOptionsMap = useMemo(
    () => R.indexBy(R.prop('name'), planTypeOptions),
    [planTypeOptions],
  )

  const hasFilters = useMemo(
    () =>
      computeHasFilters({
        nextVisitMonths,
        nextVisitStatuses,
        equipmentTypes,
        equipmentAges,
        zipCodes,
        tagNames,
        visitsRemaining,
        planTypes,
        planStatuses,
        autoRenewingStatus,
        planRenewalDate,
      }),
    [
      nextVisitMonths,
      nextVisitStatuses,
      equipmentTypes,
      equipmentAges,
      zipCodes,
      tagNames,
      visitsRemaining,
      planTypes,
      planStatuses,
      autoRenewingStatus,
      planRenewalDate,
    ],
  )

  const clearAllFilters = useCallback(() => {
    setNextVisitMonths([])
    setNextVisitStatuses([])
    setEquipmentTypes([])
    setEquipmentAges([])
    setZipCodes([])
    setTagNames([])
    setVisitsRemaining('ANY')
    setPlanTypes([])
    setPlanStatuses([])
    setAutoRenewingStatus('ALL')
    setPlanRenewalDate('ALL_TIME')
  }, [
    setNextVisitStatuses,
    setNextVisitMonths,
    setEquipmentTypes,
    setEquipmentAges,
    setZipCodes,
    setTagNames,
    setVisitsRemaining,
    setPlanTypes,
    setPlanStatuses,
    setAutoRenewingStatus,
    setPlanRenewalDate,
  ])

  const clearFilter = useCallback(
    (filterKey: string, option: string) => {
      switch (filterKey) {
        case 'nextVisitMonths': {
          setNextVisitMonths(nextVisitMonths.filter(month => month !== option))
          break
        }
        case 'nextVisitStatuses': {
          setNextVisitStatuses(
            nextVisitStatuses.filter(
              status => VisitStatusDisplayNames[status] !== option,
            ),
          )
          break
        }
        case 'equipmentTypes': {
          setEquipmentTypes(
            equipmentTypes.filter(type => formatEquipmentType(type) !== option),
          )
          break
        }
        case 'equipmentAges': {
          setEquipmentAges(equipmentAges.filter(age => age !== option))
          break
        }
        case 'visitsRemaining': {
          setVisitsRemaining('ANY')
          break
        }
        case 'zipCodes': {
          setZipCodes(zipCodes.filter(code => code !== option))
          break
        }
        case 'tags': {
          setTagNames(tagNames.filter(tag => tag !== option))
          break
        }
        case 'planTypes': {
          setPlanTypes(planTypes.filter(type => type !== option))
          break
        }
        case 'planStatuses': {
          setPlanStatuses(
            planStatuses.filter(
              status => maintenancePlanStatusDisplayNames[status] !== option,
            ),
          )
          break
        }
        case 'autoRenewingStatus': {
          setAutoRenewingStatus('ALL')
          break
        }
        case 'planRenewalDate': {
          setPlanRenewalDate('ALL_TIME')
          break
        }
      }
    },
    [
      setNextVisitMonths,
      nextVisitMonths,
      setNextVisitStatuses,
      nextVisitStatuses,
      setEquipmentTypes,
      equipmentTypes,
      setEquipmentAges,
      equipmentAges,
      setVisitsRemaining,
      setZipCodes,
      zipCodes,
      setTagNames,
      tagNames,
      setPlanTypes,
      planTypes,
      setPlanStatuses,
      planStatuses,
      setAutoRenewingStatus,
      setPlanRenewalDate,
    ],
  )

  const filters = useMemo(() => {
    const filters: { key: string; optionsSelected: string[] }[] = []

    if (nextVisitMonths.length) {
      filters.push({
        key: 'nextVisitMonths',
        optionsSelected: nextVisitMonths.map(month => month),
      })
    }

    if (nextVisitStatuses.length) {
      filters.push({
        key: 'nextVisitStatuses',
        optionsSelected: nextVisitStatuses.map(
          status => VisitStatusDisplayNames[status],
        ),
      })
    }

    if (equipmentTypes.length) {
      filters.push({
        key: 'equipmentTypes',
        optionsSelected: equipmentTypes.map(type => formatEquipmentType(type)),
      })
    }

    if (equipmentAges.length) {
      filters.push({
        key: 'equipmentAges',
        optionsSelected: equipmentAges.map(age => age),
      })
    }

    if (visitsRemaining !== 'ANY') {
      filters.push({
        key: 'visitsRemaining',
        optionsSelected: [VisitsRemainingDisplayNames[visitsRemaining]],
      })
    }

    if (zipCodes.length) {
      filters.push({
        key: 'zipCodes',
        optionsSelected: zipCodes,
      })
    }

    if (tagNames.length) {
      filters.push({
        key: 'tags',
        optionsSelected: tagNames.map(tag => tagsByNameMap[tag]?.name),
      })
    }

    if (planTypes.length) {
      filters.push({
        key: 'planTypes',
        optionsSelected: planTypes.map(type => planTypeOptionsMap[type]?.name),
      })
    }

    if (planStatuses.length) {
      filters.push({
        key: 'planStatuses',
        optionsSelected: planStatuses.map(
          status => maintenancePlanStatusDisplayNames[status],
        ),
      })
    }

    if (autoRenewingStatus !== 'ALL') {
      filters.push({
        key: 'autoRenewingStatus',
        optionsSelected: [autoRenewingStatus === 'AUTO' ? 'True' : 'False'],
      })
    }

    if (planRenewalDate !== 'ALL_TIME') {
      filters.push({
        key: 'planRenewalDate',
        optionsSelected: [PlanRenewalDateDisplayNames[planRenewalDate]],
      })
    }
    return filters
  }, [
    nextVisitMonths,
    nextVisitStatuses,
    equipmentTypes,
    equipmentAges,
    zipCodes,
    tagNames,
    tagsByNameMap,
    visitsRemaining,
    planTypes,
    planTypeOptionsMap,
    planStatuses,
    autoRenewingStatus,
    planRenewalDate,
  ])

  const [sortType, setSortTypeRaw] = useQueryParamStateWithOptions(
    'sort',
    SORT_TYPES[0],
    SORT_TYPES,
  )

  const setSortType = useCallback(
    (type: SortType) => {
      setSortTypeRaw(type)
      resetPage()
    },
    [resetPage, setSortTypeRaw],
  )

  const orderByClause = useMemo<MaintenancePlansQueryableOrderBy[]>(() => {
    switch (sortType) {
      case 'NEXT_VISIT_DATE':
        return [{ nextVisit: { affinityDate: 'ASC' } }]
      case 'PLAN_RENEWAL_DATE':
        return [
          { terminatesAt: 'ASC_NULLS_LAST' },
          { activatedAt: 'ASC_NULLS_LAST' },
        ]
      case 'ZIP_CODE_ASC':
        return [{ location: { address: { zipCode: 'ASC' } } }]
      case 'ZIP_CODE_DESC':
        return [{ location: { address: { zipCode: 'DESC' } } }]
      default:
        return [{ nextVisit: { affinityDate: 'ASC' } }]
    }
  }, [sortType])
  const tzId = useExpectedCompanyTimeZoneId()
  const whereClause = useMemo<MaintenancePlansQueryableBoolExp>(() => {
    const whereClauses: MaintenancePlansQueryableBoolExp[] = []

    if (searchTerm) {
      const _ilike = `%${searchTerm}%`

      return {
        _or: [
          // Account name
          { account: { accountDisplayName: { _ilike } } },
          // Primary contact
          {
            account: { accountContacts: { contact: { fullName: { _ilike } } } },
          },
          // Plan name
          {
            maintenancePlanDefinition: { marketingInfo: { name: { _ilike } } },
          },
          // Location
          {
            location: {
              address: {
                _or: [
                  { line1: { _ilike } },
                  { line2: { _ilike } },
                  { city: { _ilike } },
                  { stateAbbreviation: { _ilike } },
                ],
              },
            },
          },
          // Equipment type
          {
            maintenancePlanVisits: {
              maintenancePlanVisitInstalledEquipments: {
                installedEquipment: { equipmentType: { _ilike } },
              },
            },
          },
          // Zip code
          { location: { address: { zipCode: { _ilike } } } },
          // Next visit affinity date
          { nextVisit: { affinityDateStr: { _ilike } } },
        ],
      }
    }

    if (nextVisitStatuses.length) {
      whereClauses.push({
        nextVisit: { visitStatus: { _in: nextVisitStatuses } },
      })
    }

    if (nextVisitMonths.length) {
      const currentYear = BzDateFns.getYear(BzDateFns.now(tzId))
      const lastYear = currentYear - 1
      const nextYear = currentYear + 1
      const years = [lastYear, currentYear, nextYear]
      const monthRanges = getMonthRanges(nextVisitMonths, years)

      whereClauses.push({
        nextVisit: {
          _or: monthRanges.map(range => ({
            affinityDate: {
              _gte: range.start,
              _lte: range.end,
            },
          })),
        },
      })
    }

    if (equipmentTypes.length) {
      whereClauses.push({
        maintenancePlanVisits: {
          maintenancePlanVisitInstalledEquipments: {
            installedEquipment: {
              equipmentType: { _in: equipmentTypes },
            },
          },
        },
      })
    }

    if (equipmentAges.length) {
      const equipmentAgeRanges = getEquipmentAgeRanges(equipmentAges, tzId)
      whereClauses.push({
        maintenancePlanVisits: {
          maintenancePlanVisitInstalledEquipments: {
            installedEquipment: {
              _or: equipmentAgeRanges.map(range => {
                if (!range) {
                  return {
                    installationDate: {
                      _isNull: true,
                    },
                  }
                }
                return {
                  installationDate: {
                    _gte: range.start,
                    _lte: range.end,
                  },
                }
              }),
            },
          },
        },
      })
    }

    whereClauses.push({
      numUnusedVisits: VISITS_REMAINING_TO_WHERE_CONDITION[visitsRemaining],
    })

    if (zipCodes.length) {
      whereClauses.push({
        location: { address: { zipCode: { _in: zipCodes } } },
      })
    }

    if (tagNames.length) {
      whereClauses.push({
        account: {
          tags: {
            tag: {
              tagGuid: {
                _in: tagNames
                  .map(name => tagsByNameMap[name]?.tagGuid)
                  .filter(Boolean),
              },
            },
          },
        },
      })
    }

    if (planTypes.length) {
      whereClauses.push({
        maintenancePlanDefinition: {
          maintenancePlanDefinitionGuid: {
            _in: planTypes.map(
              type => planTypeOptionsMap[type]?.maintenancePlanDefinitionGuid,
            ),
          },
        },
      })
    }

    if (planStatuses.length) {
      whereClauses.push({
        status: { _in: planStatuses },
      })
    }

    if (autoRenewingStatus !== 'ALL') {
      whereClauses.push({
        paymentFlow: {
          _eq:
            autoRenewingStatus === 'AUTO'
              ? MaintenancePlanPaymentFlow.AUTO
              : MaintenancePlanPaymentFlow.MANUAL,
        },
      })
    }

    if (autoRenewingStatus === 'MANUAL' && planRenewalDate) {
      whereClauses.push({
        terminatesAt: PLAN_RENEWAL_DATE_TO_WHERE_CONDITION[planRenewalDate],
      })
    }

    return {
      _and: whereClauses,
    }
  }, [
    searchTerm,
    nextVisitStatuses,
    nextVisitMonths,
    equipmentTypes,
    equipmentAges,
    visitsRemaining,
    zipCodes,
    tagNames,
    tzId,
    tagsByNameMap,
    planTypes,
    planTypeOptionsMap,
    planStatuses,
    autoRenewingStatus,
    planRenewalDate,
  ])

  const [
    { data: maintenancePlansData, fetching: fetchingMaintenancePlans },
    refetchMpData,
  ] = useQuery({
    query: NEW_MAINTENANCE_PLANS_LIST_PAGE_QUERY,
    variables: {
      limit,
      offset,
      orderBy: orderByClause,
      where: whereClause,
    },
  })

  const data = maintenancePlansData
  const fetching =
    fetchingMaintenancePlans ||
    fetchingTagOptions ||
    fetchingPlanTypeOptions ||
    fetchingZipCodes

  const [
    { data: activePlansSummaryData, fetching: fetchingActivePlansSummary },
    refetchActivePlansSummary,
  ] = useQuery({
    query: MAINTENANCE_PLAN_COUNT_SUMMARY_QUERY,
    variables: {
      where: {
        _and: [
          whereClause,
          {
            status: {
              _eq: MaintenancePlanStatus.ACTIVE,
            },
          },
        ],
      },
    },
  })
  const [
    {
      data: activePlanYearlyPriceSummaryData,
      fetching: fetchingActivePlanYearlyPriceSummary,
    },
    refetchActivePlanYearlyPriceSummary,
  ] = useQuery({
    query: MAINTENANCE_PLAN_YEARLY_PRICE_SUMMARY_QUERY,
    variables: {
      where: {
        _and: [
          whereClause,
          {
            status: {
              _eq: MaintenancePlanStatus.ACTIVE,
            },
          },
        ],
      },
    },
  })

  const [
    {
      data: lapsedPlanYearlyPriceSummaryData,
      fetching: fetchingLapsedPlanYearlyPriceSummary,
    },
    refetchLapsedPlanYearlyPriceSummary,
  ] = useQuery({
    query: MAINTENANCE_PLAN_YEARLY_PRICE_SUMMARY_QUERY,
    variables: {
      where: {
        _and: [
          whereClause,
          {
            status: {
              _eq: MaintenancePlanStatus.LAPSED,
            },
          },
        ],
      },
    },
  })

  const [
    { data: overdueVisitsSummaryData, fetching: fetchingOverdueVisitsSummary },
    refetchOverdueVisitsSummary,
  ] = useQuery({
    query: MAINTENANCE_PLAN_OVERDUE_VISITS_SUMMARY_QUERY,
    variables: {
      where: whereClause,
    },
  })
  const refetch = useCallback(() => {
    refetchMpData()
    refetchActivePlansSummary()
    refetchActivePlanYearlyPriceSummary()
    refetchLapsedPlanYearlyPriceSummary()
    refetchOverdueVisitsSummary()
  }, [
    refetchActivePlansSummary,
    refetchActivePlanYearlyPriceSummary,
    refetchLapsedPlanYearlyPriceSummary,
    refetchMpData,
    refetchOverdueVisitsSummary,
  ])

  const [isCancelingModalOpen, openCancelingModal, closeCancelingModal] =
    useModalState()

  const [pendingPlanCancellation, setPendingPlanCancellation] = useState<
    MaintenancePlanCancelInfo | undefined
  >()
  const onCancelCancellation = useCallback(() => {
    setPendingPlanCancellation(undefined)
    openCancelingModal()
  }, [openCancelingModal])
  const onCancellationComplete = useCallback(() => {
    closeCancelingModal()
    setPendingPlanCancellation(undefined)
    refetch()
  }, [closeCancelingModal, refetch])

  const onCancelPlan = useCallback(
    (vm: MaintenancePlanCancelInfo) => {
      setPendingPlanCancellation(vm)
      openCancelingModal()
    },
    [openCancelingModal],
  )

  const cols = useMemo(() => {
    const cols: ListPageTableColsConfig<MaintenancePlanListPageItem> = [
      {
        header: 'Details',
        render: maintenancePlan => {
          const { accountGuid, account, location } = maintenancePlan
          const numUnusedVisits = maintenancePlan.numUnusedVisits

          const { accountDisplayName, tags } = account

          const maintenancePlanLite = {
            name: `${maintenancePlan.maintenancePlanDefinition?.marketingInfo?.name} (${numUnusedVisits} remaining)`,
            color:
              maintenancePlan.maintenancePlanDefinition?.flare?.primaryColorHex,
            visits: mapQueryVisitsToVisitViewModels(
              maintenancePlan.maintenancePlanVisits,
            ),
            activatedAt: maintenancePlan.activatedAt,
          }

          const accountDetailsLink = CalculatePaths.accountDetails({
            accountGuid,
          })

          const maintenancePlanDetailsLink =
            CalculatePaths.maintenancePlanDetails({
              maintenancePlanGuid: maintenancePlan.maintenancePlanGuid,
            })

          const maintenancePlanDisplayName = `${
            maintenancePlan.maintenancePlanDefinition?.marketingInfo?.name ??
            'Unknown'
          }${
            maintenancePlan.paymentFlow === MaintenancePlanPaymentFlow.AUTO
              ? ` (${
                  MaintenancePlanPaymentIntervalDisplayNames[
                    maintenancePlan.paymentInterval
                  ]
                })`
              : ''
          }`

          const flatTags = tags.flatMap(tag => tag.tag.name)

          const detailItems: React.ReactNode[] = [
            <Link blue={false} bold={false} to={accountDetailsLink}>
              {account?.accountContacts[0]?.contact.fullName ??
                accountDisplayName}
            </Link>,
          ]

          const address = streetAddressLine1And2Condensed(
            location.address.line1,
            location.address.line2,
          )
          detailItems.push(
            <Link
              blue={false}
              bold={false}
              to={CalculatePaths.locationDetails({
                locationGuid: location.locationGuid,
              })}
            >
              {address}
            </Link>,
          )

          return (
            <DetailsCell
              dropdown={
                <MaintenancePlanActionsDropdown
                  onCancel={onCancelPlan}
                  refetch={refetch}
                  {...maintenancePlan}
                />
              }
              link={{
                to: maintenancePlanDetailsLink,
                label: maintenancePlanDisplayName,
              }}
              detailItems={detailItems}
              footer={
                <MaintenancePlanTagRow
                  maintenancePlan={maintenancePlanLite}
                  tags={flatTags}
                />
              }
            />
          )
        },
      },
      {
        header: 'Status',
        minWidth: 150,
        cellClassName: 'align-middle',
        render: ({ status, paymentFlow }) => (
          <MaintenancePlanStatusTag paymentFlow={paymentFlow} status={status} />
        ),
      },

      {
        header: 'Primary Contact',
        render: maintenancePlan => (
          <ContactCell account={maintenancePlan.account} />
        ),
      },
      {
        header: 'Next Visit',
        render: ({ nextVisit }) => (
          <NextVisitCell
            nextVisit={mapQueryNextVisitToNextVisitViewModel(nextVisit)}
          />
        ),
      },
      {
        header: 'Plan Renewal',
        render: maintenancePlan => {
          return <PlanRenewalCell maintenancePlan={maintenancePlan} />
        },
      },
      {
        header: 'Zip Code',
        render: ({ location }) => {
          return <div>{location.address.zipCode}</div>
        },
      },
      {
        header: 'Equipment Covered',
        render: ({ maintenancePlanVisits }) => {
          const visits = mapQueryVisitsToVisitViewModels(maintenancePlanVisits)
          const equipment = getAllInstalledEquipmentFromVisits(visits)
          return <EquipmentCell equipment={equipment} />
        },
      },
    ]

    return cols
  }, [onCancelPlan, refetch])

  const totalItems =
    data?.maintenancePlansQueryableAggregate.aggregate?.count ?? 0

  return (
    <Page requiresCompanyUser className="flex min-w-[1024px] overflow-auto">
      <ListPageContainer
        summaryStats={
          <>
            <SummaryStatBox
              label="Active Plans"
              tooltipContent="The total number of active maintenance plans."
              loading={fetchingActivePlansSummary}
              bottomBarColorClasses={['bg-bz-green-300']}
            >
              {activePlansSummaryData?.maintenancePlansQueryableAggregate
                .aggregate?.count ?? 0}
            </SummaryStatBox>
            <SummaryStatBox
              label="Monthly Revenue"
              tooltipContent="The average monthly revenue for active maintenance plans."
              loading={fetchingActivePlanYearlyPriceSummary}
            >
              {formatAbbreviatedUsc(
                (activePlanYearlyPriceSummaryData
                  ?.maintenancePlansQueryableAggregate.aggregate?.sum
                  ?.yearlyPriceUsc ?? 0) / 12,
              )}
            </SummaryStatBox>
            <SummaryStatBox
              label="Lapsed Payments"
              tooltipContent="The average monthly revenue for lapsed maintenance plans."
              loading={fetchingLapsedPlanYearlyPriceSummary}
              bottomBarColorClasses={['bg-bz-purple-300']}
            >
              {formatAbbreviatedUsc(
                (lapsedPlanYearlyPriceSummaryData
                  ?.maintenancePlansQueryableAggregate.aggregate?.sum
                  ?.yearlyPriceUsc ?? 0) / 12,
              )}
            </SummaryStatBox>
            <SummaryStatBox
              label="Overdue Visits"
              tooltipContent="The number of visits that have not been completed by their due date."
              loading={fetchingOverdueVisitsSummary}
              bottomBarColorClasses={['bg-bz-magenta-300']}
            >
              {overdueVisitsSummaryData?.maintenancePlansQueryableAggregate
                .aggregate?.sum?.numOverdueVisits ?? 0}
            </SummaryStatBox>
          </>
        }
        filterBar={
          <ListPageFilterBar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            totalItems={totalItems}
            sortByControl={
              <ListPageFilter
                label="Sort by"
                options={SORT_TYPE_OPTIONS}
                value={sortType}
                onChange={setSortType}
              />
            }
            hasFilters={hasFilters}
            filters={filters}
            filterLabels={FILTER_LABELS}
            clearAllFilters={clearAllFilters}
            clearFilter={clearFilter}
          >
            <MoreFilters
              nextVisitMonths={nextVisitMonths}
              setNextVisitMonths={setNextVisitMonths}
              nextVisitStatuses={nextVisitStatuses}
              setNextVisitStatuses={setNextVisitStatuses}
              equipmentTypes={equipmentTypes}
              setEquipmentTypes={setEquipmentTypes}
              equipmentAges={equipmentAges}
              setEquipmentAges={setEquipmentAges}
              zipCodeOptions={zipCodeOptions}
              zipCodes={zipCodes}
              setZipCodes={setZipCodes}
              tagOptions={tagOptionsData?.tags ?? []}
              tagNames={tagNames}
              setTagNames={setTagNames}
              visitsRemaining={visitsRemaining}
              setVisitsRemaining={setVisitsRemaining}
              planTypeOptions={planTypeOptions.map(option => option.name) ?? []}
              planTypes={planTypes}
              setPlanTypes={setPlanTypes}
              planStatuses={planStatuses}
              setPlanStatuses={setPlanStatuses}
              autoRenewingStatus={autoRenewingStatus}
              setAutoRenewingStatus={setAutoRenewingStatus}
              planRenewalDate={planRenewalDate}
              setPlanRenewalDate={setPlanRenewalDate}
            />
          </ListPageFilterBar>
        }
        table={
          <ListPageTable
            autoSize
            data={data?.maintenancePlansQueryable}
            fetching={fetching}
            cols={cols}
            rowKey="maintenancePlanGuid"
            page={page}
            pageSize={pageSize}
            totalItems={totalItems}
            setPage={setPage}
            setPageSize={setPageSize}
            hasFilters={hasFilters || !!searchTerm}
            itemsDescriptor="Maintenance Plans"
          />
        }
      />
      <MaintenancePlanCancelModal
        item={
          isCancelingModalOpen && pendingPlanCancellation
            ? {
                vm: pendingPlanCancellation,
                onSubmit: onCancellationComplete,
                onClose: onCancelCancellation,
              }
            : undefined
        }
      />
    </Page>
  )
})
