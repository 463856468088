import { z } from 'zod'
import { AsyncFn } from '../../common'
import { MaintenancePlanPaymentInterval, guidSchema } from '../../contracts'
import { CompanyGuidContainer, ForCompany } from '../Company/Company'
import { EquipmentType } from '../Equipment/EquipmentType'
import { CartItem } from '../Finance/Transactions/TransactionTypes'
import { JobClass } from '../Job'
import { PricebookTaxRateDto } from '../Pricebook/PricebookTypes'
import { Guid, bzOptional } from '../common-schemas'
import { EquipmentCoverageDefinition } from './EquipmentCoverageTypes'

const maintenancePlanBenefitSchema = z.object({
  maintenancePlanDefinitionBenefitGuid: bzOptional(guidSchema),
  name: z.string(),
  description: z.string(),
})

const maintenancePlanDiscountSchema = z.object({
  maintenancePlanDefinitionDiscountGuid: guidSchema,
  discountJobClass: z.nativeEnum(JobClass),
  discountRate: z.number(),
})

export const maintenancePlanFlareSchema = z.object({
  imageUrl: z.string(),
  primaryColorHex: z.string(),
})

export const maintenancePlanAllowedPaymentIntervalSchema = z.object({
  maintenancePlanDefinitionAllowedPaymentIntervalGuid: guidSchema,
  paymentInterval: z.nativeEnum(MaintenancePlanPaymentInterval),
  isDefault: z.boolean(),
})

export const maintenancePlanDefinitionSchema = z.object({
  maintenancePlanDefinitionGuid: guidSchema,
  companyGuid: guidSchema,
  numVisitCreditsPerYear: z.number(),
  yearlyStaticPriceUsc: bzOptional(z.number()),
  numDaysUntilVisitCreditExpiration: z.number(),
  name: z.string(),
  shortDescription: z.string(),
  termsAndConditions: z.string(),
  isAvailableForSale: z.boolean(),
  flare: bzOptional(maintenancePlanFlareSchema),
  benefits: z.array(maintenancePlanBenefitSchema),
  discounts: z.array(maintenancePlanDiscountSchema),
  allowedPaymentIntervals: z.array(maintenancePlanAllowedPaymentIntervalSchema),
})

export type ComprehensiveMaintenancePlanConfigViewModel = {
  readonly maintenancePlansEnabled: boolean
  readonly plans: ReadonlyArray<MaintenancePlanDefinition>
  readonly equipmentCoverageDefinitions: ReadonlyArray<EquipmentCoverageDefinition>
  readonly taxRate?: PricebookTaxRateDto
  readonly serviceItem?: CartItem
}

export type ComprehensiveMaintenancePlanConfigReader = AsyncFn<
  CompanyGuidContainer,
  ComprehensiveMaintenancePlanConfigViewModel
>

export type MaintenancePlanDefinitionReader = AsyncFn<
  ForCompany<MaintenancePlanDefinitionGuidContainer>,
  MaintenancePlanDefinition
>

export type MaintenancePlanDefinitionGuid = Guid
export type MaintenancePlanDefinitionGuidContainer = {
  readonly maintenancePlanDefinitionGuid: MaintenancePlanDefinitionGuid
}

export type MaintenancePlanDefinition = MaintenancePlanBaseDefinition &
  MaintenancePlanDefinitionMarketingInfo & {
    readonly flare?: MaintenancePlanDefinitionFlare
    readonly benefits: MaintenancePlanDefinitionBenefit[]
    readonly discounts: MaintenancePlanDefinitionDiscount[]
    readonly allowedPaymentIntervals: MaintenancePlanDefinitionAllowedPaymentInterval[]
  }

export type MaintenancePlanBaseDefinition = MaintenancePlanDefinitionGuidContainer & {
  readonly companyGuid: string
  readonly numVisitCreditsPerYear: number
  readonly numDaysUntilVisitCreditExpiration: number
  readonly yearlyStaticPriceUsc?: number | null
}

export type MaintenancePlanDefinitionFlare = {
  readonly imageUrl: string
  readonly primaryColorHex: string
}

export type MaintenancePlanDefinitionMarketingInfo = {
  readonly name: string
  readonly shortDescription: string
  readonly termsAndConditions: string
  readonly isAvailableForSale: boolean
}

export type MaintenancePlanDefinitionBenefit = {
  readonly maintenancePlanDefinitionBenefitGuid?: Guid
  readonly name: string
  readonly description: string
}

export type MaintenancePlanDefinitionDiscount = {
  readonly maintenancePlanDefinitionDiscountGuid: Guid
  readonly discountJobClass: JobClass
  readonly discountRate: number
}

export type MaintenancePlanDefinitionAllowedPaymentInterval = {
  readonly maintenancePlanDefinitionAllowedPaymentIntervalGuid: Guid
  readonly paymentInterval: MaintenancePlanPaymentInterval
  readonly isDefault: boolean
}

export type MaintenancePlanRenewalType = 'AUTO_RENEW' | 'MANUAL_RENEW'
export const MAINTENANCE_PLAN_MANUAL_RENEWAL_NUMBER_OF_DAYS_TIL_EXPIRATION = 365

export const getCoveredEquipmentTypesMap = (
  items?: readonly EquipmentCoverageDefinition[],
): Map<EquipmentType, EquipmentCoverageDefinition> =>
  (items ?? []).reduce((acc, e) => {
    acc.set(e.equipmentType, e)
    return acc
  }, new Map<EquipmentType, EquipmentCoverageDefinition>())

export type RenewalTypeOption = 'AUTO' | 'MANUAL' | 'BOTH'

export const getRenewalTypeOptionFromBillingProfiles = (
  billingProfiles: { maintenancePlanAutoRenewalAllowed: boolean; maintenancePlanManualRenewalAllowed: boolean }[],
) => {
  if (billingProfiles.length < 1) return 'AUTO'
  const bp = billingProfiles[0]
  if (bp.maintenancePlanAutoRenewalAllowed && bp.maintenancePlanManualRenewalAllowed) return 'BOTH'
  if (bp.maintenancePlanManualRenewalAllowed) return 'MANUAL'

  return 'AUTO'
}

export const getFixedPlanDurationsFromBillingProfiles = (
  billingProfiles: { maintenancePlanStaticDurationNumYearsAllowed: number[] }[],
): number[] => {
  if (billingProfiles.length < 1) return [1]

  const bp = billingProfiles[0]
  return bp.maintenancePlanStaticDurationNumYearsAllowed
}
