import {
  IsoDateString,
  tryParseEndOfAppointmentNextSteps,
} from '@breezy/shared'
import { faWrench } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import {
  toEventColorConfig,
  useScheduleColorMode,
} from '../../hooks/fetch/useScheduleColorMode'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { getColorForJobClass } from '../../utils/job-utils'
import { StateSetter, useStrictContext } from '../../utils/react-utils'
import { BaseEventBox } from './BaseEventBox'
import { AssignmentCard } from './Cards/AssignmentCard'
import { ScheduleAssignment } from './Schedule.gql'
import {
  FullScheduleAppointment,
  getInferredAppointmentStatusFromAssignments,
  SchedulePageContext,
  TechnicianResource,
  usePopoverState,
  useRunningLateStatus,
} from './scheduleUtils'

type AssignmentEventBoxProps = {
  assignment: ScheduleAssignment
  tech?: TechnicianResource
  start: IsoDateString
  end: IsoDateString
  setChecklistAppointment: StateSetter<FullScheduleAppointment | undefined>
}

export const AssignmentEventBox = React.memo<AssignmentEventBoxProps>(
  ({ assignment, tech, start, end, setChecklistAppointment }) => {
    const { scheduleView } = useStrictContext(SchedulePageContext)
    const companyGuid = useExpectedCompanyGuid()
    const colorMode = useScheduleColorMode(companyGuid)
    const colorConfig =
      !!tech && !!tech.userPrimaryHueHex && colorMode === 'byTech'
        ? toEventColorConfig(tech.userPrimaryHueHex)
        : getColorForJobClass(assignment.appointment.job.jobType.jobClass)

    const isCompleted = useMemo(() => {
      if (assignment.assignmentStatus?.status === 'COMPLETED') {
        return true
      }
      if (assignment.appointment.endOfAppointmentNextSteps) {
        return true
      }
      if (assignment.appointment.cancellationStatus?.canceled) {
        return true
      }
      return false
    }, [
      assignment.appointment.cancellationStatus?.canceled,
      assignment.appointment.endOfAppointmentNextSteps,
      assignment.assignmentStatus?.status,
    ])

    const status = useMemo(
      () =>
        getInferredAppointmentStatusFromAssignments(
          [assignment],
          assignment.appointment.cancellationStatus?.canceled,
        ),
      [assignment],
    )

    const runningLateStatus = useRunningLateStatus(
      assignment.appointmentGuid,
      assignment.appointment.appointmentWindowEnd,
      status,
    )

    const [warning, withPulse] = useMemo(() => {
      if (assignment.appointment.endOfAppointmentNextSteps) {
        const parsedNextSteps = tryParseEndOfAppointmentNextSteps(
          assignment.appointment.endOfAppointmentNextSteps,
        )
        if (parsedNextSteps?.data.allOnsiteWorkCompleted === false) {
          return ['INC.']
        }
      }
      if (runningLateStatus) {
        return ['LATE', true]
      }

      return []
    }, [assignment.appointment.endOfAppointmentNextSteps, runningLateStatus])

    const [popoverOpen, setPopoverOpen] = usePopoverState()

    return (
      <>
        <BaseEventBox
          title={assignment.appointment.job.account.accountDisplayName}
          icon={<FontAwesomeIcon icon={faWrench} />}
          avatarContent={tech?.avatarShortString}
          colorConfig={colorConfig}
          start={start}
          end={end}
          completed={isCompleted}
          warning={warning}
          className={classNames({
            'warning-pulse': withPulse,
          })}
          popoverOpen={popoverOpen}
          setPopoverOpen={setPopoverOpen}
          popoverContent={
            <AssignmentCard
              openChecklists={() =>
                setChecklistAppointment(assignment.appointment)
              }
              closePopover={() => setPopoverOpen(false)}
              start={start}
              end={end}
              assignment={assignment}
              tech={tech}
            />
          }
        >
          {assignment.appointment.job.location.address.city}
          {scheduleView === 'DISPATCH' || !tech
            ? ''
            : ` • ${tech.firstName} ${tech.lastName}`}
        </BaseEventBox>
      </>
    )
  },
)
