import { z } from 'zod'
import {
  AsyncFn,
  BzDateFns,
  HtmlString,
  IsoDateString,
  MatchToShape,
  TimeZoneId,
  WithRequiredKeys,
  usCentsToUsd,
  uscMultiply,
  usdToUsCents,
} from '../../common'
import { guidSchema, htmlStringSchema, isoDateStringSchema, localDateSchema, uscSchema } from '../../contracts/_common'
import { AccountGuid } from '../Accounts/Account'
import { Guid, bzOptional } from '../common-schemas'
import { CompanyGuidContainer, ForCompany, ForCompanyUser, ForCompanyUserWithTimeZone } from '../Company/Company'
import { CommonDiscount, CommonDiscountUsc, DiscountType } from '../Discounts/DiscountTypes'
import { DynamicPricingType, getDynamicPricingMultiplier } from '../DynamicPricing/DynamicPricing'
import {
  InvoiceGuidContainer,
  InvoiceStatuses,
  InvoiceTerm,
  InvoiceV1ExistsReader,
} from '../Finance/Invoicing/InvoiceTypes'
import { MIN_FINANCEABLE_AMOUNT_USC } from '../Finance/Lending/Lending'
import { PaymentMethod, PaymentStatus, paymentPendingStatuses } from '../Finance/Payments/PaymentTypes'
import { SimpleInvoiceCreationRequest } from '../Finance/SimpleInvoiceCreator'
import {
  CartItemType,
  CartItemUsc,
  CartItemUscSchema,
  TransactionLinkMap,
} from '../Finance/Transactions/TransactionTypes'

export const INVOICE_STATUSES = ['DRAFT', 'OPEN', 'PAID', 'VOIDED', 'UNCOLLECTABLE'] as const

export const InvoiceV2StatusSchema = z.enum(INVOICE_STATUSES)
export type InvoiceV2Status = z.infer<typeof InvoiceV2StatusSchema>

export const INVOICE_V2_FRIENDLY_STATUS_NAMES: Record<InvoiceV2Status, string> = {
  DRAFT: 'Draft',
  OPEN: 'Open',
  PAID: 'Paid',
  VOIDED: 'Voided',
  UNCOLLECTABLE: 'Uncollectable',
}

export type InvoiceV2StatusWriter = AsyncFn<ForCompany<InvoiceGuidContainer> & { status: InvoiceV2Status }, void>

export const INVOICE_TERMS_V2 = [
  'DUE_ON_RECEIPT',
  'NET_7',
  'NET_10',
  'NET_14',
  'NET_15',
  'NET_30',
  'NET_60',
  'AUTO',
] as const

export const InvoiceV2TermSchema = z.enum(INVOICE_TERMS_V2)
export type InvoiceTermV2 = z.infer<typeof InvoiceV2TermSchema>

export const INVOICE_TERM_V1_TO_V2: Record<InvoiceTerm, InvoiceTermV2> = {
  DUE_ON_RECEIPT: 'DUE_ON_RECEIPT',
  NET_7: 'NET_7',
  NET_10: 'NET_10',
  NET_14: 'NET_14',
  NET_15: 'NET_15',
  NET_30: 'NET_30',
  NET_60: 'NET_60',
  AUTO: 'AUTO',
}

export const INVOICE_TERM_V2_TO_V1: Record<InvoiceTermV2, InvoiceTerm> = {
  DUE_ON_RECEIPT: InvoiceTerm.DUE_ON_RECEIPT,
  NET_7: InvoiceTerm.NET_7,
  NET_10: InvoiceTerm.NET_10,
  NET_14: InvoiceTerm.NET_14,
  NET_15: InvoiceTerm.NET_15,
  NET_30: InvoiceTerm.NET_30,
  NET_60: InvoiceTerm.NET_60,
  AUTO: InvoiceTerm.AUTO,
}

export const INVOICE_TERM_DAYS_MAP: Record<InvoiceTermV2, number> = {
  DUE_ON_RECEIPT: 0,
  NET_7: 7,
  NET_10: 10,
  NET_14: 14,
  NET_15: 15,
  NET_30: 30,
  NET_60: 60,
  AUTO: 0,
}

export const invoiceV2ToInvoiceV1Status = (invoiceV2Status: InvoiceV2Status): InvoiceStatuses => {
  switch (invoiceV2Status) {
    case 'DRAFT':
      return InvoiceStatuses.DRAFT
    case 'OPEN':
      return InvoiceStatuses.CREATED
    case 'PAID':
      return InvoiceStatuses.FULLY_PAID
    case 'VOIDED':
      return InvoiceStatuses.VOID
    case 'UNCOLLECTABLE':
      return InvoiceStatuses.VOID
    default:
      throw new Error(`Unknown invoice status ${invoiceV2Status}`)
  }
}

export const InvoiceCartItemSchema = CartItemUscSchema.extend({
  cartItemGuid: guidSchema,
  seq: z.number(),
  savedToPricebook: bzOptional(z.boolean()),
})
export type InvoiceCartItem = z.infer<typeof InvoiceCartItemSchema>

export const InvoiceTotalsSchema = z.object({
  subtotalUsc: uscSchema,
  unMarkedUpSubtotalUsc: uscSchema,
  discountAmountUsc: uscSchema,
  discountableTotalUsc: uscSchema,
  taxRate: z.number().min(0).max(1),
  taxAmountUsc: uscSchema,
  totalUsc: uscSchema,
  paidUsc: uscSchema,
  processingPaymentsUsc: uscSchema,
  dueUsc: uscSchema,
  refundedUsc: uscSchema,
  markupUsc: uscSchema,
})
export type InvoiceTotals = z.infer<typeof InvoiceTotalsSchema>

export type InvoicePayment = {
  paymentRecordGuid: Guid
  paidUsc: number
  status: PaymentStatus
  occurredAt: IsoDateString
  paymentMethod: PaymentMethod
  refunds: { amountUsc: number; occurredAt: IsoDateString }[]
}

export const getSingleDiscountAmountUsc = (totals: InvoiceTotals, discount: CommonDiscountUsc): number => {
  if (discount.type === DiscountType.FLAT) {
    return Math.min(discount.discountAmountUsc, totals.subtotalUsc)
  } else {
    return uscMultiply(totals.discountableTotalUsc, Math.max(0, discount.discountRate))
  }
}

export const getInvoiceTotals = (invoice: InvoiceV2Enriched): InvoiceTotals => ({
  subtotalUsc: invoice.subtotalUsc,
  unMarkedUpSubtotalUsc: invoice.unMarkedUpSubtotalUsc,
  discountAmountUsc: invoice.discountAmountUsc,
  discountableTotalUsc: invoice.discountableTotalUsc,
  taxRate: invoice.taxRate,
  taxAmountUsc: invoice.taxAmountUsc,
  totalUsc: invoice.totalUsc,
  paidUsc: invoice.paidUsc,
  processingPaymentsUsc: invoice.processingPaymentsUsc,
  dueUsc: invoice.dueUsc,
  refundedUsc: invoice.refundedUsc,
  markupUsc: invoice.markupUsc,
})

type InvoiceTotalsLineItem = MatchToShape<
  CartItemUsc,
  {
    unitPriceUsc: true
    quantity: true
    isDiscountable: true
    isTaxable: true
  }
>

export const calculateInvoiceTotals = (
  lineItems: InvoiceTotalsLineItem[],
  taxRate: number,
  discounts: CommonDiscountUsc[],
  payments: InvoicePayment[],
  // I'm doing " | undefined" instead of "?" because I want you to have to pass the value in, even if the value is
  // undefined. This is so people don't forget.
  dynamicPricingType: DynamicPricingType | undefined,
): InvoiceTotals => {
  let discountableTotalUsc = 0
  let taxableTotalUsc = 0
  let subtotalUsc = 0
  let markupUsc = 0

  const dynamicMultiplier = dynamicPricingType ? getDynamicPricingMultiplier(dynamicPricingType) : 1

  for (const lineItem of lineItems) {
    const lineItemTotalUsc = uscMultiply(lineItem.unitPriceUsc, lineItem.quantity)
    const markedUpLineItemTotalUsc = uscMultiply(lineItemTotalUsc, dynamicMultiplier)
    const markupAmountUsc = markedUpLineItemTotalUsc - lineItemTotalUsc

    markupUsc += markupAmountUsc
    subtotalUsc += markedUpLineItemTotalUsc

    if (lineItem.isDiscountable) {
      discountableTotalUsc += markedUpLineItemTotalUsc
    }
  }
  let discountAmountUsc = 0
  for (const discount of discounts) {
    if (discount.type === DiscountType.FLAT) {
      discountAmountUsc += Math.min(discount.discountAmountUsc, discountableTotalUsc)
    } else {
      discountAmountUsc += Math.max(Math.ceil(discountableTotalUsc * discount.discountRate), 0)
    }
  }

  const discountedSubtotalUsc = Math.max(subtotalUsc - discountAmountUsc, 0)

  for (const lineItem of lineItems) {
    const markedUpLineItemTotalUsc = uscMultiply(lineItem.unitPriceUsc * dynamicMultiplier, lineItem.quantity)

    if (lineItem.isTaxable) {
      // We need to apply sales tax to our taxable items. If there's a discount, however, how much should the sales
      // tax be? If it's a $50 discount and there's a $50 taxable and a $50 non-taxable, which one "gets the -$50"?
      // The fairest answer is to allocate the discount proportionally to all the line items. In other words, if it's
      // a 10% off discount, we should take 10% off each discountable item (which will add up to the total discount)
      // and calculate the sales tax based on that final amount. So that means a more expensive item "gets more of the
      // discount". So the way we compute this is we first find the "part of the total discount that this item gets".
      // We can take the ratio of this item's price and the total discountable amount, which gives us the proportion
      // of the total discount that this item gets. We multiply that by the total discount to get the discount that
      // "belongs" to this item. Then we take that out of the total amount of the line item, and THAT's what we use to
      // calculate the taxes.
      const thisItemsDiscount =
        discountableTotalUsc && lineItem.isDiscountable
          ? discountAmountUsc * (markedUpLineItemTotalUsc / discountableTotalUsc)
          : 0

      const thisItemsTaxableAmount = markedUpLineItemTotalUsc - thisItemsDiscount

      taxableTotalUsc += thisItemsTaxableAmount
    }
  }

  const taxAmountUsc = uscMultiply(taxableTotalUsc, taxRate)

  const totalUsc = discountedSubtotalUsc + taxAmountUsc

  let paidUsc = 0
  let refundedUsc = 0
  let processingPaymentsUsc = 0
  for (const payment of payments) {
    if (payment.status === PaymentStatus.PAID) {
      paidUsc += payment.paidUsc
    }
    if (paymentPendingStatuses.has(payment.status)) {
      processingPaymentsUsc += payment.paidUsc
    }
    refundedUsc += payment.refunds.reduce((acc, refund) => acc + refund.amountUsc, 0)
  }

  return {
    subtotalUsc,
    unMarkedUpSubtotalUsc: subtotalUsc - markupUsc,
    discountAmountUsc,
    discountableTotalUsc,
    taxRate,
    taxAmountUsc,
    totalUsc,
    paidUsc,
    refundedUsc,
    processingPaymentsUsc,
    dueUsc: totalUsc - paidUsc - processingPaymentsUsc,
    markupUsc,
  }
}

export type InvoiceMigratorResponse = {
  newInvoicesMigrated: number
  migratedInvoicesUpdated: number
  invoicesNotMigrated: {
    invoiceGuid: Guid
    reason: string
  }[]
}

export type InvoiceMigrator = AsyncFn<CompanyGuidContainer, InvoiceMigratorResponse>

export type InvoiceV2PaymentSummary = {
  invoiceGuid: Guid
  totalInvoiceAmountUsc: number
  totalPaidAmountUsc: number
  totalPendingAmountUsc: number
  amountDueUsc: number
}

export type InvoiceV2PaymentsSummaryReader = AsyncFn<
  CompanyGuidContainer & InvoiceGuidContainer,
  InvoiceV2PaymentSummary
>

export type InvoiceV2ExistsReader = AsyncFn<InvoiceGuidContainer, boolean>

export type CompanyInvoiceContainer = ForCompany<InvoiceGuidContainer>
type InvoiceStraddlerRequest = CompanyInvoiceContainer & {
  invoiceV1Fn: AsyncFn<CompanyInvoiceContainer, void>
  invoiceV2Fn: AsyncFn<CompanyInvoiceContainer, void>
}
export type InvoiceStraddler = AsyncFn<InvoiceStraddlerRequest, void>

// Since we're currently train straddling between V1 and V2, we need to handle both cases until we have parity and are able to
// deprecate the v1 status write when the invoiceV2 flag is on.
// TODO: cleanup this procedure once we have parity between V1 and V2 https://getbreezyapp.atlassian.net/browse/BZ-3582
export const createInvoiceStraddler =
  (invoiceV1Exists: InvoiceV1ExistsReader, invoiceV2Exists: InvoiceV2ExistsReader): InvoiceStraddler =>
  async ({ companyGuid, invoiceGuid, invoiceV1Fn, invoiceV2Fn }: InvoiceStraddlerRequest) => {
    const hasV1Invoice = await invoiceV1Exists({ companyGuid, invoiceGuid })
    const hasV2Invoice = await invoiceV2Exists({ invoiceGuid })

    if (hasV1Invoice) {
      await invoiceV1Fn({ companyGuid, invoiceGuid })
    }

    if (hasV2Invoice) {
      await invoiceV2Fn({ companyGuid, invoiceGuid })
    }
  }

const InvoiceBaseDiscountSchema = z.object({
  name: z.string(),
  discountGuid: guidSchema,
  descriptionHtml: htmlStringSchema,
})

const InvoiceFlatDiscountSchema = InvoiceBaseDiscountSchema.extend({
  type: z.literal(DiscountType.FLAT),
  discountAmountUsc: z.number(),
})

const InvoiceRateDiscountSchema = InvoiceBaseDiscountSchema.extend({
  type: z.literal(DiscountType.RATE),
  discountRate: z.number(),
})

export const InvoiceDiscountSchema = z.discriminatedUnion('type', [
  InvoiceFlatDiscountSchema,
  InvoiceRateDiscountSchema,
])

export type InvoiceDiscount = z.infer<typeof InvoiceDiscountSchema>

export const InvoiceV2EnrichedSchema = z
  .object({
    invoiceGuid: guidSchema,
    companyGuid: guidSchema,
    accountGuid: guidSchema,
    displayId: z.number().min(1),

    messageHtml: htmlStringSchema,

    customerPurchaseOrderNumber: bzOptional(z.string()),

    serviceCompletionDate: localDateSchema,
    dueAt: isoDateStringSchema,
    issuedAt: isoDateStringSchema,

    taxRate: z.number().min(0).max(1),
    taxRateGuid: guidSchema,
    taxRateName: z.string(),
    subtotalUsc: uscSchema,
    totalUsc: uscSchema,

    createdByUserGuid: z.string(),
    createdByUserFirstNameInitial: bzOptional(z.string()),

    signatureFileUrl: bzOptional(z.string()),
    invoiceTerm: InvoiceV2TermSchema,
    status: InvoiceV2StatusSchema,

    lineItems: z.array(CartItemUscSchema),

    discounts: z.array(InvoiceDiscountSchema),

    // Links
    jobGuid: bzOptional(guidSchema),
    jobAppointmentGuid: bzOptional(guidSchema),
    maintenancePlanGuid: bzOptional(guidSchema),
    paymentSubscriptionGuid: bzOptional(guidSchema),
    locationGuid: bzOptional(guidSchema),
  })
  .merge(InvoiceTotalsSchema)

export type InvoiceV2Enriched = z.infer<typeof InvoiceV2EnrichedSchema>

export type InvoiceV2Reader = AsyncFn<InvoiceGuidContainer, InvoiceV2Enriched>

export const getDueDate = (issuedAt: IsoDateString, term: InvoiceTermV2, tzId: TimeZoneId): IsoDateString =>
  BzDateFns.withTimeZone(issuedAt, tzId, date =>
    BzDateFns.endOfDay(BzDateFns.addDays(date, INVOICE_TERM_DAYS_MAP[term])),
  )

export type SimpleInvoiceV2WriterRequest = ForCompanyUserWithTimeZone<
  Omit<SimpleInvoiceCreationRequest, 'links'> & {
    serviceAddressGuid: Guid
    messageHtml: HtmlString
    links: WithRequiredKeys<TransactionLinkMap, 'locationGuid'>
  }
>

export type SimpleInvoiceV2Writer = AsyncFn<SimpleInvoiceV2WriterRequest, { invoiceGuid: Guid; displayId: number }>

export type InvoiceBillingContact = {
  fullName: string
  contactGuid: Guid
  primaryPhoneNumber?: {
    phoneNumber: string
  }
  primaryEmailAddress?: {
    emailAddress: string
  }
}

export type InvoiceAccountBillingInfo = {
  billingContactGuid: Guid
  billingAddressGuid: Guid
}

export type InvoiceAccountBillingInfoReader = AsyncFn<AccountGuid, InvoiceAccountBillingInfo>

export const paymentRecordsToInvoicePayments = (
  paymentRecords: {
    paymentRecordGuid: Guid
    amountUsd: number
    occurredAt: IsoDateString
    paymentMethod: PaymentMethod
    paymentRefunds: { amountUsd: number; occurredAt: IsoDateString }[]
    paymentStatuses: { paymentStatus: PaymentStatus }[]
  }[],
): InvoicePayment[] =>
  paymentRecords.map<InvoicePayment>(
    ({ paymentRecordGuid, amountUsd, occurredAt, paymentMethod, paymentRefunds, paymentStatuses }) => ({
      paymentRecordGuid,
      paidUsc: usdToUsCents(amountUsd),
      paymentMethod,
      occurredAt,
      status: paymentStatuses[0]?.paymentStatus ?? PaymentStatus.FAILED,
      refunds: paymentRefunds.map(({ amountUsd, occurredAt }) => ({
        amountUsc: usdToUsCents(amountUsd),
        occurredAt,
      })),
    }),
  )

export const convertToCommonDiscountsUsc = (invoiceDiscounts: InvoiceDiscount[]): CommonDiscountUsc[] => {
  return invoiceDiscounts.map(discount => {
    if (discount.type === DiscountType.FLAT) {
      return {
        type: discount.type,
        discountAmountUsc: discount.discountAmountUsc,
        discountRate: undefined,
      }
    } else {
      return {
        type: discount.type,
        discountAmountUsc: undefined,
        discountRate: discount.discountRate,
      }
    }
  })
}
export const convertToCommonDiscounts = (invoiceDiscounts: InvoiceDiscount[]): CommonDiscount[] => {
  return invoiceDiscounts.map(discount => {
    if (discount.type === DiscountType.FLAT) {
      return {
        type: discount.type,
        discountAmountUsd: usCentsToUsd(discount.discountAmountUsc),
        discountRate: undefined,
      }
    } else {
      return {
        type: discount.type,
        discountAmountUsc: undefined,
        discountRate: discount.discountRate,
      }
    }
  })
}

type InvoiceV2SendInvoiceRequest = ForCompanyUser<{
  paymentLink: string
  invoiceGuid: Guid
  deliveryMethod: 'EMAIL' | 'SMS'
}>

export type InvoiceV2SendInvoice = AsyncFn<InvoiceV2SendInvoiceRequest, void>

export const convertInvoiceCartItemToLineItem = ({
  cartItem,
}: {
  cartItem: {
    cartItemGuid: string
    name: string
    description?: HtmlString | undefined
    quantity: number
    unitPriceUsc: number
    isTaxable: boolean
    isDiscountable: boolean
    cartItemType: CartItemType
  }
}): CartItemUsc => ({
  itemGuid: cartItem.cartItemGuid,
  name: cartItem.name,
  description: cartItem.description ?? '',
  quantity: cartItem.quantity,
  unitPriceUsc: cartItem.unitPriceUsc,
  isTaxable: cartItem.isTaxable,
  isDiscountable: cartItem.isDiscountable,
  itemType: cartItem.cartItemType,
})

export const convertToInvoiceDiscounts = (
  discounts: {
    invoiceDiscountGuid: string
    discountType: DiscountType
    discountAmountUsc?: number | undefined
    discountRate?: number | undefined
    name: string
    descriptionHtml?: HtmlString | undefined
  }[],
): InvoiceDiscount[] => {
  return discounts.map(({ discountType, discountAmountUsc, discountRate, ...rest }) => {
    if (discountType === DiscountType.FLAT) {
      return {
        ...rest,
        descriptionHtml: rest.descriptionHtml ?? '',
        discountGuid: rest.invoiceDiscountGuid,
        type: discountType,
        discountAmountUsc: discountAmountUsc ?? 0,
        discountRate: undefined,
      }
    } else {
      return {
        ...rest,
        descriptionHtml: rest.descriptionHtml ?? '',
        discountGuid: rest.invoiceDiscountGuid,
        type: discountType,
        discountAmountUsc: undefined,
        discountRate: discountRate ?? 0,
      }
    }
  })
}

export const INVOICE_V2_FRIENDLY_DELIVERY_NAME: Record<'EMAIL' | 'SMS', string> = {
  EMAIL: 'Email',
  SMS: 'Text Message',
}

export const INVOICE_V2_FRIENDLY_FINANCING_APPLICATION_TYPE: Record<
  'LOAN_APPLICATION' | 'PREQUAL_APPLICATION',
  string
> = {
  LOAN_APPLICATION: 'loan',
  PREQUAL_APPLICATION: 'prequal',
}

export const isFinanceableAmountUsc = (amountUsc: number) => amountUsc > MIN_FINANCEABLE_AMOUNT_USC
export const FINANCEABLE_INVOICE_V2_STATUSES = ['DRAFT', 'OPEN']
export const isFinanceableInvoiceV2 = (invoice: { status: InvoiceV2Status; dueUsc: number }) =>
  FINANCEABLE_INVOICE_V2_STATUSES.includes(invoice.status) && isFinanceableAmountUsc(invoice.dueUsc)

export const isInvoiceFullyPaid = (invoice: { dueUsc: number; processingPaymentsUsc: number }) =>
  invoice.dueUsc <= 0 && invoice.processingPaymentsUsc <= 0

export const isProcessingFinalPayment = (invoice: { dueUsc: number; processingPaymentsUsc: number }) =>
  invoice.dueUsc <= 0 && invoice.processingPaymentsUsc > 0
