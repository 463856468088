import {
  DateTimeFormatter,
  ENGLISH_LOCALE,
  EquipmentOperationalStatus,
  formatEquipmentTypeString,
  InstalledEquipmentSummary,
  isNullish,
  LocalDate,
} from '@breezy/shared'
import { faCopy, faEllipsis } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown, MenuProps } from 'antd'
import classNames from 'classnames'
import { useCallback, useMemo } from 'react'
import { LabeledItemGrid } from 'src/adam-components/LabeledItemGrid'
import { SectionedCard } from 'src/adam-components/SectionedCard/SectionedCard'
import CopyToClipboard from 'src/elements/CopyToClipboard/CopyToClipboard'
import { EmDash } from 'src/elements/EmDash/EmDash'
import StatusTag from 'src/elements/StatusTag/StatusTag'

export interface EquipmentCardProps {
  // TODO: Refactor so this only takes in the minimal amount of data
  equipment: InstalledEquipmentSummary
  onTitleClick?: (equipment: InstalledEquipmentSummary) => void
  onEdit?: (equipment: InstalledEquipmentSummary) => void
  onReactivate?: (equipment: InstalledEquipmentSummary) => void
  onDeactivate?: (equipment: InstalledEquipmentSummary) => void
}

export const EquipmentCard = (props: EquipmentCardProps) => {
  const { equipment } = props

  const equipmentAge = useMemo(() => {
    if (!equipment.installationDate) {
      return 0
    }
    return Math.floor(
      equipment.installationDate.until(LocalDate.now()).toTotalMonths() / 12,
    )
  }, [equipment.installationDate])

  const dropdownItems = useMemo<NonNullable<MenuProps['items']>>(() => {
    const items: NonNullable<MenuProps['items']> = [
      { key: 'equipment-edit', label: 'Edit' },
    ]

    if (
      equipment.operationalStatus === EquipmentOperationalStatus.IN_SERVICE &&
      !isNullish(props.onDeactivate)
    ) {
      items.push({
        key: 'equipment-deactivate',
        label: <span className="text-bz-error">Deactivate</span>,
      })
    }

    if (
      equipment.operationalStatus !== EquipmentOperationalStatus.IN_SERVICE &&
      !isNullish(props.onReactivate)
    ) {
      items.push({
        key: 'equipment-reactivate',
        label: 'Reactivate',
      })
    }

    return items
  }, [equipment.operationalStatus, props.onDeactivate, props.onReactivate])

  const onDropdownClick: NonNullable<MenuProps['onClick']> = useCallback(
    ({ key }) => {
      switch (key) {
        case 'equipment-edit':
          props.onEdit?.(equipment)
          break
        case 'equipment-deactivate':
          props.onDeactivate?.(equipment)
          break
        case 'equipment-reactivate':
          props.onReactivate?.(equipment)
          break
        default:
          break
      }
    },
    [equipment, props],
  )

  return (
    <SectionedCard
      small
      dashed
      sections={[
        {
          verticalPaddingClassName: 'p-3',
          content: (
            <div className="flex flex-row items-center justify-between">
              {!isNullish(props.onTitleClick) ? (
                <span
                  className={classNames([
                    'w-full cursor-pointer truncate text-sm font-semibold ',
                    {
                      'text-bz-primary':
                        equipment.operationalStatus ===
                        EquipmentOperationalStatus.IN_SERVICE,
                      'text-bz-text-tertiary':
                        equipment.operationalStatus ===
                        EquipmentOperationalStatus.DEACTIVATED,
                    },
                  ])}
                  onClick={() => props.onTitleClick?.(equipment)}
                >
                  {formatEquipmentTypeString(equipment.equipmentType)}
                </span>
              ) : (
                <span className="w-full truncate text-sm font-semibold">
                  {formatEquipmentTypeString(equipment.equipmentType)}
                </span>
              )}

              <div className="flex flex-row items-center gap-2">
                {equipment.operationalStatus ===
                  EquipmentOperationalStatus.DEACTIVATED && (
                  <StatusTag color="darkGray" text="Deactivated" />
                )}

                <Dropdown
                  menu={{ items: dropdownItems, onClick: onDropdownClick }}
                >
                  <FontAwesomeIcon
                    icon={faEllipsis}
                    className="cursor-pointer"
                  />
                </Dropdown>
              </div>
            </div>
          ),
        },
        {
          content: (
            <div
              className={classNames([
                {
                  'text-bz-text-tertiary':
                    equipment.operationalStatus ===
                    EquipmentOperationalStatus.DEACTIVATED,
                },
              ])}
            >
              <LabeledItemGrid
                items={[
                  'Manufacturer',
                  equipment.manufacturer ?? <EmDash />,
                  'Installed On',
                  equipment.installationDate ? (
                    `${equipment.installationDate.format(
                      DateTimeFormatter.ofPattern('MMM d, yyyy').withLocale(
                        ENGLISH_LOCALE,
                      ),
                    )} (${equipmentAge} year${
                      equipmentAge === 1 ? '' : 's'
                    } old)`
                  ) : (
                    <EmDash />
                  ),
                  'Installed By',
                  equipment.installationParty ?? <EmDash />,
                  'Serial Number',
                  equipment.serialNumber ? (
                    <CopyToClipboard
                      payload={equipment.serialNumber}
                      label={
                        <span className="flex items-center justify-end gap-2">
                          {equipment.serialNumber}
                          <FontAwesomeIcon icon={faCopy} />
                        </span>
                      }
                    />
                  ) : (
                    <EmDash />
                  ),
                  'Model Number',
                  equipment.modelNumber ? (
                    <CopyToClipboard
                      payload={equipment.modelNumber}
                      label={
                        <span className="flex items-center justify-end gap-2">
                          {equipment.modelNumber}
                          <FontAwesomeIcon icon={faCopy} />
                        </span>
                      }
                    />
                  ) : (
                    <EmDash />
                  ),
                  'Est. End of Life',
                  equipment.estimatedEndOfLifeDate ? (
                    equipment.estimatedEndOfLifeDate.format(
                      DateTimeFormatter.ofPattern('MMM d, yyyy').withLocale(
                        ENGLISH_LOCALE,
                      ),
                    )
                  ) : (
                    <EmDash />
                  ),
                  'Avg. Life Expectancy',
                  equipment.averageLifeExpectancyYears ?? <EmDash />,
                ]}
              />
            </div>
          ),
        },
      ]}
    />
  )
}
