import { SectionedCard } from 'src/adam-components/SectionedCard/SectionedCard'
import { useWisetackEnabled } from '../../providers/CompanyFinancialConfigWrapper'
import { AccountWrapper, Editable } from './accountDetailsV2Utils'
import { AccountEstimatesCollapsible } from './collapsibles/AccountEstimatesCollapsible'
import { AccountInvoicesCollapsible } from './collapsibles/AccountInvoicesCollapsible'
import { AccountJobsCollapsible } from './collapsibles/AccountJobsCollapsible'
import { AccountMaintenancePlansCollapsible } from './collapsibles/AccountMaintenancePlansCollapsible'
import { AccountPaymentsCollapsible } from './collapsibles/AccountPaymentsCollapsible'
import { AccountVisitsCollapsible } from './collapsibles/AccountVisitsCollapsible'
import { AccountWisetackFinancingCollapsible } from './collapsibles/AccountWisetackFinancingCollapsible'

export const AccountDetailsRightSidePane = ({
  meta,
  editable = true,
}: Editable<AccountWrapper>) => {
  const { accountGuid } = meta

  const wisetackEnabled = useWisetackEnabled()

  return (
    <SectionedCard
      sections={[
        {
          content: (
            <>
              <AccountMaintenancePlansCollapsible
                accountGuid={accountGuid}
                className="pt-0"
              />

              <AccountJobsCollapsible
                accountGuid={accountGuid}
                editable={editable}
              />

              <AccountVisitsCollapsible
                accountGuid={accountGuid}
                editable={editable}
              />

              {wisetackEnabled && (
                <AccountWisetackFinancingCollapsible
                  accountGuid={accountGuid}
                />
              )}

              <AccountPaymentsCollapsible accountGuid={accountGuid} />

              <AccountInvoicesCollapsible accountGuid={accountGuid} />

              <AccountEstimatesCollapsible accountGuid={accountGuid} />
            </>
          ),
        },
      ]}
    />
  )
}
