import '@js-joda/timezone'

import { BusinessResourceDoesNotExistException, MoreThanOneResourceFoundException } from './errors/BusinessException'
import { ThisShouldNeverHappenError } from './errors/PlatformErrors'
import { Log } from './logging'
import { isNullish } from './type-utils'

export * from '@js-joda/core'
export * from './Alert'
export * from './application-tasks'
export * from './async-fn-adapters'
export { AuthServiceCachedTokenValidation, type AuthService, type AuthToken, type AuthTokenGenerator } from './auth'
export * from './BzDateFns'
export * from './collection-utils'
export * from './csv-utils'
export * from './date-fns-generate-config'
export * as dates from './date-utils'
export * from './DayOfTheWeek'
export * from './dfns'
export * from './DurationLogging'
export * as durationMs from './durations-ms'
export * as emailUtils from './email-utils'
export { getErrorSeverity, isBreezyError, type BreezyError } from './errors/BreezyError'
export * from './errors/BreezyErrorSeverity'
export * from './errors/BusinessException'
export * from './errors/IntegrationException'
export * from './errors/PlatformErrors'
export * from './event-store'
export * from './file-utils'
export * from './functional-adapters'
export * from './functional-core'
export * from './fuzzy-match'
export * from './graphql-helpers'
export * from './hasura-utils'
export * from './html-to-pdf'
export { RandomNumberGenerator } from './Ids/RandomNumberGenerator'
export { ReferenceNumberGenerator } from './Ids/ReferenceNumberGenerator'
export * from './in-memory-cache'
export * from './InMemorySearch/BTree'
export * from './json-compare'
export * from './keyboard-utils'
export * from './locales'
export * from './locks'
export * from './lodash-helpers'
export * from './logging'
export * from './mergeLists'
export * from './metrics'
export * from './money-utils'
export * from './number-utils'
export * from './path-utils'
export * as phoneUtils from './phone-utils'
export * from './promise-utils'
export * from './query-string'
export * from './ramda'
export * from './reporting'
export * from './rrule'
export * from './sample-data'
export * from './string-utils'
export * from './timing-utils'
export * from './type-utils'
export * from './upload'
export * from './url-minifier/url-minifier'
export * from './uuid-utils'
export const IMPERSONATION_HEADER_KEY = 'X-BRZ-IMPERSONATE-USER-GUID'

// [X] Audited in BZ-921 -> No Action Required immediately
/* TODO: Once all PRs are merged, I'm thinking we should make 'valueName' required and perhaps 'errorMessage' too.
This will help a TON with understanding the thinking of the other developers when one encounters an unexpected scenario */
export const bzExpect = <T>(t: T | null | undefined | void, valueName?: string, errorMsg?: string): T => {
  if (isNullish(t)) {
    let msg = `Expected '${valueName ?? 'value'}' to be set but found '${t}'.`

    if (errorMsg) {
      msg += ` ${errorMsg}`
    }

    throw new Error(msg)
  }

  return t
}

export const bzExpectExactlyOne = <T>(tArray: T[]): T => {
  if (tArray.length === 0) {
    throw new BusinessResourceDoesNotExistException(`Expected 1 but found 0`)
  }

  if (tArray.length > 1) {
    throw new MoreThanOneResourceFoundException(`Expected 1 but found ${tArray.length}`)
  }

  return tArray[0]
}

type UrlComponents =
  | {
      scheme: string
      host: string
      port?: string | number | null
      path?: string | null
    }
  | {
      baseUrl: string
      path?: string | null
    }

type UrlBuildingOptions = {
  withTrailingSlash?: boolean
}

const DEFAULT_URL_BUILDING_OPTIONS: UrlBuildingOptions = {
  withTrailingSlash: false,
}

export const buildUrl = (urlComponents: UrlComponents, options?: UrlBuildingOptions) => {
  const effectiveOptions = {
    ...DEFAULT_URL_BUILDING_OPTIONS,
    ...(options || {}),
  }

  let url: string

  if ('scheme' in urlComponents) {
    const { scheme, host, port } = urlComponents
    url = `${scheme}://${host}`

    url = port ? `${url}:${port}` : url
  } else {
    url = urlComponents.baseUrl
  }

  const { path } = urlComponents

  if (path) {
    const trimmedPath = path.startsWith('/') ? path.substring(1) : path
    url = url.endsWith('/') ? url.slice(0, url.length - 1) : url
    url = `${url}/${trimmedPath}`
  }

  if (effectiveOptions.withTrailingSlash && !url.endsWith('/')) {
    url = url + '/'
  }

  return url
}

export const isPromise = <T = unknown>(u: unknown): u is Promise<T> => {
  if (typeof u === 'object' && !isNullish(u) && 'then' in u && 'catch' in u) {
    const thenKey = 'then' as keyof typeof u
    const catchKey = 'catch' as keyof typeof u
    return typeof u[thenKey] === 'function' && typeof u[catchKey] === 'function'
  }

  return false
}

export const noOp = () => {}
export const asyncNoOp = async () => {}

export const prettyJson = (thing: unknown) => {
  return JSON.stringify(thing, null, 2)
}

// Retry function with exponential backoff and jitter
export const retryOnError = async <T>(
  fn: () => Promise<T>,
  options?: { retries?: number; baseDelay?: number; verbose?: boolean; messagePrefix?: string },
): Promise<T> => {
  const { retries = 3, baseDelay = 1000, verbose = true, messagePrefix = '' } = options ?? {}

  if (retries < 0) {
    throw new Error('You cannot have a negative number of retries.')
  }
  let attempt = 0

  while (attempt <= retries) {
    try {
      return await fn()
    } catch (err) {
      if (attempt === retries) {
        throw err // Exceeded retries, throw the error
      }
      // Exponential backoff with jitter
      const delay = baseDelay * Math.pow(2, attempt)
      const jitter = Math.random() * delay
      const finalDelay = delay + jitter

      if (verbose) {
        Log.warn(
          `${messagePrefix ? `${messagePrefix} - ` : ''}Attempt ${attempt + 1} failed. Retrying in ${finalDelay.toFixed(
            0,
          )}ms...`,
        )
      }
      await new Promise(res => setTimeout(res, finalDelay))
      attempt++
    }
  }

  // If retries is negative, we throw an error above. Thus, `attempt <= retries` will always be true on the first
  // iteration, so the loop will always enter. Inside the loop, we run our function. In the `try/catch`, if we're out of
  // retries then we throw the error we got. Otherwise we increment the attempt count. Since it happens once, attempt is
  // always incrementally increasing, and retries doesn't change, if the function repeatedly throws, eventually the two
  // numbers will equal and we'll throw the error it got. So there shouldn't be a way for `attempt <= retries` to be
  // false. We could make it `while(true)` if we wanted and it would logically be the same thing.
  throw new ThisShouldNeverHappenError("We somehow exited the retry loop, which shouldn't be possible")
}
