import { gql } from '../../generated'

export const JOB_QUERY = gql(/* GraphQL */ `
  query EditJobModalJobQuery($companyGuid: uuid!, $jobGuid: uuid!) {
    jobs(
      where: { companyGuid: { _eq: $companyGuid }, jobGuid: { _eq: $jobGuid } }
    ) {
      jobGuid
      displayId
      locationGuid
      accountGuid
      businessUnit {
        businessUnitGuid
        name
      }
      jobType {
        ...JobType
      }
      installProjectType
      summary
      pointOfContactGuid
      isOpportunity
      isHotLead
      isMembershipOpportunity
      isMembershipRenewalOpportunity
      workCompletedAt
      equipmentTypeJobLinks {
        equipmentType
        estimatedEquipmentAge
        relationshipType
      }
      tags {
        tagGuid
      }
      useMaintenancePlanCredit
      customerPurchaseOrderNumber
      location {
        maintenancePlans {
          maintenancePlanGuid
          maintenancePlanVisits {
            ...MaintenancePlanVisit
          }
        }
      }
    }
    companyLeadSourceLinks(where: { linkedJobGuid: { _eq: $jobGuid } }) {
      companyLeadSourceGuid
      referringContactGuid
      attributionDescription
    }
  }
`)
