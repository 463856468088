import {
  AccountingIntegrationType,
  BzDateFns,
  IsoDateString,
  mapStringToFriendlyAccountingName,
} from '@breezy/shared'
import {
  faCheck,
  faExclamation,
  faSync,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Tooltip } from 'antd'
import classNames from 'classnames'
import React, { useMemo, useState } from 'react'
import { OnsitePageSection } from '../../../adam-components/OnsitePage/OnsitePageSection'
import { OnsitePageSimpleSectionItem } from '../../../adam-components/OnsitePage/OnsitePageSimpleSectionItem'
import { useAccountingSyncLoading } from '../../../hooks/useAccountingSyncLoading'
import useIsMobile from '../../../hooks/useIsMobile'
import { useSyncInvoice } from '../../../hooks/useSyncInvoice'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import { useStrictContext } from '../../../utils/react-utils'
import { InvoiceContext } from '../invoiceUtils'

const logoUrls = {
  QBO: 'https://d3j5nzmmhjc8v6.cloudfront.net/quickbooks_logo.png',
  QBD: 'https://d3j5nzmmhjc8v6.cloudfront.net/quickbooks_logo.png',
}

type InvoiceAccountingSectionProps = {
  accountingIntegrationType: AccountingIntegrationType
  invoiceGuid: string
  isStale: boolean
  lastSyncedAt?: IsoDateString
  lastPaymentAt?: IsoDateString
  invoiceIssuedAt?: IsoDateString
  lastUpdatedAt?: IsoDateString
  isFullyPaid?: boolean
  isVoided?: boolean
}

export const InvoiceAccountingSection =
  React.memo<InvoiceAccountingSectionProps>(
    ({
      accountingIntegrationType,
      invoiceGuid,
      isStale,
      lastSyncedAt,
      lastPaymentAt,
      invoiceIssuedAt,
      lastUpdatedAt,
      isFullyPaid,
      isVoided,
    }) => {
      const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)

      const { onSyncClick, loading: manualSyncLoading } = useSyncInvoice({
        accountingIntegrationType,
        invoiceGuid,
        onSuccess: () => {
          setErrorMsg(undefined)
        },
        onError: setErrorMsg,
      })

      return (
        <InvoiceAccountingSectionStateless
          accountingIntegrationType={accountingIntegrationType}
          invoiceGuid={invoiceGuid}
          isStale={isStale}
          lastSyncedAt={lastSyncedAt}
          lastPaymentAt={lastPaymentAt}
          invoiceIssuedAt={invoiceIssuedAt}
          lastUpdatedAt={lastUpdatedAt}
          isFullyPaid={isFullyPaid}
          onSyncClick={async () => {
            await onSyncClick(false)
          }}
          isLoading={manualSyncLoading}
          errorMsg={errorMsg}
          isVoided={isVoided}
        />
      )
    },
  )

type InvoiceAccountingSectionStatelessProps = {
  accountingIntegrationType: AccountingIntegrationType
  invoiceGuid: string
  isStale: boolean
  lastSyncedAt?: IsoDateString
  lastPaymentAt?: IsoDateString
  invoiceIssuedAt?: IsoDateString
  isFullyPaid?: boolean
  lastUpdatedAt?: IsoDateString
  onSyncClick: () => Promise<void>
  isLoading: boolean
  errorMsg?: string
  isVoided?: boolean
}

export const InvoiceAccountingSectionStateless =
  React.memo<InvoiceAccountingSectionStatelessProps>(
    ({
      accountingIntegrationType,
      isStale,
      lastSyncedAt,
      lastPaymentAt,
      invoiceIssuedAt,
      lastUpdatedAt,
      isFullyPaid,
      onSyncClick,
      isLoading,
      errorMsg,
      isVoided,
    }) => {
      const tzId = useExpectedCompanyTimeZoneId()
      const { accountingAutoSyncSettings } = useStrictContext(InvoiceContext)
      const isMobile = useIsMobile()

      const loading = useAccountingSyncLoading({
        manualSyncLoading: isLoading,
        issuedAt: invoiceIssuedAt,
        lastPaymentAt,
        lastUpdatedAt,
        isFullyPaid,
        isVoided,
        accountingAutoSyncSettings,
      })

      const warningMessage = useMemo(() => {
        if (errorMsg) {
          return isMobile ? 'Failed' : 'Sync Failed'
        }
        return isMobile ? 'Error' : 'Not synced'
      }, [isMobile, errorMsg])

      const warningInfo = useMemo(() => {
        if (errorMsg) {
          return errorMsg
        }
        if (isStale) {
          return `The invoice is out of sync with ${accountingIntegrationType}.`
        }
      }, [isStale, errorMsg, accountingIntegrationType])

      const syncButton = useMemo(
        () => (
          <Button
            className={classNames({
              'mt-2 w-fit': isMobile,
            })}
            type="primary"
            size="large"
            onClick={() => onSyncClick()}
            loading={loading}
            icon={<FontAwesomeIcon icon={faSync} />}
          >
            Sync now
          </Button>
        ),
        [isMobile, loading, onSyncClick],
      )

      if (accountingIntegrationType === 'NONE') {
        return null
      }

      return (
        <OnsitePageSection hideBottomBorder>
          <OnsitePageSimpleSectionItem
            theme={isStale ? 'warning' : undefined}
            layoutClassName={
              isMobile
                ? `${isStale ? 'items-start' : 'items-center'} gap-3`
                : undefined
            }
            icon={
              <img
                alt={`Sync with ${accountingIntegrationType}`}
                src={logoUrls[accountingIntegrationType]}
                className="absolute inset-0"
              />
            }
            rightContent={
              !isStale ? (
                <div className="bz-text-800 flex h-full flex-row items-center space-x-2 text-sm font-semibold">
                  <div>Synced</div>
                  <div className="flex h-5 w-5 items-center justify-center rounded-full bg-bz-green-700 text-xs text-white">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                </div>
              ) : (
                <div className="flex flex-row items-center justify-center gap-3">
                  <WarningStatus message={warningMessage} info={warningInfo} />
                  {!isMobile && syncButton}
                </div>
              )
            }
            footer={isMobile && isStale && syncButton}
            subContent={
              <span className="text-bz-text-secondary">
                Last sync:{' '}
                {`${
                  lastSyncedAt
                    ? BzDateFns.formatFromISO(
                        lastSyncedAt,
                        'MMM. d, yyyy',
                        tzId,
                      )
                    : 'Never'
                }`}
              </span>
            }
          >
            {mapStringToFriendlyAccountingName(accountingIntegrationType)}
          </OnsitePageSimpleSectionItem>
        </OnsitePageSection>
      )
    },
  )

type WarningStatusProps = {
  message: string
  info?: string
}

const WarningStatus = React.memo(({ message, info }: WarningStatusProps) => {
  return (
    <div className="flex min-h-9 flex-row items-center justify-center gap-2">
      <div className="text-sm font-semibold text-bz-orange-700">{message}</div>
      <Tooltip title={info}>
        <div className="flex h-4 w-4 items-center justify-center rounded-full bg-bz-orange-700  text-xs font-semibold text-white">
          <FontAwesomeIcon icon={faExclamation} />
        </div>
      </Tooltip>
    </div>
  )
})
