import {
  BzDateTime,
  LocalDateString,
  MaintenancePlanPaymentInterval,
  TimeZoneId,
  bzOptional,
  getLocalDateForMonth,
  guidSchema,
  isNullish,
  localDateSchema,
  visitRequestSchema,
} from '@breezy/shared'
import { z } from 'zod'

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
] as const

export const MonthJsNumbers: Record<string, number> = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
}

export const getAffinityDateOptions = (
  fromActivationDate: LocalDateString,
  tzId: TimeZoneId,
  numYears: number,
): LocalDateString[] => {
  const activationDate = BzDateTime.fromDateString(fromActivationDate, tzId)
  const activationMonth = activationDate.toDate().getMonth()
  const currentYear = activationDate.toDate().getFullYear()
  const options: LocalDateString[] = []
  for (let year = 0; year < numYears; year++) {
    for (let i = 0; i < 12; i++) {
      const month = (activationMonth + i) % 12
      options.push(
        getLocalDateForMonth(
          month,
          month < activationMonth ? currentYear + year + 1 : currentYear + year,
        ),
      )
    }
  }
  return options
}

export const configureMaintenancePlanFormSchema = z.object({
  accountGuid: guidSchema,
  locationGuid: guidSchema,

  businessUnitGuid: bzOptional(guidSchema),

  isImportedPlan: z.boolean().default(false),
  isFreePlan: z.boolean().default(false),
  isAutoRenewing: z.boolean().default(true),
  billingAnchorDate: bzOptional(localDateSchema),
  activationDate: localDateSchema,
  shouldSendActivationEmail: z.boolean().default(true),

  taxRate: z.object({
    taxRateGuid: guidSchema.nullable(),
    rate: z.number().min(0).max(1),
  }),

  billingPaymentInterval: z.nativeEnum(MaintenancePlanPaymentInterval),
  numFixedDurationYears: bzOptional(z.number()),

  // NOTE: These two get collapsed into just the Subtotal during Form Submission
  subtotalYearlyPriceUsc: z.number().min(0),
  adjustmentYearlyPriceUsc: z.number(),

  visits: z.array(visitRequestSchema).refine(
    visits => {
      // Ensure that there are no duplicate jobGuids in the visits
      const jobGuids = visits
        .map(visit => visit.jobGuid)
        .filter(j => !isNullish(j))

      return new Set(jobGuids).size === jobGuids.length
    },
    {
      message: 'Visits must not have duplicate linked jobs',
    },
  ),

  // NOTE: This is an intermediate value for connecting with the form
  // We type this as a string so its compatible with our BzSelect component, but it should represent a valid number
  billingAnchorDay: z.string().refine(val => {
    const parsedNumber = Number(val)
    return !isNaN(parsedNumber) && parsedNumber >= 1 && parsedNumber <= 31
  }),
})

export type ConfigureMaintenancePlanFormData = z.infer<
  typeof configureMaintenancePlanFormSchema
>
