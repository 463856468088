import { z } from 'zod'
import { CartItemType, guidSchema, isoDateStringSchema } from '../..'
import { AsyncFn, IsoDateString, TaskGuid } from '../../common'
import { bzOptional, Guid, GuidContainer, GuidsCollectionContainer } from '../common-schemas'
import { CompanyGuid, CompanyGuidContainer, ForCompany } from '../Company/Company'

export type AccountingSyncTask = {
  taskGuid: TaskGuid
  companyGuid: CompanyGuid
  entityType: string
  entityGuid: Guid
  createdAt: IsoDateString
  startedAt?: IsoDateString
}

export const accountingSyncInfoSchema = z.object({
  companyGuid: guidSchema,
  accountingIntegrationType: z.string(),
  breezyEntityType: z.string(),
  breezyEntityGuid: guidSchema,
  externalEntityType: z.string(),
  externalEntityId: z.string(),
  externalEntitySyncKey: bzOptional(z.string()),
  lastSyncedAt: isoDateStringSchema,
})

export type AccountingSyncDeferred = {
  type: 'deferred'
  companyGuid: CompanyGuid
  breezyEntityType: string
  breezyEntityGuid: Guid
}

export type AccountingSyncResponse = AccountingSyncInfo | AccountingSyncDeferred

export type AccountingSyncInfo = z.infer<typeof accountingSyncInfoSchema>
export type AccountingSync<T> = AsyncFn<ForCompany<T>, Required<AccountingSyncResponse>>
export type AccountingSyncAsync<T> = AsyncFn<ForCompany<T>>
export type AccountingSyncInfoWriter = AsyncFn<AccountingSyncInfo>

export type AccountingSyncInfosRequest = {
  companyGuid: CompanyGuid
  breezyGuids: Guid[]
  accountingIntegrationType: AccountingIntegrationType
}
export type AccountingSyncInfosReader = AsyncFn<AccountingSyncInfosRequest, AccountingSyncInfo[]>

export const syncAccountRequestSchema = z.object({
  accountGuid: guidSchema,
  force: bzOptional(z.boolean()),
})

export type SyncAccountRequest = z.infer<typeof syncAccountRequestSchema>

export const syncInvoiceRequestSchema = z.object({
  invoiceGuid: guidSchema,
  userGuid: bzOptional(guidSchema),
  force: bzOptional(z.boolean()),
})
export type SyncInvoiceRequest = z.infer<typeof syncInvoiceRequestSchema>
export type InvoiceSyncAsync = AsyncFn<ForCompany<SyncInvoiceRequest>>

export const syncPaymentRecordRequestSchema = z.object({
  paymentRecordGuid: guidSchema,
  force: bzOptional(z.boolean()),
})
export type SyncPaymentRecordRequest = z.infer<typeof syncPaymentRecordRequestSchema>

export const syncPayoutRequestSchema = z.object({
  payoutGuid: guidSchema,
  force: bzOptional(z.boolean()),
})
export type SyncPayoutRequest = z.infer<typeof syncPayoutRequestSchema>

export type AccountingSyncDataWriter = AsyncFn<AccountingSyncInfo>
export type AccountingSyncDataReader = AsyncFn<ForCompany<GuidContainer>, AccountingSyncInfo>
export type AccountingSyncDataMultiReader = AsyncFn<
  ForCompany<GuidsCollectionContainer>,
  Record<Guid, AccountingSyncInfo>
>

export interface IAccountingSyncService {
  isOnline: AsyncFn<CompanyGuidContainer, boolean>
  queryAccounts: AccountingGeneralLedgerAccountReader
  syncAccount: AccountingSync<SyncAccountRequest>
  syncInvoice: AccountingSync<SyncInvoiceRequest>
  syncPayout: AccountingSync<SyncPayoutRequest>
}

export type AccountingIntegrationType = 'QBO' | 'QBD' | 'NONE'

export const AccountingIntegrationTypeFriendlyNames: Record<AccountingIntegrationType, string> = {
  QBO: 'QuickBooks Online',
  QBD: 'QuickBooks Desktop',
  NONE: 'None',
}

export type CartItemTypeAccountingAccountIdMapping = Record<CartItemType, string>
export type CartItemTypeAccountingAccountIdMappingReader = AsyncFn<
  CompanyGuidContainer,
  CartItemTypeAccountingAccountIdMapping
>

export const PlaceholderAccountingAccountIdMappingReader: CartItemTypeAccountingAccountIdMappingReader = async ({
  companyGuid,
}) => ({
  [CartItemType.SERVICE]: '1234',
  [CartItemType.LABOR]: '1235',
  [CartItemType.CREDIT]: '1236',
  [CartItemType.MEMBERSHIP]: '1237',
  [CartItemType.MATERIAL]: '1238',
  [CartItemType.EQUIPMENT]: '1239',
  [CartItemType.UNKNOWN]: '1240',
})

export type AccountingGeneralLedgerAccount = {
  name: string
  id: string
  parentId?: string
  accountType: string
}

export type AccountingGeneralLedgerAccountReader = AsyncFn<CompanyGuidContainer, AccountingGeneralLedgerAccount[]>

export const accountingCartItemTypeAccountsConfigSchema = z.object({
  defaultItemIncomeAccountId: z.string().nonempty(),
  serviceItemDefaultIncomeAccountId: bzOptional(z.string().nonempty()),
  materialItemDefaultIncomeAccountId: bzOptional(z.string().nonempty()),
  equipmentItemDefaultIncomeAccountId: bzOptional(z.string().nonempty()),
  laborItemDefaultIncomeAccountId: bzOptional(z.string().nonempty()),
  membershipItemDefaultIncomeAccountId: bzOptional(z.string().nonempty()),
})

export type AccountingCartItemTypeAccountsConfig = z.infer<typeof accountingCartItemTypeAccountsConfigSchema>

export type AccountingCompanyConfig = {
  accountingIntegrationType: AccountingIntegrationType
  autosyncInvoiceOnFullyPaid: boolean
  autosyncInvoiceOnIssued: boolean
  autosyncInvoiceOnPayment: boolean
  autosyncInvoiceOnVoided: boolean
  autosyncPayouts: boolean
}
export type AccountingCompanyConfigReader = AsyncFn<CompanyGuidContainer, AccountingCompanyConfig>

export const mapStringToAccountingIntegrationType = (s: string | undefined): AccountingIntegrationType => {
  const lowerCase = s?.toLowerCase() ?? ''
  if (lowerCase === 'qbo' || lowerCase === 'quickbooks-online' || lowerCase === 'quickbooksonline') {
    return 'QBO'
  }
  if (lowerCase === 'qbd' || lowerCase === 'quickbooks-desktop' || lowerCase === 'quickbooksdesktop') {
    return 'QBD'
  }
  return 'NONE'
}

export const mapStringToFriendlyAccountingName = (s: string | undefined): string => {
  return AccountingIntegrationTypeFriendlyNames[mapStringToAccountingIntegrationType(s)]
}

export type AccountingPayoutAccounts = {
  accountingIntegrationType: AccountingIntegrationType
  payoutDepositAccountId: string
  payoutFeesAccountId: string
  payoutPaymentProcessorBalanceAccountId: string
  payoutRefundsAccountId: string
}

export type AccountingPayoutAccountsIntegrationRequest = {
  accountingIntegrationType: AccountingIntegrationType
  companyGuid: CompanyGuid
}

export type AccountingPayoutAccountsReader = AsyncFn<
  AccountingPayoutAccountsIntegrationRequest,
  AccountingPayoutAccounts | undefined
>
