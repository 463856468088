import {
  bzExpect,
  DayOfTheWeek,
  nextGuid,
  ONLINE_BOOKING_EARLIEST_AVAILABLE_TIMES,
  ONLINE_BOOKING_LATEST_AVAILABLE_TIMES,
  ONLINE_BOOKING_TYPES,
  OnlineBookingServiceTypeConfigDescriptions,
  OnlineBookingServiceTypeDisplayNames,
  OnlineBookingServiceTypeSettingsConfig,
  OnlineBookingServiceTypeToJobClasses,
  OnlineBookingTypeDisplayNames,
  toRoleDisplayName,
} from '@breezy/shared'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Form } from 'antd'
import React, { useCallback, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useMutation } from 'urql'
import { CloseConfirmModal } from '../../adam-components/OnsiteModal/useCloseConfirmModal'
import { useGoBack } from '../../adam-components/OnsitePage/onsitePageUtils'
import { CategorySection } from '../../components/Settings/SettingsBuilderComponents'
import { FormDescription } from '../../elements/Forms/FormDescription'
import { RadioButtonField } from '../../elements/Forms/RadioButtonField'
import { ReactHookFormItem } from '../../elements/Forms/ReactHookFormItem'
import { SelectField } from '../../elements/Forms/SelectField'
import { TextAreaField } from '../../elements/Forms/TextAreaField'
import { useDirtyingSetValue } from '../../elements/Forms/useDirtyingSetValue'
import { useReactHookFormSubmit } from '../../elements/Forms/useReactHookFormSubmit'
import ThinDivider from '../../elements/ThinDivider'
import { BlockerFunction, useBlocker } from '../../providers/BlockerWrapper'
import {
  useExpectedCompanyGuid,
  useExpectedUserGuid,
} from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import { ONLINE_BOOKING_SERVICE_TYPE_CONFIG_UPSERT } from './OnlineBookingEditServiceTypePage.gql'
import { OnlineBookingSectionHeader } from './OnlineBookingSectionHeader'
import { OnlineBookingWeeklyScheduleAvailability } from './OnlineBookingWeeklyScheduleAvailability'
import {
  CompanyArrivalWindowOption,
  CompanyJobType,
  CompanyTechnician,
  convertInstantBookingWeeklyScheduleToFormData,
  OnlineBookingEditServiceTypeForm,
  OnlineBookingEditServiceTypeFormSchema,
  useServiceTypeArrivalWindowOptions,
} from './utils'
import { WithOnlineBookingServiceTypeConfig } from './WithOnlineBookingServiceTypeConfig'

export type OnlineBookingEditServiceTypeProps =
  OnlineBookingServiceTypeSettingsConfig & {
    companyJobTypes: CompanyJobType[]
    companyTechnicians: CompanyTechnician[]
    companyArrivalWindowOptions: CompanyArrivalWindowOption[]
  }

const OnlineBookingEditServiceType =
  React.memo<OnlineBookingEditServiceTypeProps>(
    ({
      onlineBookingServiceTypeGuid,
      serviceType,
      bookingType = 'REQUEST',
      bookableJobTypeGuids = [],
      bookableTechnicianGuids = [],
      legalBlurb = '',
      earliestAvailability = '1 day',
      latestAvailability = '60 days',
      companyJobTypes,
      companyTechnicians,
      companyArrivalWindowOptions,
      instantBookingWeeklySchedule,
    }) => {
      const companyGuid = useExpectedCompanyGuid()
      const userGuid = useExpectedUserGuid()
      const message = useMessage()
      const [{ fetching: submitting }, executeMutation] = useMutation(
        ONLINE_BOOKING_SERVICE_TYPE_CONFIG_UPSERT,
      )
      const onlineBookingEditServiceTypeForm = useForm({
        resolver: zodResolver(OnlineBookingEditServiceTypeFormSchema),
        defaultValues: {
          bookingType,
          bookableJobTypeGuids,
          bookableTechnicianGuids,
          legalBlurb,
          earliestAvailability,
          latestAvailability,
          instantBookingWeeklySchedule:
            convertInstantBookingWeeklyScheduleToFormData(
              instantBookingWeeklySchedule,
            ),
        },
      })

      const arrivalWindowOptions = useServiceTypeArrivalWindowOptions(
        serviceType,
        companyArrivalWindowOptions,
      )

      const {
        control,
        handleSubmit,
        formState: { isDirty, errors },
        watch,
        setValue: rawSetValue,
        reset,
      } = onlineBookingEditServiceTypeForm

      const setValue = useDirtyingSetValue(rawSetValue)

      const selectedBookingType = watch('bookingType')
      const isInstantBookingType = useMemo(
        () => selectedBookingType === 'INSTANT',
        [selectedBookingType],
      )

      const [submitElement, triggerSubmit] = useReactHookFormSubmit()
      const onSubmit = useCallback(
        async (data: OnlineBookingEditServiceTypeForm) => {
          try {
            await executeMutation({
              onlineBookingServiceTypeGuid,
              config: {
                onlineBookingServiceTypeGuid,
                companyGuid,
                serviceType,
                bookingType: data.bookingType,
                bookableJobTypes: {
                  data: data.bookableJobTypeGuids.map(jobTypeGuid => ({
                    jobTypeGuid,
                    companyGuid,
                  })),
                },
                bookableTechnicians: {
                  data: data.bookableTechnicianGuids.map(technicianGuid => ({
                    technicianGuid,
                    companyGuid,
                  })),
                },
                earliestAvailability:
                  data.bookingType === 'INSTANT'
                    ? data.earliestAvailability
                    : undefined,
                latestAvailability:
                  data.bookingType === 'INSTANT'
                    ? data.latestAvailability
                    : undefined,
                instantBookingWeeklyScheduleEntries:
                  data.bookingType === 'INSTANT'
                    ? {
                        data: Object.entries(
                          data.instantBookingWeeklySchedule,
                        ).map(([day, daySchedule]) => ({
                          instantBookingWeeklyScheduleEntryGuid: nextGuid(),
                          companyGuid,
                          dayOfWeek: day as DayOfTheWeek,
                          isEnabled: daySchedule.enabled,
                          createdByUserGuid: userGuid,
                          bookableArrivalWindows: {
                            data: daySchedule.arrivalWindowGuids.map(
                              arrivalWindowGuid => ({
                                companyAppointmentArrivalWindowGuid:
                                  arrivalWindowGuid,
                                companyGuid,
                              }),
                            ),
                          },
                        })),
                      }
                    : undefined,
                legalBlurb: data.legalBlurb,
              },
              onConflict: {
                constraint: 'online_booking_service_type_configs_pkey',
                updateColumns: [
                  'bookingType',
                  'legalBlurb',
                  'earliestAvailability',
                  'latestAvailability',
                ],
              },
            })
            message.success('Online Booking configuration saved')
            reset(data)
          } catch (e) {
            console.error(e)
            message.error('Error saving Online Booking configuration')
          }
        },
        [
          companyGuid,
          executeMutation,
          message,
          onlineBookingServiceTypeGuid,
          reset,
          serviceType,
          userGuid,
        ],
      )

      const onlineBookingJobTypeOptions = useMemo(() => {
        return companyJobTypes
          .filter(jobType =>
            OnlineBookingServiceTypeToJobClasses[serviceType].includes(
              jobType.jobClass,
            ),
          )
          .map(jobType => ({
            label: jobType.displayName,
            value: jobType.jobTypeGuid,
          }))
      }, [companyJobTypes, serviceType])

      const onlineBookingTechnicianOptions = useMemo(() => {
        return companyTechnicians.map(technician => ({
          label: `${technician.fullName} (${technician.roles
            .map(role => toRoleDisplayName(role))
            .join(', ')})`,
          value: technician.technicianGuid,
        }))
      }, [companyTechnicians])

      const goBack = useGoBack()

      const shouldBlock = useCallback<BlockerFunction>(() => {
        return isDirty
      }, [isDirty])

      const blocker = useBlocker(
        'OnlineBookingEditServiceTypePage',
        shouldBlock,
      )
      return (
        <div className="flex min-h-0 flex-col gap-y-6">
          <div className="flex">
            <Button
              className="-ml-4 flex items-center text-sm font-semibold leading-[22px] text-bz-primary"
              type="text"
              onClick={goBack}
              icon={
                <FontAwesomeIcon
                  className="h-5 w-5 text-bz-primary"
                  icon={faArrowLeft}
                />
              }
            >
              Back to configuration
            </Button>
          </div>
          <div className="flex items-center justify-between">
            <OnlineBookingSectionHeader
              title={OnlineBookingServiceTypeDisplayNames[serviceType]}
              description={
                OnlineBookingServiceTypeConfigDescriptions[serviceType]
              }
            />
            <div className="flex justify-end gap-2">
              <Button onClick={goBack} disabled={!isDirty || submitting}>
                Cancel
              </Button>
              <Button
                type="primary"
                disabled={!isDirty || submitting}
                onClick={triggerSubmit}
                loading={submitting}
              >
                Save Changes
              </Button>
            </div>
          </div>
          <ThinDivider
            widthPx={1}
            styleOverrides={{ marginTop: 0, marginBottom: 0 }}
          />
          <FormProvider {...onlineBookingEditServiceTypeForm}>
            <Form
              layout="vertical"
              className="flex flex-col gap-6"
              disabled={submitting}
              onSubmitCapture={handleSubmit(onSubmit)}
            >
              <CategorySection
                title="Configuration"
                subtitle="Configure job types, technician schedules, and booking preferences to streamline your operations."
              >
                <div className="flex flex-col gap-6">
                  <OnlineBookingSectionHeader
                    title="Service Preferences"
                    description="Configure how each service type is booked and managed by assigning technicians, setting job types, and choosing scheduling preferences."
                  />
                  <ReactHookFormItem
                    control={control}
                    name="bookingType"
                    label="Booking Type"
                    required
                    errors={errors}
                    noBottomMargin
                    render={({ field }) => (
                      <>
                        <RadioButtonField
                          optionType="button"
                          {...field}
                          disabled={submitting}
                          onChange={value => {
                            // If we're switching to a request type, clear the technician selection
                            if (value.target.value === 'REQUEST') {
                              setValue('bookableTechnicianGuids', [])
                            }
                            field.onChange(value)
                          }}
                          options={ONLINE_BOOKING_TYPES.map(type => ({
                            label: OnlineBookingTypeDisplayNames[type],
                            value: type,
                          }))}
                        />
                        <FormDescription>
                          Home owners will see availability and book directly to
                          the schedule.
                        </FormDescription>
                      </>
                    )}
                  />
                  <ReactHookFormItem
                    control={control}
                    name="bookableJobTypeGuids"
                    label="Bookable Job Types"
                    required
                    errors={errors}
                    noBottomMargin
                    render={({ field }) => (
                      <SelectField
                        {...field}
                        title="Bookable Job Types"
                        mode="multiple"
                        loading={submitting}
                        values={field.value}
                        options={onlineBookingJobTypeOptions}
                      />
                    )}
                  />
                  {isInstantBookingType && (
                    <>
                      <ReactHookFormItem
                        control={control}
                        name="bookableTechnicianGuids"
                        label="Bookable Technicians"
                        required
                        errors={errors}
                        noBottomMargin
                        render={({ field }) => (
                          <SelectField
                            {...field}
                            title="Bookable Technicians"
                            mode="multiple"
                            loading={submitting}
                            values={field.value}
                            options={onlineBookingTechnicianOptions}
                          />
                        )}
                      />
                      <ThinDivider
                        widthPx={1}
                        styleOverrides={{ marginTop: 0, marginBottom: 0 }}
                      />
                      <OnlineBookingSectionHeader
                        title="Schedule Availability"
                        description="Define when your team is available for online bookings. Set available hours for each day and determine the earliest and latest times customers can book."
                      />
                      <ReactHookFormItem
                        control={control}
                        name="instantBookingWeeklySchedule"
                        errors={errors}
                        render={({ field }) => (
                          <OnlineBookingWeeklyScheduleAvailability
                            {...field}
                            serviceTypeArrivalWindowOptions={
                              arrivalWindowOptions
                            }
                          />
                        )}
                      />
                      <ThinDivider
                        widthPx={1}
                        styleOverrides={{ marginTop: 0, marginBottom: 0 }}
                      />
                      <OnlineBookingSectionHeader
                        title="Availability Settings"
                        description="Set the boundaries for for when bookings can be made."
                      />
                      <div className="flex gap-3">
                        <ReactHookFormItem
                          control={control}
                          name="earliestAvailability"
                          label="Earliest Availability"
                          required
                          errors={errors}
                          render={({ field }) => (
                            <>
                              <SelectField
                                {...field}
                                title="Earliest Availability"
                                options={ONLINE_BOOKING_EARLIEST_AVAILABLE_TIMES.map(
                                  time => ({
                                    label: time,
                                    value: time,
                                  }),
                                )}
                              />
                              <FormDescription>
                                Minimum time before the visit start that it can
                                be booked.
                              </FormDescription>
                            </>
                          )}
                        />
                        <ReactHookFormItem
                          control={control}
                          name="latestAvailability"
                          label="Latest Availability"
                          required
                          errors={errors}
                          render={({ field }) => (
                            <>
                              <SelectField
                                {...field}
                                title="Latest Availability"
                                options={ONLINE_BOOKING_LATEST_AVAILABLE_TIMES.map(
                                  time => ({
                                    label: time,
                                    value: time,
                                  }),
                                )}
                              />
                              <FormDescription>
                                Maximum time in advance that a visit can be
                                booked.
                              </FormDescription>
                            </>
                          )}
                        />
                      </div>
                    </>
                  )}
                </div>
              </CategorySection>
              <ThinDivider
                widthPx={1}
                styleOverrides={{ marginTop: 0, marginBottom: 0 }}
              />
              <CategorySection
                title="Legal"
                subtitle="Specify your company’s policies regarding service terms. "
              >
                <div className="flex flex-col gap-6">
                  <OnlineBookingSectionHeader
                    title="Terms and Conditions"
                    description="Detail your company’s policies, cancellation rules, and service guarantees to ensure transparency with your customers during the booking process."
                  />
                  <ReactHookFormItem
                    control={control}
                    name="legalBlurb"
                    errors={errors}
                    render={({ field }) => (
                      <TextAreaField {...field} placeholder="Enter text..." />
                    )}
                  />
                </div>
              </CategorySection>
              {submitElement}
            </Form>
          </FormProvider>
          {blocker.state === 'blocked' && (
            <CloseConfirmModal
              open
              onCancel={blocker.reset}
              onConfirm={blocker.proceed}
            />
          )}
        </div>
      )
    },
  )

export const OnlineBookingEditServiceTypePage = React.memo(() => {
  const serviceTypeGuid = bzExpect(
    useParams<{ serviceTypeGuid: string }>().serviceTypeGuid,
    'serviceTypeGuid',
    'Service Type ID is required',
  )

  return (
    <WithOnlineBookingServiceTypeConfig
      onlineServiceTypeConfigGuid={serviceTypeGuid}
      render={(
        serviceTypeConfig,
        companyJobTypes,
        companyTechnicians,
        companyArrivalWindowOptions,
      ) => {
        if (!serviceTypeConfig) {
          return <></>
        }

        return (
          <OnlineBookingEditServiceType
            {...serviceTypeConfig}
            companyJobTypes={companyJobTypes}
            companyTechnicians={companyTechnicians}
            companyArrivalWindowOptions={companyArrivalWindowOptions}
          />
        )
      }}
    />
  )
})
