import {
  BzDateTime,
  MaintenancePlanPaymentInterval,
  PaymentMethod,
  RenewalTypeOption,
  getBillingAnchorDayLabel,
} from '@breezy/shared'
import { Form, Radio, Select } from 'antd'
import { memo, useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import DatePicker from '../../../components/DatePicker/DatePicker'
import { BzSwitch } from '../../../elements/BzSwitch/BzSwitch'
import { Card } from '../../../elements/Card/Card'
import { DateField } from '../../../elements/Forms/DateField'
import { ReactHookFormItem } from '../../../elements/Forms/ReactHookFormItem'
import { SelectField } from '../../../elements/Forms/SelectField'
import ThinDivider from '../../../elements/ThinDivider'
import { useIsMobile } from '../../../hooks/useIsMobile'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import { ConfigureMaintenancePlanFormData } from '../configureMaintenancePlanFormSchema'
import {
  autoRenewingPlanLabel,
  fixedNumYearsPlanLabel,
  getNumImpliedYears,
} from '../MaintenancePlanV3Utils'
import { SupportedPaymentTypesSection } from './SupportedPaymentTypesSection'

interface ConfigureBillingInfoSectionProps {
  renewalTypeOption: RenewalTypeOption | undefined
  fixedDurationYearOptions: number[]
  hiddenPaymentMethods?: PaymentMethod[]
}

export const BillingInfoSectionHeader = () => {
  return (
    <div>
      <div className="mb-1 text-base font-semibold text-bz-text">
        Billing Info
      </div>
      <div className="text-md text-bz-text-tertiary">
        Configure the billing settings for this plan.
      </div>
    </div>
  )
}

export const ConfigureBillingInfoSection =
  memo<ConfigureBillingInfoSectionProps>(
    ({ renewalTypeOption, hiddenPaymentMethods, fixedDurationYearOptions }) => {
      const tzId = useExpectedCompanyTimeZoneId()
      const {
        control,
        formState: { errors },
        watch,
        setValue,
      } = useFormContext<ConfigureMaintenancePlanFormData>()
      const isMobile = useIsMobile()
      const interval = watch('billingPaymentInterval')
      const isFreePlan = watch('isFreePlan')
      const isAutoRenewing = watch('isAutoRenewing')
      const isImportedPlan = watch('isImportedPlan')
      const numFixedDurationYears = watch('numFixedDurationYears')
      const numYears = getNumImpliedYears(numFixedDurationYears)
      const numMonths = useMemo(() => numYears * 12, [numYears])

      const activationDateValue = watch('activationDate')
      const planRenewalDateValue = useMemo(() => {
        const activationDateTime = BzDateTime.fromDateString(
          activationDateValue,
          tzId,
        )
        return activationDateTime.plusYears(numYears).toDate()
      }, [activationDateValue, tzId, numYears])

      const isAutoRenewingAllowed =
        renewalTypeOption === 'AUTO' || renewalTypeOption === 'BOTH'
      const isManualRenewingAllowed =
        renewalTypeOption === 'MANUAL' || renewalTypeOption === 'BOTH'

      // If auto-renewing is not allowed, set isAutoRenewing to false
      useEffect(() => {
        if (!isAutoRenewingAllowed && isAutoRenewing) {
          setValue('isAutoRenewing', false)
        }
      }, [isAutoRenewingAllowed, isAutoRenewing, setValue])

      const durationOptions = useMemo(() => {
        const options: { value: number | undefined; label: string }[] = []
        if (isAutoRenewingAllowed) {
          options.push({ value: -1, label: autoRenewingPlanLabel })
        }
        if (isManualRenewingAllowed) {
          for (const year of fixedDurationYearOptions) {
            options.push({ value: year, label: fixedNumYearsPlanLabel(year) })
          }
        }
        return options
      }, [
        fixedDurationYearOptions,
        isAutoRenewingAllowed,
        isManualRenewingAllowed,
      ])

      return (
        <div className="grid grid-cols-1 gap-x-3 gap-y-6 lg:grid-cols-2">
          <div className="flex flex-col gap-y-6">
            <BillingInfoSectionHeader />

            <ReactHookFormItem
              data-testid="numFixedDurationYears"
              noBottomMargin
              control={control}
              errors={errors}
              name={'numFixedDurationYears'}
              label={'Plan Duration'}
              required={true}
              render={({ field }) => (
                <Select
                  {...field}
                  size={isMobile ? 'large' : 'middle'}
                  className="w-full"
                  placeholder="Select plan duration"
                  disabled={durationOptions.length === 1}
                >
                  {durationOptions.map(option => (
                    <Select.Option
                      key={option.value ?? -1}
                      value={option.value}
                    >
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              )}
              helperText={
                isAutoRenewing
                  ? 'The plan will automatically renew on the renewal date.'
                  : 'This plan will expire on the end date and won’t renew automatically.'
              }
            />

            <ReactHookFormItem
              noBottomMargin
              control={control}
              errors={errors}
              name={'billingPaymentInterval'}
              label={'Billing Frequency'}
              required={false}
              render={({ field }) => (
                <Radio.Group {...field} size={isMobile ? 'large' : 'middle'}>
                  <Radio.Button
                    value={MaintenancePlanPaymentInterval.MONTHLY}
                    disabled={!isAutoRenewing || isFreePlan || isImportedPlan}
                  >
                    Monthly
                  </Radio.Button>
                  <Radio.Button
                    value={MaintenancePlanPaymentInterval.QUARTERLY}
                    disabled={!isAutoRenewing || isFreePlan || isImportedPlan}
                  >
                    Quarterly
                  </Radio.Button>
                  <Radio.Button
                    value={MaintenancePlanPaymentInterval.YEARLY}
                    disabled={!isAutoRenewing || isFreePlan}
                  >
                    Yearly
                  </Radio.Button>
                  <Radio.Button
                    value={MaintenancePlanPaymentInterval.UPFRONT}
                    disabled={isFreePlan || isAutoRenewing}
                  >
                    One-time
                  </Radio.Button>
                </Radio.Group>
              )}
              helperText={
                isFreePlan
                  ? 'Annual plan, for free.'
                  : !isAutoRenewing
                  ? 'This plan requires a one-time payment upfront for the full duration.'
                  : `Annual plan, paid ${(
                      interval ?? MaintenancePlanPaymentInterval.MONTHLY
                    )
                      .toString()
                      .toLocaleLowerCase()}.`
              }
            />

            <ReactHookFormItem
              noBottomMargin
              control={control}
              errors={errors}
              name="billingAnchorDay"
              label="Billing Anchor Day"
              required={true}
              render={({ field }) => (
                <SelectField
                  disabled={!isAutoRenewing || isFreePlan}
                  options={Array.from({ length: 31 }, (_, i) => ({
                    label: getBillingAnchorDayLabel(
                      i + 1,
                      interval ?? MaintenancePlanPaymentInterval.MONTHLY,
                    ),
                    value: String(i + 1),
                  }))}
                  {...field}
                  size={isMobile ? 'large' : 'middle'}
                  title="Billing Anchor Day"
                  showSearch
                  optionFilterProp="label"
                />
              )}
              helperText="Payments will occur on this day according to the selected frequency (Monthly, Quarterly, Yearly)."
            />

            <ReactHookFormItem
              noBottomMargin
              control={control}
              errors={errors}
              required={true}
              name="activationDate"
              label="Plan Activation Date"
              render={({ field }) => (
                <DateField
                  {...field}
                  size={isMobile ? 'large' : 'middle'}
                  disabled={true}
                  disabledDate={current => {
                    const today = new Date()
                    today.setHours(0, 0, 0, 0)
                    return current && current < today
                  }}
                />
              )}
              helperText="The plan's benefits and visits will become activated on this date."
            />

            {/* This is not a real form item, we just use this to display the renewal date to the user.
             * The input itself is disabled */}
            {isAutoRenewing && (
              <div>
                <Form.Item
                  label="Plan Renewal Date"
                  required={true}
                  className="mb-0"
                >
                  <DatePicker
                    size={isMobile ? 'large' : 'middle'}
                    disabled={true}
                    value={planRenewalDateValue}
                    format={'MMM d, yyyy'}
                    className="w-full"
                  />
                </Form.Item>
                <div className="letter-spacing-[-0.14px] mt-[4px] text-[14px] leading-[22px] text-[#8C8C8C]">
                  The plan will renew {numMonths} months from the activation
                  date.
                </div>
              </div>
            )}
            {!isAutoRenewing && (
              <div>
                <Form.Item
                  label="Plan Expiration Date"
                  required={true}
                  className="mb-0"
                >
                  <DatePicker
                    size={isMobile ? 'large' : 'middle'}
                    disabled={true}
                    value={planRenewalDateValue}
                    format={'MMM d, yyyy'}
                    className="w-full"
                  />
                </Form.Item>
                <div className="letter-spacing-[-0.14px] mt-[4px] text-[14px] leading-[22px] text-[#8C8C8C]">
                  The plan will expire {numMonths} months from the activation
                  date and will need to be renewed.
                </div>
              </div>
            )}
          </div>
          <div>
            <Card
              className="bg-[#fafafa] shadow-none"
              noPadding
              bodyClassName="p-3"
            >
              <SupportedPaymentTypesSection
                isFreePlan={isFreePlan}
                isAutoRenewing={isAutoRenewing}
                isImportedPlan={isImportedPlan}
                hiddenPaymentMethods={hiddenPaymentMethods}
              />
              <ThinDivider
                dividerStyle="solid"
                widthPx={1}
                styleOverrides={{ marginTop: '12px', marginBottom: '12px' }}
              />
              <div className="flex flex-row justify-between gap-x-3">
                <div className="flex flex-col gap-y-[2px]">
                  <div className="text-md font-semibold">
                    Create as Free Plan
                  </div>
                  <div className="letter-spacing-[-0.14px] text-[14px] leading-[22px] text-[#898989]">
                    This will disable and reset billing settings to their
                    default values for non-billed plans.
                  </div>
                </div>
                <BzSwitch
                  disabled={isImportedPlan}
                  checked={isFreePlan}
                  data-testid="create-as-free-plan-switch"
                  onChange={value => setValue('isFreePlan', value)}
                />
              </div>
            </Card>
          </div>
        </div>
      )
    },
  )
