import { gql } from '../../generated'
import {
  AppointmentDetailsSubscription,
  CompanyScheduleInfoQuery,
  InternalEventsSubscription,
  ScheduleAppointmentFragment,
  ScheduleAssignmentsSubscription,
  UnassignedAppointmentsSubscription,
} from '../../generated/user/graphql'

gql(/* GraphQL */ `
  fragment ScheduleAppointment on JobAppointments {
    appointmentType
    appointmentWindowEnd
    appointmentWindowStart
    appointmentGuid: jobAppointmentGuid
    description
    endOfAppointmentNextSteps
    sendConfirmationEnabled
    notificationType
    sendConfirmationTo
    confirmationLastSentAt
    sendReminderEnabled
    reminderLastSentAt
    job {
      displayId
      jobGuid
      location {
        locationGuid
        address {
          addressGuid
          line1
          zipCode
          line2
          stateAbbreviation
          city
          geoLocation
        }
      }
      pointOfContact {
        fullName
        primaryPhoneNumber {
          phoneNumber
        }
      }
      jobType {
        jobClass
        name
        description
      }
      account {
        accountDisplayName
        tags {
          tag {
            name
            color
          }
        }
      }
      jobInvoices(orderBy: { invoice: { createdAt: DESC } }) {
        invoice {
          invoiceGuid
          displayId
          totalUsc
          status
        }
      }
    }
    appointmentChecklistInstances {
      appointmentChecklist {
        name
      }
      checklist
      lastEditorUserGuid
      updatedAt
    }
    cancellationStatus {
      canceled
      updatedAt
      reason
      note
    }
  }
`)

export type ScheduleAppointment = ScheduleAppointmentFragment

export const UNASSIGNED_APPOINTMENTS_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription UnassignedAppointments(
    $startDate: timestamptz!
    $endDate: timestamptz!
  ) {
    appointments: jobAppointments(
      where: {
        assignmentsAggregate: { count: { predicate: { _eq: 0 } } }
        appointmentWindowStart: { _lte: $endDate }
        appointmentWindowEnd: { _gte: $startDate }
      }
    ) {
      ...ScheduleAppointment
    }
  }
`)

export type UnassignedAppointment =
  UnassignedAppointmentsSubscription['appointments'][0]

export const SCHEDULE_ASSIGNMENTS_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription ScheduleAssignments(
    $startDate: timestamptz!
    $endDate: timestamptz!
  ) {
    jobAppointmentAssignments(
      where: {
        assignmentEnd: { _gte: $startDate }
        assignmentStart: { _lte: $endDate }
      }
    ) {
      start: assignmentStart
      end: assignmentEnd
      assignmentGuid: jobAppointmentAssignmentGuid
      technicianUserGuid
      appointmentGuid: jobAppointmentGuid
      appointment: jobAppointment {
        ...ScheduleAppointment
      }
      assignmentStatus {
        status: jobAppointmentAssignmentStatusType
      }
    }
  }
`)

export type ScheduleAssignment =
  ScheduleAssignmentsSubscription['jobAppointmentAssignments'][0]

export const INTERNAL_EVENTS_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription InternalEvents(
    $startDate: timestamptz!
    $endDate: timestamptz!
  ) {
    technicianCapacityBlocks(
      where: {
        _or: [
          { end: { _gte: $startDate }, start: { _lte: $endDate } }
          { start: { _lte: $endDate }, recurrenceRule: { _isNull: false } }
        ]
      }
    ) {
      end
      reasonDescription
      reasonType
      recurrenceRule
      recurrenceRuleExceptions
      start
      userGuids
      technicianCapacityBlockGuid
    }
  }
`)

export type InternalEvent =
  InternalEventsSubscription['technicianCapacityBlocks'][0]

export const COMPANY_SCHEDULE_INFO_QUERY = gql(/* GraphQL */ `
  query CompanyScheduleInfo($companyGuid: uuid!) {
    companyWorkingDisplayHours {
      workingEndHour
      workingStartHour
    }
    companyUsers {
      userGuid
      schedulingCapability
      user: userByUserGuid {
        firstName
        lastName
        userRoles {
          role
        }
        deactivatedAt
        userPrimaryHueHex
      }
    }
    companyConfigByPk(companyGuid: $companyGuid) {
      scheduleTechOrder
    }
  }
`)

export type CompanyScheduleInfo = CompanyScheduleInfoQuery

export const APPLY_SCHEDULE_CHANGES_MUTATION = gql(/* GraphQL */ `
  mutation ApplyScheduleChanges(
    $blocks: [TechnicianCapacityBlocksInsertInput!]!
    $deletedBlockGuids: [uuid!]!
    $canceledAppointmentGuids: [uuid!]!
  ) {
    insertTechnicianCapacityBlocks(
      objects: $blocks
      onConflict: {
        constraint: technician_capacity_blocks_pkey
        updateColumns: [
          userGuids
          start
          end
          reasonType
          reasonDescription
          recurrenceRule
          recurrenceRuleExceptions
        ]
      }
    ) {
      __typename
    }
    deleteTechnicianCapacityBlocks(
      where: { technicianCapacityBlockGuid: { _in: $deletedBlockGuids } }
    ) {
      __typename
    }
    updateJobAppointmentCancellationStatuses(
      where: { jobAppointmentGuid: { _in: $canceledAppointmentGuids } }
      _set: { canceled: true }
    ) {
      affectedRows
    }
  }
`)

export const APPOINTMENT_DETAILS_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription AppointmentDetails($appointmentGuid: uuid!) {
    jobAppointmentsByPk(jobAppointmentGuid: $appointmentGuid) {
      cancellationStatus {
        canceled
      }
      assignments {
        assignmentStatus {
          status: jobAppointmentAssignmentStatusType
        }
        assignmentEnd
        assignmentStart
        assignmentGuid: jobAppointmentAssignmentGuid
        technician {
          fullName
          userRoles {
            role
          }
          userPhoneNumbers {
            phoneNumber {
              phoneNumber
            }
          }
          userGuid
        }
      }
      job {
        equipmentTypeJobLinks {
          estimatedEquipmentAge
          equipmentType
        }
        estimates {
          displayId
          estimateGuid
          estimateOptionsAggregate {
            aggregate {
              count(distinct: false)
            }
          }
        }
        account {
          accountGuid
          accountDisplayName
          accountType
          accountLeadSource {
            attributionDescription
            companyLeadSource {
              canonicalLeadSourceName
              canonicalLeadSourceNameOverride
            }
          }
        }
        pointOfContact {
          ...JobPointOfContactFragment
        }
        summary
        tags {
          tag {
            name
          }
        }
      }
      description
    }
  }
`)

export type AppointmentDetails = NonNullable<
  AppointmentDetailsSubscription['jobAppointmentsByPk']
>

export const EQUIPMENT_TYPE_LINKS_FOR_APPOINTMENT_QUERY = gql(/* GraphQL */ `
  query EquipmentTypeLinksForAppointment($appointmentGuid: uuid!) {
    jobAppointmentsByPk(jobAppointmentGuid: $appointmentGuid) {
      job {
        equipmentTypeJobLinks {
          estimatedEquipmentAge
          equipmentType
          relationshipType
        }
      }
    }
  }
`)

export const REORDER_TECHS_MUTATION = gql(/* GraphQL */ `
  mutation ReorderTechMutation(
    $companyGuid: uuid!
    $scheduleTechOrder: [uuid!]!
  ) {
    updateCompanyConfigByPk(
      pkColumns: { companyGuid: $companyGuid }
      _set: { scheduleTechOrder: $scheduleTechOrder }
    ) {
      __typename
    }
  }
`)

export const FETCH_JOBS_SCHEDULE_QUERY = gql(/* GraphQL */ `
  query FetchAllJobsForSchedule($companyGuid: uuid!) {
    jobs(
      where: {
        companyGuid: { _eq: $companyGuid }
        jobLifecycleStatus: { specialStatus: { _isNull: true } }
      }
    ) {
      jobGuid
      account {
        accountDisplayName
      }
      jobType {
        name
        jobClass
      }
      location {
        displayName
        address {
          addressGuid
          line1
          zipCode
          city
          stateAbbreviation
          geoLocation
        }
      }
      jobLifecycleStatus {
        name
      }
    }
  }
`)
