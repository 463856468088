import {
  BzDateFns,
  formatTechnicianCapacityBlockReasonType,
  IsoDateString,
} from '@breezy/shared'
import { faCalendar } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo } from 'react'
import {
  toEventColorConfig,
  useScheduleColorMode,
} from '../../hooks/fetch/useScheduleColorMode'
import {
  useExpectedCompanyGuid,
  useExpectedCompanyTimeZoneId,
} from '../../providers/PrincipalUser'
import { DEFAULT_EVENT_COLOR_CONFIG } from '../../utils/job-utils'
import { StateSetter, useStrictContext } from '../../utils/react-utils'
import { BaseEventBox } from './BaseEventBox'
import { InternalEventCard } from './Cards/InternalEventCard'
import { DeleteEventData } from './DeleteEventModal'
import {
  BlockCalendarEvent,
  fixMbscDate,
  SchedulePageContext,
  TechnicianResource,
  usePopoverState,
} from './scheduleUtils'

type InternalEventBoxProps = {
  event: BlockCalendarEvent
  techs?: TechnicianResource[]
  start: IsoDateString
  end: IsoDateString
  setDeleteEventData: StateSetter<DeleteEventData | undefined>
  onEdit: (event: BlockCalendarEvent) => void
}

export const InternalEventBox = React.memo<InternalEventBoxProps>(
  ({ event, techs, end, start, setDeleteEventData, onEdit }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const { scheduleView } = useStrictContext(SchedulePageContext)
    const companyGuid = useExpectedCompanyGuid()
    const colorMode = useScheduleColorMode(companyGuid)

    const colorConfig = useMemo(() => {
      if (
        techs?.length === 1 &&
        techs[0].userPrimaryHueHex &&
        colorMode === 'byTech'
      ) {
        return toEventColorConfig(techs[0].userPrimaryHueHex)
      }
      return DEFAULT_EVENT_COLOR_CONFIG
    }, [techs, colorMode])

    const hasPassed = useMemo(
      () =>
        BzDateFns.isBefore(BzDateFns.parseISO(end, tzId), BzDateFns.now(tzId)),
      [end, tzId],
    )

    const [popoverOpen, setPopoverOpen] = usePopoverState()

    return (
      <BaseEventBox
        title={formatTechnicianCapacityBlockReasonType(event.reasonType)}
        icon={<FontAwesomeIcon icon={faCalendar} />}
        avatarContent={
          !techs?.length
            ? undefined
            : techs.length === 1
            ? techs[0].avatarShortString
            : techs.length
        }
        colorConfig={colorConfig}
        end={end}
        start={start}
        completed={hasPassed}
        popoverOpen={popoverOpen}
        setPopoverOpen={setPopoverOpen}
        popoverContent={
          <InternalEventCard
            start={start}
            end={end}
            event={event}
            onDelete={() => {
              setPopoverOpen(false)
              setDeleteEventData({
                event,
                occurrenceStart: fixMbscDate(event.start),
              })
            }}
            onEdit={() => {
              setPopoverOpen(false)
              onEdit(event)
            }}
          />
        }
      >
        {scheduleView !== 'DISPATCH' && techs
          ? techs.map(tech => `${tech.firstName} ${tech.lastName}`).join(', ')
          : ''}
      </BaseEventBox>
    )
  },
)
